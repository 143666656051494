import { Platform } from 'react-native'

import * as colors from './colors'
import { BASE } from './spacing'

// Default
export const defaultFontFamily = 'FuturaPT-Book'
export const defaultFontSize = 16
export const defaultLineHeight = BASE
export const defaultFontWeight = '400'

// Demi
export const book =
{...Platform.select({
  web: {fontFamily: 'futura-pt', fontWeight: '400'},
  ios: {fontWeight: '400'},
  android: {fontFamily: 'FuturaPT-Book'}
})}

// Medium
export const medium =
{...Platform.select({
  web: {fontFamily: 'futura-pt', fontWeight: '500'},
  ios: {fontWeight: '500'},
  android: {fontFamily: 'FuturaPT-Medium'}
})}

// Demi
export const demi =
{...Platform.select({
  web: {fontFamily: 'futura-pt', fontWeight: '600'},
  ios: {fontWeight: '600'},
  android: {fontFamily: 'FuturaPT-Demi'}
})}

// Light Bold
export const lightbold =
{...Platform.select({
  web: {fontFamily: 'futura-pt-bold', fontWeight: '700'},
  ios: {fontWeight: '700'},
  android: {fontFamily: 'FuturaPT-Bold'}
})}

// Bold
export const bold =
{...Platform.select({
  web: {fontFamily: 'futura-pt-bold', fontWeight: '700'},
  ios: {fontWeight: '800'},
  android: {fontFamily: 'FuturaPT-ExtraBold'}
})}

// ExtraBold
export const extrabold =
{...Platform.select({
  web: {fontFamily: 'futura-pt-bold', fontWeight: '700'},
  ios: {fontWeight: '900'},
  android: {fontFamily: 'FuturaPT-ExtraBold'}
})}

export function calculateLineHeight(fontSize) {
  return Math.ceil(fontSize / BASE) * 24
}

export const defaultTextStyle = {
  fontFamily: defaultFontFamily,
  fontSize: defaultFontSize,
  fontWeight: defaultFontWeight,
  color: colors.exisDarkCharcoal
}
