import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, } from 'react-native'
import { withRouter } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useClient, client } from '@sart/client'
import { SelectCustom, HoverableDropdownMenu, Space} from '@sart/components'
import { useContent } from '@sart/content'
import { Menu, Cross } from '@sart/icons'
import { colors, backgroundColors, typography } from '@sart/styles'
import { testID } from '@sart/utilities'

import Wrapper from '../Wrapper'
import NavbarLink from './NavbarLink'
import HomeLink from './HomeLink'
import ShoppingBagLink from './ShoppingBagLink'
import {
  isMobileOnly
} from "react-device-detect";

function Navbar(props) {
  const { history } = props

  const { cache } = useClient()
  
  const [eliotJourney, setEliotJourney] = useState(false)

  useEffect(() => {
    getEliotJourney()
  })

  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  const [openMenu, setOpenMenu] = useState(false)

  const content = useContent()
  
  const menuItems = [
    ...isMobileOnly ? [{ id: "home", label: content.navigation.home, path: '/' }] : [],
    ...isMobileOnly ? [{ id: "plans", label: content.navigation.plansPortal, path: eliotJourney ? '/hol_plans' : '/plans/'}] : [],
    ...isMobileOnly ? [{ id: "account", label: content.navigation.account, url:'https://exis.mx/cuenta/'}] : [],
    // { id: "adt", label: content.adt.adtAlert, path: '/adt' },
    // { id: "voxox", label: content.voxox.title, path: '/voxox' },
  ]
  
  function handleRouting(option) {
    setOpenMenu(!openMenu)
    if (option.path) {
      return history.push(option.path)
    } else if (option.url) {
      window.open(option.url, '_blank')
    }
  }

  return (
    <View style={styles.container}>
    <View style={styles.headerContainer}>
    <Wrapper
      wrapperStyle={styles.navigationStyle}
      style={styles.innerStyle}
    >

      <HomeLink to="/" title={content.navigation.home} {...testID("navbarHomeButton")}/>
      {!isMobileOnly && [
        <NavbarLink to="/about/" title={content.navigation.about} />,
        <NavbarLink to={eliotJourney ? '/hol_plans' : "/plans/"} title={content.navigation.plansPortal} {...testID('plansNavbar')} />,
        // <HoverableDropdownMenu
        //   label={content.adt.services} 
        //   labelStyle={styles.labelStyle}
        //   containerStyle={styles.dropdownContainerStyle}
        //   dropdownMenuContainerStyle={styles.dropdownMenuContainerStyle}
        //   menuItemStyle={styles.menuItemStyle}
        //   hoverStyle={styles.hoverStyle}
        //   menuTextStyle={styles.menuTextStyle}
        //   chevronColor={colors.white}
        //   options={menuItems}
        // />,
        <NavbarLink to="/alreadyhavesim/" title={content.navigation.alreadyHaveSim} {...testID('alreadyHaveSimNavbar')} />,
        <NavbarLink to="/port/" title={content.navigation.port} {...testID('portNavbar')} />,
        <NavbarLink url="https://exis.mx/cuenta/" title={content.navigation.account} {...testID('accountNavbar')} />,

        <View style={styles.shoppingBag}>
          <ShoppingBagLink to="/checkout" title={content.navigation.checkout} />
        </View>
        ]}
    </Wrapper>

    {!isMobileOnly &&
    <View style={styles.selectView}>
      <SelectCustom
        value={cache.language}
        options={[
          { label: content.languages.en, value: 'en' },
          { label: content.languages.es, value: 'es' }
        ]}
        onOptionChange={option => {
          client.updateLanguageInCache(option.value)
        }}
        styles={selectStyle}
    />
    </View>
    }

    {isMobileOnly &&
     [
     <View style={styles.shoppingBag}>
      <ShoppingBagLink to="/checkout" title={content.navigation.checkout} />
     </View>
     ,
      <TouchableOpacity style={styles.menuContainer} onPress={() => setOpenMenu(!openMenu)}>
        {!openMenu ? <Menu color={colors.white} size={30}/> : <Cross color={colors.white} backgroundColor={colors.exisOrange} size={30}/> }
      </TouchableOpacity>
     ]
    }

  </View>
    {isMobileOnly && openMenu &&
      <View style={styles.menu}> 
        {menuItems && menuItems.map(menuItem => (
          <TouchableOpacity style={styles.mobileMenuItemStyle} onPress={() => handleRouting(menuItem)}>
            <Text style={styles.mobileMenuTextStyle}>{menuItem.label}</Text>
          </TouchableOpacity>
        ))}
        <Space height={4} />
        <View style={styles.selectView}>
          <SelectCustom
            value={cache.language}
            options={[
              { label: content.languages.en, value: 'en' },
              { label: content.languages.es, value: 'es' }
            ]}
            onOptionChange={option => {
              client.updateLanguageInCache(option.value)
            }}
            styles={selectStyle}
          />
        <Space height={8} />
        </View>
      </View>
    }
  </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.exisOrange
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 96,
    justifyContent: 'flex-end',
    backgroundColor: backgroundColors.exisOrange,
    alignSelf: 'center',
    width: '100%',
  },
  navigationStyle: {
    justifyContent: "space-between",
    backgroundColor: backgroundColors.exisOrange,
    alignSelf: 'center',
    marginRight: isMobileOnly ? '26%' :'6%',
    width: isMobileOnly ? '80%' : '60%'
  },
  innerStyle: {
    flexDirection: 'row',
    width: '100%',

    ...(isMobileOnly ? 
      
      { //Mobile Only
        justifyContent: 'center'
      } 
      : 
      { //Non-Mobile Only
        alignItems: 'center',
        justifyContent: 'space-between',
      }
    ),
  },
  labelStyles: {
    ...typography.demi
  },
  inputStyles: {
    ...typography.medium
  },
  selectView: {
    justifyContent: 'center',
    paddingRight: isMobileOnly ? '0%' : '5%'
  },
  labelStyle: {
      fontSize: 13,
      fontWeight: '700',
      color: colors.clear,
      ...typography.bold
  },
  dropdownMenuContainerStyle: {
    ...typography.medium,
    backgroundColor: colors.white,
    borderRadius: 8,
    color: colors.white,
    position: 'absolute',
    top: 24,
  }, 
  menuItemStyle: {
    height: 35,
    width: 110,
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 8
  },
  hoverStyle: {
    height: 35,
    width: 110,
    justifyContent: 'center',
    backgroundColor: colors.exisBlue + 40,
    borderRadius: 8,
  }, 
  menuTextStyle: {
    fontSize: 13,
    fontWeight: '700',
    color: colors.black,
    ...typography.bold,
    textAlign: 'center',
  },
  menuContainer: {
    justifyContent: 'center',
    marginRight: '4%',
  },
  menuContainerOpen: {
    justifyContent: 'center',
    marginRight: '4%',
  },
  menu: {
    flex: 1,
    backgroundColor: colors.exisOrange,
    alignItems: 'center'
  },
  mobileMenuItemStyle: {
    alignItems: 'center',
    paddingVertical: 8,
    width: '100%'
  },
  mobileMenuItemHoverStyle: {
  },
  mobileMenuTextStyle: {
    textAlign: 'center',
    color: colors.white,
    ...typography.bold,
    fontSize: 16
  },
  shoppingBag: {
    ...(isMobileOnly ? 
      
      { //Mobile Only
        justifyContent: 'center',
        paddingRight: '6%'
      } 
      : 
      { //Non-Mobile Only

      }
    ),
  },
  dropdownContainerStyle: {
    paddingTop: 6,
    zIndex: 999
  }
})

const selectStyle = {
  control: (base) => ({
    ...base,
    ...typography.medium,
    minWidth: 120,
    backgroundColor: backgroundColors.exisOrange,
    borderColor: colors.exisOrange,
    color: colors.white,
  }),
  menu: (base) => ({
    ...base,
    ...typography.medium,
    minWidth: 120,
    backgroundColor: backgroundColors.exisOrange,
    borderColor: colors.exisOrange,
    color: colors.white,
  })
};

export default withRouter(Navbar)