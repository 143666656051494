import { toUpper } from "lodash";

export default {
  id: 'es',
  languages: {
    en: 'English',
    es: 'Español'
  },
  general: {
    name: 'Exis',
    businesName: 'Exis',
    channel: "SART APP",
  },
  common: {
    loading: "Cargando",
    change: 'Cambiar',
    choose: 'Elegir',
    send: "Enviar",
    sending: "Enviando...",
    continue: 'Continuar',
    processing: 'Procesando...',
    apply: "Aplicar",
    submit: "Enviar",
    submitting: "Enviando...",
    cancel: "Cancelar",
    cancelled: "Cancelado",
    back: "Regresar",
    reset: "Restablecer",
    clear: "Remover",
    delete: "Suprimir",
    remove: "Remover",
    help: '¿Necesitas ayuda?',
    save: "Guardar",
    confirm: "Confirmar",
    holName: "hol_",
    infinity: "∞"
  },
  forms: {
    titleLabel: "Título",
    titlePlaceholder: "Título",
    sex: "Género",
    sexTwo: "Sexo",
    firstNameLabel: "Nombre(s)",
    firstNamePlaceholder: "Nombre(s)",
    middleNameLabel: "Apellido Paterno",
    middleNamePlaceholder: "Apellido Paterno",
    lastNameLabel: "Apellido(s)",
    lastNamePlaceholder: "Apellido(s)",
    lastNameOptionTwo: "Apellido Materno",
    lastNameOptionTwoPlaceholder: "Apellido Materno",
    accountHolderLabel: "Titular de la Cuenta",
    IdTypeLabel: "Tipo de identificación",
    IdTypePlaceholder: "Selecciona el tipo de identificación",
    idTypePassport: "Pasaporte",
    idTypeIdCard: "INE",
    idTypeDriversLicense: "Licencia de Manejo",
    idTypeOther: "Otra",
    idNumberLabel: "Número de identificación",
    idNumberPlaceholder: "Número de identificación",
    idNumberFooter: "Los datos serán verificados por una compañía independiente",
    vatLabel: "RFC (En Caso de Requerir Factura)",
    vatPlaceholder: "RFC",
    vatFooter: "Será usado para generar tus facturas",
    dobLabel: "Fecha de nacimiento",
    dobInvalid: "Por favor ingresa una fecha de nacimiento válida (sólo mayores de 18 años)",
    dayLabel: "Día",
    dayPlaceholder: "DD",
    monthLabel: "Mes",
    monthPlaceholder: "MM",
    monthInvalid:"Mes inválido",
    yearLabel: "Año",
    yearPlaceholder: "AAAA",
    yearPlaceHolderTwoDigit: "AA",
    emailLabel: "Email",
    usernameLabel: "Usuario",
    emailPlaceholder: "Email",
    emailInvalid: "Por favor ingresa una dirección de email válida",
    emailInvalidCharacters: "Por favor ingresa una dirección de email válida \nSólo minúsculas y sin caracteres especiales",
    passwordLabel: "Contraseña",
    passwordConfirmationLabel: "Confirmación de Contraseña",
    passwordPlaceholder: "Ingresa tu Contraseña",
    passwordInvalid: "Por favor ingresa una Contraseña válida",
    usernameOrPasswordInvalid: "Usuario y/o Contraseña Incorrecta",
    forgotPassword: "¿Olvidaste tu Contraseña?",
    yearTwoCharacters: "Año debe contener 2 dígitos",
    cvvMinCharacters: "CVV debe contener al menos 3 caracteres",
    addressLabel: "Dirección de Entrega",
    addressLabelNonDelivery: "Dirección",
    address1Label: "Calle No. Ext, No. Int",
    address1Placeholder: "Calle No. Ext, No. Int",
    address2Label: "Colonia",
    address2Placeholder: "Colonia",
    cityLabel: "Alcaldía/Municipio",
    cityPlaceholder: "Alcaldía / Municipio",
    countyLabel: "Estado",
    countyPlaceholder: "Estado",
    postcodeLabel: "Código Postal",
    postcodePlaceholder: "Código Postal",
    countryLabel: "País",
    countryPlaceholder: "País",
    phoneNumberLabel: "Número telefónico de contacto",
    phoneNumberPlaceholder: "Teléfono al que le enviarás regalo",
    customerDetailsPhonePlaceholder: "Ingresa tu Número Telefónico",
    phoneNumberInvalid: "Número telefónico inválido",
    verificationCodeInvalid: "Código de verificación Inválido",
    companyLabel: "Compañía",
    companyPlaceholder: "Compañía (opcional)",
    genderLabel: "Género",
    genderPlaceholder: "Seleccionar",
    genderSubtext: "Para estudios de mercado",
    editDetails: "Editar Datos",
    nameOnCardLabel: "Nombre en la Tarjeta",
    nameOnCardPlaceholder: "Ingresa el Nombre en la Tarjeta",
    cardNumberLabel: "Número de Tarjeta",
    cardNumberPlaceholder: "0000 0000 0000 0000",
    cardNumberInvalid: 'El número en la Tarjeta de Crédito es Inválido',
    cvvLabel: "CCV",
    cvvPlaceholder: "CVC/CVV",
    saveCardLabel: "Guardar datos de la tarjeta",
    saveCardSubtext: "Esta se convertirá en la tarjeta de pago para compras recurrentes",
    removeCardTitle: "Remover datos de la tarjeta",
    editPayment: "Editar Pago",
    isRequired: '{label} es requerido',
    required: 'Requerido',
    regionLabel: "Código de Área",
    regionPlaceholder: "Selecciona la región de tu número EXiS",
    helpPoint: "Este Código de área se va a utilizar en tu nuevo número EXiS",
    dataObtain: "Datos para obtener tu CURP",
    validCurp: "Se requieren 18 caracteres alfanuméricos",
    uppercase: "Deben ser solo caracteres en mayúscula",
    deliveryInfo: "En caso de que todavía no tengas tu SIM esta será la dirección a la cual te será enviada."
  },
  storedPayment: {
    title: "Método de Pago",
    selectPayment: 'Seleccionar Método de Pago',
    storedCard: "Tarjeta Almacenada"
  },
  navigation: {
    dashboard: "Inicio",
    dashboardBack: "Inicio",
    minutes: 'Minutos',
    data: 'Datos',
    texts: 'Textos',
    topup: "Recarga",
    addons: "Extras",
    profile: "Cuenta",
    bill: 'Cuenta',
    menu: "Menú",
    menuBack: "Menú",
    payment: "Pago",
    paymentPortal: "Pago",
    checkout: "Finalizar compra",
    confirmation: "Confirmación",
    receipt: "Actualizar número de recibo",
    logout: "Cerrar Sesión",
    home: "Inicio",
    about: "Acerca de",
    plans: "Planes",
    plansPortal: "Planes",
    alreadyHaveSim: "¿Ya tienes una SIM?",
    port: "Portabilidad",
    termsAndConditions: "Términos y Condiciones",
    termsAndConditionsCaps: "TÉRMINOS Y CONDICIONES",
    dataProtectionCaps: "AVISO DE PRIVACIDAD Y PROTECCIÓN DE DATOS",
    privacy: "Política de privacidad",
    accessibility: "Accesibilidad",
    cookies: "Política de Cookies",
    shipping: 'Envío',
    support: 'Soporte',
    contact: 'Contáctanos',
    faq: 'Preguntas Frecuentes',
    siteMap: "Mapa del Sitio",
    lostFoundAndStolen: "Robo, Daño o Extravío",
    loginAsAgent: "Ingresar como Agente",
    loginAsAmbassador: "Ingresar como Embajador",
    customer: 'Cliente',
    review: 'Evaluar',
    backToShop: "Regresar a Tienda",
    returnHome: "Regresar a Inicio",
    seeHelp: "Ver sección de Ayuda",
    switch: 'Portabilidad',
    identification: 'Editar Datos Personales',
    address: 'Editar Dirección',
    swap: 'Intercambiar',
    coverage: "Cobertura",
    compatible: "Dispositivos compatibles",
    webAndApp: "USO DE WEB Y APP",
    activateSIM: "Activa tu SIM",
    deleteProfile: "Eliminar Cuenta",
    eSIM: "eSIM",
    selfcare: "Administra tu Cuenta",
    account: "Cuenta"
  },
  socialMedia: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    youtube: 'YouTube'
  },
  welcome: {
    title: "Bienvenido a EXiS",
    instructions: "Estás iniciando un nuevo camino.\n Comencemos",
    action: "Regístrate",
    footer: "¿Necesitas ayuda?"
  },
  unavailable: {
    title: "Servidor No Disponible",
    instructions: "El servidor no está respondiendo.\n\n Es posible que necesites actualizar tu App EXiS\n a la versión más reciente.\n\n Intenta de nuevo con tu App EXiS actualizada.\n\n Si el problema persiste, revisa si estás conectado \na la Red en configuraciones.",
    action: "Intenta de nuevo"
  },
  contactus: {
    header: "Contacto",
    subheader: "Tranqui, aquí te \nayudamos a resolverlo",
    faqs: "La respuesta que buscas puede estar en ",
    faqsSecondLine: "nuestra sección de ",
    faq: "Preguntas Frecuentes.",
    support: "Atención y Soporte a Clientes 24 horas",
    telephoneNumber: "55 8996 0111",
    internetNumber: "55 8996 0060",
    number: "*11",
    telephone: "Telefonía",
    internet: "Internet",
    exisNumber: "Desde tu Línea EXiS",
    whatsapp: "Contactar vía WhatsApp",
    email: "Contactar vía Email",
  },
  login: {
    title: "Bienvenido nuevamente",
    instructions: "Por favor inicia sesión para administrar tu cuenta",
    action: "Inicia Sesión",
    forgottenPassword: "¿Olvidaste tu contraseña?",
    signUpLabel: "¿No tienes una cuenta?",
    signUpLink: "  Regístrate",
    inProgress: "Ingresando...",
    validateToken: "Contraseña Invalid",
    actionLost: "Enviar",
    inProgressLost: "Enviando",
    actionPort: "Portar",
    inProgressPort: "Portando",
  },
  registerHome: {
    title: 'Comencemos',
    login: 'Inicia sesión con tu cuenta existente',
    newSIM: 'Registra una sim nueva',
    register: 'Regístrate en la app con tu número EXiS'
  },
  register_number: {
    title: "Regístrate",
    instructions: "Ingresa el teléfono asociado\n con esta tarjeta SIM, por favor",
    note: "Te enviaremos un código de verificación",
    action: "Enviar",
    inProgress: "Enviando...",
    validateErrorMessage: "Por favor ingresa una dirección de correo válida \nSólo caracteres en minúscula y sin acento",
    phoneNumberLabel: "Número Telefónico",
    phoneNumberPlaceholder: "Ingresa tu teléfono",
    phoneNumberPlaceholderTwo: "Ingresa Número Telefónico",
    emailLabel: "Dirección de Email",
    emailPlaceholder: "Ingresa tu email",
    emailSubtext: "Este será tu nombre de usuario",
    numberLength: 'El número debe tener 10 dígitos',
    numberDigits: 'El número sólo debe tener números',
    validNumber: 'Número Inválido'
  },
  register_new_customer: {
    signup: 'Registra tu SIM',
    noEXiSNumber: '¿Aún no tienes número?',
    getOneHere: ' Consigue uno aquí',
    buttonTitle: 'Activar Cuenta',
    buttonSubmitting: 'Configurando Tablero',
    giveUs5Minutes: 'La activación de tu cuenta puede tardar hasta 5 minutos. \nSi en el tablero no ves los datos actualizados, \nespera unos minutos y recarga la pantalla jalándola hacia abajo',
    registerEXiSNumber: "Si ya tienes un número EXiS,",
    register: "regístrate",
    end: "para administrar tu cuenta",
    registerCaps: "Regístrate",
    unrecognisedId: "El tipo de documento no se reconoce",
    withSIM: "con tu SIM EXiS",
    tryAgainLater1: "¡Ooops! Parece que tu registro está tardando más de lo esperado. \n\nMándanos un WhatsApp \nal",
    tryAgainLaterNumber: "+52 449 676 0000",
    tryAgainLater2: "\npara que podamos solucionarlo\n o márcanos al +52 55 8996 0111",
    backToLogin: 'Volver al inicio de sesión'
  },
  camera_components: {
    cancel_scanning: 'Cancelar Escaneo',
    loading_camera: 'Cargando Cámara',
    no_access: 'Sin acceso a la cámara'
  },
  verify_number: {
    title: "Verificación",
    instructions: "Recibirás un mensaje de texto y un correo electrónico\n con un código de verificación.",
    action: "Verificar",
    inProgress: "Verificando...",
    verificationCodeLabel:"Código de Verificación",
    verificationCodePlaceholder: "Ingresa el código",
    resendLabel: "Reenviar",
    cancel: "Cancelar",
    resendMessageTemplate: "RESEND-AUTHCODE"
  },
  register_details: {
    title: "Registro",
    instructions: "Completa tus datos personales",
    dob: "Fecha de Nacimiento",
    action: 'Continuar',
    firstName: "Nombre",
    middleName: "Apellido Paterno",
    lastName: "Apellido Materno",
    postcode: "Código Postal",
    day:"DD",
    month:"MM",
    year: "AAAA",
    genderLabel:"Género",
    genderPlaceholder:"Seleccionar género",
    genderSubtext:"Para estudios de mercado",
    cancel:"Cancelar",
    noSpecialCharacters: 'El nombre no debe tener ningún caracter especial',
    validPostcode: 'Código postal debe de contener 5 dígitos',
    invalidDay: 'Día es inválido',
    invalidMonth: 'Mes debe de ser entre 1-12',
    invalidYear: 'Debes ser mayor de edad para adquirir una cuenta',
    invalidYear2: 'No se permite la edad superior a 100 años',
    dayLength: 'El Día sólo puede tener como máx. 2 dígitos',
    monthLength: 'El Mes sólo puede tener como máx. 2 dígitos',
    yearLength: ' El año debe de tener un máximo de 4 dígitos',
    dayRequired: 'Día es necesario',
    monthRequired: 'Mes es necesario',
    yearRequired: 'Año es requerido',
    male: 'Hombre ',
    female: 'Mujer',
    preferNotToSay: 'Prefiero no decirlo'
  },
  dashboard: {
    title: "Tablero",
    instructions: "¡Llegamos al tablero de control! ¡Hurra!",
    action: "Desconectar",
    footer: "¿Necesitas Ayuda?",
    setup: 'Configuración',
    setupDescription: 'Configura un plan recurrente para que nunca te quedes sin servicio.',
    texts: 'Textos',
    data: 'Datos',
    minutes: 'Minutos',
    pendingPayment: 'Pago Pendiente',
    description: 'Descripción',
    cashPurchase: 'Compra con Efectivo',
    payUntil: 'Pagar Hasta',
    buttonTitle: 'Mostrar Código de Pago',
    button: 'Recarga',
    planDescription: '¡Simplifica tu vida! Configura aquí tu plan recurrente',
    gift: "REGALO",
    cancelDescription: "¿Estás seguro que deseas cancelar tu plan recurrente?",
    cancelSuccessful: "ha sido cancelado exitosamente",
    cancelFailed: "cancelación fallida",
    exp: "Exp: "
  },
  balance: {
    loading_title: 'Cargando',
    loading_legend: 'Esperando al servidor',
    postpaid_title: 'Estimado',
    postpaid_legend: 'Para este Mes',
    prepaid_title: 'Saldo',
    prepaid_legend: 'Servicio Activo',
    active: 'Servicio activo',
    disabled: 'Servicio Deshabilitado',
    unlimited: 'Ilimitados'
  },
  tariff: {
    title: 'Tarifa',
    prepaid: 'Prepago',
    postpoad: 'Mensual'
  },
  topup: {
    title: 'Recarga',
    instructions: '¿Cuánto quieres recargar?'
  },
  addons: {
    title: 'Extras',
    instructions: 'Escoge complementos extra',
    adtTitle: 'ADT Extras',
    adtDescription: 'Suscríbase a los servicios de ADT',
    startingFrom: 'Desde',
    adtInstructions: 'Escoge complementos extra'
  },
  adt: {
    services: "Servicios",
    adtAlert: "ADT Alert"
  },
  voxox: {
    title: "Voxox"
  },
  profile: {
    title: 'Perfil',
    instructions: 'Las preferencias de tu cuenta',
    accountLabel: 'Resumen de tu perfil',
    settingsLabel: 'Configuración del Servicio',
    switchLabel: 'Portabilidad',
  },
  profile_summary: {
    description: 'Modifica tu información',
    id: 'Identificación',
    idDescription: 'Tus datos',
    idEdit: 'Edita tus datos',
    address: 'Dirección',
    addressDescription: 'Tu dirección',
    addressEdit: 'Editar dirección',
    payment: 'Pago',
    paymentDescription: 'Tu método de pago recurrente',
    paymentEdit: 'Editar forma de pago',
    deleteProfile: 'Eliminar Cuenta de Usuario'
  },
  profile_identification: {
    description: 'Edita la información de tu identificación',
    noSpecialCharacters: 'El nombre no debe tener carateres especiales',
    rfcValid: 'El RFC debe tener 12–13 caracteres alfanuméricos',
    title: 'Editar Identificación'
  },
  profile_address: {
    description: 'Edita la información de tu Dirección',
    noSpecialCharacters: 'La dirección no debe contener caracteres especiales',
    validPostcode: 'El Código Postal debe tener 5 dígitos',
    title: 'Editar Dirección',
    submit: 'Guardar'
  },
  profile_payment: {
    updateSuccess: '¡Listo! Tu tarjeta terminación',
    updateSuccessTwo: "ha sido guardada",
    updateFailed: 'Unfortunately updating your saved card has failed, please try again.',
    action: 'Regresa a tu perfil'
  },
  profile_delete: {
    description: '¿Estás seguro que quieres eliminar tu cuenta de usuario?',
    point1: 'Tu cuenta de usuario e información se eliminarán en EXiS App. Ya no podrás iniciar sesión hasta que te registres de nuevo usando tu número EXiS',
    point2: 'La eliminación de tu cuenta de usuario de EXiS App no cancela tus servicios EXiS.',
    point3: 'Para cancelar servicios marca *11.',
    submit: 'Eliminar Cuenta de Usuario'
  },
  profile_deleted: {
    title: 'Cuenta de usuario eliminada',
    description: 'Tu cuenta de usuario de EXiS App está siendo eliminada.\n\nEste proceso puede tardar unos minutos. \nLamentamos que no quieras seguir utilizando la App. Si cambias de opinión, puedes registrarte de nuevo en cualquier momento y recuperar el acceso a tu cuenta EXiS.',
    action: 'Cerrar Sesión'
  },
  service_settings: {
    description: 'Modifica tus configuraciones del servicio',
    title: 'Configuraciones',
    numberNotValid: 'Number must be 10 digits',
    invalidPAC: 'Check you have entered a valid PAC code',
    invalidNumber: 'Number not valid',
    portInProduct: 'Port-In Product not Found',
    switch: 'Switch',
    pacInvalid: 'Number must be 4 digits'
  },
  switch_number: {
    description: 'Cambia tu línea a EXiS',
    numberNotValid: 'El número debe tener al menos 10 caracteres',
    invalidPAC: 'Revisa que hayas ingresado un NIP válido',
    invalidNumber: 'Número inválido',
    portInProduct: 'Port-In Product not Found',
    switch: 'Portar',
    pacInvalid: 'El número debe tener 4 dígitos'
  },
  set_password: {
    title: "Crear Contraseña",
    instructions: "Esta contraseña será usada \n para accesos futuros a tu cuenta",
    action: 'Continuar',
    inProgress: "Enviando",
    passwordLabel: "Contraseña",
    passwordPlaceholder: "Ingresar Contraseña",
    passwordConfirmLabel: "Confirmación de Contraseña",
    passwordConfirmPlaceholder: "Confirmación de Contraseña",
    passwordsMatchError: "Las contraseñas no coinciden",
    passwordRequirements: "Mínimo 8 caracteres \n1 Minúscula \n1 Mayúscula \n1 Número",
    passwordRequirementsError: "Las contraseñas deben tener: \n Mínimo 8 caracteres \n1 Minúscula \n1 Mayúscula \n1 Número"
  },
  forgotten_password: {
    title: "Contraseña Olvidada",
    instructions: "Ingresa la dirección de Email \n asociada con la cuenta, por favor",
    note: "Te enviaremos un código de verificación",
    action: "Enviar",
    inProgress: "Enviando...",
    validateErrorMessage: "Por favor ingresa una dirección de email válida \n Sólo minúsculas y sin caracteres especiales",
    emailLabel: "Dirección de Email",
    emailPlaceholder: "Ingresa tu email"
  },
  forgotten_password_auth: {
    title: "Verificación",
    instructions: "Recibirás un mensaje de texto \n con un código de verificación",
    action: "Verificar",
    inProgress: "Verificando...",
    verificationCodeLabel:"Código de Verificación",
    verificationCodePlaceholder: "Ingresa el Código de Verificación",
    resendLabel: "Reenviar",
    resendLabelActive: "Reenviando...",
    cancel: "Cancelar",
    resendMessageTemplate: "FORGOTTEN-PASSWORD"
  },
  forgotten_password_new_password: {
    title: "Restablecer Contraseña",
    instructions: "Esta contraseña será usada \n para accesos futuros a tu cuenta",
    action: 'Continuar',
    actionInProgress: "Continuando",
	  inProgress: "Enviando...",
    passwordLabel: "Contraseña",
    passwordPlaceholder: "Ingresar Contraseña",
    passwordConfirmLabel: "Confirmación de Contraseña",
    passwordConfirmPlaceholder: "Confirmación de Contraseña",
    validateErrorMessage: "Las contraseñas no coinciden"
  },
  bill_item: {
    title: "Pagos",
    vat_included: "(Incl. IVA)",
    due_date: "Próximo corte",
    over_due: "Pago atrasado",
    pay_bill: "Pagar Cuenta"
  },
  pay_bill: {
    title: "Pagar Cuenta",
    balance: "Detalle de saldo",
    loading: "Cargando...",
    fullAmount: "Monto Total",
    partialAmount: "Monto Parcial",
    fullAmountDescription: "Paga el monto total y renueva tu cuota de datos.",
    partialAmountDescription: "Pagar parcialmente podría pasar tu saldo restante a la siguiente factura y podría generar cargos por pago tardío.",
    partialPaymentLabel: "Ingresa otra cantidad ($)",
    partialAmountPlaceholder: "Ingresa cantidad",
    makingPayment: "Haciendo Pago...",
    buttonApology: "Pago aún no disponible",
    apologyError: "Cannot make payment yet, story VAD-58 is not complete, sorry :(",
    invalidAmount: "No puedes pagar más del saldo pendiente",
    tooLow: "No puedes pagar menos de $1"
  },
  payment_method: {
    title: "Método de Pago",
    instructions: "Por favor ingresa tus datos de pago",
    storedPaymentInstructions: "Paga la orden usando tu tarjeta almacenada",
    creditCard: "Tarjeta de Crédito / Débito",
    creditCardInstructions: "Transferencia de dinero segura usando tu cuenta bancaria. Mastercard, Visa, American Express.",
    cash: "Pago en Tienda",
    cashInstructions: "Se generará un código de pago, llévalo a tu OXXO más cercano. Una vez pagado procesaremos tu pedido.",
    balance: "Saldo",
    balanceInstructions: "Compra usando tu saldo disponible.",
    bill: "Añadir a Cuenta",
    billInstructions: "Añade el monto del pago a tu próxima facturación.",
    yearValid: 'El año debe ser válido',
    cvvValid: 'El CVC/CVV debe ser válido',
    refundedPayment: "Haremos un cargo temporal por",
    refundedPaymentTwo: " y guardaremos los detalles de la tarjeta.",
    saveCardDetails: "Guardar la tarjeta"
  },
  add_on_checkout: {
    title: 'Comprar',
    recurringTitle:'Hazlo recurrente',
    recurringText:"Haz este producto recurrente para que no tengas que recordarlo cada mes.",
    submit: "Enviar",
    submitting: "Enviando...",
    submitFailedButton: 'La Compra No ha sido Posible...',
    submitFailedFakeError: 'Purchasing not available until Steven gets the basket flow done!',
    cancel: 'Cancelar'
  },
  payment_confirm: {
    done: "¡Listo!",
    almostDone: "¡Casi listo!",
    success: "¡Pago exitoso!",
    cashSuccess: "¡Orden exitosa!",
    title: "¡Pago Confirmado!",
    action: "Regresa al Inicio",
    topUp: "Has recargado exitosamente tu saldo por",
    addOn1: "Has añadido: ",
    addOn2: " a tu servicio.",
    payBill1: "Ahora has pagado ",
    payBill2: " tu saldo.",
    cash1: "Gracias por tu compra, tu referencia es: ",
    cashDescription: "Lleva este código de barras a un OXXO para completar la transacción. Puedes tomar una captura de pantalla o usar el correo electrónico que recibirás con la información de la compra.",
    currencyCode: "MX",
    saveAndReturn: "Regresa al Inicio",
    adtInstruction: "Inicie sesión en el sitio web de ADT y siga las instrucciones"
  },
  paymentOptions: {
    title:"Opciones de Pago",
    instructions: "Selecciona lo que te gustaría hacer: "
  },
  methods:{
    cash: "Efectivo",
    balance: "Saldo en Prepago",
    bill: "Añadir a Factura"
  },
  menu: {
    language: 'Idioma',
    whoMayUseThisService: '¿Quién puede usar este servicio?',
    privacy: 'Privacidad',
    contenTonService: 'Contenido de este servicio',
    usingTheServices: 'Uso de los servicios',
    disclaimer: 'Declaración de Términos',
    general: 'General',
    voicemailStatus: "Procesando",
    orderFailed: 'Pedido Fallido',
    gift: 'Transferir',
    swap: 'Transformar',
    giftLabel: 'Regalar',
    swapLabel: 'Transformar',
    transform: "Transformar",
    termsAndConditions: 'Uso de Web y App',
    privacy: "Aviso de Privacidad y Protección de Datos",
    faqs: 'Preguntas Frecuentes',
    preferences: 'Preferencias',
    help: "Ayuda"
  },
  transfer: {
    transferFooter: 'Busca el número telefónico',
    giftTo: 'Regalar',
    giftAmount: 'Cantidad a Transferir',
    send: 'Regalar',
    to: 'al',
    button: 'Regalar'
  },
  home: {
    title: 'Aquí tú tienes el control.',
    action: 'Conoce los planes',
    firstPoint: "Descarga e instala la app",
    secondTitle: "Contrata fácilmente.",
    button: "Comienza el proceso",
    subheader: "Somos una compañía dedicada a mantener a todos conectados de una forma sencilla y sin complicaciones. Contamos con 2 servicios, conócelos:",
    maintenance: "We are currently undergoing maintenance"
  },
  about: {
    header: "EXiS es Telefonía \nMóvil de alta \nvelocidad 4G.",
    subheader: "Centramos el servicio en ti, un usuario que quiere tener el control de sus planes desde una app. Modifica tus datos, minutos, SMSs consumidos y haz pagos desde la palma de tu mano.",
    buttonText: "Conoce los Planes"
  },
  plans: {
    header: "Elige el mejor plan para ti",
    subheader: "Sin contratos, cancela en cualquier momento",
    single: "Sencillo",
    recurring: "Recurrente",
    singleMobile: "Sencillo",
    singleWeb: "Sencillo",
    recurringWeb: "Recurrente",
    link: "Verifica que tu dispositivo sea compatible",
    checkout: "Revisa",
    termsAndConditions: "términos y condiciones",
    forMoreDetails: "para más detalles",
    eliotHeader1: "ELIGE EL MEJOR",
    eliotHeader2: "PLAN",
    eliotHeader3: "PARA TI"
  },
  plan: {
    header: "Características del plan",
    subheader: "Llamadas y textos ilimitados incluidos",
    
  },
  planitem: {
    action: "Elegir",
    contract: "{validity} {validityUnit}{recurring}",
    day: "Día",
    days: "Días",
    week: "Semana",
    weeks: "Semanas",
    month: "Mes",
    months: "Meses",
    year: "Año",
    years: "Años",
    autorenew: "Renovación Automática",
    allowsGiftSwap: "Permite regalar/transformar",
    minutes: "Minutos",
    eliotDescription: "¡OBTENDRÁS CONTENIDOS EXCLUSIVOS PARA TI \nDE ELIOT MEDIA!",
    eliotLink: "(da click para conocer todos los beneficios)",
    inAdditionTo: "ADEMÁS DE:",
    portacredencial: "Portacredencial (No aplica en eSIM)",
    whatsappExclusive: "Whatsapp exclusivo con Eliot",
    contentCreated: "Contenidos creados para la",
    community: "Comunidad",
    hol: "hol_",
    stickers: "Stickers de whatsapp inéditos",
    wallpapers: "Fondos de pantalla y descargables increíbles",
    plusBenefits: "Más todos los beneficios por ser parte de la",
    unlimitedData: "GB ILIMITADOS"
  },
  alreadyHaveSim: {
    header: "Activa tu SIM",
    subheader: "Ingresa los primeros 19 dígitos del número de serie SIM de 20 carateres (Sólo números).",
    charactersRequired: "Por favor ingresa 19 dígitos exactamente",
    validSIM: 'Por favor revisa que hayas ingresado el número SIM correctamente',
    checkSIM: 'Revisa que hayas ingresado un número SIM válido',
    label: 'Número de SIM',
    placeholder: 'Ingresa número de SIM',
    getSIM: '¿No tienes una SIM? ¡Obtén una aquí!',
    withoutLetter: "Sólo los 19 dígitos, sin la letra",
    valid: "Por favor ingresa tu número de SIM",
    eSIM1: "Si tienes una SIM física pero prefieres una eSIM, realiza la selección de tu plan",
    eSIM2: "aquí",
    eSIM3: "sin registrar \nla SIM que tienes en mano y selecciona eSIM \nal llenar tus datos en el carrito de compra."
  },
  port: {
    header: "Portabilidad",
    subheader: "¿Quieres tener el control?",
    thirdHeader: "Cambiar tu línea a EXiS es súper sencillo. Sólo llena este formulario.",
    oldNumberLabel: "Teléfono",
    oldNumberPlaceholder: "10 dígitos",
    oldNumberLabelTwo: "Número que quieres conservar",
    pacCodeLabel: "NIP",
    pacCodePlaceholder: "Ingresa tu NIP",
    pacCodeFooter: "Proporcionado por tu proveedor anterior",
    confirmationTitle: "Portabilidad en proceso",
    confirmationInstruction: "Hemos comenzado tu proceso de portabilidad, \n puedes experimentar desconexión temporal mientras cambiamos \ntu número, puede tomar hasta 48 horas",
    enterDetails: "Ingresa tus datos",
    yourNames: "Tu(s) Nombre(s)",
    names: "Nombre(s)",
    forename: "Primer Apellido",
    surname: "Segundo Apellido",
    fathersName: "Apellido paterno",
    mothersName: "Apellido materno",
    curp: "CURP",
    curpPlaceholder: "Clave Única de Registro de Población",
    curpFooter: "¿No te sabes tu CURP? Te ayudamos",
    stateLabel: "¿En qué Estado naciste?",
    stateFooter: "Selecciona 'Ciudad de México' si naciste en el D.F.",
    federalDistrict: "CIUDAD DE MÉXICO",
    curpButton: "Buscar mi CURP",
    helpPoint: "Para obtenerlo, manda un SMS con la palabra NIP al 051 usando la SIM originalmente asociada al número que deseas portar.",
    noID: 'Si no tienes CURP o prefieres usar otra identificación, comunícate al 55 8996 0111 para terminar el trámite de portabilidad.'
  },
  lost: {
    header: "Robo, daño o extravío",
    subheader: "Pausa tu servicio y obtén una SIM de reemplazo",
    reasonLabel: 'Motivo',
    reasonPlaceholder: 'Selecciona tu motivo',
    reasonLost: 'Extravío',
    reasonStolen: 'Robo',
    reasonDamaged: 'Daño',
    reasonOther: 'Otro',
    confirmationTitle: "Cuenta suspendida",
    confirmationInstruction: "Todo va a estar bien. \nTu servicio ha sido temporalmente cancelado, \nse te enviará una nueva SIM. \nUna vez que la actives tu cuenta se reanudará."
  },
  help: {
    title: "Tranqui, aquí te ayudamos a \nresolverlo",
    header: "Preguntas Frecuentes",
    subheader: "Navega por nuestras preguntas frecuentes y sus respuestas",
    learnAboutUs: "Conoce más acerca de nosotros",
    learnAboutUsDescription: "EXiS es una compañía dedicada a mantener a todos conectados de una forma sencilla y sin complicaciones.",
    contactCallCenterTitle: "Contactar a Atención al Cliente",
    contactWhatsAppTitle: "Contáctanos por WhatsApp",
    visitWebsite: "Visita nuestro sitio web",
    cancel:"Marca *11 para eliminar/cambiar tu cuenta"
  },
  legal: {
    header: "Información Legal",
    headerCaps: "LEGAL",
    subheader: "Explora nuestros términos y condiciones legales",
  },
  checkout: {
    header: "Comprar",
    customerInformation: "Información del Cliente",
    paymentMethod: "Método de Pago",
    review: "Examinar",
    processing: "Procesando",
    summary: "Resumen",
    cartIsEmpty: "Tu carrito está vacío",
    emptyCart: "Vaciar Carrito",
    discount: "Descuento",
    shipping: "Envío",
    free: "Gratis",
    tax: "IVA",
    subtotal: "Subtotal",
    total: "Total",
    purchase: "Comprar",
    orderProcessing: "Procesando tu pedido...",
    orderProcessingFailed: "Tu pedido falló. ¡No te preocupes! No hemos realizado ningún cargo a tu tarjeta.",
    orderProcessingFailedDescription: "Por favor intenta nuevamente y si todavía recibes este error, comunícate desde cualquier línea al 55 8996 0111, o desde tu línea EXiS al *11. También puedes enviarnos un correo electrónico a contacto@exis.mx para apoyarte.",
    orderPendingShippingNoSim: "¡Gracias por tu orden!",
    orderPendingShippingWithSim: "¡Gracias por tu compra!",
    orderProcessedShortly: "Tu orden será procesada en breve",
    orderPendingShippingInfoNoSim: 'Tu SIM te llegará pronto por mensajería,\n cuando llegue podrás configurar tu cuenta (revisa tu email para más detalles),\n mientras tanto descarga la app, tu llave para todo el control de tu cuenta EXiS.',
    orderPendingShippingInfoeSim: 'Tu eSIM llegará en un momento al correo electrónico que registraste. También te estaremos enviando un correo \ncon los detalles de tu cuenta (revisa tu email en bandeja de entrada y SPAM... por si las dudas). \n\n Necesitas descargar la app, tu llave para todo el control de tu cuenta EXiS:',
    orderPendingShippingInfoeSimHol1: 'Si has elegido una SIM física, te llegará en breve por mensajería. Sigue las instrucciones del correo electrónico que recibirás para configurar tu cuenta (revisa tu bandeja de entrada y la carpeta de SPAM... por si acaso). \n\nSi has solicitado una eSIM, recibirás el código QR en la bandeja de entrada de tu correo electrónico. ¿Necesitas ayuda para activarla? Consulta ',
    orderPendingShippingInfoeSimHol2: 'aquí',
    orderPendingShippingInfoeSimHol3: '\n\nDescarga la App EXiS desde los siguientes enlaces para que puedas activar y configurar tu cuenta ',
    orderPendingShippingInfoeSimHol4: 'hol_:',
    orderPendingShippingInfoeSimHol5: '',
    orderPendingShippingInfoeSimHol6: 'hol_:',
    orderPendingShippingInfoNoSimHol: 'Tu SIM te llegará pronto por mensajería, cuando llegue podrás configurar tu cuenta (revisa tu email para más detalles), mientras tanto descarga la',
    orderPendingShippingInfoNoSimHol2: ' App EXiS, ',
    orderPendingShippingInfoNoSimHol3: 'tu llave para todo el control de tu cuenta',
    orderPendingShippingInfoNoSimHol4: ' hol_.',
    orderPendingShippingInfoWithSim1: 'Para activar tu servicio, consulta la siguiente liga\n',
    orderPendingShippingInfoWithSim2: 'https://www.youtube.com/@exis_mx\n\n',
    orderPendingShippingInfoWithSim3: 'Recibirás un email con los detalles. \n(revisa tu email en bandeja de entrada y SPAM... por si las dudas) \nSi te registraste con eSIM, ',
    orderPendingShippingInfoWithSim4: 'aquí',
    orderPendingShippingInfoWithSim5: ' puedes encontrar instrucciones \npara activarlo en tu dispositivo.',
    orderPendingShippingInfoWithSim6: '\n\nDescarga la app desde los siguientes enlaces para que puedas\n activar y configurar tu cuenta:',
    orderPendingShippingInfoWithSimHol: 'Revisa tu email para más detalles. Descarga la App EXiS desde los siguientes enlaces para que puedas activar tu SIM y configurar tu cuenta.',
    orderPendingShippingInfo: 'Tu SIM ha sido pedida, \n recibirás un email una vez que haya sido enviada. \n Una vez que esté sincronizada podrás configurar tu cuenta, \n mientras tanto puedes descargar la app desde los siguientes enlaces:',
    orderPendingPairing: "Tu orden será procesada en breve",
    orderPendingPairingInfo: 'Tu SIM se sincronizará pronto, \n una vez que esté sincronizado podrás configurar tu cuenta, \n mientras tanto puedes descargar la app desde los siguientes enlaces:',
    orderStillProcessing: 'Your order is taking longer than usual to process.\n It is still processing and you will receive an email when it is done.\n Please check back in 2 hours and if your order has still not completed then contact the call centre on *11',
    receiptWanted: "¿Necesitas factura?",
    changeRFC: "Cambiar RFC",
    noRFC: "Sin número de RFC",
    total: "Total",
    numberDigits: 'El número dólo debe contener dígitos',
    numberLength: 'El número debe ser de 10 dígitos',
    referalCode: "Ingresa tu código de descuento",
    saveCardSpanish: "Guarda los detalles de tu tarjeta",
    saveCardSpanishTwo: "para compras futuras",
    areYouSure: "Are You Sure?",
    areYouSureLong: "Are you sure you want to save the \ncard ending in ",
    yourNewNumberIs: "Tu nuevo número es",
    preview: "No tienes una tarjeta guardada, agrega una para que sean más sencillas tus compras",
    preview2: "Selecciona tu método de pago",
    validDevice: "Mi dispositivo es compatible",
    checkHere: "(Verifica aquí)",
    paymentPollingFailed: "El pago no se realizó correctamente dentro del tiempo permitido. Inténtalo de nuevo.",
    imeiRequired: "Must be a valid device",
    termsAndConditionsRequired: "Terms and conditions must be read",
    privacyPolicyRequired: "Privacy policy must be read",
    mustBeSelected: "Must be selected",
    readAndAccept: "He leído y acepto los",
    readAndAcceptTwo: "He leído y acepto el",
    termsFull: "Términos y Condiciones del Servicio",
    privacyFull: "Aviso de Privacidad",
    loading: "Cargando",
    mobileView: 'Para acceso a todos nuestros servicios de usuario EXiS, entra a la app o visualiza la página desde una computadora.',
    choosePaymentMethod: "Cambiar",
    eSIM: "Quiero contratar mi servicio con eSIM",
    moreDetails: "(más info aquí)",
    eSIMCompatible: "mi dispositivo es compatible con eSIM",
    checkHere: "(verifica aquí)",
    doesntApply: "No aplica para usuarios que ya tienen o requieren una SIM física.",
    instantlyConnect: "¡Prueba eSIM!",
    instantlyConnectNewLine: "¡Prueba eSIM!"
  },
  update_receipt_number: {
    title: 'Actualiza tu RFC',    
    label: "RFC",
    validateRFC: 'El RFC no debe tener caracteres especiales',
    failed: 'Falló' 
  },
  agent: {
    navbarTitle: "Actuando como Agente",
    header: "Portal de Agentes",
    subheader: "El acceso a esta página es sólo para agentes de ventas, \ningrese sus datos",
    contactManagement: "Contactar a la Gerencia",
    multipleAccounts: 'Existen múltiples cuentas con este número',
    referralCode: "Código de referencia",
    invalidReferralCode: "Código de referencia no válido"
  },
  product: {
    plan: 'Plan',
    renewDate: 'Renovar Fecha',
    expiryDate: 'Fecha de Renovación',
    price: 'Costo',
    switch: 'Cambiar',
    makeRecurring: 'Make Recurring',
    expiryShort: 'Exp'
  },
  gift: {
    confirm: 'Transferido',
    validnumber: 'El número debe tener 10 dígitos',
    numberNotValid: 'El número no es válido',
    send: 'Enviar',
    to: 'a',
    notFoundBalanceTransfer: 'not found balance transfer product',
    ofYourOwn: 'de tu propio plan han sido enviados al',
    asGift: 'como un plan de regalo',
    transferAllowances: 'Transferir tu plan',
    transferAllowances: 'Elige cuánto quieres enviar',
    giftInstructions: "Comparte datos, minutos o sms de tu plan con otros. Ingresa el teléfono al que le quieras enviar un regalo",
    noCredits: "No tienes saldo para este regalo"
  },
  swap: {
    confirm: 'Transformado',
    description: 'Modifica tu plan',
    secondDescription: 'Cambia Datos, minutos y SMS',
    submit: 'Transformar',
    texts: 'Textos',
    data: 'Datos',
    textsDisplayUnits: 'Textos',
    minutesDisplayUnits: 'Minutos',
    minutes: 'Minutos',
    noApplicableBalances: 'No hay saldos aplicables',
    notFoundBalance: 'Not found balance transform product',
    incorrectBalanceInput: 'Balance no ingresado correctamente',
    incorrectBalanceInputData: "Los datos deben incluir unidades. (MB)",
    from: 'De',
    to: 'A',
    transform: 'Transformar',
    into: 'a',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    swapped: 'Transformado',
    ofYourOwn: 'de tu saldo ha sido transformado a',
    textsSuffix: "Textos",
    minutesSuffix: "Minutos",
    dataSuffix: "de Datos"
  },
  errors: {
    unknown: "Error Desconocido",
    timeoutReached: "El servidor encontró un error temporal y no pudo completar tu solicitud. Por favor, inténtalo de nuevo más tarde",
    SMSCountExceeded: "Cuenta de peticiones excedidas \n Por favor contacta con Servicio al Cliente",
    userLocked: "Usuario Bloqueado\n Por favor contacta con Servicio al Cliente",
    notAllowed: "Este no es el camino correcto. El acceso a esta página no está permitido",
    failedPurchase: "Compra fallida",
    invalidEmailAddress: "Esta dirección de email no pertenece a un usuario registrado",
    alreadyRegistered: "Este email o número de teléfono ya está registrado",
    networkUnavailable: "Estás desconectado. Asegúrate de estar en línea y vuelve a intentarlo",
    customerLoginAsAgent: "Debes ser un agente para ingresar",
    validDOB: "Por favor ingresa una fecha de nacimiento válida",
    pendingOrders: "El cliente tiene órdenes pendientes",
    emailNotExist: "Este email no existe",
    serviceDisconnected: "Usuario Desconectado. Por favor contacta con Servicio al Cliente",
    customerNotFound: "El cliente no existe",
    statusInvalid: "Estado inválido",
    unauthorised: "No autorizado",
    paymentFailed: "Pago Fallido",
    mustUpdate:"Actualice su aplicación a la última versión en la App Store",
    orderFailed: "Orden Fallida",
    invalidSimLength: "La ICCID tiene una longitud no válida",
    simInUse:"La ICCID ya está en uso",
    productNotLoaded: "Producto no cargado",
    payments: {
      paymentError01: "Llama a tu banco (01)",
      paymentError02: "Llama a tu banco (02)",
      paymentError06: "Tarjeta inválida (06)",
      paymentError07: "Tarjeta inválida (07)",
      paymentError13: "Importe inválido (13)",
      paymentError14: "Tarjeta inválida (14)",
      paymentError41: "Tarjeta reportada como extraviada. Llama a tu banco (41)",
      paymentError57: "Transacción no permitida (57)",
      paymentError62: "Tarjeta inválida (62)",
      paymentError78: "Código reservado (78)",
      paymentError84: "Código reservado (84)",
      paymentError100: "Cargo rechazado (100)",
      paymentError101: "Tarjeta expirada (101)",
      paymentError107: "Llama a tu banco (107)",
      paymentError200: "Tarjeta inválida. Llama a tu banco (200)",
      paymentErrorD99: "Formato inválido (D99)",
      paymentError15: "No reconocemos el banco (15)",
      paymentError45: "Código reservado (45)",
      paymentError46: "Código reservado (46)",
      paymentError48: "Código reservado (48)",
      paymentError80: "Cargo rechazado (80)",
      paymentError82: "Tarjeta inválida (82)",
      paymentError83: "Tarjeta inválida (83)",
      paymentError87: "Tarjeta inválida (87)",
      paymentError94: "Transacción duplicada (94)",
      paymentErrorN0: "Intenta de nuevo (N0)",
      paymentErrorR1: "Cargo rechazado (R1)",
      paymentErrorT5: "Tarjeta inactiva (T5)",
      paymentError03: "Comercio inválido (03)",
      paymentError05: "Transacción inválida (05)",
      paymentError12: "Transacción no permitida (12)",
      paymentError30: "Error de formato (30)",
      paymentError51: "Crédito insuficiente (51)",
      paymentError52: "Cuenta incorrecta (52)",
      paymentError61: "Límite excedido (61)",
      paymentError65: "Límite excedido (65)",
      paymentErrorN8: "Cargo rechazado (N8)",
      paymentErrorO8: "Tarjeta inválida (O8)",
      paymentErrorP1: "Transacción no permitida (P1)",
      paymentErrorT4: "Cargo rechazado (T4)",
      paymentError04: "Cargo rechazado (04)",
      paymentError34: "Cargo rechazado. Llama a tu banco (34)",
      paymentError35: "Cargo rechazado. Llama a tu banco (35)",
      paymentError36: "Tarjeta restringida. Llama a tu banco (36)",
      paymentError37: "Cargo rechazado. Llama a tu banco (37)",
      paymentError43: "Cargo rechazado. Llama a tu banco (43)",
      paymentError56: "No hay registro de tarjeta (56)",
      paymentErrorQ5: "Cargo rechazado. Llama a tu banco (Q5)",
      paymentErrorN7: "Cargo recurrente rechazado. Llama a tu banco (N7)",
      paymentErrorN6: "Cargo recurrente rechazado. Llama a tu banco (N6)",
    }
  },
  errorMapping: {
    SR0001:{
      authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
    },
    SR0002: {
      login: "errors.userLocked",
      forgottenPassword: "errors.userLocked",
    },
    SR0004: {
      login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
    },
    SR0005: {
      login: "errors.notAllowed",
    },
    SR0009: {
      forgottenPassword: "errors.unauthorised"
    },
    SR0014: {
      registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
    },
    SR0017: {
      registration: "errors.alreadyRegistered"
    },
    SR0020:{
      forgottenPassword: "errors.invalidEmailAddress"
    },
    SR0022:{
      forgottenPassword: "forms.phoneNumberInvalid",
      registration: "forms.phoneNumberInvalid",
    },
    SR0041: {
      registration: "errors.statusInvalid"
    },
    SR0042: {
      forgottenPassword: "errors.SMSCountExceeded",
      registration: "errors.SMSCountExceeded"
    },
    SR0043: {
      login: "errors.mustUpdate",
      registration: "errors.mustUpdate",
      forgottenPassword: "errors.mustUpdate"
    },
    SR0101: {
      lost: "errors.pendingOrders",
      gift: "errors.pendingOrders",
      checkout: "errors.pendingOrders"
    },
    SR9999: {
      login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
    },
    SR00172: {
      login: "errors.customerNotFound",
      registration: "errors.customerNotFound"
    },
    1000:"errors.orderFailed",
    1002:"errors.alreadyRegistered",
    1003:"errors.invalidSimLength",
    1004:"errors.simInUse",
		CREDENTIALS_INVALID: {
			authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
		},
		AUTHENTICATION_ERROR: {
			authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
		},
		USER_LOCKED: {
			login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
		},
		MAX_PASSWORD_ATTEMPTS: {
			login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
		},
		SECURITY_CHECK_INVALID: {
			forgottenPassword: "errors.unauthorised"
		},
		NOT_FOUND: {
			forgottenPassword: "errors.unauthorised"
		},
		UNSPECIFIED_ERROR: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		USER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		NO_USER_EXISTS: {
			login: "errors.customerNotFound"
		},
		CUSTOMER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		FILTER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		CONFIRMED_BASKET_INVALID: {
      checkout: "errors.failedPurchase"
		},
		ALREADY_REGISTERED: {
			registration: "errors.alreadyRegistered"
		},
		USERNAME_UNAVAILABLE: {
			registration: "errors.alreadyRegistered"
		},
		USER_INVALID: {
			forgottenPassword: "errors.invalidEmailAddress"
		},
		SERVICE_INVALID: {
			forgottenPassword: "forms.phoneNumberInvalid",
      registration: "forms.phoneNumberInvalid",
			login: "errors.customerNotFound",
		},
		IS_POOLED: {
			registration: "errors.statusInvalid"
		},
		IS_BLACKLISTED: {
			registration: "errors.statusInvalid"
		},
		REGISTRATION_INVALID: {
			registration: "errors.statusInvalid"
		},
		MAX_NUMBER_OF_AUTHSMS_SENDS: {
			forgottenPassword: "errors.SMSCountExceeded",
      registration: "errors.SMSCountExceeded"
		},
		API_CLIENT_VERSION_NOT_SUPPORTED: {
			login: "errors.mustUpdate",
      registration: "errors.mustUpdate",
      forgottenPassword: "errors.mustUpdate"
		},
		ORDERS_PENDING: {
			lost: "errors.pendingOrders",
      gift: "errors.pendingOrders",
      checkout: "errors.pendingOrders"
		},
		CONNECTION_TIMEOUT: {
			login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
		},
		TIMEOUT: {
			login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
		}
  }
}