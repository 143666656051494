import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, StyleSheet, ScrollView, Dimensions, ActivityIndicator } from 'react-native'
// import ItemsCarousel from 'react-items-carousel';
import Whirligig from 'react-whirligig'
import { Switch } from 'react-native-switch';
import Carousel from 'nuka-carousel';
import AsyncStorage from '@react-native-async-storage/async-storage';

import client, { useClient, useData } from '@sart/client'
import { Subhead, Space, Text, Button } from '@sart/components'
import { EliotCircleBackground } from '@sart/icons';
import { useContent } from '@sart/content'
import {
  colors,
  backgroundColors,
  typography,
  adaptive,
  // adaptive,
} from '@sart/styles'
import { testID } from '@sart/utilities';

import {
  isMobileOnly
} from "react-device-detect";

// import Wrapper from '../Wrapper'
import PlanItemEliot from './PlanItemEliot'
import EliotExternalLinkBar from './EliotExternalLinkBar';
import EliotBottomBar from './EliotBottomBar';

export default function PlansEliot(props) {
  const { history } = props

  const { cache } = useClient()
  const content = useContent()

  const [{ allPlans, error }, setData] = useData({})
  const [loading, setLoading] = useState(true)

  const plans = React.useMemo(() => allPlans)

  const [interest, /*setInterest*/] = useState('postpaid')

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  
  const [windowWidth, windowHeight] = useWindowSize();
  const WINDOW_WIDTH = Dimensions.get('window').width
  const ITEM_WIDTH = 248
  const max_displayed_plans = isMobileOnly || windowWidth < 1050 ? 1 : 3

   let whirligig
   const next = () => whirligig.next()
   const prev = () => whirligig.prev()

  useEffect(() => {
    getProducts()
    setEliotJourney()
  }, [])

  async function setEliotJourney() {
    await AsyncStorage.setItem('eliotJourney', true)
  }

  async function getProducts() {
    setLoading(true)
    try {
        let auth = await client.signInWithAgentUser({username: 'ELIOT-GUEST-ROOT', password: 'Passw001'})
        await client.cacheUserAccountInformation(auth.customerId)


        //force the vas settings to sync so that we can use selection rules
        const _settingInstances = await client.callApi('service','getServiceBooleanValueAddedServiceProductInstances',[
          client.cache.accountId,
          client.cache.serviceId
        ])


        let _plans =  await client.callApi('catalog','getAddOnsProducts',[client.cache.catalogId, {
          tags: "package",
          accountId: client.cache.accountId,
          serviceId: client.cache.serviceId
        }])
        // let _recurringPlans =  await client.callApi('catalog','getAddOnsProducts',[client.cache.catalogId, {
        //   tags: "package,recurring"
        // }])
        _plans = client.filterOutExpiredOffers(_plans)
        _plans = _plans.slice().sort((a, b) => a.rank - b.rank)

        //filterOutExpiredOffers
        // _recurringPlans = client.filterOutExpiredOffers(_recurringPlans)
        // _recurringPlans = _recurringPlans.slice().sort((a, b) => a.rank - b.rank)
        setData({
          allPlans: _plans,
          //  recurringPlans: _recurringPlans,
        })
        client.setCache(
          {
            plans: {
              plans: _plans,
              // oneoff: _oneOffPlans
            }
          }
          )
        setLoading(false)
    } catch (error) {
      // TODO: handle error
      setLoading(true)
      setData({ error })
    }
  }

  function handlePreviewPlan(plan) {
    history.push(`/hol_plan/${plan.productLegacyId}`)
  }

  return ([
    <EliotExternalLinkBar />,
    <View style={styles.container}>
      <EliotCircleBackground width={isMobileOnly ? "390px" : "900px"} height={"700px"} style={{
        position: 'absolute',
        alignSelf: 'center'
      }} />

      <View style={styles.headers}>
        <Space height={isMobileOnly ? 64 : 124} />
        <Text style={styles.headerText}>{content.plans.eliotHeader1} </Text>
        <Text style={styles.headerTextHighlight}>{content.plans.eliotHeader2} </Text>
        <Text style={styles.headerText}>{content.plans.eliotHeader3}</Text>
        {/* <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead> */}
      </View>

      {!isMobileOnly &&
        <Space height={12} />
      }
      {loading &&
          <ActivityIndicator size="large" color="#0000ff" style={{height: 300, justifyContent: 'flex-end'}}/>
      }

      {!isMobileOnly &&
        <View style={{ flexDirection: 'row', justifyContent: 'center', width: windowWidth < 1050 ? 410 : '100%', alignSelf: windowWidth < 1050 ? 'center' : 'flex-start' }}>
          {(plans && plans.length > max_displayed_plans) &&
            <View style={{ justifyContent: 'center' }}>
              <Button
                title={"<"}
                style={[styles.button, { backgroundColor: colors.eliotYellow }]}
                titleStyle={{ ...typography.bold }}
                onPress={prev}
              />
            </View>
          }
          <View style={styles.blankView} />
          <Whirligig
            visibleSlides={max_displayed_plans}
            preventScroll={true}
            preventSwipe={false}
            snapToSlide={true}
            // gutter="2em"
            ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
          >
            {plans && plans.map(plan => (
              <PlanItemEliot
                key={plan.productId}
                style={{ width: ITEM_WIDTH }}
                plan={plan}
                onPress={() => handlePreviewPlan(plan)}
                {...testID('PlanItem')}
              />
            ))
            }
          </Whirligig>
          {(plans && plans.length > max_displayed_plans) &&
            <View style={{ justifyContent: 'center' }}>
              <Button
                title={">"}
                style={[styles.button, { backgroundColor: colors.eliotPurple }]}
                titleStyle={{ ...typography.bold }}
                onPress={next}
              />
            </View>
          }
        </View>
      }
      {isMobileOnly && plans &&
        <Carousel
          // disableAnimation={true}
          disableEdgeSwiping={true}
          easing="easeQuadIn"
          speed={300}
          defaultControlsConfig={{
            nextButtonText: '>',
            prevButtonText: '<',
            pagingDotsStyle: {
              fill: 'none'
            },
            prevButtonStyle: {
              backgroundColor: colors.eliotYellow,
              width: 45,
              height: 45,
              borderRadius: 5
            },
            nextButtonStyle: {
              backgroundColor: colors.eliotPurple,
              width: 45,
              height: 45,
              borderRadius: 5
            },
          }}
        >
          {plans && plans.map(plan => (
            <PlanItemEliot
              key={plan.productId}
              plan={plan}
              onPress={() => handlePreviewPlan(plan)}
            />
          ))
          }
        </Carousel>
        // </View>
      }
      <Space height={16} />
      <View style={styles.subheaders}>
        <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead>
      </View>
      <Space height={16} />

    </View>,
    <EliotBottomBar />
  ]
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 750,
    maxWidth: '100%',
    paddingHorizontal: '5%',
    backgroundColor: '#EDEEF0',
    width: '100%',
  },
  headers: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 80
  },
  subheaders: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 70
  },
  headerText: {
    textAlign: 'flex-start',
    fontSize: isMobileOnly ? 22 : 40,
    color: colors.eliotPurple,
    fontFamily: 'AvertaExtraBold',
  },
  headerTextHighlight: {
    textAlign: 'center',
    fontSize: isMobileOnly ? 22 : 40,
    color: colors.eliotYellow,
    fontFamily: 'AvertaExtraBold',
  },
  subheaderText: {
    fontSize: 22,
    textAlign: 'center',
    color: colors.eliotPurple,
    fontFamily: 'AvertaRegular',
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  switchLabels: {
    flex: 1,
    fontSize: 20,
    lineHeight: 20,
    fontWeight: '600',
    color: colors.black,
    ...typography.bold
  },
  button: {
    backgroundColor: colors.exisOrange,
    height: 40
  },
  blankView: {
    height: 620,
    ...adaptive.portalSpaceWidth
  }
})
