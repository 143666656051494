import React, { useState, useEffect } from 'react'
import { View, ScrollView, StyleSheet, Text } from 'react-native'

import {
  Headline, HorizontalLine, Space,
} from '@sart/components'
import { useContent } from '@sart/content'
import { typography, colors } from '@sart/styles'
import client, { useData } from '@sart/client'
import { Download, Share } from '@sart/icons'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'

import Accordion from 'react-native-collapsible/Accordion';

export default function TermsAndConditions(props) {

  const content = useContent()

  const [activeSections, setActiveSections] = useState([])

  const [{ termsAndConditionsItems, error, loading }, setData] = useData({})

  const items = React.useMemo(() => termsAndConditionsItems)

  useEffect(() => {
    getTermsAndConditionsItems()
  }, [])

  async function getTermsAndConditionsItems() {
    setData({ loading: true })
    try {
      const termsAndConditionsItems =  await client.callApi('contentCms','getPages',[1, { category: "TERMSANDCONDITIONS" }]);
      const parsedItems = termsAndConditionsItems.map(item => {
        let excerpt = {}
        try {
          excerpt = JSON.parse(item.excerpt)
        } catch(error) {
          return {}
        }
        // let excerpt = JSON.parse(item.excerpt)
        // let content = JSON.parse(item.excerpt)
        let parsed = {};
        parsed.rank = excerpt.rank;
        parsed.downloadIcon = excerpt.downloadIcon;
        parsed.shareIcon = excerpt.shareIcon
        parsed.title = excerpt.title;
        parsed.content = <a dangerouslySetInnerHTML={ { __html: excerpt.description } }></a>
        return parsed;
      });
      const orderedItems = parsedItems.sort(function(a,b) {
        return a.rank - b.rank;
      })
      setData({
        termsAndConditionsItems: orderedItems,
        loading: false
      })
      client.setCache({
        termsAndConditionsItems: orderedItems
      })
    } catch (error) {
      // TODO: handle error
      setData({ loading: true, error })
    }
  }

  var _renderHeader = section => {
    return (
      <View style={styles.header}>
        <View style={{flexDirection: 'row'}}>
          {activeSections.length ==  0 &&
            <Text style={styles.bulletPoint}>+</Text>
          }
          {activeSections.length ==  1 &&
            <Text style={styles.bulletPoint}>-</Text>
          }
          <Space width={5} height={5} />
          <Text style={styles.headerText}>{section.title}</Text>
        </View>
        <Space height={20} />
      </View>
    );
  };

  var _renderContent = section => {
    return (
      <View>
        <View style={styles.content}>
          {section.downloadIcon &&
            <View style={{paddingLeft: 24}}>
              <Download size={25}/>
            </View>
          }
          {section.shareIcon &&
            <Share size={25}/>
          }
          <Space width={5} height={5} />
          <Text style={styles.contentText}>{section.content}</Text>
        </View>
        <Space height={10} />
        <HorizontalLine height={300} color={colors.exisBlue} />
        <Space height={10} />
      </View>
    );
  };

  var _updateSections = activeSections => {
    setActiveSections(activeSections);
  };

  return (
    <Wrapper style={styles.container}>
      <Headline style={styles.title} {...testID('TermsAndConditionsHeader')}>{content.navigation.termsAndConditions}</Headline>
      <Space height={48} />
      {termsAndConditionsItems &&
      <Accordion
        sections={termsAndConditionsItems}
        activeSections={activeSections}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
        onChange={_updateSections}
        underlayColor={colors.clear}	
      />
      }
      <Space height={200} />
      <HorizontalLine height={3} color={colors.exisBlue} />
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48
  },
  title: {
    ...typography.bold,
    color: colors.exisBlue,
    fontSize: 45
  },
  headerText: {
    ...typography.bold
  },
  contentText: {
    ...typography.book,
    alignSelf: 'center'
  },
  bulletPoint: {
    color: colors.exisOrange,
    ...typography.bold
  },
  content: {
    flexDirection: 'row',
  }
})

