var CryptoJS = require("crypto-js");

export function encryptSensitiveString(plainText, key){
    var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(key));
    var hashedKey = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.Hex).substr(0, 32));
  
    var encrypted = CryptoJS.AES.encrypt(plainText, hashedKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
  
    return(CryptoJS.enc.Base64.stringify(encrypted.ciphertext))
  }