import React, { useEffect } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';

import { colors, typography, adaptive } from '@sart/styles'
import { useContent } from '@sart/content'
import { Text, Space, Anchor, Description, Title, Button } from '@sart/components'

import { HolFullLogo, HolSmallLogo, WideUpArrow, EXiSAppIcon, HolSmallLogoWithSpeechBubble } from '@sart/icons'

import { useBarcode } from 'react-barcodes';

import client, { useClient } from '@sart/client'

import { testID, concatStringID } from '@sart/utilities'

import {
    isMobileOnly
} from "react-device-detect";
import AlmostReady from './AlmostReady'

export default function BarcodeEliot(props) {
    const { history } = props

    const content = useContent()

    const { orderMessage, hasSimOrder, cashBasket } = client.cache

    useEffect(() => {
        client.unsetCache('selectedPlan')
        client.unsetCache('customerDetails')
        client.unsetCache('cachedBasket')
        client.unsetCache('existingSIM')
        client.unsetCache('promoPlans')
        client.unsetCache('referralCode')
        setEliotJourney()
    }, [])

    async function setEliotJourney() {
        try {
            await AsyncStorage.removeItem('eliotJourney')
        } catch(error) {
           //do nothing for now
        }
    }

    function handleReturn() {
        history.push('/')
    }

    function handleRouting() {
        window.open('http://hol.com.mx', '_blank')
    }

    var total = cashBasket.total;

    function calculateTotalCost() {
        return (total / 100).toFixed(2)
    }

    const { inputRef } = useBarcode({
        value: client.cache.barCode,
        options: {}
    });

    const source = 'https://exis.mx/files/eliotBackgroundImage.png'

    return ([
        // <EliotExternalLinkBar />,
        <div
            style={{
                backgroundImage: `url(${source})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                // width: 1700,
                height: 700,
            }}>

            <View style={styles.navbarContainer}>

                <HolFullLogo />

                <TouchableOpacity onPress={() => handleRouting()}>
                    <WideUpArrow />
                </TouchableOpacity>

                <HolSmallLogo />

            </View>
            <View style={styles.wrapper}>

                <Space height={24} />

                <HolSmallLogoWithSpeechBubble text={"THX"} />

                <Space height={36} />

                <AlmostReady />

                <Space height={36} />

                <Description style={styles.description} {...testID("barcodeDescription")}>{content.payment_confirm.cashDescription}</Description>

                <Space height={48} />
                {/* Unsure if 'CODE128' format is correct */}
                {/* <Barcode value={'98000007077160'} format={'CODE128'} text={'98000007077160'} width={styles.barcodeSize.width} /> */}
                <svg ref={inputRef} {...concatStringID('barcode')} />
                <Description style={styles.price}>{content.checkout.total} ${calculateTotalCost()} {content.payment_confirm.currencyCode}</Description>
                <Space height={24} />

                {/* <Button
                    style={styles.button}
                    title={content.payment_confirm.action}
                    onPress={() => { history.push('/') }}
                    titleStyle={{ ...typography.extrabold, fontSize: 18 }}
                />
                <Space height={24} /> */}
            </View>
        </div>
    ])
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isMobileOnly ? 480 : 600,
        paddingTop: isMobileOnly ? 0 : 0,
        width: '100%',
        // backgroundColor: colors.eliotPurple
    },
    navbarContainer: {
        // backgroundColor: colors.eliotPurple,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '2%',
        height: 50
    },
    iconsRow: {
        flexDirection: 'row'
    },
    iconsColumn: {
        flexDirection: 'column',
        // justifyContent: 'center'
    },
    title: {
        color: '#FCEE21',
        fontSize: isMobileOnly ? 16 : 48,
        fontFamily: 'BebasNeueBold',
        // fontStyle: 'italic',
        // ...typography.bold,
        textAlign: 'center'
    },
    description: {
        fontFamily: 'AvertaExtraBold',
        color: colors.white,
        fontSize: 22,
        width: '35%',
        textAlign: 'center'
    },
    wrapper: {
        flex: 1,
        ...adaptive.wrapperPadding,
        // justifyContent: 'center',
        alignItems: 'center'
    },
    price: {
        backgroundColor: colors.exisDarkCharcoal,
        color: colors.clear,
        ...typography.lightbold,
        textAlign: 'center',
        width: 247
    },
})
