export default {
    // basePath:'https://uat.exis.mx/ssb-jaxrs-server/exis/ssb/v2.0', //Vadsa UAT
    basePath:'https://exis.mx/ssb-jaxrs-server/exis/ssb/v2.0', //Vadsa PROD
    // basePath:'http://127.0.0.1:8080/ssb-jaxrs-server/exis/ssb/v2.0', // Local
    // basePath:'http://3.11.54.98:8080/ssb-jaxrs-server/exis/ssb/v2.0', // SR QA Cloud
    // basePath:'http://3.8.107.152:8080/ssb-jaxrs-server/exis/ssb/v2.0', // SR DEV Cloud
    // basePath:'http://10.0.1.104:8080/ssb-jaxrs-server/exis/ssb/v2.0',
    apiVersion:'V3.0.30',
    catalogName: 'VADSA',
    numberRegex:'^((?!(0))[0-9]{1,10})$',
    encryptionKey: 'M&Pdnm8!_exis',
    activationProduct: 'VADSA-ACTIVATE-SUBSCRIPTION',
    channelName: 'CUSTOMER-PORTAL',
    paymentGateway: 'webpayplus',
    defaultLanguage: 'es',
    pollingMaxRetries: 300,
    callCenterNumber: '+525589960111',
    whatsAppNumber: '524496760000',
    orderRefreshTime: 2000, // 2 seconds
    orderRefreshTimeout: 600000 // 10 mins
}