import React from 'react'
import { View, StyleSheet } from 'react-native'
import { WebView } from 'react-native-webview'

import { backgroundColors } from '@sart/styles'

export default function Voxox(props) {

    return (
        <View style={styles.container}>
            <WebView
                source={{ uri: 'https://www.voxox.com/' }}
                style={styles.webView}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: backgroundColors.white,
        height: 700
    },
    webView: {
        width: '100%',
        height: '100%'
    }
})

