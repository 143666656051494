import React from 'react'
import { Provider, useSelector } from 'react-redux'

import cacheStore from './cache'

// React Hooks: How to create and update Context.Provider
// https://dev.to/oieduardorabelo/react-hooks-how-to-create-and-update-contextprovider-1f68

// export const ClientContext = React.createContext({
//   cache: cacheStore.getState()
// })

// export class ClientProvider extends React.Component {
//   state = cacheStore.getState()

//   componentDidMount() {
//     this.unsubscribe = cacheStore.subscribe(() => {
//       const state = cacheStore.getState()

//       this.setState(state)
//     })
//   }

//   componentWillUnmount() {
//     this.unsubscribe()
//   }

//   render() {
//     const value = {
//       cache: this.state
//     }

//     return React.createElement(ClientContext.Provider,
//       { value: value },
//       this.props.children
//     )
//   }
// }

// export const ClientConsumer = ClientContext.Consumer

// export const useClient = () => React.useContext(ClientContext)

// export default ClientContext
export class ClientProvider extends React.Component {
  render() {
    return React.createElement(Provider, {
        store: cacheStore
      },
      this.props.children
    )
  }
}
export function useClient() {
  const state = useSelector(state => state)

  return {
    cache: state
  }
}

