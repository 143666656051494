import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Dimensions, ActivityIndicator } from 'react-native'
// import ItemsCarousel from 'react-items-carousel';
import Whirligig from 'react-whirligig'
import { Switch } from 'react-native-switch';
import Carousel from 'nuka-carousel';

import client, { useData } from '@sart/client'
import { Headline, Subhead, Space, Text, Button } from '@sart/components'
import { useContent } from '@sart/content'
import {
  colors,
  backgroundColors,
  typography,
  adaptive,
  // adaptive,
} from '@sart/styles'
import { testID } from '@sart/utilities'

import {
  isMobileOnly
} from "react-device-detect";

// import Wrapper from '../Wrapper'
import PlanItem from './PlanItem'

export default function Plans(props) {
  const { history } = props

  // const { cache } = useClient()
  const content = useContent()

  const [{ oneOffPlans, recurringPlans, error }, setData] = useData({})
  const [recurring, setRecurring] = useState(true)
  const [loading, setLoading] = useState(true)

  const plans = React.useMemo(() => recurring ? recurringPlans : oneOffPlans, [recurring, recurringPlans, oneOffPlans])

  const [interest, /*setInterest*/] = useState('postpaid')

  const WINDOW_WIDTH = Dimensions.get('window').width
  const ITEM_WIDTH = 248
  const max_displayed_plans = isMobileOnly ? 1 : 3

  let whirligig
  const next = () => whirligig.next()
  const prev = () => whirligig.prev()

  useEffect(() => {
    getProducts()
  }, [])

  async function getProducts() {
    setLoading(true)
    try {
      let auth = await client.signInWithAgentUser({ username: 'VADSA-GUEST-ROOT', password: 'Passw001' })
      await client.cacheUserAccountInformation(auth.customerId)


      //force the vas settings to sync so that we can use selection rules
      const _settingInstances = await client.callApi('service', 'getServiceBooleanValueAddedServiceProductInstances', [
        client.cache.accountId,
        client.cache.serviceId
      ])
      let _oneOffPlans = await client.callApi('catalog', 'getAddOnsProducts', [client.cache.catalogId, {
        tags: "package,oneoff",
        accountId: client.cache.accountId,
        serviceId: client.cache.serviceId
      }])
      let _recurringPlans = await client.callApi('catalog', 'getAddOnsProducts', [client.cache.catalogId, {
        tags: "package,recurring",
        accountId: client.cache.accountId,
        serviceId: client.cache.serviceId
      }])
      _oneOffPlans = client.filterOutExpiredOffers(_oneOffPlans)
      _oneOffPlans = _oneOffPlans.slice().sort((a, b) => a.rank - b.rank)

      //filterOutExpiredOffers
      _recurringPlans = client.filterOutExpiredOffers(_recurringPlans)
      _recurringPlans = _recurringPlans.slice().sort((a, b) => a.rank - b.rank)
      setData({
        oneOffPlans: _oneOffPlans,
        recurringPlans: _recurringPlans,
      })
      setLoading(false)
      client.setCache(
        {
          plans: {
            recurring: _recurringPlans,
            oneoff: _oneOffPlans
          }
        }
      )
    } catch (error) {
      // TODO: handle error
      setLoading(true)
      setData({ error })
    }
  }

  function handlePreviewPlan(plan) {
    history.push(`/plan/${plan.productLegacyId}`)
  }

  return (
    <View style={styles.container}>
      <View style={styles.headers}>
        <Headline style={styles.headerText}>{content.plans.header}</Headline>
        {/* <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead> */}
      </View>


      <Space height={24} />


      <View style={styles.switchContainer}>
        <Text style={[styles.switchLabels, { textAlign: 'right' }]}>{content.plans.singleWeb}</Text>
        <Space width={12} />

        <Switch
          value={recurring}
          onValueChange={value => setRecurring(value)}
          circleSize={20}
          barHeight={20}
          circleBorderWidth={0}
          backgroundActive={colors.exisOrange}
          backgroundInactive={colors.trivial}
          circleActiveColor={colors.white}
          circleInActiveColor={colors.white}
          innerCircleStyle={{ alignItems: "center", justifyContent: "center" }}
          renderActiveText={false}
          renderInActiveText={false}
          switchLeftPx={2}
          switchRightPx={2}
          switchWidthMultiplier={2}
          switchBorderRadius={30}
          {...testID('PlansSwitch')}
        />
        <Space width={12} />
        <Text style={[styles.switchLabels, { textAlign: 'left' }]}>{content.plans.recurringWeb}</Text>
      </View>

      {loading &&
        <ActivityIndicator size="large" color="#0000ff" style={{ height: 300, justifyContent: 'center' }} />
      }
      <Space height={24} />
      {!isMobileOnly &&
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          {(plans && plans.length > max_displayed_plans) &&
            <View style={{ justifyContent: 'center' }}>
              <Button
                title={"<"}
                style={styles.button}
                titleStyle={{ ...typography.bold }}
                onPress={prev}
              />
            </View>
          }
          <View style={styles.blankView} />
          <Whirligig
            visibleSlides={max_displayed_plans}
            preventScroll={true}
            preventSwipe={false}
            snapToSlide={true}
            // gutter="2em"
            ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
          >
            {plans && plans.map(plan => (
              <PlanItem
                key={plan.productId}
                style={{ width: ITEM_WIDTH }}
                plan={plan}
                onPress={() => handlePreviewPlan(plan)}
                {...testID('PlanItem')}
              />
            ))
            }
          </Whirligig>
          {(plans && plans.length > max_displayed_plans) &&
            <View style={{ justifyContent: 'center' }}>
              <Button
                title={">"}
                style={styles.button}
                titleStyle={{ ...typography.bold }}
                onPress={next}
              />
            </View>
          }
        </View>
      }
      {isMobileOnly && plans &&
        <Carousel
          // disableAnimation={true}
          disableEdgeSwiping={true}
          easing="easeQuadIn"
          speed={300}
          defaultControlsConfig={{
            nextButtonText: '>',
            prevButtonText: '<',
            pagingDotsStyle: {
              fill: 'none'
            },
            prevButtonStyle: {
              backgroundColor: colors.exisOrange,
              width: 45,
              height: 45,
              borderRadius: 5
            },
            nextButtonStyle: {
              backgroundColor: colors.exisOrange,
              width: 45,
              height: 45,
              borderRadius: 5
            },
          }}
        >
          {plans && plans.map(plan => (
            <PlanItem
              key={plan.productId}
              plan={plan}
              onPress={() => handlePreviewPlan(plan)}
            />
          ))
          }
        </Carousel>
        // </View>
      }
      <View style={styles.headers}>
        <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    maxWidth: '100%',
    paddingHorizontal: '5%',
    paddingTop: 48,
    backgroundColor: backgroundColors.exisBlue,
    width: '100%',
  },
  headers: {
    alignItems: 'center'
  },
  headerText: {
    ...typography.bold,
    textAlign: 'center',
    fontSize: isMobileOnly ? 26 : 36,
  },
  subheaderText: {
    ...typography.bold,
    textAlign: 'center'
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  switchLabels: {
    flex: 1,
    fontSize: 20,
    lineHeight: 20,
    fontWeight: '600',
    color: colors.black,
    ...typography.bold
  },
  button: {
    backgroundColor: colors.exisOrange,
    height: 40
  },
  blankView: {
    ...adaptive.portalSpaceWidth
  }
})
