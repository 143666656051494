import React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "svgs";

export default function US(props) {
  return (
    <Svg width={30} height={20} viewBox="0 0 36 24" {...props}>
      <Defs>
        <ClipPath id="clipPath">
          <Rect x="0" y="0" rx="4" ry="4" width="36" height="24" />
        </ClipPath>
      </Defs>

      <G clipPath="url(#clipPath)">
        <Path fill="#F0F0F0" d="M0 .2h36v24H0z" />
        <Path fill="#D80027" d="M0 3.2h36v3H0zM0 9.2h36v3H0zM0 15.2h36v3H0zM0 21.2h36v3H0z" />
        <Path fill="#2E52B2" d="M0 .2h18v12.9H0z" />
        <Path fill="#F0F0F0" d="M7 5.5l-.3.9h-.9l.7.6-.2.9.7-.6.8.6-.3-.9.7-.6h-.9zM7.3 9.6L7 8.8l-.3.8h-.9l.7.6-.2.9.7-.6.8.6-.3-.9.7-.6zM3.3 9.6l-.2-.8-.3.8h-1l.8.6-.3.9.8-.6.7.6-.3-.9.8-.6zM3.1 5.5l-.3.9h-1l.8.6-.3.9.8-.6.7.6-.3-.9.8-.6h-1zM7 2.3l-.3.9h-.9l.7.6-.2.9.7-.6.8.6-.3-.9.7-.6h-.9zM3.1 2.3l-.3.9h-1l.8.6-.3.9.8-.6.7.6-.3-.9.8-.6h-1zM11 5.5l-.3.9h-.9l.7.6-.3.9.8-.6.7.6-.3-.9.8-.6h-.9zM11.3 9.6l-.3-.8-.3.8h-.9l.7.6-.3.9.8-.6.7.6-.3-.9.8-.6zM15.2 9.6l-.3-.8-.2.8h-1l.8.6-.3.9.7-.6.8.6-.3-.9.8-.6zM14.9 5.5l-.2.9h-1l.8.6-.3.9.7-.6.8.6-.3-.9.8-.6h-1zM11 2.3l-.3.9h-.9l.7.6-.3.9.8-.6.7.6-.3-.9.8-.6h-.9zM14.9 2.3l-.2.9h-1l.8.6-.3.9.7-.6.8.6-.3-.9.8-.6h-1z" />
      </G>
    </Svg>
  )
}
