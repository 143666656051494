import { selectStyleByDefaultSize } from './sizes'

/*
TODO: adaptive text

window < 312    Month
window > 312    Exp Month
window > 480    Expiration Month
*/

export const wrapperPadding = selectStyleByDefaultSize({
  small: {
    paddingLeft: 8,
    paddingRight: 8
  },
  medium: {
    paddingLeft: 12,
    paddingRight: 12
  },
  large: {
    paddingLeft: 24,
    paddingRight: 24
  }
})
