import React, { useEffect, useState } from 'react'

import { View, StyleSheet } from 'react-native'
import { Redirect } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  Subhead,
  Space,
  Button
} from '@sart/components'
import client from '@sart/client'
import { useContent } from '@sart/content'
import { colors, typography } from '@sart/styles'

export default function CheckoutEmpty(props) {
  const { history } = props

  const content = useContent()

  const [eliotJourney, setEliotJourney] = useState(false)
  
  useEffect(() => {
    getEliotJourney()
  })

  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  const {
    selectedPlan,
    customerDetails,
    cardDetails
  } = client.cache

  if (cardDetails) return <Redirect to="/checkout/review" />
  if (customerDetails) return <Redirect to="/checkout/payment" />
  if (selectedPlan) return <Redirect to="/checkout/customer" />

  return (
    <View style={styles.container}>
        <Subhead style={styles.subHeaderText}>{content.checkout.cartIsEmpty}</Subhead>

        <Space height={24} />

        <Button
          style={styles.button}
          title={content.navigation.plans}
          onPress={() => void history.push(eliotJourney ? '/hol_plans' : '/plans')}
          titleStyle={{...typography.bold}}
        />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center'
  },
  button: {
    width: 280,
    backgroundColor: colors.exisOrange
  },
  subHeaderText: {
    ...typography.demi
  }
})
