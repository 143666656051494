import React, { useEffect, useState }from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { WebView } from 'react-native-webview'
import { Redirect } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import client, { useData } from '@sart/client'

import { useContent } from '@sart/content'
import {
  Subhead,
  Space,
  HorizontalLineSpacer,
  RadioExtended,
  Text,
  Button,
 } from '@sart/components'
import { typography, colors } from '@sart/styles'

import {
  Visa,
  MasterCard,
  AmericanExpress,
  CashIcon
} from '@sart/icons'

import { testID } from '@sart/utilities'

import CheckoutSummary from './CheckoutSummary'
import pollingTimer from './PollingTimer'

import {
  isMobileOnly
} from "react-device-detect";

 export default function CheckoutPayment(props) {
  const content = useContent()
  const [basketRefId, setBasketRefId] = useState([]);
  const [submitting, setSubmitting] = useState(false)
  const [fetchingCardLink, setFetchingCardLink] = useState(false)
  const [error,setError] = useState()
  const { history } = props
  const [optionSelected, setOptionSelected] = useState('')

  const {
    selectedPlan,
    customerDetails,
  } = client.cache

  const [eliotJourney, setEliotJourney] = useState(false)
  
  useEffect(() => {
    getEliotJourney()
  })
  
  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }
  const [{ link, timer }, setData] = useData({})

  useEffect(() => {
    checkPaymentStatus()
  }, [basketRefId])


  async function getWebPayLink() {
    if (!fetchingCardLink && !link){
      setData({ link: null })
      setError(null)
      setFetchingCardLink(true)
      try {
        const  { basketRefId, gatewayURL } = await client.submitNCAHostedPaymentBasket({
                                                partner: eliotJourney ? 'ELIOT' : 'EXIS'});
        await client.signInWithAgentUser({
          username: 'VADSA-SHOPPING-AGENT',
          password: 'Passw001'
        })
        setBasketRefId(basketRefId)
        setData({ link: gatewayURL })
        setFetchingCardLink(false)
      } catch (error) {
        // TODO: handle error
        setData({ link: null, loading: true, error })
        setFetchingCardLink(false)
        setError(error)
      }
    }
  }


  function selectedOption(option) {
    if (option === 'cash') {
      pollingTimer.stop(timer)
      setData({ link: null })
    }
    setOptionSelected(option)
  }

  async function selectCreditCard() {
    selectedOption('creditCard')
    await getWebPayLink()
  }

   async function handleCashMethod(method) {
     setSubmitting(true)
     
     const cashBasket = await client.submitNCACashPaymentBasket({
      partner: eliotJourney ? 'ELIOT' : 'EXIS'});

      client.setCache({
       barCode: cashBasket.cashPayment.barCode,
       cashBasket: cashBasket.basket
     })

     if (!eliotJourney) {
       history.push('/barcode')
     } else if(eliotJourney) {
      history.push('/hol_barcode')
     }
   }

  async function checkPaymentStatus() {
    if (basketRefId && basketRefId.length && client.cache.customerId) {
      pollingTimer.stop(timer);
      const pollOrderTimer = pollingTimer.start(
        basketRefId,
        () => {
          setError("polling failed");
          setFetchingCardLink(false);
          setBasketRefId(null);
        },
        () => {
          setFetchingCardLink(false);
          setBasketRefId(null);
          history.push("/checkout/process");
        }
      );
      setData({ timer: pollOrderTimer });
    } else {
      pollingTimer.stop(timer);
    }
  }

  if (!selectedPlan) return <Redirect to="/checkout" />
  if (!customerDetails) return <Redirect to="/checkout/customer" />


  return (
    <View style={{flexDirection:'row'}}>
      <View style={styles.container}>
        <Subhead style={styles.demi}>{content.checkout.paymentMethod}</Subhead>

        <Space height={12} />
        <HorizontalLineSpacer />
        <Space height={12} />

        <TouchableOpacity style={styles.section} onPress={async () => await selectCreditCard()}>
        <View style={{flexDirection: 'row'}}>
          <RadioExtended
            radioLabelStyle={styles.radioLabel}
            label={content.payment_method.creditCard}
            selected={optionSelected === 'creditCard'}
            {...testID("checkoutPaymentCardSelect")}
          />

          <Space width={8} />

          <Visa />
          <Space width={4} />
          <MasterCard />
          <Space width={4} />
          <AmericanExpress />
        </View>  

        <Space height={12} />

        <Text style={styles.description}>{content.payment_method.creditCardInstructions}</Text>
        </TouchableOpacity>
      <Space height={24} /> 
      {optionSelected === 'creditCard' &&
        <>
          {/* {!link &&
          <Spinner />
          } */}
          {error &&
          <React.Fragment>
            <Text style={styles.demi} {...testID('paymentError')}>{content.checkout.paymentPollingFailed}</Text>
            <Space height={12} />
          </React.Fragment>
          }
          {link &&
          <WebView
            source={{uri: link}}
            style={styles.webView}
          />    
          }  
       </>
      }
      {!selectedPlan.recurring &&
        <TouchableOpacity onPress={() => selectedOption('cash')}>
          <View style={{flexDirection: 'row'}}>
            <RadioExtended
              radioLabelStyle={styles.radioLabel}
              label={content.payment_method.cash}
              selected={optionSelected === 'cash'}
              {...testID("checkoutPaymentCashSelect")}
            />
            <Space flex/>
            <CashIcon/>
          </View>

          <Space height={12} />

          <Text style={styles.description}>{content.payment_method.cashInstructions}</Text>
        </TouchableOpacity>
        }
        {optionSelected === 'cash' &&
        <>
          <Space height={24} />
          <Button
            style={styles.button}
            title={submitting ? content.common.submitting : content.common.submit}
            disabled={submitting}
            onPress={handleCashMethod}
            titleStyle={{...typography.extrabold, fontSize: 18}}
            {...testID("checkoutPaymentCashSubmit")}
          />
        </>
      }
      </View>

      <Space flex={0.1} />

      <CheckoutSummary />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...(isMobileOnly ? 
      
      { //Mobile Only
        flex: 1,
        alignSelf: 'center',
        marginLeft: '6%',
      } 
      : 
      { //Non-Mobile Only
        flex: 0.5
      }
    ),
  },
  webView: {
    height: 700,
    width: '100%'
  },
  demi: {
    ...typography.demi
  },
  description: {
    ...typography.medium
  },
  radioLabel: {
    ...typography.medium,
    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 14
      } 
      : 
      { //Non-Mobile Only

      }
    ),
  },
  loadingText: {
    ...typography.demi
  },
  button: {
    ...typography.bold,
    backgroundColor: colors.exisOrange
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  },
  buttonTitle: {
    ...typography.demi
  },
  labelStyles: {
    ...typography.demi
  },
  inputStyles: {
    ...typography.demi
  },
  switchStyle: {
    ...typography.demi
  }
})
