import client from "@sart/client";
import AppConfig from '../Configuration';

export default class PollingTimer {
  
  /**
   * Start a Polling timer
   * @param {*} basketRefId 
   * @param {*} onError 
   * @param {*} onSuccess 
   * @returns 
   */
  static start(basketRefId, onError, onSuccess) {
    const timer = setInterval(this.pollOrder.bind(this, basketRefId, onSuccess), AppConfig.orderRefreshTime);
    setTimeout(() => {
      this.stop(timer)
      onError()
    }, AppConfig.orderRefreshTimeout);
    return timer;
  }

  /**
   * Stop a polling timer
   * @param {*} timer 
   */
  static stop(timer) {
    clearInterval(timer);
  }

  /**
   * Poll order
   * @param {*} basketRefId 
   * @param {*} cb 
   */
  static async pollOrder(basketRefId, cb) {
    if (basketRefId && client.cache.customerId) {
      const order = await client.getOrder(basketRefId);
      
      if (order) {
        client.setCache({ orderId: order.orderId });
        cb();
      }
    }
  }

}