import * as React from "react"
import Svg, { G, Path, Text, TSpan } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const HolSmallLogoWithSpeechBubble = (props) => {

const { text } = props;

return (
  <Svg width={75} height={77} xmlns="http://www.w3.org/2000/svg" {...props}>
  <G fill="none" fillRule="evenodd">
    <Path
      d="m25.43 23.615-18.476.71L1 5.812 39.927 1 40 22.497l-7.276.602L32.419 28l-6.989-4.385ZM36.18 3.631 4.49 7.448l4.099 14.768 17.642-.673 3.962 2.486.173-2.78 7.027-.581L36.18 3.63Z"
      fill="#000"
      fillRule="nonzero"
    />
    <Path
      fill="#FFF"
      fillRule="nonzero"
      d="m39 2-.075 19.54-7.146.595-.24 3.865-5.47-3.457-17.903.693L3 6.492z"
    />
    <Text
      fontFamily="AvertaDemoPE-ExtraBold, Averta Demo PE"
      fontSize={12}
      fontStyle="italic"
      fontWeight={700}
      fill="#292828"
      transform="translate(.441 .411)"
    >
      <TSpan x={10.531} y={17.589}>
        {text}
      </TSpan>
    </Text>
    <Path
      d="M16.336 60.075C14.874 48.9 20.544 38.02 32.786 36.418c8.975-1.173 15.585 3.463 16.824 12.924 1.482 11.307-3.272 22.003-16.583 23.746-8.822 1.16-15.441-3.477-16.69-13.013Zm22.548-8.6c-.652-4.982-3.78-5.659-5.703-5.407-4.697.615-6.799 6.682-6.102 12.025.538 4.129 3.202 5.663 5.48 5.365 4.7-.612 7.071-6.289 6.325-11.982ZM66.769 20.794l.305 42.458a5.349 5.349 0 0 1-10.696.084l-.359-42.45a5.35 5.35 0 0 1 4.654-5.35h.056a5.349 5.349 0 0 1 6.04 5.258Z"
      stroke="#000"
      strokeWidth={3.77}
      strokeLinejoin="round"
    />
    <Path
      d="M17.408 58.7c-1.465-11.175 4.207-22.054 16.45-23.656 8.974-1.174 15.583 3.462 16.823 12.923 1.481 11.318-3.274 22.003-16.584 23.746-8.826 1.161-15.442-3.482-16.69-13.013Zm22.547-8.6c-.651-4.982-3.782-5.658-5.702-5.405-4.7.613-6.8 6.68-6.103 12.025.539 4.128 3.202 5.663 5.48 5.365 4.699-.623 7.07-6.29 6.325-11.982V50.1ZM67.84 19.42l.304 42.459a5.347 5.347 0 0 1-10.694.083l-.36-42.451a5.347 5.347 0 0 1 4.653-5.347h.058a5.347 5.347 0 0 1 6.04 5.255Z"
      fill="#FCEE21"
      fillRule="nonzero"
    />
    <Path
      d="M73.744 18.922a8.237 8.237 0 0 0-1.013-3.669 9.838 9.838 0 0 0-2.455-2.98c-.501-.43-1.05-.802-1.635-1.107a18.013 18.013 0 0 0-1.919-.718.035.035 0 1 0-.025.065 7.646 7.646 0 0 1 1.588 1.124c.486.394.946.819 1.379 1.27.793.875 1.454 1.86 1.962 2.926.464 1.028.825 2.1 1.077 3.2.309 1.143.41 2.331.298 3.51-.01.093.138.134.166.039.382-1.183.577-2.417.577-3.66ZM13.228 70.47a41.72 41.72 0 0 1-2.903-10.627 30.721 30.721 0 0 1-.12-5.496 15.21 15.21 0 0 1 1.511-6.21c.026-.048-.052-.09-.084-.046-2.454 3.288-2.74 7.759-2.37 11.72a25.68 25.68 0 0 0 1.155 5.645c.285.878.668 1.721 1.143 2.513.52.856.987 1.746 1.585 2.554a.049.049 0 0 0 .083-.052ZM9.704 68.672c-.237-.657-.522-1.29-.766-1.944-.21-.64-.359-1.297-.445-1.965-.239-1.34-.57-2.656-.73-4.01a29.944 29.944 0 0 1-.19-4.05 26.582 26.582 0 0 1 .238-4.073.06.06 0 0 0-.113-.029 21.048 21.048 0 0 0-.98 4.103c-.13 1.398-.11 2.805.06 4.198a14.78 14.78 0 0 0 .952 4.078c.273.637.63 1.239.897 1.88.263.654.588 1.28.973 1.872.042.059.126 0 .104-.06Z"
      fill="#000"
      fillRule="nonzero"
    />
  </G>
</Svg>
)}

export default HolSmallLogoWithSpeechBubble
