import * as colors from './colors'
import * as backgroundColors from './backgroundColors'
import { typography } from '.'

export default {
  switch: {
    trackColor: {
      false: backgroundColors.exisOrangeclear,
      true: backgroundColors.exisOrange
    }
  },
  slider: {
    minimumTrackTintColor: colors.exisOrange,
    maximumTrackTintColor: colors.subtle,
  },
  tabSelector: {
    container: {
      backgroundColor: colors.exisOrange,
      borderColor: colors.exisOrange,
    },
    activeTab: {
      backgroundColor: colors.exisOrange,
    },
    separator: {
      backgroundColor: colors.exisOrange,
    }
  },
  horizontalLineSpacer: {
    backgroundColor: colors.exisBlue
  },
  plans: {
    container: {
      backgroundColor: backgroundColors.exisBlue
    },
    title: {
      color: colors.exisDarkCharcoal
    },
  },
  planItem: {
    label: { color: colors.exisBlue },
    value: { color: colors.exisBlue },
    unit: { color: colors.exisBlue },
    duration: { color: colors.exisBlue },
    price: { color: colors.exisBlue },
  },
  navigationHeader: {
    color: colors.exisBlue,
    textAlign:'center',
    ...typography.extrabold,
    fontSize: 22
  },
  navigationHeaderProcessing: {
    color: colors.white,
    textAlign:'center',
    ...typography.extrabold,
    fontSize: 22
  },
  navigationHeaderLong: {
    color: colors.exisBlue,
    textAlign:'center',
    ...typography.extrabold,
    fontSize: 18
  },
  navigationItemHeader: {
    color: colors.exisBlue,
    textAlign: 'center',
    ...typography.bold,
    fontSize: 16,
  },
  navigationHeaderSwitchNumber: {
    color: colors.white,
    textAlign:'center',
    ...typography.extrabold,
    fontSize: 22
  },
  navigationHeaderPlan: {
    color: colors.clear,
    textAlign:'center',
    ...typography.lightbold,
    fontSize: 22
  },
  navigationHeaderUpdateReciept: {
    color: colors.exisBlue,
    flex:1,
    textAlign:'center',
    ...typography.extrabold,
    fontSize: 20
  },
}
