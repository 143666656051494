import * as React from "react";
import PropTypes from 'prop-types'
import Svg, { G, Path, Polygon } from "react-native-svg";


export default function Youtube(props) {
  return (
    <Svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G
        id="7.9\u201D-iPad-mini-Copy-3"
        transform="translate(-627.000000, -998.000000)"
        fillRule="nonzero"
      >
        <G id="youtube" transform="translate(627.000000, 998.000000)">
          <Path
            d="M12.7929688,10.4129639 C12.6776123,10.8411865 12.3399658,11.178833 11.9117432,11.2940674 C11.1293945,11.5 8,11.5 8,11.5 C8,11.5 4.87878419,11.5 4.08825684,11.2858887 C3.66003419,11.1706543 3.32238769,10.8328858 3.20703125,10.4046631 C3.00109863,9.63061525 3.00109863,8 3.00109863,8 C3.00109863,8 3.00109863,6.37768556 3.20703125,5.59533691 C3.32226563,5.16711425 3.66821288,4.821167 4.08825684,4.70593262 C4.87060547,4.5 8,4.5 8,4.5 C8,4.5 11.1293945,4.5 11.9117432,4.71411131 C12.3399658,4.82934569 12.6776123,5.16711425 12.7929688,5.59533691 C13.0070801,6.37768556 12.9988646,8.00817872 12.9988646,8.00817872 C12.9988646,8.00817872 12.9988646,9.63061525 12.7929688,10.4129639 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <Polygon
            id="Path"
            fill="#808080"
            points="6.85714286 9.52380952 9.52380952 8 6.85714286 6.47619048"
          />
          <Path
            d="M12.7929688,10.4129639 C12.6776123,10.8411865 12.3399658,11.178833 11.9117432,11.2940674 C11.1293945,11.5 8,11.5 8,11.5 C8,11.5 4.87878419,11.5 4.08825684,11.2858887 C3.66003419,11.1706543 3.32238769,10.8328858 3.20703125,10.4046631 C3.00109863,9.63061525 3.00109863,8 3.00109863,8 C3.00109863,8 3.00109863,6.37768556 3.20703125,5.59533691 C3.32226563,5.16711425 3.66821288,4.821167 4.08825684,4.70593263 C4.87060547,4.5 8,4.5 8,4.5 C8,4.5 11.1293945,4.5 11.9117432,4.71411131 C12.3399658,4.82934569 12.6776123,5.16711425 12.7929688,5.59533691 C13.0070801,6.37768556 12.9988646,8.00817872 12.9988646,8.00817872 C12.9988646,8.00817872 12.9988646,9.63061525 12.7929688,10.4129639 Z M8,0 C3.58239747,0 0,3.58239747 0,8 C0,12.4176025 3.58239747,16 8,16 C12.4176025,16 16,12.4176025 16,8 C16,3.58239747 12.4176025,0 8,0 Z"
            id="Shape"
            fill="#808080"
          />
        </G>
      </G>
    </G>
  </Svg>
  )
}

Youtube.defaultProps = {
  width: 121,
  height: 154,
  color: '#3BA9EE'
}

Youtube.propTypes = {
  color: PropTypes.string
}
