import * as React from 'react'

import { StyleSheet, TouchableOpacity } from 'react-native'

import Svg, { G, Path } from 'react-native-svg'

export default function Delete(props) {
    const { color, size, onPress, ...otherProps } = props;

    let localColor
    color ? localColor = color : localColor = 'black'

    let localSize
    size ? localSize = size : localSize = 33

    return (
        <TouchableOpacity onPress={onPress}>
        <Svg width={localSize} height={localSize} viewBox="0 0 32 32" {...props}>
          <G fill="none" fillRule="evenodd">
            <G fill={localColor} fillRule="nonzero">
                <Path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
            </G>
          </G>
        </Svg>
        </TouchableOpacity>
    )
}