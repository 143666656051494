import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import {
  Headline,
  Button,
  Space,
} from '@sart/components'
import { backgroundColors, colors, typography, adaptive } from '@sart/styles'
import { useContent } from '@sart/content'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'

import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  isMobileOnly
} from "react-device-detect";

export default function Home(props) {
  const { history } = props

  const content = useContent()

  const [eliotJourney, setEliotJourney] = useState(false)

  useEffect(() => {
    getEliotJourney()
  })

  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  return (
    <Wrapper
      wrapperStyle={styles.wrapper}
      style={styles.container}
    >
      <Headline style={styles.header}>{content.home.title}</Headline>

      <Space height={48} />
      <Button
        title={content.home.action}
        onPress={() => void history.push(eliotJourney ? 'hol_plans' : '/plans')}
        style={{ backgroundColor: colors.exisOrange, borderRadius: 15 }}
        titleStyle = {{...typography.bold}}
        {...testID("welcomePlansButton")}
      />
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: backgroundColors.exisBlue,
  },
  container: {
    minHeight: isMobileOnly ? 460 : 640,
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    fontSize: isMobileOnly ? 80 : 150,
    lineHeight: isMobileOnly ? 80 : 130,
    fontWeight: 700,
    textAlign: 'center',
    ...typography.bold,
  }
})
