import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

function Share(props) {
  const { size } = props;
   
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#019ABC" fillRule="nonzero">
        <Path d="M10.99 9.796a.495.495 0 00.666 0l6.56-6.56v4.882c0 .258.193.473.472.473l.645.022a.457.457 0 00.323-.15.638.638 0 00.15-.323V1.043a.457.457 0 00-.15-.323.638.638 0 00-.323-.15h-7.096a.464.464 0 00-.474.473v.624c0 .258.194.473.474.473l4.881.043-6.559 6.559a.495.495 0 000 .667l.43.387z" />
        <Path d="M19.785 12.785a.466.466 0 00-.452-.43h-.623c-.215 0-.387.15-.452.365 0 .043-.021.065-.021.108v11.01h.021H1.57V2.119h5.957A.477.477 0 008 1.645v-.623a.477.477 0 00-.473-.474h-6.28C.56.548 0 1.108 0 1.796V24.14c0 .688.56 1.247 1.247 1.247h17.29c.689 0 1.248-.559 1.248-1.247V12.85c.021-.022.021-.044 0-.065z" />
      </G>
    </Svg>
  )
}

export default Share
