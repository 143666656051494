import React from 'react'
// import PropTypes from 'prop-types'
import Svg, { G, Path, Polygon, Text, TSpan } from 'svgs'

// import { colors } from '@sart/styles'

const EliotCost = (props) => {
  const { cost, colour, fontFamily } = props
  return (
    <Svg
    width="115px"
    height="80px"
    viewBox="0 0 115 80"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Path
        d="M71.91,66.22 L18.2,68.29 L0.9,14.29 L114.05,0.29 L114.26,63.02 L93.11,64.77 L92.22,79.07 L71.91,66.22 Z M103.16,7.9 L11,19 L23,62.14 L74.28,60.14 L85.79,67.4 L86.3,59.28 L106.72,57.59 L103.16,7.9 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
      <Polygon
        id="Path"
        fill="#FFFFFF"
        fillRule="nonzero"
        points="111.68 2.23 111.47 58.88 90.68 60.61 89.98 71.81 74.07 61.79 21.99 63.8 6.96 15.25"
      />
      <Text
        id="$279"
        fill={colour}
        fillRule="nonzero"
        transform="translate(56.521067, 36.860669) rotate(-9.710593) translate(-56.521067, -36.860669) "
        fontFamily={fontFamily}
        fontSize={30}
        fontWeight="normal"
      >
        <TSpan x={24.0255881} y={41.8606694}>
          {`$${cost}`}
        </TSpan>
        <TSpan y={30.8606694} fontSize={16}>
          {"00"}
        </TSpan>
      </Text>
    </G>
  </Svg>
  )
}

export default EliotCost
/*Logo.defaultProps = {
  width: 121,
  height: 154,
  color: colors.exisOrange
}

Logo.propTypes = {
  color: PropTypes.string
}*/
