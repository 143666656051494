import { Dimensions } from 'react-native'

export const small = 'small'
export const medium = 'medium'
export const large = 'large'

export const list = [small, medium, large]

export const defaultSize = (() => {
  const window = Dimensions.get('window')

  if (window.scale >= 3) {
    return large
  }

  if (window.scale > 1 || window.scale < 3) {
    return medium
  }

  if (window.scale <= 1) {
    return small
  }
})()

export function selectStyleByDefaultSize(styles) {
  return styles[defaultSize]
}
