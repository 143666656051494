import React from 'react'
import { TouchableOpacity } from 'react-native'

import { withRouter } from 'react-router-dom'

import { colors } from '@sart/styles'
import { Logo } from '@sart/icons'

function NavbarLink(props) {
  const { to, history, ...rest} = props

  return (
    <TouchableOpacity onPress={() => history.push(to)} {...rest}>
      <Logo color={colors.exisOnTint} width="55" height="70" />
    </TouchableOpacity>
  )
}

export default withRouter(NavbarLink)


