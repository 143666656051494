import React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "svgs";

export default function UK(props) {
  return (
    <Svg width={30} height={20} viewBox="0 0 36 24" {...props}>
      <Defs>
        <ClipPath id="clipPath">
          <Rect x="0" y="0" rx="4" ry="4" width="36" height="24" />
        </ClipPath>
      </Defs>

      <G clipPath="url(#clipPath)">
        <Path fill="#41479B" d="M.3.2h36v24H.3z" />
        <Path fill="#F5F5F5" d="M26.6 15.4h9.7V9.1h-9.7l9.7-6.4V.2h-3.1L21.5 7.9V.2h-6.3v7.7L3.4.2H.3v2.5L10 9.1H.3v6.3H10L.3 21.8v2.4h3.1l11.8-7.6v7.6h6.3v-7.6l11.7 7.6h3.1v-2.4z" />
        <Path fill="#FF4B55" d="M35.1.2L21.5 9h2.3L36.3.9V.2h-1.2zM13.9 9.1L.3.3v1.5l11.3 7.3h2.3zm8.6 6.3l13.6 8.8h.2v-1.4l-11.5-7.5h-2.3v.1zm-9.7 0L.3 23.5v.7h1.2l13.6-8.8h-2.3zM20.2.2h-3.8v10.1H.3v3.8h16.1v10.1h3.8V14.1h16.1v-3.8H20.2V.2z" />
      </G>
    </Svg>
  )
}
