import React from "react";
import Svg, { Path } from "svgs";

export default function Confirmation(props) {
  return (
    <Svg width={96} height={96} viewBox="0 0 96 96" {...props}>
      <Path
        d="M48 0c26.51 0 48 21.49 48 48S74.51 96 48 96 0 74.51 0 48 21.49 0 48 0zm0 5.486C24.52 5.486 5.486 24.52 5.486 48 5.486 71.48 24.52 90.514 48 90.514c23.48 0 42.514-19.034 42.514-42.514C90.514 24.52 71.48 5.486 48 5.486zm21.14 26.86a2.743 2.743 0 0 1 0 3.88L41.71 63.653a2.743 2.743 0 0 1-3.878 0L25.489 51.31a2.743 2.743 0 0 1 3.88-3.88L39.77 57.836l25.49-25.489a2.743 2.743 0 0 1 3.878 0z"
        fill="#BCC3CA"
        fillRule="evenodd"
      />
    </Svg>
  )
}
