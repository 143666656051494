// Custom
export const feature = '#4D5054'
export const focus = '#7C7E82'
export const trivial = '#DBE0E6'
export const subtle = '#F1F3F5'
export const clear = '#FFFFFF'
export const white = '#FFFFFF'

// Exis
export const exisOrange = '#FF5210'
export const exisBlue = '#009ABA'
export const exisOrangeclear = '#FFDFD3'
