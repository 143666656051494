import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TriangleDown(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 20 20"
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      transform="rotate(360)"
      {...props}
    >
      <Path d="M5 6h10l-5 9-5-9z" fill="#626262" />
      <Path fill="rgba(0, 0, 0, 0)" d="M0 0h20v20H0z" />
    </Svg>
  )
}

export default TriangleDown
