import React from 'react'
// import PropTypes from 'prop-types'
import Svg, { G, Path, Circle } from 'svgs'

// import { colors } from '@sart/styles'

const EliotCircleBackground = (props) => {
  const { width, height } = props
  return (
    <Svg
    width={width}
    height={height}
    viewBox="0 0 883 684"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <G
      id="Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      opacity={0.1}
    >
      <G
        id="Group"
        style={{
          mixBlendMode: "multiply",
        }}
        fill="#000000"
        fillRule="nonzero"
      >
        <Path
          d="M641.47,682.4 C641.409249,682.4 641.36,682.449249 641.36,682.51 C641.36,682.570751 641.409249,682.62 641.47,682.62 C641.530751,682.62 641.58,682.570751 641.58,682.51 C641.58,682.449249 641.530751,682.4 641.47,682.4 L641.47,682.4 Z"
          id="Path"
        />
        <Path
          d="M601.43,682.07 C601.246235,682.06596 601.078484,682.17409 601.006281,682.343125 C600.934078,682.51216 600.971937,682.708116 601.101911,682.838089 C601.231884,682.968063 601.42784,683.005922 601.596875,682.933719 C601.76591,682.861516 601.87404,682.693765 601.87,682.51 C601.87,682.393305 601.823643,682.281389 601.741127,682.198873 C601.658611,682.116357 601.546695,682.07 601.43,682.07 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={682.51} r={1} />
        <Path
          d="M521.36,681.4 C520.746964,681.4 520.25,681.896964 520.25,682.51 C520.25,683.123036 520.746964,683.62 521.36,683.62 C521.973036,683.62 522.47,683.123036 522.47,682.51 C522.47,681.896964 521.973036,681.4 521.36,681.4 L521.36,681.4 Z"
          id="Path"
        />
        <Path
          d="M481.33,681.4 C480.716964,681.4 480.22,681.896964 480.22,682.51 C480.22,683.123036 480.716964,683.62 481.33,683.62 C481.943036,683.62 482.44,683.123036 482.44,682.51 C482.44,681.896964 481.943036,681.4 481.33,681.4 L481.33,681.4 Z"
          id="Path"
        />
        <Path
          d="M441.29,681.4 C440.676964,681.4 440.18,681.896964 440.18,682.51 C440.18,683.123036 440.676964,683.62 441.29,683.62 C441.903036,683.62 442.4,683.123036 442.4,682.51 C442.4,681.896964 441.903036,681.4 441.29,681.4 L441.29,681.4 Z"
          id="Path"
        />
        <Path
          d="M401.26,681.51 C400.707715,681.51 400.26,681.957715 400.26,682.51 C400.26,683.062285 400.707715,683.51 401.26,683.51 C401.812285,683.51 402.26,683.062285 402.26,682.51 C402.26,681.957715 401.812285,681.51 401.26,681.51 Z"
          id="Path"
        />
        <Path
          d="M361.23,681.73 C360.799218,681.73 360.45,682.079218 360.45,682.51 C360.45,682.940782 360.799218,683.29 361.23,683.29 C361.660782,683.29 362.01,682.940782 362.01,682.51 C362.01,682.079218 361.660782,681.73 361.23,681.73 L361.23,681.73 Z"
          id="Path"
        />
        <Path
          d="M321.19,682.07 C321.007092,682.070045 320.842408,682.180798 320.773384,682.350182 C320.704359,682.519567 320.744727,682.713879 320.875508,682.841753 C321.006289,682.969627 321.201459,683.00562 321.36925,682.932807 C321.53704,682.859994 321.644065,682.692863 321.64,682.51 C321.640031,682.391551 321.592303,682.278093 321.507612,682.195283 C321.42292,682.112474 321.308418,682.067308 321.19,682.07 Z"
          id="Path"
        />
        <Path
          d="M721.53,642.26 C721.408497,642.26 721.31,642.358497 721.31,642.48 C721.31,642.601503 721.408497,642.7 721.53,642.7 C721.651503,642.7 721.75,642.601503 721.75,642.48 C721.75,642.358497 721.651503,642.26 721.53,642.26 L721.53,642.26 Z"
          id="Path"
        />
        <Path
          d="M681.5,641.7 C681.069218,641.7 680.72,642.049218 680.72,642.48 C680.72,642.910782 681.069218,643.26 681.5,643.26 C681.930782,643.26 682.28,642.910782 682.28,642.48 C682.28,642.049218 681.930782,641.7 681.5,641.7 L681.5,641.7 Z"
          id="Path"
        />
        <Path
          d="M641.47,641 C640.882637,640.995949 640.350853,641.34665 640.123266,641.888145 C639.895679,642.429639 640.017255,643.054943 640.431154,643.471716 C640.845052,643.88849 641.4695,644.014389 642.012557,643.790555 C642.555613,643.566722 642.91,643.037377 642.91,642.45 C642.91,641.653076 642.266905,641.005496 641.47,641 Z"
          id="Path"
        />
        <Path
          d="M601.43,640.37 C600.264679,640.37 599.32,641.314679 599.32,642.48 C599.32,643.645321 600.264679,644.59 601.43,644.59 C602.595321,644.59 603.54,643.645321 603.54,642.48 C603.54,641.314679 602.595321,640.37 601.43,640.37 Z"
          id="Path"
        />
        <Path
          d="M561.4,639.809981 C560.319185,639.805952 559.342541,640.453904 558.926123,641.451287 C558.509705,642.44867 558.735664,643.598721 559.498486,644.364411 C560.261308,645.1301 561.410503,645.360373 562.409442,644.947701 C563.40838,644.535028 564.06,643.560823 564.06,642.48 C564.06,641.009296 562.870694,639.815508 561.4,639.809981 Z"
          id="Path"
        />
        <Path
          d="M521.36,639.37 C519.642394,639.37 518.25,640.762394 518.25,642.48 C518.25,644.197606 519.642394,645.59 521.36,645.59 C523.077606,645.59 524.47,644.197606 524.47,642.48 C524.47,640.762394 523.077606,639.37 521.36,639.37 Z"
          id="Path"
        />
        <Circle id="Oval" cx={481.33} cy={642.48} r={3.22} />
        <Path
          d="M441.29,639.26 C439.511643,639.26 438.07,640.701643 438.07,642.48 C438.07,644.258357 439.511643,645.7 441.29,645.7 C443.068357,645.7 444.51,644.258357 444.51,642.48 C444.51,641.626003 444.170751,640.806983 443.566884,640.203116 C442.963017,639.599249 442.143997,639.26 441.29,639.26 Z"
          id="Path"
        />
        <Path
          d="M401.26,639.48 C399.603146,639.48 398.26,640.823146 398.26,642.48 C398.26,644.136854 399.603146,645.48 401.26,645.48 C402.916854,645.48 404.26,644.136854 404.26,642.48 C404.26,640.823146 402.916854,639.48 401.26,639.48 Z"
          id="Path"
        />
        <Path
          d="M361.23,639.809981 C360.149185,639.805952 359.172541,640.453904 358.756123,641.451287 C358.339705,642.44867 358.565664,643.598721 359.328486,644.364411 C360.091308,645.1301 361.240503,645.360373 362.239442,644.947701 C363.23838,644.535028 363.89,643.560823 363.89,642.48 C363.89,641.009296 362.700694,639.815508 361.23,639.809981 Z"
          id="Path"
        />
        <Path
          d="M321.19,640.37 C320.024679,640.37 319.08,641.314679 319.08,642.48 C319.08,643.645321 320.024679,644.59 321.19,644.59 C322.355321,644.59 323.3,643.645321 323.3,642.48 C323.3,641.314679 322.355321,640.37 321.19,640.37 Z"
          id="Path"
        />
        <Path
          d="M281.16,641 C280.572637,640.995949 280.040853,641.34665 279.813266,641.888145 C279.585679,642.429639 279.707255,643.054943 280.121154,643.471716 C280.535052,643.88849 281.1595,644.014389 281.702557,643.790555 C282.245613,643.566722 282.6,643.037377 282.6,642.45 C282.6,641.653076 281.956905,641.005496 281.16,641 Z"
          id="Path"
        />
        <Path
          d="M241.12,641.7 C240.914039,641.699983 240.716655,641.782479 240.571962,641.929052 C240.427268,642.075625 240.347325,642.274056 240.35,642.48 C240.347284,642.685037 240.427536,642.882472 240.572532,643.027468 C240.717528,643.172464 240.914963,643.252716 241.12,643.25 C241.325944,643.252675 241.524375,643.172732 241.670948,643.028038 C241.817521,642.883345 241.900017,642.685961 241.9,642.48 C241.9,642.273131 241.817822,642.074735 241.671543,641.928457 C241.525265,641.782178 241.326869,641.7 241.12,641.7 Z"
          id="Path"
        />
        <Path
          d="M201.09,642.37 C201.029249,642.37 200.98,642.419249 200.98,642.48 C200.98,642.540751 201.029249,642.59 201.09,642.59 C201.150751,642.59 201.2,642.540751 201.2,642.48 C201.2,642.419249 201.150751,642.37 201.09,642.37 L201.09,642.37 Z"
          id="Path"
        />
        <Path
          d="M761.57,601.89 C761.341732,601.885959 761.133864,602.020895 761.044632,602.231039 C760.955401,602.441183 761.002672,602.684457 761.164108,602.845892 C761.325543,603.007328 761.568817,603.054599 761.778961,602.965368 C761.989105,602.876136 762.124041,602.668268 762.12,602.44 C762.114662,602.138482 761.871518,601.895338 761.57,601.89 L761.57,601.89 Z"
          id="Path"
        />
        <Path
          d="M721.53,601 C720.942623,601 720.413278,601.354387 720.189445,601.897443 C719.965611,602.4405 720.09151,603.064948 720.508284,603.478846 C720.925057,603.892745 721.550361,604.014321 722.091855,603.786734 C722.63335,603.559147 722.984051,603.027363 722.98,602.44 C722.974504,601.643095 722.326924,601 721.53,601 Z"
          id="Path"
        />
        <Path
          d="M681.5,599.99998 C680.507259,599.995956 679.610206,600.591392 679.22843,601.507797 C678.846654,602.424202 679.055629,603.480412 679.757609,604.182391 C680.459588,604.884371 681.515798,605.093346 682.432203,604.71157 C683.348608,604.329794 683.944044,603.432741 683.94002,602.44 C683.94002,601.092425 682.847575,599.99998 681.5,599.99998 L681.5,599.99998 Z"
          id="Path"
        />
        <Path
          d="M641.47,599.11 C639.630892,599.11 638.14,600.600892 638.14,602.44 C638.14,604.279108 639.630892,605.77 641.47,605.77 C643.309108,605.77 644.8,604.279108 644.8,602.44 C644.794509,600.603171 643.306829,599.115491 641.47,599.11 L641.47,599.11 Z"
          id="Path"
        />
        <Path
          d="M601.43,598.229988 C599.72226,598.229988 598.182866,599.25926 597.530281,600.837394 C596.877695,602.415528 597.240577,604.231407 598.449563,605.437528 C599.658549,606.643649 601.475284,607.002222 603.051865,606.345894 C604.628446,605.689566 605.654047,604.147735 605.650012,602.44 C605.650012,601.321704 605.205078,600.249356 604.413386,599.459539 C603.621694,598.669723 602.548293,598.227344 601.43,598.229988 Z"
          id="Path"
        />
        <Path
          d="M561.4,597.55999 C559.420365,597.555956 557.633596,598.745881 556.874153,600.574054 C556.11471,602.402227 556.532442,604.507926 557.932258,605.907742 C559.332074,607.307558 561.437773,607.72529 563.265946,606.965847 C565.094119,606.206404 566.284044,604.419635 566.28001,602.44 C566.274499,599.747132 564.092868,597.565501 561.4,597.55999 Z"
          id="Path"
        />
        <Path
          d="M521.36,597.12 C519.203307,597.12 517.259166,598.419701 516.434773,600.412614 C515.610379,602.405526 516.068227,604.698837 517.594671,606.222416 C519.121114,607.745996 521.415281,608.199536 523.406641,607.371402 C525.398002,606.543268 526.694046,604.596689 526.690009,602.44 C526.684484,599.500228 524.299777,597.12 521.36,597.12 Z"
          id="Path"
        />
        <Path
          d="M481.33,596.779991 C479.034884,596.775956 476.963698,598.155922 476.083526,600.275562 C475.203354,602.395201 475.687819,604.836392 477.310713,606.459287 C478.933608,608.082181 481.374799,608.566646 483.494438,607.686474 C485.614078,606.806302 486.994044,604.735116 486.990009,602.44 C486.990009,599.314068 484.455932,596.779991 481.33,596.779991 Z"
          id="Path"
        />
        <Path
          d="M441.29,596.78 C438.99579,596.78 436.927672,598.162539 436.050653,600.282501 C435.173634,602.402463 435.660571,604.842013 437.284254,606.462833 C438.907936,608.083652 441.348343,608.566281 443.466753,607.685521 C445.585163,606.804761 446.964046,604.734207 446.960009,602.44 C446.954484,599.312451 444.417553,596.78 441.29,596.78 L441.29,596.78 Z"
          id="Path"
        />
        <Path
          d="M401.26,597.12 C399.103307,597.12 397.159166,598.419701 396.334773,600.412614 C395.510379,602.405526 395.968227,604.698837 397.494671,606.222416 C399.021114,607.745996 401.315281,608.199536 403.306641,607.371402 C405.298002,606.543268 406.594046,604.596689 406.590009,602.44 C406.584484,599.500228 404.199777,597.12 401.26,597.12 L401.26,597.12 Z"
          id="Path"
        />
        <Path
          d="M361.23,597.55999 C359.250365,597.555956 357.463596,598.745881 356.704153,600.574054 C355.94471,602.402227 356.362442,604.507926 357.762258,605.907742 C359.162074,607.307558 361.267773,607.72529 363.095946,606.965847 C364.924119,606.206404 366.114044,604.419635 366.11001,602.44 C366.104499,599.747132 363.922868,597.565501 361.23,597.55999 Z"
          id="Path"
        />
        <Path
          d="M321.19,598.229988 C319.48226,598.229988 317.942866,599.25926 317.290281,600.837394 C316.637695,602.415528 317.000577,604.231407 318.209563,605.437528 C319.418549,606.643649 321.235284,607.002222 322.811865,606.345894 C324.388446,605.689566 325.414047,604.147735 325.410012,602.44 C325.410012,601.321704 324.965078,600.249356 324.173386,599.459539 C323.381694,598.669723 322.308293,598.227344 321.19,598.229988 Z"
          id="Path"
        />
        <Path
          d="M281.16,599.11 C279.320892,599.11 277.83,600.600892 277.83,602.44 C277.83,604.279108 279.320892,605.77 281.16,605.77 C282.999108,605.77 284.49,604.279108 284.49,602.44 C284.49,600.600892 282.999108,599.11 281.16,599.11 Z"
          id="Path"
        />
        <Path
          d="M241.12,599.999979 C240.128159,599.999979 239.234174,600.598019 238.855554,601.51475 C238.476934,602.43148 238.688385,603.486049 239.391155,604.185951 C240.093925,604.885852 241.14935,605.092988 242.064524,604.710622 C242.979699,604.328256 243.574048,603.431832 243.570021,602.44 C243.570021,601.791135 243.311562,601.168992 242.851809,600.711114 C242.392055,600.253237 241.768859,599.997341 241.12,599.999979 L241.12,599.999979 Z"
          id="Path"
        />
        <Path
          d="M201.09,601 C200.501729,600.995957 199.969317,601.347747 199.742324,601.890474 C199.515331,602.433202 199.638749,603.059291 200.054729,603.475271 C200.470709,603.891251 201.096798,604.014669 201.639526,603.787676 C202.182253,603.560683 202.534043,603.028271 202.53,602.44 C202.53,601.64471 201.88529,601 201.09,601 L201.09,601 Z"
          id="Path"
        />
        <Path
          d="M161.06,601.89 C160.831732,601.885959 160.623864,602.020895 160.534632,602.231039 C160.445401,602.441183 160.492672,602.684457 160.654108,602.845892 C160.815543,603.007328 161.058817,603.054599 161.268961,602.965368 C161.479105,602.876136 161.614041,602.668268 161.61,602.44 C161.604662,602.138482 161.361518,601.895338 161.06,601.89 L161.06,601.89 Z"
          id="Path"
        />
        <Path
          d="M801.6,561.74 C801.229969,561.74 800.93,562.039969 800.93,562.41 C800.93,562.780031 801.229969,563.08 801.6,563.08 C801.970031,563.08 802.27,562.780031 802.27,562.41 C802.27,562.039969 801.970031,561.74 801.6,561.74 Z"
          id="Path"
        />
        <Path
          d="M761.57,560.74 C760.94435,560.691477 760.344308,560.997787 760.016628,561.532968 C759.688948,562.068148 759.688948,562.741852 760.016628,563.277032 C760.344308,563.812213 760.94435,564.118523 761.57,564.07 C762.43962,564.002555 763.110868,563.277231 763.110868,562.405 C763.110868,561.532769 762.43962,560.807445 761.57,560.74 L761.57,560.74 Z"
          id="Path"
        />
        <Path
          d="M721.53,559.41 C719.873146,559.41 718.53,560.753146 718.53,562.41 C718.53,564.066854 719.873146,565.41 721.53,565.41 C723.186854,565.41 724.53,564.066854 724.53,562.41 C724.53,560.753146 723.186854,559.41 721.53,559.41 L721.53,559.41 Z"
          id="Path"
        />
        <Path
          d="M681.5,558.19 C679.169358,558.19 677.28,560.079358 677.28,562.41 C677.28,564.740642 679.169358,566.63 681.5,566.63 C683.830642,566.63 685.72,564.740642 685.72,562.41 C685.72,561.290786 685.275394,560.217413 684.483991,559.426009 C683.692587,558.634606 682.619214,558.19 681.5,558.19 L681.5,558.19 Z"
          id="Path"
        />
        <Path
          d="M641.47,556.999991 C639.26882,556.995954 637.282131,558.318799 636.436971,560.351264 C635.591811,562.383729 636.054762,564.725208 637.609802,566.283111 C639.164841,567.841015 641.505465,568.308274 643.539482,567.466854 C645.573498,566.625435 646.9,564.641184 646.9,562.44 C646.9,559.439472 644.470523,557.005516 641.47,556.999991 Z"
          id="Path"
        />
        <Path
          d="M601.43,556 C597.873286,556 594.99,558.883286 594.99,562.44 C594.99,565.996714 597.873286,568.88 601.43,568.88 C604.986714,568.88 607.87,565.996714 607.87,562.44 C607.87,560.732006 607.191502,559.093967 605.983768,557.886232 C604.776033,556.678498 603.137994,556 601.43,556 Z"
          id="Path"
        />
        <Path
          d="M561.4,554.999993 C558.389894,554.995954 555.673933,556.806085 554.519211,559.585899 C553.364488,562.365714 553.998553,565.56743 556.125589,567.697329 C558.252625,569.827228 561.453485,570.465597 564.23485,569.314615 C567.016216,568.163633 568.83,565.450109 568.83,562.44 C568.83,558.334904 565.505093,555.005518 561.4,554.999993 Z"
          id="Path"
        />
        <Path
          d="M521.36,554.309994 C516.887799,554.315521 513.266323,557.944456 513.27,562.416658 C513.273682,566.888861 516.901126,570.511831 521.373329,570.509994 C525.845533,570.508155 529.47,566.882204 529.47,562.41 C529.47,560.260013 528.615239,558.198229 527.09403,556.678897 C525.572822,555.159564 523.509986,554.307346 521.36,554.309994 Z"
          id="Path"
        />
        <Path
          d="M481.33,554 C476.668717,554 472.89,557.778717 472.89,562.44 C472.89,567.101283 476.668717,570.88 481.33,570.88 C485.991283,570.88 489.77,567.101283 489.77,562.44 C489.77,557.778717 485.991283,554 481.33,554 Z"
          id="Path"
        />
        <Path
          d="M441.29,553.999994 C436.628717,553.999994 432.85,557.778717 432.85,562.44 C432.85,567.101283 436.628717,570.88 441.29,570.88 C445.951283,570.88 449.730006,567.101283 449.730006,562.44 C449.732658,560.200759 448.8443,558.052467 447.260916,556.469084 C445.677533,554.8857 443.529241,553.997342 441.29,553.999994 L441.29,553.999994 Z"
          id="Path"
        />
        <Path
          d="M401.26,554.309994 C396.786494,554.309994 393.16,557.936494 393.16,562.41 C393.16,566.883506 396.786494,570.51 401.26,570.51 C405.733506,570.51 409.360006,566.883506 409.360006,562.41 C409.362658,560.260933 408.510121,558.199121 406.9905,556.6795 C405.470879,555.159879 403.409067,554.307342 401.26,554.309994 Z"
          id="Path"
        />
        <Path
          d="M361.23,554.999993 C358.219894,554.995954 355.503933,556.806085 354.349211,559.585899 C353.194488,562.365714 353.828553,565.56743 355.955589,567.697329 C358.082625,569.827228 361.283485,570.465597 364.06485,569.314615 C366.846216,568.163633 368.66,565.450109 368.66,562.44 C368.66,558.334904 365.335093,555.005518 361.23,554.999993 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(321.195064, 562.414647) rotate(-45.000000) translate(-321.195064, -562.414647) "
          cx={321.195064}
          cy={562.414647}
          r={6.55}
        />
        <Path
          d="M281.16,557 C278.155571,557 275.72,559.435571 275.72,562.44 C275.72,565.444429 278.155571,567.88 281.16,567.88 C284.164429,567.88 286.6,565.444429 286.6,562.44 C286.6,560.997222 286.026859,559.613537 285.006661,558.593339 C283.986463,557.573141 282.602778,557 281.16,557 Z"
          id="Path"
        />
        <Path
          d="M241.12,558.08 C239.368678,558.08 237.789803,559.134971 237.119602,560.752981 C236.4494,562.370991 236.819856,564.233401 238.058228,565.471772 C239.296599,566.710144 241.159009,567.0806 242.777019,566.410398 C244.395029,565.740197 245.45,564.161322 245.45,562.41 C245.45,560.018607 243.511393,558.08 241.12,558.08 L241.12,558.08 Z"
          id="Path"
        />
        <Path
          d="M201.09,559.3 C199.372394,559.3 197.98,560.692394 197.98,562.41 C197.98,564.127606 199.372394,565.52 201.09,565.52 C202.807606,565.52 204.2,564.127606 204.2,562.41 C204.2,561.585177 203.87234,560.794136 203.289102,560.210898 C202.705864,559.62766 201.914823,559.3 201.09,559.3 Z"
          id="Path"
        />
        <Path
          d="M161.06,560.629972 C160.339161,560.62595 159.687046,561.057056 159.408382,561.721866 C159.129718,562.386675 159.279529,563.15392 159.787809,563.665072 C160.29609,564.176223 161.062478,564.330355 161.728847,564.055441 C162.395216,563.780527 162.83,563.13085 162.83,562.41 C162.83,561.430825 162.039159,560.635501 161.06,560.629972 L161.06,560.629972 Z"
          id="Path"
        />
        <Path
          d="M121,561.74 C120.629969,561.74 120.33,562.039969 120.33,562.41 C120.33,562.780031 120.629969,563.08 121,563.08 C121.370031,563.08 121.67,562.780031 121.67,562.41 C121.67,562.039969 121.370031,561.74 121,561.74 L121,561.74 Z"
          id="Path"
        />
        <Path
          d="M841.64,521.93 C841.456235,521.92596 841.288484,522.03409 841.216281,522.203125 C841.144078,522.37216 841.181937,522.568116 841.311911,522.698089 C841.441884,522.828063 841.63784,522.865922 841.806875,522.793719 C841.97591,522.721516 842.08404,522.553765 842.08,522.37 C842.074706,522.129222 841.880778,521.935294 841.64,521.93 L841.64,521.93 Z"
          id="Path"
        />
        <Path
          d="M801.6,520.70997 C800.923641,520.710012 800.314075,521.117985 800.056188,521.74325 C799.7983,522.368515 799.943021,523.087589 800.422713,523.564409 C800.902405,524.041229 801.622336,524.181628 802.246041,523.91999 C802.869746,523.658352 803.27405,523.046347 803.27003,522.37 C803.27003,521.928003 803.093744,521.504252 802.780267,521.192653 C802.466791,520.881054 802.041989,520.707337 801.6,520.70997 L801.6,520.70997 Z"
          id="Path"
        />
        <Path
          d="M761.57,519.269984 C760.311214,519.269984 759.176567,520.02882 758.69579,521.192176 C758.215013,522.355531 758.48293,523.693979 759.374458,524.58264 C760.265986,525.471302 761.60529,525.734907 762.767091,525.250385 C763.928892,524.765864 764.684048,523.62878 764.680016,522.37 C764.680016,521.546093 764.352026,520.756077 763.768498,520.174426 C763.184971,519.592775 762.393902,519.267342 761.57,519.269984 Z"
          id="Path"
        />
        <Path
          d="M721.53,517.819989 C719.684743,517.819989 718.021372,518.932098 717.316161,520.637281 C716.61095,522.342464 717.00292,524.304584 718.309145,525.607944 C719.615371,526.911305 721.578346,527.298967 723.281977,526.590014 C724.985608,525.881061 726.094047,524.215252 726.090011,522.37 C726.090011,521.16153 725.609257,520.002702 724.753802,519.149123 C723.898348,518.295545 722.738467,517.817344 721.53,517.819989 L721.53,517.819989 Z"
          id="Path"
        />
        <Path
          d="M681.5,516.269992 C679.02692,516.265956 676.795294,517.753125 675.847018,520.037181 C674.898742,522.321237 675.420852,524.951681 677.169586,526.700414 C678.918319,528.449148 681.548763,528.971258 683.832819,528.022982 C686.116875,527.074706 687.604044,524.84308 687.600008,522.37 C687.594494,519.003346 684.866654,516.275506 681.5,516.269992 Z"
          id="Path"
        />
        <Path
          d="M641.47,514.829993 C638.414494,514.825956 635.65779,516.66397 634.48663,519.486118 C633.315469,522.308265 633.960779,525.558079 636.12135,527.71865 C638.281921,529.879221 641.531735,530.524531 644.353882,529.35337 C647.17603,528.18221 649.014044,525.425506 649.010007,522.37 C649.004491,518.208057 645.631943,514.835509 641.47,514.829993 L641.47,514.829993 Z"
          id="Path"
        />
        <Path
          d="M601.43,513.489994 C597.832514,513.485956 594.587197,515.650451 593.20863,518.973322 C591.830063,522.296194 592.590018,526.122365 595.133826,528.666174 C597.677635,531.209982 601.503806,531.969937 604.826678,530.59137 C608.149549,529.212803 610.314044,525.967486 610.310006,522.37 C610.304489,517.467996 606.332004,513.495511 601.43,513.489994 L601.43,513.489994 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={522.37} r={10.1} />
        <Path
          d="M521.36,511.5 C516.958541,511.5 512.990667,514.151912 511.307238,518.218717 C509.623808,522.285522 510.556489,526.965988 513.670221,530.076858 C516.783953,533.187728 521.465274,534.116105 525.53053,532.428936 C529.595785,530.741768 532.244045,526.771457 532.240005,522.37 C532.234481,516.365048 527.364955,511.5 521.36,511.5 Z"
          id="Path"
        />
        <Path
          d="M481.33,511.049996 C476.745625,511.045956 472.610596,513.804938 470.854363,518.039575 C469.09813,522.274213 470.066841,527.149872 473.308485,530.391515 C476.550128,533.633159 481.425787,534.60187 485.660425,532.845637 C489.895062,531.089404 492.654044,526.954375 492.650004,522.37 C492.650004,519.367749 491.457361,516.488463 489.334449,514.365551 C487.211537,512.242639 484.332251,511.049996 481.33,511.049996 Z"
          id="Path"
        />
        <Path
          d="M441.29,510.939996 C436.662042,510.939996 432.489972,513.728352 430.719865,518.004414 C428.949758,522.280477 429.93035,527.201809 433.204241,530.472838 C436.478132,533.743867 441.400319,534.720155 445.674832,532.946309 C449.949345,531.172463 452.734045,526.997956 452.730004,522.37 C452.730004,519.336842 451.5244,516.42806 449.378696,514.284232 C447.232992,512.140403 444.323157,510.937346 441.29,510.939996 Z"
          id="Path"
        />
        <Path
          d="M401.26,511.38 C395.184868,511.38 390.26,516.304868 390.26,522.38 C390.26,528.455132 395.184868,533.38 401.26,533.38 C407.335132,533.38 412.26,528.455132 412.26,522.38 C412.26,516.304868 407.335132,511.38 401.26,511.38 Z"
          id="Path"
        />
        <Path
          d="M361.23,512.159995 C357.094579,512.155956 353.364296,514.644495 351.77987,518.464354 C350.195444,522.284212 351.069188,526.682441 353.993374,529.606626 C356.917559,532.530812 361.315788,533.404556 365.135646,531.82013 C368.955505,530.235704 371.444044,526.505421 371.440005,522.37 C371.434487,516.733458 366.866542,512.165513 361.23,512.159995 Z"
          id="Path"
        />
        <Path
          d="M321.19,513.269994 C317.504439,513.269994 314.181972,515.490666 312.772505,518.896068 C311.363038,522.301469 312.144286,526.220626 314.751801,528.825279 C317.359317,531.429933 321.279329,532.206875 324.683181,530.793669 C328.087032,529.380463 330.304046,526.055558 330.300006,522.37 C330.300006,519.954796 329.339882,517.638659 327.631137,515.93179 C325.922393,514.224921 323.605202,513.267346 321.19,513.269994 L321.19,513.269994 Z"
          id="Path"
        />
        <Path
          d="M281.16,514.489994 C277.966976,514.485956 275.086297,516.406809 273.86251,519.356006 C272.638724,522.305203 273.313123,525.701256 275.570934,527.959066 C277.828744,530.216877 281.224797,530.891276 284.173994,529.66749 C287.123191,528.443703 289.044044,525.563024 289.040006,522.37 C289.040006,518.017996 285.512004,514.489994 281.16,514.489994 Z"
          id="Path"
        />
        <Path
          d="M241.12,516.049992 C238.558845,516.049992 236.250067,517.593342 235.270892,519.959929 C234.291717,522.326516 234.83512,525.049945 236.647562,526.859523 C238.460003,528.669101 241.184287,529.208198 243.549323,528.225282 C245.914359,527.242367 247.454046,524.931152 247.450008,522.37 C247.450008,520.692098 246.782774,519.083064 245.595381,517.897546 C244.407987,516.712028 242.7979,516.047345 241.12,516.049992 Z"
          id="Path"
        />
        <Path
          d="M201.09,517.599989 C199.154856,517.595956 197.408197,518.759081 196.66578,520.546149 C195.923363,522.333218 196.331684,524.391604 197.70004,525.75996 C199.068396,527.128316 201.126782,527.536637 202.913851,526.79422 C204.700919,526.051803 205.864044,524.305144 205.860011,522.37 C205.860011,519.735602 203.724398,517.599989 201.09,517.599989 Z"
          id="Path"
        />
        <Path
          d="M161.06,519.159984 C159.75582,519.155956 158.577995,519.938998 158.077037,521.143133 C157.57608,522.347268 157.850933,523.73467 158.773132,524.656868 C159.69533,525.579067 161.082732,525.85392 162.286867,525.352963 C163.491002,524.852005 164.274044,523.67418 164.270016,522.37 C164.26451,520.599445 162.830555,519.16549 161.06,519.159984 L161.06,519.159984 Z"
          id="Path"
        />
        <Path
          d="M121,520.49 C120.234659,520.49 119.544872,520.951584 119.252931,521.659057 C118.960991,522.36653 119.124533,523.180231 119.667144,523.719972 C120.209756,524.259713 121.024313,524.418935 121.730228,524.123246 C122.436142,523.827556 122.894049,523.135331 122.890027,522.37 C122.884498,521.330089 122.039925,520.49 121,520.49 L121,520.49 Z"
          id="Path"
        />
        <Path
          d="M81,521.71 C80.7272325,521.705958 80.4792499,521.867698 80.3729906,522.11895 C80.2667314,522.370202 80.3234151,522.660791 80.516312,522.853688 C80.7092089,523.046585 80.9997983,523.103269 81.25105,522.997009 C81.5023016,522.89075 81.6640417,522.642767 81.66,522.37 C81.6546323,522.007739 81.3622613,521.715368 81,521.71 Z"
          id="Path"
        />
        <Path
          d="M881.67,482.23 C881.609249,482.23 881.56,482.279249 881.56,482.34 C881.56,482.400751 881.609249,482.45 881.67,482.45 C881.730751,482.45 881.78,482.400751 881.78,482.34 C881.78,482.279249 881.730751,482.23 881.67,482.23 L881.67,482.23 Z"
          id="Path"
        />
        <Path
          d="M841.64,481.23 C841.026964,481.23 840.53,481.726964 840.53,482.34 C840.53,482.953036 841.026964,483.45 841.64,483.45 C842.253036,483.45 842.75,482.953036 842.75,482.34 C842.75,481.726964 842.253036,481.23 841.64,481.23 L841.64,481.23 Z"
          id="Path"
        />
        <Path
          d="M801.6,479.679981 C800.519177,479.679981 799.544972,480.33162 799.132299,481.330558 C798.719627,482.329497 798.9499,483.478692 799.715589,484.241514 C800.481279,485.004336 801.63133,485.230295 802.628713,484.813877 C803.626096,484.397459 804.274048,483.420815 804.270019,482.34 C804.270019,481.632788 803.988384,480.954687 803.487372,480.455552 C802.98636,479.956416 802.307207,479.677341 801.6,479.679981 L801.6,479.679981 Z"
          id="Path"
        />
        <Path
          d="M761.57,478 C759.178607,478 757.24,479.938607 757.24,482.33 C757.24,484.721393 759.178607,486.66 761.57,486.66 C763.961393,486.66 765.9,484.721393 765.9,482.33 C765.9,479.938607 763.961393,478 761.57,478 Z"
          id="Path"
        />
        <Path
          d="M721.53,476.239992 C719.057826,476.239992 716.829269,477.729741 715.884146,480.01412 C714.939023,482.298498 715.463604,484.927301 717.213126,486.673959 C718.962647,488.420618 721.592306,488.940892 723.875133,487.992029 C726.15796,487.043165 727.644046,484.81217 727.640008,482.34 C727.640008,480.720445 726.995953,479.167369 725.849817,478.023109 C724.703682,476.878849 723.149553,476.237345 721.53,476.239992 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(683.789400, 483.776600) rotate(-9.180542) translate(-683.789400, -483.776600) "
          cx={683.7894}
          cy={483.7766}
          r={7.90242609}
        />
        <Circle
          id="Oval"
          transform="translate(641.471195, 482.331382) rotate(-48.010000) translate(-641.471195, -482.331382) "
          cx={641.471195}
          cy={482.331382}
          r={9.77}
        />
        <Path
          d="M601.43,471 C595.178137,471 590.11,476.068137 590.11,482.32 C590.11,488.571863 595.178137,493.64 601.43,493.64 C607.681863,493.64 612.75,488.571863 612.75,482.32 C612.75,479.317749 611.557361,476.438463 609.434449,474.315551 C607.311537,472.192639 604.432251,471 601.43,471 L601.43,471 Z"
          id="Path"
        />
        <Path
          d="M561.4,469.569996 C556.234109,469.565955 551.574635,472.674699 549.594931,477.446199 C547.615226,482.2177 548.705304,487.711944 552.35671,491.366212 C556.008117,495.02048 561.501505,496.114861 566.274555,494.138895 C571.047605,492.162929 574.16,487.505893 574.16,482.34 C574.16,475.291227 568.448771,469.57552 561.4,469.569996 Z"
          id="Path"
        />
        <Path
          d="M521.36,468.46 C513.694288,468.46 507.48,474.674288 507.48,482.34 C507.48,490.005712 513.694288,496.22 521.36,496.22 C529.025712,496.22 535.24,490.005712 535.24,482.34 C535.24,474.674288 529.025712,468.46 521.36,468.46 L521.36,468.46 Z"
          id="Path"
        />
        <Circle id="Oval" cx={481.33} cy={482.34} r={14.43} />
        <Path
          d="M441.29,467.799997 C433.261084,467.805522 426.756321,474.317745 426.76,482.346662 C426.763682,490.375579 433.274413,496.881836 441.303331,496.879997 C449.332249,496.878157 455.84,490.368918 455.84,482.34 C455.84,478.482019 454.30674,474.782197 451.577798,472.05513 C448.848856,469.328064 445.14798,467.797347 441.29,467.799997 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(401.252821, 482.322710) rotate(-67.270000) translate(-401.252821, -482.322710) "
          cx={401.252821}
          cy={482.32271}
          r={14.1}
        />
        <Circle
          id="Oval"
          transform="translate(361.244326, 482.318554) rotate(-12.720000) translate(-361.244326, -482.318554) "
          cx={361.244326}
          cy={482.318554}
          r={13.1}
        />
        <Circle
          id="Oval"
          transform="translate(321.184287, 482.327189) rotate(-67.690000) translate(-321.184287, -482.327189) "
          cx={321.184287}
          cy={482.327189}
          r={11.66}
        />
        <Circle
          id="Oval"
          transform="translate(281.161540, 482.342091) rotate(-45.000000) translate(-281.161540, -482.342091) "
          cx={281.16154}
          cy={482.342091}
          r={10.1}
        />
        <Circle id="Oval" cx={241.12} cy={482.34} r={8.33} />
        <Circle
          id="Oval"
          transform="translate(201.087924, 482.395158) rotate(-67.390000) translate(-201.087924, -482.395158) "
          cx={201.087924}
          cy={482.395158}
          r={6.44}
        />
        <Circle id="Oval" cx={161.06} cy={482.34} r={4.66} />
        <Path
          d="M121,479.23 C119.282394,479.23 117.89,480.622394 117.89,482.34 C117.89,484.057606 119.282394,485.45 121,485.45 C122.717606,485.45 124.11,484.057606 124.11,482.34 C124.11,481.515177 123.78234,480.724136 123.199102,480.140898 C122.615864,479.55766 121.824823,479.23 121,479.23 Z"
          id="Path"
        />
        <Path
          d="M81,480.9 C80.20471,480.9 79.56,481.54471 79.56,482.34 C79.56,483.13529 80.20471,483.78 81,483.78 C81.79529,483.78 82.44,483.13529 82.44,482.34 C82.44,481.958088 82.2882861,481.591819 82.0182338,481.321766 C81.7481814,481.051714 81.3819117,480.9 81,480.9 Z"
          id="Path"
        />
        <Path
          d="M41,482 C40.8191494,482.005483 40.6764385,482.155568 40.6800664,482.336465 C40.6836942,482.517363 40.8323078,482.661605 41.0132328,482.659833 C41.1941578,482.65806 41.3399169,482.510934 41.34,482.33 C41.340041,482.240718 41.3039039,482.155229 41.2398369,482.093047 C41.1757699,482.030864 41.0892409,481.997294 41,482 Z"
          id="Path"
        />
        <Path
          d="M881.67,441.86 C881.487137,441.855935 881.320006,441.96296 881.247193,442.13075 C881.17438,442.298541 881.210373,442.493711 881.338247,442.624492 C881.466121,442.755273 881.660433,442.795641 881.829818,442.726616 C881.999202,442.657592 882.109955,442.492908 882.11,442.31 C882.11006,442.065325 881.914614,441.865437 881.67,441.86 Z"
          id="Path"
        />
        <Circle id="Oval" cx={841.64} cy={442.31} r={1.89} />
        <Path
          d="M801.6,438.749986 C800.159211,438.745953 798.85804,439.610748 798.303868,440.940704 C797.749695,442.270659 798.0518,443.803516 799.069162,444.823743 C800.086523,445.84397 801.618524,446.150386 802.950033,445.599957 C804.281542,445.049528 805.15,443.750794 805.15,442.31 C805.15,440.347765 803.562227,438.755512 801.6,438.749986 L801.6,438.749986 Z"
          id="Path"
        />
        <Path
          d="M761.57,436.999991 C758.626322,436.999991 756.24,439.386322 756.24,442.33 C756.24,445.273678 758.626322,447.66 761.57,447.66 C764.513678,447.66 766.900009,445.273678 766.900009,442.33 C766.902661,440.915582 766.341964,439.558329 765.341817,438.558183 C764.341671,437.558036 762.984418,436.997339 761.57,436.999991 Z"
          id="Path"
        />
        <Path
          d="M721.53,434.87 C717.421001,434.87 714.09,438.201001 714.09,442.31 C714.09,446.418999 717.421001,449.75 721.53,449.75 C725.638999,449.75 728.97,446.418999 728.97,442.31 C728.97,438.201001 725.638999,434.87 721.53,434.87 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(681.499184, 442.313478) rotate(-45.000000) translate(-681.499184, -442.313478) "
          cx={681.499184}
          cy={442.313478}
          r={9.44}
        />
        <Circle id="Oval" cx={641.47} cy={442.31} r={11.55} />
        <Path
          d="M601.43,428.65 C593.88579,428.65 587.77,434.76579 587.77,442.31 C587.77,449.85421 593.88579,455.97 601.43,455.97 C608.97421,455.97 615.09,449.85421 615.09,442.31 C615.09,434.76579 608.97421,428.65 601.43,428.65 Z"
          id="Path"
        />
        <Path
          d="M561.4,426.879997 C555.159148,426.879997 549.53281,430.639399 547.144539,436.405195 C544.756268,442.17099 546.076393,448.807709 550.489342,453.220658 C554.902291,457.633607 561.53901,458.953732 567.304805,456.565461 C573.070601,454.17719 576.830003,448.550852 576.830003,442.31 C576.832655,438.216896 575.207852,434.290672 572.31359,431.39641 C569.419328,428.502148 565.493104,426.877345 561.4,426.879997 L561.4,426.879997 Z"
          id="Path"
        />
        <Circle id="Oval" cx={521.36} cy={442.31} r={16.87} />
        <Circle
          id="Oval"
          transform="translate(481.364876, 442.265531) rotate(-9.280000) translate(-481.364876, -442.265531) "
          cx={481.364876}
          cy={442.265531}
          r={17.76}
        />
        <Path
          d="M441.29,424.43 C431.415149,424.43 423.41,432.435149 423.41,442.31 C423.41,452.184851 431.415149,460.19 441.29,460.19 C451.164851,460.19 459.17,452.184851 459.17,442.31 C459.17,432.435149 451.164851,424.43 441.29,424.43 Z"
          id="Path"
        />
        <Path
          d="M401.26,425.1 C391.755179,425.1 384.05,432.805179 384.05,442.31 C384.05,451.814821 391.755179,459.52 401.26,459.52 C410.764821,459.52 418.47,451.814821 418.47,442.31 C418.47,432.805179 410.764821,425.1 401.26,425.1 L401.26,425.1 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(361.219925, 442.282985) rotate(-67.760000) translate(-361.219925, -442.282985) "
          cx={361.219925}
          cy={442.282985}
          r={15.99}
        />
        <Circle
          id="Oval"
          transform="translate(321.158412, 442.344813) rotate(-9.260000) translate(-321.158412, -442.344813) "
          cx={321.158412}
          cy={442.344813}
          r={14.21}
        />
        <Circle
          id="Oval"
          transform="translate(281.166055, 442.306607) rotate(-45.000000) translate(-281.166055, -442.306607) "
          cx={281.166055}
          cy={442.306607}
          r={12.32}
        />
        <Path
          d="M241.12,432.09 C235.47565,432.09 230.9,436.66565 230.9,442.31 C230.9,447.95435 235.47565,452.53 241.12,452.53 C246.76435,452.53 251.34,447.95435 251.34,442.31 C251.34,436.66565 246.76435,432.09 241.12,432.09 Z"
          id="Path"
        />
        <Path
          d="M201.09,434.199994 C197.808904,434.195954 194.848636,436.169325 193.590211,439.199502 C192.331786,442.229679 193.023172,445.719573 195.341827,448.041091 C197.660483,450.362609 201.149521,451.058301 204.181249,449.803615 C207.212976,448.548929 209.190006,445.591098 209.190006,442.31 C209.192654,440.160014 208.340436,438.097178 206.821103,436.57597 C205.301771,435.054761 203.239987,434.199994 201.09,434.199994 L201.09,434.199994 Z"
          id="Path"
        />
        <Circle id="Oval" cx={161.06} cy={442.31} r={6.11} />
        <Path
          d="M121,438.2 C118.73011,438.2 116.89,440.04011 116.89,442.31 C116.89,444.57989 118.73011,446.42 121,446.42 C123.26989,446.42 125.11,444.57989 125.11,442.31 C125.11,440.04011 123.26989,438.2 121,438.2 Z"
          id="Path"
        />
        <Path
          d="M81,439.859979 C80.0081675,439.855952 79.1117441,440.450301 78.7293778,441.365476 C78.3470116,442.28065 78.5541476,443.336075 79.2540493,444.038845 C79.9539509,444.741615 81.0085195,444.953066 81.9252501,444.574446 C82.8419806,444.195826 83.44,443.301841 83.44,442.31 C83.44,440.960798 82.3491908,439.865507 81,439.859979 L81,439.859979 Z"
          id="Path"
        />
        <Path
          d="M41,441.42 C40.5084666,441.42 40.11,441.818467 40.11,442.31 C40.11,442.801533 40.5084666,443.2 41,443.2 C41.4915334,443.2 41.89,442.801533 41.89,442.31 C41.89,441.818467 41.4915334,441.42 41,441.42 L41,441.42 Z"
          id="Path"
        />
        <Circle id="Oval" cx={881.67} cy={402.27} r={1} />
        <Path
          d="M841.64,399.94 C840.353177,399.94 839.31,400.983177 839.31,402.27 C839.31,403.556823 840.353177,404.6 841.64,404.6 C842.926823,404.6 843.97,403.556823 843.97,402.27 C843.964522,400.985452 842.924548,399.945478 841.64,399.94 Z"
          id="Path"
        />
        <Path
          d="M801.6,398.169988 C799.936751,398.169988 798.437467,399.17246 797.801908,400.70949 C797.166348,402.246519 797.519819,404.015085 798.697345,405.189747 C799.874871,406.364408 801.644293,406.713569 803.17977,406.074267 C804.715247,405.434965 805.714047,403.933244 805.710012,402.27 C805.710012,401.180878 805.276668,400.136508 804.505604,399.367321 C803.734541,398.598134 802.689119,398.167344 801.6,398.169988 Z"
          id="Path"
        />
        <Path
          d="M761.57,396.059992 C759.052429,396.055956 756.780693,397.569926 755.815391,399.895086 C754.850089,402.220246 755.38161,404.898003 757.161804,406.678196 C758.941997,408.45839 761.619754,408.989911 763.944914,408.024609 C766.270074,407.059307 767.784044,404.787571 767.780008,402.27 C767.780008,398.840312 764.999688,396.059992 761.57,396.059992 Z"
          id="Path"
        />
        <Path
          d="M721.53,393.839976 C718.115429,393.839976 715.037268,395.897426 713.731505,399.052466 C712.425741,402.207505 713.149666,405.838483 715.565563,408.251518 C717.98146,410.664552 721.613294,411.384173 724.766783,410.074669 C727.920273,408.765166 729.974046,405.684568 729.970006,402.27 C729.972665,400.031675 729.083988,397.884406 727.500315,396.302609 C725.916641,394.720812 723.76832,393.834682 721.53,393.839976 L721.53,393.839976 Z"
          id="Path"
        />
        <Circle id="Oval" cx={681.5} cy={402.27} r={10.77} />
        <Path
          d="M641.47,389.169996 C636.170636,389.165955 631.390833,392.355101 629.360051,397.249914 C627.329268,402.144727 628.447579,407.78091 632.193365,411.529557 C635.939151,415.278205 641.574478,416.400819 646.470841,414.373776 C651.367203,412.346732 654.56,407.569365 654.56,402.27 C654.56,395.038973 648.701025,389.17552 641.47,389.169996 Z"
          id="Path"
        />
        <Path
          d="M601.43,386.84 C592.908246,386.84 586,393.748246 586,402.27 C586,410.791754 592.908246,417.7 601.43,417.7 C609.951754,417.7 616.86,410.791754 616.86,402.27 C616.86,393.748246 609.951754,386.84 601.43,386.84 L601.43,386.84 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={402.27} r={17.76} />
        <Path
          d="M521.36,382.51 C510.446853,382.51 501.6,391.356853 501.6,402.27 C501.6,413.183147 510.446853,422.03 521.36,422.03 C532.273147,422.03 541.12,413.183147 541.12,402.27 C541.12,397.029322 539.038149,392.003289 535.33243,388.29757 C531.626711,384.591851 526.600678,382.51 521.36,382.51 L521.36,382.51 Z"
          id="Path"
        />
        <Path
          d="M481.33,381.179998 C472.794028,381.175955 465.096501,386.315322 461.828058,394.200759 C458.559614,402.086196 460.364205,411.164106 466.400049,417.199951 C472.435894,423.235795 481.513804,425.040386 489.399241,421.771942 C497.284678,418.503499 502.424045,410.805972 502.420002,402.27 C502.420002,396.676584 500.198024,391.31226 496.242882,387.357118 C492.28774,383.401976 486.923416,381.179998 481.33,381.179998 Z"
          id="Path"
        />
        <Path
          d="M441.29,380.849998 C432.621464,380.849998 424.806677,386.072335 421.490307,394.081405 C418.173937,402.090475 420.00923,411.308685 426.140241,417.436834 C432.271251,423.564984 441.490316,425.395975 449.497837,422.075867 C457.505359,418.755759 462.724045,410.938535 462.720002,402.27 C462.720002,396.58733 460.461884,391.137553 456.442692,387.120236 C452.4235,383.102919 446.97267,380.847347 441.29,380.849998 L441.29,380.849998 Z"
          id="Path"
        />
        <Path
          d="M401.26,381.85 C392.995927,381.85 385.545775,386.828693 382.384186,394.464089 C379.222597,402.099484 380.972335,410.887576 386.817348,416.729728 C392.66236,422.571879 401.451307,424.317314 409.085154,421.151987 C416.719001,417.98666 421.694045,410.534072 421.690002,402.27 C421.684479,390.990728 412.539273,381.85 401.26,381.85 Z"
          id="Path"
        />
        <Path
          d="M361.23,383.62 C353.686779,383.62 346.886312,388.163927 343.999647,395.132954 C341.112981,402.101981 342.708596,410.123679 348.042459,415.457541 C353.376321,420.791404 361.398019,422.387019 368.367046,419.500353 C375.336073,416.613688 379.88,409.813221 379.88,402.27 C379.88,391.969889 371.530111,383.62 361.23,383.62 L361.23,383.62 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(321.173365, 402.233735) rotate(-67.770000) translate(-321.173365, -402.233735) "
          cx={321.173365}
          cy={402.233735}
          r={16.54}
        />
        <Circle
          id="Oval"
          transform="translate(281.156063, 402.257083) rotate(-67.270000) translate(-281.156063, -402.257083) "
          cx={281.156063}
          cy={402.257083}
          r={14.1}
        />
        <Path
          d="M241.12,390.62 C236.403061,390.62 232.15077,393.461953 230.346611,397.820224 C228.542452,402.178495 229.541867,407.194453 232.878677,410.528401 C236.215487,413.86235 241.232301,414.85746 245.589022,413.049563 C249.945744,411.241665 252.784045,406.986938 252.780004,402.27 C252.774481,395.834265 247.555737,390.62 241.12,390.62 Z"
          id="Path"
        />
        <Circle id="Oval" cx={201.09} cy={402.27} r={9.32} />
        <Path
          d="M161.06,395.169993 C158.182457,395.165956 155.586194,396.896767 154.483137,399.554498 C153.38008,402.212229 153.987746,405.27279 156.022478,407.307522 C158.05721,409.342254 161.117771,409.94992 163.775502,408.846863 C166.433233,407.743806 168.164044,405.147543 168.160007,402.27 C168.160007,398.348778 164.981222,395.169993 161.06,395.169993 L161.06,395.169993 Z"
          id="Path"
        />
        <Circle id="Oval" cx={121.02} cy={402.27} r={5} />
        <Path
          d="M81,399.16 C79.2823944,399.16 77.89,400.552394 77.89,402.27 C77.89,403.987606 79.2823944,405.38 81,405.38 C82.7176056,405.38 84.11,403.987606 84.11,402.27 C84.11,400.552394 82.7176056,399.16 81,399.16 L81,399.16 Z"
          id="Path"
        />
        <Path
          d="M41,400.83 C40.4126227,400.83 39.8832785,401.184387 39.6594446,401.727443 C39.4356107,402.2705 39.5615104,402.894948 39.9782835,403.308846 C40.3950566,403.722745 41.0203605,403.844321 41.5618551,403.616734 C42.1033497,403.389147 42.4540509,402.857363 42.45,402.27 C42.444504,401.473095 41.7969238,400.83 41,400.83 Z"
          id="Path"
        />
        <Path
          d="M0.92,402.16 C0.859248678,402.16 0.81,402.209249 0.81,402.27 C0.81,402.330751 0.859248678,402.38 0.92,402.38 C0.980751322,402.38 1.03,402.330751 1.03,402.27 C1.03,402.209249 0.980751322,402.16 0.92,402.16 L0.92,402.16 Z"
          id="Path"
        />
        <Path
          d="M881.67,361.24 C881.117715,361.24 880.67,361.687715 880.67,362.24 C880.67,362.792285 881.117715,363.24 881.67,363.24 C882.222285,363.24 882.67,362.792285 882.67,362.24 C882.67,361.687715 882.222285,361.24 881.67,361.24 Z"
          id="Path"
        />
        <Path
          d="M841.64,359.69 C840.231674,359.69 839.09,360.831674 839.09,362.24 C839.09,363.648326 840.231674,364.79 841.64,364.79 C843.048326,364.79 844.19,363.648326 844.19,362.24 C844.184518,360.833951 843.046049,359.695482 841.64,359.69 L841.64,359.69 Z"
          id="Path"
        />
        <Path
          d="M801.6,357.69 C799.087104,357.69 797.05,359.727104 797.05,362.24 C797.05,364.752896 799.087104,366.79 801.6,366.79 C804.112896,366.79 806.15,364.752896 806.15,362.24 C806.15,359.727104 804.112896,357.69 801.6,357.69 Z"
          id="Path"
        />
        <Path
          d="M761.57,355.58 C757.891784,355.58 754.91,358.561784 754.91,362.24 C754.91,365.918216 757.891784,368.9 761.57,368.9 C765.248216,368.9 768.23,365.918216 768.23,362.24 C768.23,358.561784 765.248216,355.58 761.57,355.58 L761.57,355.58 Z"
          id="Path"
        />
        <Path
          d="M721.53,353.36 C716.625711,353.36 712.65,357.335711 712.65,362.24 C712.65,367.144289 716.625711,371.12 721.53,371.12 C726.434289,371.12 730.41,367.144289 730.41,362.24 C730.41,357.335711 726.434289,353.36 721.53,353.36 Z"
          id="Path"
        />
        <Path
          d="M681.5,350.799996 C676.872044,350.795955 672.697537,353.580655 670.923691,357.855168 C669.149845,362.129681 670.126133,367.051868 673.397162,370.325759 C676.668191,373.59965 681.589523,374.580242 685.865586,372.810135 C690.141648,371.040028 692.930004,366.867958 692.930004,362.24 C692.932654,359.206843 691.729597,356.297008 689.585768,354.151304 C687.44194,352.0056 684.533158,350.799996 681.5,350.799996 L681.5,350.799996 Z"
          id="Path"
        />
        <Path
          d="M641.47,348.25 C633.738014,348.25 627.47,354.518014 627.47,362.25 C627.47,369.981986 633.738014,376.25 641.47,376.25 C649.201986,376.25 655.47,369.981986 655.47,362.25 C655.47,354.518014 649.201986,348.25 641.47,348.25 L641.47,348.25 Z"
          id="Path"
        />
        <Path
          d="M601.43,345.589997 C592.234459,345.589997 584.78,353.044459 584.78,362.24 C584.78,371.435541 592.234459,378.89 601.43,378.89 C610.625541,378.89 618.080003,371.435541 618.080003,362.24 C618.082655,357.823332 616.329316,353.586797 613.20626,350.46374 C610.083203,347.340684 605.846668,345.587345 601.43,345.589997 Z"
          id="Path"
        />
        <Path
          d="M561.4,342.919997 C553.584881,342.915955 546.537039,347.620556 543.543519,354.839624 C540.549999,362.058692 542.200467,370.370209 547.725161,375.897764 C553.249855,381.425318 561.560515,383.080089 568.781132,380.090307 C576.001749,377.100525 580.71,370.05512 580.71,362.24 C580.71,351.573763 572.066236,342.925521 561.4,342.919997 L561.4,342.919997 Z"
          id="Path"
        />
        <Path
          d="M521.36,340.15 C509.16003,340.15 499.27,350.04003 499.27,362.24 C499.27,374.43997 509.16003,384.33 521.36,384.33 C533.55997,384.33 543.45,374.43997 543.45,362.24 C543.45,350.04003 533.55997,340.15 521.36,340.15 L521.36,340.15 Z"
          id="Path"
        />
        <Path
          d="M481.33,337.82 C467.843206,337.82 456.91,348.753206 456.91,362.24 C456.91,375.726794 467.843206,386.66 481.33,386.66 C494.816794,386.66 505.75,375.726794 505.75,362.24 C505.75,348.753206 494.816794,337.82 481.33,337.82 L481.33,337.82 Z"
          id="Path"
        />
        <Path
          d="M441.29,337.149998 C431.142042,337.149998 421.993318,343.262982 418.109863,352.638473 C414.226407,362.013963 416.373001,372.805619 423.548691,379.981309 C430.724381,387.156999 441.516037,389.303593 450.891527,385.420137 C460.267018,381.536682 466.380002,372.387958 466.380002,362.24 C466.382654,355.584905 463.740103,349.201623 459.03424,344.49576 C454.328377,339.789897 447.945095,337.147346 441.29,337.149998 Z"
          id="Path"
        />
        <Path
          d="M401.26,338.93 C388.386242,338.93 377.95,349.366242 377.95,362.24 C377.95,375.113758 388.386242,385.55 401.26,385.55 C414.133758,385.55 424.57,375.113758 424.57,362.24 C424.57,349.366242 414.133758,338.93 401.26,338.93 L401.26,338.93 Z"
          id="Path"
        />
        <Path
          d="M361.23,341.47999 C349.765872,341.485522 340.47632,350.782535 340.48,362.246663 C340.483682,373.710792 349.779203,383.001837 361.243332,382.999997 C372.707461,382.998157 382.000002,373.704129 382.000002,362.24 C382.002657,356.731558 379.814929,351.448111 375.918935,347.553992 C372.02294,343.659873 366.73844,341.47469 361.23,341.47999 L361.23,341.47999 Z"
          id="Path"
        />
        <Path
          d="M321.19,344.139997 C311.193646,344.139997 303.09,352.243646 303.09,362.24 C303.09,372.236354 311.193646,380.34 321.19,380.34 C331.186354,380.34 339.290003,372.236354 339.290003,362.24 C339.292655,357.438768 337.386549,352.83342 333.991564,349.438436 C330.59658,346.043451 325.991232,344.137345 321.19,344.139997 Z"
          id="Path"
        />
        <Path
          d="M281.16,346.92 C272.698998,346.92 265.84,353.778998 265.84,362.24 C265.84,370.701002 272.698998,377.56 281.16,377.56 C289.621002,377.56 296.48,370.701002 296.48,362.24 C296.48,353.778998 289.621002,346.92 281.16,346.92 L281.16,346.92 Z"
          id="Path"
        />
        <Path
          d="M241.12,349.469996 C235.955017,349.469996 231.298612,352.581311 229.322058,357.353133 C227.345505,362.124955 228.438052,367.617559 232.090246,371.269754 C235.742441,374.921948 241.235045,376.014495 246.006867,374.037942 C250.778689,372.061388 253.890004,367.404983 253.890004,362.24 C253.892656,358.852372 252.548102,355.602729 250.152686,353.207314 C247.757271,350.811898 244.507628,349.467344 241.12,349.469996 L241.12,349.469996 Z"
          id="Path"
        />
        <Path
          d="M201.09,352 C195.451173,352 190.88,356.571173 190.88,362.21 C190.88,367.848827 195.451173,372.42 201.09,372.42 C206.728827,372.42 211.3,367.848827 211.3,362.21 C211.3,359.50214 210.224307,356.905186 208.30956,354.99044 C206.394814,353.075693 203.79786,352 201.09,352 Z"
          id="Path"
        />
        <Path
          d="M161.06,354.36 C156.707996,354.36 153.18,357.887996 153.18,362.24 C153.18,366.592004 156.707996,370.12 161.06,370.12 C165.412004,370.12 168.94,366.592004 168.94,362.24 C168.94,357.887996 165.412004,354.36 161.06,354.36 L161.06,354.36 Z"
          id="Path"
        />
        <Path
          d="M121,356.69 C117.93482,356.69 115.45,359.17482 115.45,362.24 C115.45,365.30518 117.93482,367.79 121,367.79 C124.06518,367.79 126.55,365.30518 126.55,362.24 C126.55,360.768048 125.96527,359.356384 124.924443,358.315557 C123.883616,357.27473 122.471952,356.69 121,356.69 Z"
          id="Path"
        />
        <Path
          d="M81,358.69 C79.0393891,358.69 77.45,360.279389 77.45,362.24 C77.45,364.200611 79.0393891,365.79 81,365.79 C82.9606109,365.79 84.55,364.200611 84.55,362.24 C84.55,360.279389 82.9606109,358.69 81,358.69 Z"
          id="Path"
        />
        <Path
          d="M41,360.46 C40.2800572,360.46 39.6310046,360.893683 39.3554944,361.558823 C39.0799843,362.223964 39.2322735,362.989574 39.7413499,363.49865 C40.2504264,364.007727 41.0160361,364.160016 41.6811765,363.884506 C42.3463169,363.608995 42.78,362.959943 42.78,362.24 C42.78,361.256933 41.9830669,360.46 41,360.46 L41,360.46 Z"
          id="Path"
        />
        <Path
          d="M0.92,361.91 C0.737746033,361.91 0.59,362.057746 0.59,362.24 C0.59,362.422254 0.737746033,362.57 0.92,362.57 C1.10225397,362.57 1.25,362.422254 1.25,362.24 C1.25,362.057746 1.10225397,361.91 0.92,361.91 Z"
          id="Path"
        />
        <Path
          d="M881.67,321.21 C881.117715,321.21 880.67,321.657715 880.67,322.21 C880.67,322.762285 881.117715,323.21 881.67,323.21 C882.222285,323.21 882.67,322.762285 882.67,322.21 C882.67,321.657715 882.222285,321.21 881.67,321.21 Z"
          id="Path"
        />
        <Path
          d="M841.64,319.539981 C840.558276,319.535956 839.581002,320.184994 839.165174,321.183608 C838.749347,322.182221 838.977144,323.333057 839.742044,324.097956 C840.506943,324.862856 841.657779,325.090653 842.656392,324.674826 C843.655006,324.258998 844.304044,323.281724 844.300019,322.2 C844.300019,321.494524 844.019751,320.817943 843.520904,320.319096 C843.022057,319.820249 842.345476,319.539981 841.64,319.539981 L841.64,319.539981 Z"
          id="Path"
        />
        <Path
          d="M801.6,317.649989 C799.753838,317.645956 798.087399,318.755479 797.379034,320.460339 C796.670669,322.165199 797.060168,324.128959 798.365604,325.434396 C799.671041,326.739832 801.634801,327.129331 803.339661,326.420966 C805.044521,325.712601 806.154044,324.046162 806.150011,322.2 C806.150011,320.993265 805.670626,319.835955 804.817336,318.982664 C803.964045,318.129374 802.806735,317.649989 801.6,317.649989 L801.6,317.649989 Z"
          id="Path"
        />
        <Path
          d="M761.57,315.539992 C758.870421,315.535956 756.434598,317.159565 755.399645,319.652879 C754.364691,322.146193 754.934713,325.017502 756.843605,326.926395 C758.752498,328.835287 761.623807,329.405309 764.117121,328.370355 C766.610435,327.335402 768.234044,324.899579 768.230008,322.2 C768.230008,320.433658 767.528323,318.739661 766.279331,317.490669 C765.030339,316.241677 763.336342,315.539992 761.57,315.539992 L761.57,315.539992 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(721.524073, 322.207398) rotate(-45.000000) translate(-721.524073, -322.207398) "
          cx={721.524073}
          cy={322.207398}
          r={8.99}
        />
        <Circle
          id="Oval"
          transform="translate(681.498588, 322.202883) rotate(-45.000000) translate(-681.498588, -322.202883) "
          cx={681.498588}
          cy={322.202883}
          r={11.55}
        />
        <Circle id="Oval" cx={641.47} cy={322.2} r={14.1} />
        <Path
          d="M601.43,305.329997 C594.601768,305.329997 588.446075,309.443764 585.833959,315.752615 C583.221843,322.061467 584.667858,329.32264 589.497578,334.149499 C594.327297,338.976357 601.589326,340.418069 607.896628,337.802215 C614.203931,335.18636 618.314045,329.028231 618.310003,322.2 C618.310003,317.724065 616.531258,313.431598 613.365356,310.267572 C610.199455,307.103545 605.905935,305.327347 601.43,305.329997 L601.43,305.329997 Z"
          id="Path"
        />
        <Path
          d="M561.4,302.439997 C553.401963,302.435955 546.189403,307.251278 543.126818,314.639728 C540.064233,322.028178 541.755034,330.534031 547.410501,336.189499 C553.065969,341.844966 561.571822,343.535767 568.960272,340.473182 C576.348722,337.410597 581.164045,330.198037 581.160003,322.2 C581.160003,311.286853 572.313147,302.439997 561.4,302.439997 L561.4,302.439997 Z"
          id="Path"
        />
        <Circle id="Oval" cx={521.36} cy={322.2} r={22.76} />
        <Path
          d="M481.33,296.449998 C470.909234,296.445955 461.512502,302.720695 457.522779,312.347455 C453.533055,321.974214 455.736336,333.056474 463.104931,340.425069 C470.473526,347.793664 481.555786,349.996945 491.182545,346.007221 C500.809305,342.017498 507.084045,332.620766 507.080002,322.2 C507.080002,315.370675 504.367061,308.821062 499.538,303.992 C494.708938,299.162939 488.159325,296.449998 481.33,296.449998 Z"
          id="Path"
        />
        <Path
          d="M441.29,294.78 C426.146352,294.78 413.87,307.056352 413.87,322.2 C413.87,337.343648 426.146352,349.62 441.29,349.62 C456.433648,349.62 468.71,337.343648 468.71,322.2 C468.71,307.056352 456.433648,294.78 441.29,294.78 Z"
          id="Path"
        />
        <Path
          d="M401.26,297.779998 C391.377168,297.775955 382.465403,303.726651 378.681539,312.856423 C374.897674,321.986196 376.987166,332.496399 383.975383,339.484617 C390.963601,346.472834 401.473804,348.562326 410.603577,344.778461 C419.733349,340.994597 425.684045,332.082832 425.680002,322.2 C425.680002,315.723413 423.107186,309.512091 418.527548,304.932452 C413.947909,300.352814 407.736587,297.779998 401.26,297.779998 Z"
          id="Path"
        />
        <Path
          d="M361.23,300.669998 C352.516065,300.665955 344.658098,305.912525 341.321551,313.962378 C337.985003,322.012232 339.827239,331.279394 345.988922,337.441078 C352.150606,343.602761 361.417768,345.444997 369.467622,342.108449 C377.517475,338.771902 382.764045,330.913935 382.760002,322.2 C382.760002,316.489889 380.491667,311.013649 376.454009,306.975991 C372.416351,302.938333 366.940111,300.669998 361.23,300.669998 Z"
          id="Path"
        />
        <Path
          d="M321.19,303.67 C313.69036,303.67 306.929372,308.18821 304.060319,315.117361 C301.191266,322.046512 302.779303,330.02168 308.08378,335.323296 C313.388256,340.624911 321.364281,342.208646 328.291883,339.335855 C335.219485,336.463064 339.734045,329.699639 339.730003,322.2 C339.724479,311.964546 331.425455,303.67 321.19,303.67 L321.19,303.67 Z"
          id="Path"
        />
        <Path
          d="M281.16,306.439997 C274.779812,306.435956 269.025797,310.27671 266.582336,316.170461 C264.138876,322.064213 265.487454,328.849596 269.998929,333.361071 C274.510404,337.872546 281.295787,339.221124 287.189539,336.777664 C293.08329,334.334203 296.924044,328.580188 296.920003,322.2 C296.914484,313.498278 289.861722,306.445516 281.16,306.439997 L281.16,306.439997 Z"
          id="Path"
        />
        <Path
          d="M241.12,309.11 C235.820635,309.11 231.043268,312.302797 229.016224,317.199159 C226.989181,322.095522 228.111795,327.730849 231.860443,331.476635 C235.60909,335.222421 241.245273,336.340732 246.140086,334.309949 C251.034899,332.279167 254.224045,327.499364 254.220004,322.2 C254.21448,314.968975 248.351027,309.11 241.12,309.11 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(201.081388, 322.179276) rotate(-67.330000) translate(-201.081388, -322.179276) "
          cx={201.081388}
          cy={322.179276}
          r={10.55}
        />
        <Path
          d="M161.06,314.099994 C157.777995,314.095956 154.817095,316.07041 153.559257,319.101815 C152.301419,322.133221 152.99464,325.6239 155.31537,327.94463 C157.6361,330.26536 161.126779,330.958581 164.158185,329.700743 C167.18959,328.442905 169.164044,325.482005 169.160006,322.2 C169.15449,317.728778 165.531222,314.10551 161.06,314.099994 Z"
          id="Path"
        />
        <Path
          d="M121,316.429991 C118.660393,316.425956 116.549097,317.832723 115.651899,319.993467 C114.754701,322.15421 115.248577,324.642714 116.902931,326.297069 C118.557286,327.951423 121.04579,328.445299 123.206533,327.548101 C125.367277,326.650903 126.774044,324.539607 126.770009,322.2 C126.770009,319.013317 124.186683,316.429991 121,316.429991 L121,316.429991 Z"
          id="Path"
        />
        <Path
          d="M81,318.429987 C79.4693202,318.425956 78.0872979,319.345438 77.4996625,320.758831 C76.9120271,322.172225 77.2347917,323.800493 78.3171496,324.88285 C79.3995074,325.965208 81.027775,326.287973 82.4411686,325.700337 C83.8545622,325.112702 84.7740441,323.73068 84.7700133,322.2 C84.7700133,320.117886 83.0821135,318.429987 81,318.429987 Z"
          id="Path"
        />
        <Path
          d="M41,320.319973 C40.2346587,320.319973 39.5448719,320.781584 39.2529314,321.489057 C38.9609909,322.19653 39.1245326,323.010231 39.6671442,323.549972 C40.2097559,324.089713 41.0243133,324.248935 41.7302277,323.953246 C42.436142,323.657556 42.8940494,322.965331 42.8900266,322.2 C42.8900266,321.699656 42.6905641,321.219948 42.3358299,320.867091 C41.9810958,320.514234 41.5003374,320.317339 41,320.319973 L41,320.319973 Z"
          id="Path"
        />
        <Path
          d="M0.92,321.76 C0.736234503,321.75596 0.568483997,321.86409 0.496280772,322.033125 C0.424077548,322.20216 0.461937439,322.398116 0.59191067,322.528089 C0.721883901,322.658063 0.917840479,322.695922 1.08687532,322.623719 C1.25591015,322.551516 1.36404026,322.383765 1.36,322.2 C1.35470626,321.959222 1.16077847,321.765294 0.92,321.76 L0.92,321.76 Z"
          id="Path"
        />
        <Path
          d="M881.67,281.39 C881.354519,281.39 881.070103,281.580041 880.949374,281.871507 C880.828645,282.162973 880.895378,282.498465 881.118457,282.721543 C881.341535,282.944622 881.677027,283.011355 881.968493,282.890626 C882.259959,282.769897 882.45,282.485481 882.45,282.17 C882.45,281.739218 882.100782,281.39 881.67,281.39 L881.67,281.39 Z"
          id="Path"
        />
        <Path
          d="M841.64,279.84 C840.353177,279.84 839.31,280.883177 839.31,282.17 C839.31,283.456823 840.353177,284.5 841.64,284.5 C842.926823,284.5 843.97,283.456823 843.97,282.17 C843.97,280.883177 842.926823,279.84 841.64,279.84 L841.64,279.84 Z"
          id="Path"
        />
        <Path
          d="M801.6,278 C799.269358,278 797.38,279.889358 797.38,282.22 C797.38,284.550642 799.269358,286.44 801.6,286.44 C803.930642,286.44 805.82,284.550642 805.82,282.22 C805.82,281.100786 805.375394,280.027413 804.583991,279.236009 C803.792587,278.444606 802.719214,278 801.6,278 Z"
          id="Path"
        />
        <Path
          d="M761.57,275.84 C758.074038,275.84 755.24,278.674038 755.24,282.17 C755.24,285.665962 758.074038,288.5 761.57,288.5 C765.065962,288.5 767.9,285.665962 767.9,282.17 C767.9,280.49118 767.233091,278.881119 766.045986,277.694014 C764.858881,276.506909 763.24882,275.84 761.57,275.84 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(721.536340, 282.178858) rotate(-67.360000) translate(-721.536340, -282.178858) "
          cx={721.53634}
          cy={282.178858}
          r={8.55}
        />
        <Path
          d="M681.5,271.18 C675.424868,271.18 670.5,276.104868 670.5,282.18 C670.5,288.255132 675.424868,293.18 681.5,293.18 C687.575132,293.18 692.5,288.255132 692.5,282.18 C692.5,276.104868 687.575132,271.18 681.5,271.18 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(641.486157, 282.104453) rotate(-13.200000) translate(-641.486157, -282.104453) "
          cx={641.486157}
          cy={282.104453}
          r={13.43}
        />
        <Path
          d="M601.43,266.07 C592.538216,266.07 585.33,273.278216 585.33,282.17 C585.33,291.061784 592.538216,298.27 601.43,298.27 C610.321784,298.27 617.53,291.061784 617.53,282.17 C617.53,273.278216 610.321784,266.07 601.43,266.07 Z"
          id="Path"
        />
        <Path
          d="M561.4,263.52 C551.099889,263.52 542.75,271.869889 542.75,282.17 C542.75,292.470111 551.099889,300.82 561.4,300.82 C571.700111,300.82 580.05,292.470111 580.05,282.17 C580.05,271.869889 571.700111,263.52 561.4,263.52 L561.4,263.52 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(521.374837, 282.133625) rotate(-9.290000) translate(-521.374837, -282.133625) "
          cx={521.374837}
          cy={282.133625}
          r={21.09}
        />
        <Circle id="Oval" cx={481.33} cy={282.17} r={23.2} />
        <Path
          d="M441.29,258.3 C428.106963,258.3 417.42,268.986963 417.42,282.17 C417.42,295.353037 428.106963,306.04 441.29,306.04 C454.473037,306.04 465.16,295.353037 465.16,282.17 C465.16,268.986963 454.473037,258.3 441.29,258.3 Z"
          id="Path"
        />
        <Path
          d="M401.26,259.52 C388.75075,259.52 378.61,269.66075 378.61,282.17 C378.61,294.67925 388.75075,304.82 401.26,304.82 C413.76925,304.82 423.91,294.67925 423.91,282.17 C423.91,269.66075 413.76925,259.52 401.26,259.52 L401.26,259.52 Z"
          id="Path"
        />
        <Path
          d="M361.23,261.739998 C352.965928,261.735955 345.51334,266.710999 342.348013,274.344846 C339.182686,281.978693 340.928121,290.76764 346.770272,296.612652 C352.612424,302.457665 361.400516,304.207403 369.035911,301.045814 C376.671307,297.884225 381.65,290.434073 381.65,282.17 C381.65,270.890727 372.509272,261.745521 361.23,261.739998 Z"
          id="Path"
        />
        <Path
          d="M321.19,264.3 C311.320672,264.3 303.32,272.300672 303.32,282.17 C303.32,292.039328 311.320672,300.04 321.19,300.04 C331.059328,300.04 339.06,292.039328 339.06,282.17 C339.06,272.300672 331.059328,264.3 321.19,264.3 L321.19,264.3 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(281.148839, 282.195699) rotate(-11.940000) translate(-281.148839, -282.195699) "
          cx={281.148839}
          cy={282.195699}
          r={15.21}
        />
        <Path
          d="M241.12,269.399996 C235.955017,269.399996 231.298612,272.511311 229.322058,277.283133 C227.345505,282.054955 228.438052,287.547559 232.090246,291.199754 C235.742441,294.851948 241.235045,295.944495 246.006867,293.967942 C250.778689,291.991388 253.890004,287.334983 253.890004,282.17 C253.892656,278.782372 252.548102,275.532729 250.152686,273.137314 C247.757271,270.741898 244.507628,269.397344 241.12,269.399996 L241.12,269.399996 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(201.094105, 282.190795) rotate(-67.330000) translate(-201.094105, -282.190795) "
          cx={201.094105}
          cy={282.190795}
          r={10.21}
        />
        <Circle id="Oval" cx={161.06} cy={282.17} r={7.88} />
        <Path
          d="M121,276.509991 C117.874068,276.509991 115.34,279.044068 115.34,282.17 C115.34,285.295932 117.874068,287.83 121,287.83 C124.125932,287.83 126.660009,285.295932 126.660009,282.17 C126.662661,280.668061 126.067195,279.226871 125.005162,278.164838 C123.943129,277.102805 122.501939,276.507339 121,276.509991 L121,276.509991 Z"
          id="Path"
        />
        <Path
          d="M81,278.62 C79.0393891,278.62 77.45,280.209389 77.45,282.17 C77.45,284.130611 79.0393891,285.72 81,285.72 C82.9606109,285.72 84.55,284.130611 84.55,282.17 C84.55,280.209389 82.9606109,278.62 81,278.62 Z"
          id="Path"
        />
        <Path
          d="M41,280.28 C39.9561818,280.28 39.11,281.126182 39.11,282.17 C39.11,283.213818 39.9561818,284.06 41,284.06 C42.0438182,284.06 42.89,283.213818 42.89,282.17 C42.89,281.668741 42.6908756,281.188012 42.3364318,280.833568 C41.9819881,280.479124 41.5012592,280.28 41,280.28 L41,280.28 Z"
          id="Path"
        />
        <Path
          d="M0.92,281.73 C0.675325301,281.72994 0.475437208,281.925386 0.47,282.17 C0.465935375,282.352863 0.572959746,282.519994 0.74075043,282.592807 C0.908541114,282.66562 1.10371111,282.629627 1.23449181,282.501753 C1.36527251,282.373879 1.40564116,282.179567 1.33661649,282.010182 C1.26759182,281.840798 1.10290836,281.730045 0.92,281.73 L0.92,281.73 Z"
          id="Path"
        />
        <Path
          d="M881.67,241.69 C881.487137,241.685935 881.320006,241.79296 881.247193,241.96075 C881.17438,242.128541 881.210373,242.323711 881.338247,242.454492 C881.466121,242.585273 881.660433,242.625641 881.829818,242.556616 C881.999202,242.487592 882.109955,242.322908 882.11,242.14 C882.11006,241.895325 881.914614,241.695437 881.67,241.69 L881.67,241.69 Z"
          id="Path"
        />
        <Path
          d="M841.64,240.249973 C840.874669,240.245951 840.182444,240.703858 839.886754,241.409772 C839.591065,242.115687 839.750287,242.930244 840.290028,243.472856 C840.829769,244.015467 841.64347,244.179009 842.350943,243.887069 C843.058416,243.595128 843.520027,242.905341 843.520027,242.14 C843.522661,241.639663 843.325766,241.158904 842.972909,240.80417 C842.620052,240.449436 842.140344,240.249973 841.64,240.249973 L841.64,240.249973 Z"
          id="Path"
        />
        <Path
          d="M801.6,238.579986 C800.159211,238.575953 798.85804,239.440748 798.303868,240.770704 C797.749695,242.100659 798.0518,243.633516 799.069162,244.653743 C800.086523,245.67397 801.618524,245.980386 802.950033,245.429957 C804.281542,244.879528 805.150014,243.580794 805.150014,242.14 C805.152657,241.19675 804.779814,240.291222 804.113771,239.623304 C803.447729,238.955385 802.543254,238.579986 801.6,238.579986 Z"
          id="Path"
        />
        <Path
          d="M761.57,236.589991 C758.50482,236.589991 756.02,239.07482 756.02,242.14 C756.02,245.20518 758.50482,247.69 761.57,247.69 C764.63518,247.69 767.120009,245.20518 767.120009,242.14 C767.122661,240.667234 766.538785,239.254024 765.497381,238.212619 C764.455976,237.171215 763.042766,236.587339 761.57,236.589991 L761.57,236.589991 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(721.522047, 242.174102) rotate(-9.190000) translate(-721.522047, -242.174102) "
          cx={721.522047}
          cy={242.174102}
          r={7.66}
        />
        <Circle
          id="Oval"
          transform="translate(681.497619, 242.141914) rotate(-45.000000) translate(-681.497619, -242.141914) "
          cx={681.497619}
          cy={242.141914}
          r={9.99}
        />
        <Path
          d="M641.47,230 C634.787355,230 629.37,235.417355 629.37,242.1 C629.37,248.782645 634.787355,254.2 641.47,254.2 C648.152645,254.2 653.57,248.782645 653.57,242.1 C653.57,238.89088 652.295182,235.813198 650.025992,233.544008 C647.756802,231.274818 644.67912,230 641.47,230 Z"
          id="Path"
        />
        <Path
          d="M601.43,227.699997 C595.588657,227.695955 590.320241,231.211582 588.082052,236.607119 C585.843863,242.002655 587.076819,248.215196 591.205842,252.347081 C595.334865,256.478966 601.546551,257.716225 606.943637,255.481774 C612.340723,253.247324 615.86,247.981345 615.86,242.14 C615.86,234.168912 609.401086,227.70552 601.43,227.699997 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={242.14} r={16.65} />
        <Circle id="Oval" cx={521.36} cy={242.14} r={18.54} />
        <Circle id="Oval" cx={481.33} cy={242.14} r={19.87} />
        <Path
          d="M441.29,221.819998 C430.067574,221.819998 420.97,230.917574 420.97,242.14 C420.97,253.362426 430.067574,262.46 441.29,262.46 C452.512426,262.46 461.610002,253.362426 461.610002,242.14 C461.612655,236.749988 459.472656,231.579974 455.661341,227.768659 C451.850026,223.957344 446.680012,221.817345 441.29,221.819998 Z"
          id="Path"
        />
        <Path
          d="M401.26,222.49 C390.407605,222.49 381.61,231.287605 381.61,242.14 C381.61,252.992395 390.407605,261.79 401.26,261.79 C412.112395,261.79 420.91,252.992395 420.91,242.14 C420.91,231.287605 412.112395,222.49 401.26,222.49 L401.26,222.49 Z"
          id="Path"
        />
        <Path
          d="M361.23,223.929997 C353.863834,223.925955 347.220739,228.360113 344.399026,235.164403 C341.577312,241.968692 343.132813,249.802778 348.340049,255.012875 C353.547286,260.222972 361.380515,261.782776 368.186354,258.964801 C374.992192,256.146826 379.43,249.506167 379.43,242.14 C379.43,232.086799 371.2832,223.935521 361.23,223.929997 L361.23,223.929997 Z"
          id="Path"
        />
        <Path
          d="M321.19,226 C312.298216,226 305.09,233.208216 305.09,242.1 C305.09,250.991784 312.298216,258.2 321.19,258.2 C330.081784,258.2 337.29,250.991784 337.29,242.1 C337.29,233.208216 330.081784,226 321.19,226 Z"
          id="Path"
        />
        <Path
          d="M281.16,228.15 C273.428014,228.15 267.16,234.418014 267.16,242.15 C267.16,249.881986 273.428014,256.15 281.16,256.15 C288.891986,256.15 295.16,249.881986 295.16,242.15 C295.16,234.418014 288.891986,228.15 281.16,228.15 L281.16,228.15 Z"
          id="Path"
        />
        <Path
          d="M241.12,230.48 C234.68036,230.48 229.46,235.70036 229.46,242.14 C229.46,248.57964 234.68036,253.8 241.12,253.8 C247.55964,253.8 252.78,248.57964 252.78,242.14 C252.78,235.70036 247.55964,230.48 241.12,230.48 Z"
          id="Path"
        />
        <Circle id="Oval" cx={201.09} cy={242.14} r={9.32} />
        <Circle id="Oval" cx={161.06} cy={242.14} r={7.22} />
        <Path
          d="M121,237 C118.177825,237 115.89,239.287825 115.89,242.11 C115.89,244.932175 118.177825,247.22 121,247.22 C123.822175,247.22 126.11,244.932175 126.11,242.11 C126.11,239.287825 123.822175,237 121,237 Z"
          id="Path"
        />
        <Path
          d="M81,238.92 C79.2216431,238.92 77.78,240.361643 77.78,242.14 C77.78,243.918357 79.2216431,245.36 81,245.36 C82.7783569,245.36 84.22,243.918357 84.22,242.14 C84.22,240.361643 82.7783569,238.92 81,238.92 L81,238.92 Z"
          id="Path"
        />
        <Path
          d="M41,240.58 C40.1384358,240.58 39.44,241.278436 39.44,242.14 C39.44,243.001564 40.1384358,243.7 41,243.7 C41.8615642,243.7 42.56,243.001564 42.56,242.14 C42.5626833,241.725446 42.3991884,241.327092 42.106048,241.033952 C41.8129075,240.740812 41.4145545,240.577317 41,240.58 L41,240.58 Z"
          id="Path"
        />
        <Path
          d="M0.92,241.91 C0.859877706,241.909938 0.802349309,241.934484 0.760791396,241.977931 C0.719233482,242.021378 0.697267135,242.07994 0.7,242.14 C0.7,242.261503 0.798497355,242.36 0.92,242.36 C1.04150264,242.36 1.14,242.261503 1.14,242.14 C1.14273287,242.07994 1.12076652,242.021378 1.0792086,241.977931 C1.03765069,241.934484 0.980122294,241.909938 0.92,241.91 L0.92,241.91 Z"
          id="Path"
        />
        <Path
          d="M881.67,202 C881.609249,202 881.56,202.049249 881.56,202.11 C881.56,202.170751 881.609249,202.22 881.67,202.22 C881.730751,202.22 881.78,202.170751 881.78,202.11 C881.78,202.049249 881.730751,202 881.67,202 L881.67,202 Z"
          id="Path"
        />
        <Path
          d="M841.64,200.88 C840.966213,200.88 840.42,201.426213 840.42,202.1 C840.42,202.773787 840.966213,203.32 841.64,203.32 C842.313787,203.32 842.86,202.773787 842.86,202.1 C842.854562,201.428478 842.311522,200.885438 841.64,200.88 Z"
          id="Path"
        />
        <Path
          d="M801.6,199.329982 C800.474687,199.329982 799.460371,200.00842 799.030672,201.048463 C798.600973,202.088506 798.840657,203.285014 799.637806,204.079296 C800.434955,204.873577 801.632319,205.108948 802.670807,204.675504 C803.709295,204.24206 804.384048,203.225306 804.380018,202.1 C804.380018,201.363614 804.086794,200.657534 803.565153,200.13777 C803.043513,199.618006 802.336381,199.327342 801.6,199.329982 L801.6,199.329982 Z"
          id="Path"
        />
        <Path
          d="M761.57,197.55 C759.057104,197.55 757.02,199.587104 757.02,202.1 C757.02,204.612896 759.057104,206.65 761.57,206.65 C764.082896,206.65 766.12,204.612896 766.12,202.1 C766.12,199.587104 764.082896,197.55 761.57,197.55 L761.57,197.55 Z"
          id="Path"
        />
        <Path
          d="M721.53,195.77 C718.034038,195.77 715.2,198.604038 715.2,202.1 C715.2,205.595962 718.034038,208.43 721.53,208.43 C725.025962,208.43 727.86,205.595962 727.86,202.1 C727.86,200.42118 727.193091,198.811119 726.005986,197.624014 C724.818881,196.436909 723.20882,195.77 721.53,195.77 Z"
          id="Path"
        />
        <Path
          d="M681.5,193.669994 C678.085429,193.669994 675.007268,195.727426 673.701505,198.882466 C672.395741,202.037505 673.119666,205.668483 675.535563,208.081518 C677.95146,210.494552 681.583294,211.214173 684.736783,209.904669 C687.890273,208.595166 689.944046,205.514568 689.940006,202.1 C689.940006,199.862491 689.050471,197.716771 687.467376,196.135552 C685.884281,194.554332 683.737507,193.667346 681.5,193.669994 L681.5,193.669994 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(641.469580, 202.094843) rotate(-45.000000) translate(-641.469580, -202.094843) "
          cx={641.46958}
          cy={202.094843}
          r={10.44}
        />
        <Path
          d="M601.43,189.78 C594.625852,189.78 589.11,195.295852 589.11,202.1 C589.11,208.904148 594.625852,214.42 601.43,214.42 C608.234148,214.42 613.75,208.904148 613.75,202.1 C613.744486,195.298137 608.231863,189.785514 601.43,189.78 L601.43,189.78 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(561.388608, 202.156686) rotate(-13.370000) translate(-561.388608, -202.156686) "
          cx={561.388608}
          cy={202.156686}
          r={14.1}
        />
        <Path
          d="M521.36,186.67 C512.838246,186.67 505.93,193.578246 505.93,202.1 C505.93,210.621754 512.838246,217.53 521.36,217.53 C529.881754,217.53 536.79,210.621754 536.79,202.1 C536.79,193.578246 529.881754,186.67 521.36,186.67 L521.36,186.67 Z"
          id="Path"
        />
        <Path
          d="M481.33,185.56 C472.19521,185.56 464.79,192.96521 464.79,202.1 C464.79,211.23479 472.19521,218.64 481.33,218.64 C490.46479,218.64 497.87,211.23479 497.87,202.1 C497.87,192.96521 490.46479,185.56 481.33,185.56 L481.33,185.56 Z"
          id="Path"
        />
        <Path
          d="M441.29,185.339997 C432.035011,185.345522 424.53632,192.851673 424.54,202.106663 C424.543682,211.361652 432.048341,218.861836 441.303331,218.859997 C450.558321,218.858157 458.06,211.35499 458.06,202.1 C458.06,197.653238 456.292848,193.388751 453.147575,190.245353 C450.002302,187.101956 445.736761,185.337347 441.29,185.339997 Z"
          id="Path"
        />
        <Path
          d="M401.26,185.78 C392.246713,185.78 384.94,193.086713 384.94,202.1 C384.94,211.113287 392.246713,218.42 401.26,218.42 C410.273287,218.42 417.58,211.113287 417.58,202.1 C417.58,193.086713 410.273287,185.78 401.26,185.78 L401.26,185.78 Z"
          id="Path"
        />
        <Path
          d="M361.23,186.78 C352.768998,186.78 345.91,193.638998 345.91,202.1 C345.91,210.561002 352.768998,217.42 361.23,217.42 C369.691002,217.42 376.55,210.561002 376.55,202.1 C376.55,193.638998 369.691002,186.78 361.23,186.78 L361.23,186.78 Z"
          id="Path"
        />
        <Path
          d="M321.19,188.23 C315.575154,188.23 310.513371,191.612838 308.365598,196.800666 C306.217825,201.988495 307.407173,207.959314 311.378899,211.928178 C315.350625,215.897043 321.3223,217.082087 326.508579,214.930576 C331.694858,212.779064 335.074045,207.714844 335.070004,202.1 C335.06448,194.438193 328.851809,188.23 321.19,188.23 L321.19,188.23 Z"
          id="Path"
        />
        <Path
          d="M281.16,190.11 C274.532583,190.11 269.16,195.482583 269.16,202.11 C269.16,208.737417 274.532583,214.11 281.16,214.11 C287.787417,214.11 293.16,208.737417 293.16,202.11 C293.16,195.482583 287.787417,190.11 281.16,190.11 Z"
          id="Path"
        />
        <Path
          d="M241.12,191.889995 C236.985486,191.889995 233.258272,194.381108 231.676998,198.201289 C230.095724,202.021469 230.971938,206.418056 233.896912,209.340168 C236.821885,212.262279 241.219328,213.134189 245.037959,211.549176 C248.856589,209.964163 251.344046,206.234512 251.340005,202.1 C251.340005,199.390406 250.262935,196.791936 248.346026,194.876902 C246.429116,192.961868 243.829593,191.887346 241.12,191.889995 Z"
          id="Path"
        />
        <Path
          d="M201.09,193.889994 C197.763504,193.885956 194.762494,195.88721 193.48763,198.95972 C192.212766,202.03223 192.915399,205.570222 195.267588,207.922412 C197.619778,210.274601 201.15777,210.977234 204.23028,209.70237 C207.30279,208.427506 209.304044,205.426496 209.300006,202.1 C209.29449,197.568026 205.621974,193.89551 201.09,193.889994 Z"
          id="Path"
        />
        <Path
          d="M161.06,195.889992 C158.542429,195.885956 156.270693,197.399926 155.305391,199.725086 C154.340089,202.050246 154.87161,204.728003 156.651804,206.508196 C158.431997,208.28839 161.109754,208.819911 163.434914,207.854609 C165.760074,206.889307 167.274044,204.617571 167.270008,202.1 C167.270008,200.453006 166.615734,198.873468 165.451133,197.708867 C164.286532,196.544266 162.706994,195.889992 161.06,195.889992 L161.06,195.889992 Z"
          id="Path"
        />
        <Path
          d="M121,197.77 C118.608607,197.77 116.67,199.708607 116.67,202.1 C116.67,204.491393 118.608607,206.43 121,206.43 C123.391393,206.43 125.33,204.491393 125.33,202.1 C125.33,199.708607 123.391393,197.77 121,197.77 L121,197.77 Z"
          id="Path"
        />
        <Path
          d="M81,199.54998 C79.9627678,199.545956 79.0256039,200.168193 78.626802,201.125702 C78.2280002,202.083212 78.4463866,203.186734 79.1798261,203.920174 C79.9132656,204.653613 81.0167883,204.872 81.9742976,204.473198 C82.9318069,204.074396 83.5540438,203.137232 83.5500196,202.1 C83.5445183,200.693951 82.4060492,199.555482 81,199.54998 Z"
          id="Path"
        />
        <Path
          d="M41,201.1 C40.4477153,201.1 40,201.547715 40,202.1 C40,202.652285 40.4477153,203.1 41,203.1 C41.5522847,203.1 42,202.652285 42,202.1 C42,201.547715 41.5522847,201.1 41,201.1 Z"
          id="Path"
        />
        <Path
          d="M841.64,161.62 C841.457137,161.615935 841.290006,161.72296 841.217193,161.89075 C841.14438,162.058541 841.180373,162.253711 841.308247,162.384492 C841.436121,162.515273 841.630433,162.555641 841.799818,162.486616 C841.969202,162.417592 842.079955,162.252908 842.08,162.07 C842.08006,161.825325 841.884614,161.625437 841.64,161.62 L841.64,161.62 Z"
          id="Path"
        />
        <Path
          d="M801.6,160.29 C800.616933,160.29 799.82,161.086933 799.82,162.07 C799.82,163.053067 800.616933,163.85 801.6,163.85 C802.583067,163.85 803.38,163.053067 803.38,162.07 C803.38,161.597915 803.192465,161.145165 802.85865,160.81135 C802.524835,160.477535 802.072085,160.29 801.6,160.29 L801.6,160.29 Z"
          id="Path"
        />
        <Circle id="Oval" cx={761.57} cy={162.07} r={3.22} />
        <Path
          d="M721.53,157.18 C718.829328,157.18 716.64,159.369328 716.64,162.07 C716.64,164.770672 718.829328,166.96 721.53,166.96 C724.230672,166.96 726.42,164.770672 726.42,162.07 C726.42,159.369328 724.230672,157.18 721.53,157.18 L721.53,157.18 Z"
          id="Path"
        />
        <Path
          d="M681.5,155.41 C677.821784,155.41 674.84,158.391784 674.84,162.07 C674.84,165.748216 677.821784,168.73 681.5,168.73 C685.178216,168.73 688.16,165.748216 688.16,162.07 C688.16,158.391784 685.178216,155.41 681.5,155.41 Z"
          id="Path"
        />
        <Path
          d="M641.47,153.629994 C638.055432,153.625954 634.974834,155.679727 633.665331,158.833217 C632.355827,161.986706 633.075448,165.61854 635.488482,168.034437 C637.901517,170.450334 641.532495,171.174259 644.687534,169.868495 C647.842574,168.562732 649.9,165.484571 649.9,162.07 C649.9,157.412619 646.127378,153.635518 641.47,153.629994 L641.47,153.629994 Z"
          id="Path"
        />
        <Path
          d="M601.43,152.08 C595.907153,152.08 591.43,156.557153 591.43,162.08 C591.43,167.602847 595.907153,172.08 601.43,172.08 C606.952847,172.08 611.43,167.602847 611.43,162.08 C611.43,159.427835 610.376432,156.884296 608.501068,155.008932 C606.625704,153.133568 604.082165,152.08 601.43,152.08 L601.43,152.08 Z"
          id="Path"
        />
        <Path
          d="M561.4,150.739996 C556.816535,150.735955 552.682138,153.493854 550.925318,157.727263 C549.168498,161.960672 550.135375,166.835546 553.374944,170.077978 C556.614513,173.320409 561.488532,174.29159 565.72349,172.538508 C569.958449,170.785427 572.72,166.653467 572.72,162.07 C572.72,155.816517 567.653481,150.745519 561.4,150.739996 L561.4,150.739996 Z"
          id="Path"
        />
        <Path
          d="M521.36,149.519996 C514.428826,149.519996 508.81,155.138826 508.81,162.07 C508.81,169.001174 514.428826,174.62 521.36,174.62 C528.291174,174.62 533.910004,169.001174 533.910004,162.07 C533.912656,158.74072 532.591281,155.547035 530.237123,153.192877 C527.882965,150.838719 524.68928,149.517344 521.36,149.519996 Z"
          id="Path"
        />
        <Path
          d="M481.33,148.859996 C474.034318,148.859996 468.12,154.774318 468.12,162.07 C468.12,169.365682 474.034318,175.28 481.33,175.28 C488.625682,175.28 494.540004,169.365682 494.540004,162.07 C494.542656,158.565677 493.151745,155.204118 490.673813,152.726187 C488.195882,150.248255 484.834323,148.857344 481.33,148.859996 Z"
          id="Path"
        />
        <Path
          d="M441.29,148.639996 C433.87412,148.645522 427.866321,154.660781 427.87,162.076662 C427.873682,169.492543 433.887449,175.501835 441.303331,175.499996 C448.719213,175.498157 454.73,169.485882 454.73,162.07 C454.73,158.506409 453.313686,155.08892 450.792909,152.570019 C448.272132,150.051117 444.85359,148.637347 441.29,148.639996 L441.29,148.639996 Z"
          id="Path"
        />
        <Path
          d="M401.26,148.859996 C393.964318,148.859996 388.05,154.774318 388.05,162.07 C388.05,169.365682 393.964318,175.28 401.26,175.28 C408.555682,175.28 414.470004,169.365682 414.470004,162.07 C414.472656,158.565677 413.081745,155.204118 410.603813,152.726187 C408.125882,150.248255 404.764323,148.857344 401.26,148.859996 L401.26,148.859996 Z"
          id="Path"
        />
        <Path
          d="M361.23,149.519996 C356.153091,149.515955 351.573837,152.571098 349.628184,157.26039 C347.682532,161.949682 348.753787,167.3493 352.342274,170.940648 C355.930761,174.531997 361.329523,175.607556 366.020365,173.665642 C370.711206,171.723728 373.77,167.146911 373.77,162.07 C373.77,155.14273 368.157268,149.52552 361.23,149.519996 L361.23,149.519996 Z"
          id="Path"
        />
        <Path
          d="M321.19,150.739996 C314.932614,150.739996 309.86,155.812614 309.86,162.07 C309.86,168.327386 314.932614,173.4 321.19,173.4 C327.447386,173.4 332.520004,168.327386 332.520004,162.07 C332.522656,159.064284 331.329816,156.18091 329.204453,154.055547 C327.07909,151.930184 324.195716,150.737344 321.19,150.739996 L321.19,150.739996 Z"
          id="Path"
        />
        <Path
          d="M281.16,152.08 C275.637153,152.08 271.16,156.557153 271.16,162.08 C271.16,167.602847 275.637153,172.08 281.16,172.08 C286.682847,172.08 291.16,167.602847 291.16,162.08 C291.16,159.427835 290.106432,156.884296 288.231068,155.008932 C286.355704,153.133568 283.812165,152.08 281.16,152.08 L281.16,152.08 Z"
          id="Path"
        />
        <Circle id="Oval" cx={241.12} cy={162.07} r={8.33} />
        <Path
          d="M201.09,155.52 C197.472535,155.52 194.54,158.452535 194.54,162.07 C194.54,165.687465 197.472535,168.62 201.09,168.62 C204.707465,168.62 207.64,165.687465 207.64,162.07 C207.64,160.332832 206.949913,158.666814 205.721549,157.438451 C204.493186,156.210087 202.827168,155.52 201.09,155.52 Z"
          id="Path"
        />
        <Path
          d="M161.06,157.17999 C159.081275,157.175954 157.295136,158.364795 156.535106,160.191739 C155.775075,162.018682 156.19097,164.123597 157.588711,165.524201 C158.986451,166.924805 161.090509,167.345009 162.919005,166.58872 C164.747501,165.83243 165.94,164.048729 165.94,162.07 C165.94,159.373228 163.756766,157.185515 161.06,157.17999 L161.06,157.17999 Z"
          id="Path"
        />
        <Circle id="Oval" cx={121.02} cy={162.07} r={3.22} />
        <Path
          d="M81,160.4 C80.3743498,160.351477 79.7743082,160.657787 79.4466283,161.192968 C79.1189483,161.728148 79.1189483,162.401852 79.4466283,162.937032 C79.7743082,163.472213 80.3743498,163.778523 81,163.73 C81.8696198,163.662555 82.5408683,162.937231 82.5408683,162.065 C82.5408683,161.192769 81.8696198,160.467445 81,160.4 L81,160.4 Z"
          id="Path"
        />
        <Path
          d="M40.9999934,161.62 C40.817992,161.62 40.6539057,161.729639 40.5842542,161.897792 C40.5146028,162.065946 40.5531029,162.259499 40.6818019,162.388198 C40.810501,162.516897 41.0040541,162.555397 41.1722075,162.485746 C41.340361,162.416094 41.45,162.252008 41.45,162.070007 C41.45,161.821472 41.2485281,161.62 40.9999934,161.62 L40.9999934,161.62 Z"
          id="Path"
        />
        <Path
          d="M801.6,121.26 C801.394056,121.257325 801.195625,121.337268 801.049052,121.481962 C800.902479,121.626655 800.819983,121.824039 800.82,122.03 C800.82,122.460782 801.169218,122.81 801.6,122.81 C802.030782,122.81 802.38,122.460782 802.38,122.03 C802.380017,121.824039 802.297521,121.626655 802.150948,121.481962 C802.004375,121.337268 801.805944,121.257325 801.6,121.26 Z"
          id="Path"
        />
        <Path
          d="M761.57,120.149973 C760.803761,120.145956 760.110906,120.604951 759.815808,121.312097 C759.520711,122.019243 759.681774,122.834585 760.223594,123.376406 C760.765415,123.918226 761.580757,124.079289 762.287903,123.784192 C762.995049,123.489094 763.454044,122.796239 763.450027,122.03 C763.444533,120.993978 762.606022,120.155467 761.57,120.149973 L761.57,120.149973 Z"
          id="Path"
        />
        <Circle id="Oval" cx={721.53} cy={122.03} r={3.33} />
        <Path
          d="M681.5,117.259989 C679.564856,117.255956 677.818197,118.419081 677.07578,120.206149 C676.333363,121.993218 676.741684,124.051604 678.11004,125.41996 C679.478396,126.788316 681.536782,127.196637 683.323851,126.45422 C685.110919,125.711803 686.274044,123.965144 686.270011,122.03 C686.270011,120.764917 685.767448,119.551649 684.872899,118.657101 C683.978351,117.762552 682.765083,117.259989 681.5,117.259989 L681.5,117.259989 Z"
          id="Path"
        />
        <Path
          d="M641.47,115.819992 C638.952429,115.815956 636.680693,117.329926 635.715391,119.655086 C634.750089,121.980246 635.28161,124.658003 637.061804,126.438196 C638.841997,128.21839 641.519754,128.749911 643.844914,127.784609 C646.170074,126.819307 647.684044,124.547571 647.680008,122.03 C647.680008,118.600312 644.899688,115.819992 641.47,115.819992 Z"
          id="Path"
        />
        <Path
          d="M601.43,114.479973 C598.375419,114.488116 595.626687,116.335997 594.466175,119.161549 C593.305663,121.987101 593.962054,125.233535 596.129122,127.38629 C598.29619,129.539044 601.546903,130.173907 604.364702,128.994696 C607.182502,127.815484 609.012127,125.054568 609.00006,122 C608.983462,117.834154 605.595864,114.468932 601.43,114.479973 L601.43,114.479973 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={122.03} r={8.66} />
        <Circle id="Oval" cx={521.36} cy={122.03} r={9.66} />
        <Circle id="Oval" cx={481.33} cy={122.03} r={10.21} />
        <Path
          d="M441.29,111.599995 C437.063781,111.599995 433.254472,114.148005 431.64093,118.054079 C430.027388,121.960152 430.927946,126.453704 433.922065,129.43635 C436.916183,132.418997 441.413158,133.302299 445.313009,131.673775 C449.21286,130.04525 451.746192,126.226188 451.730077,122 C451.713457,116.247484 447.042537,111.594461 441.29,111.599995 Z"
          id="Path"
        />
        <Path
          d="M401.26,111.819995 C397.121855,111.815983 393.389695,114.307788 391.807051,118.131333 C390.224407,121.954879 391.103673,126.355448 394.034087,129.277251 C396.964501,132.199055 401.367638,133.065368 405.18651,131.47148 C409.005382,129.877592 411.486194,126.138115 411.470079,122 C411.453466,116.372892 406.887132,111.819995 401.26,111.819995 L401.26,111.819995 Z"
          id="Path"
        />
        <Circle
          id="Oval"
          transform="translate(361.238373, 122.003121) rotate(-8.150000) translate(-361.238373, -122.003121) "
          cx={361.238373}
          cy={122.003121}
          r={9.66}
        />
        <Circle id="Oval" cx={321.19} cy={122.03} r={8.66} />
        <Path
          d="M281.16,114.37 C276.929499,114.37 273.5,117.799499 273.5,122.03 C273.5,126.260501 276.929499,129.69 281.16,129.69 C285.390501,129.69 288.82,126.260501 288.82,122.03 C288.82,117.799499 285.390501,114.37 281.16,114.37 Z"
          id="Path"
        />
        <Path
          d="M241.12,115.709992 C238.558845,115.709992 236.250067,117.253342 235.270892,119.619929 C234.291717,121.986516 234.83512,124.709945 236.647562,126.519523 C238.460003,128.329101 241.184287,128.868198 243.549323,127.885282 C245.914359,126.902367 247.454046,124.591152 247.450008,122.03 C247.450008,120.352098 246.782774,118.743064 245.595381,117.557546 C244.407987,116.372028 242.7979,115.707345 241.12,115.709992 Z"
          id="Path"
        />
        <Path
          d="M201.09,117.149907 C199.110395,117.158163 197.331008,118.359063 196.582842,120.191862 C195.834676,122.02466 196.26535,124.127729 197.673733,125.518902 C199.082116,126.910074 201.19032,127.314859 203.013782,126.544216 C204.837244,125.773573 206.016176,123.979556 206,122 C205.992068,120.705736 205.470304,119.467635 204.549495,118.558079 C203.628686,117.648522 202.384264,117.142019 201.09,117.149907 Z"
          id="Path"
        />
        <Path
          d="M161.06,118.589986 C159.660082,118.586036 158.396553,119.428421 157.861826,120.722196 C157.3271,122.015971 157.627199,123.504614 158.621421,124.490165 C159.615642,125.475716 161.106856,125.762765 162.395898,125.216727 C163.68494,124.670689 164.516225,123.39983 164.5,122 C164.483533,120.111867 162.948205,118.589986 161.06,118.589986 L161.06,118.589986 Z"
          id="Path"
        />
        <Path
          d="M121,120 C119.895431,120 119,120.895431 119,122 C119,123.104569 119.895431,124 121,124 C122.104569,124 123,123.104569 123,122 C123,120.895431 122.104569,120 121,120 Z"
          id="Path"
        />
        <Path
          d="M81,121.26 C80.7940562,121.257325 80.5956248,121.337268 80.4490518,121.481962 C80.3024787,121.626655 80.2199826,121.824039 80.22,122.03 C80.22,122.460782 80.5692179,122.81 81,122.81 C81.2059612,122.810017 81.4033445,122.727521 81.5480385,122.580948 C81.6927324,122.434375 81.7726748,122.235944 81.77,122.03 C81.7727161,121.824963 81.6924638,121.627528 81.5474678,121.482532 C81.4024719,121.337536 81.2050373,121.257284 81,121.26 L81,121.26 Z"
          id="Path"
        />
        <Path
          d="M761.57,81.22 C761.139218,81.22 760.79,81.5692179 760.79,82 C760.79,82.4307821 761.139218,82.78 761.57,82.78 C762.000782,82.78 762.35,82.4307821 762.35,82 C762.35,81.5692179 762.000782,81.22 761.57,81.22 L761.57,81.22 Z"
          id="Path"
        />
        <Path
          d="M721.53,80.22 C720.546933,80.22 719.75,81.0169331 719.75,82 C719.75,82.9830669 720.546933,83.78 721.53,83.78 C722.513067,83.78 723.31,82.9830669 723.31,82 C723.31,81.5279146 723.122465,81.0751647 722.78865,80.7413499 C722.454835,80.4075352 722.002085,80.22 721.53,80.22 L721.53,80.22 Z"
          id="Path"
        />
        <Path
          d="M681.5,79 C679.843146,79 678.5,80.3431458 678.5,82 C678.5,83.6568542 679.843146,85 681.5,85 C683.156854,85 684.5,83.6568542 684.5,82 C684.5,80.3431458 683.156854,79 681.5,79 Z"
          id="Path"
        />
        <Path
          d="M641.47,77.8899877 C639.806756,77.8859532 638.305035,78.8847527 637.665733,80.4202296 C637.026431,81.9557066 637.375592,83.7251286 638.550253,84.9026547 C639.724915,86.0801808 641.493481,86.4336517 643.03051,85.7980922 C644.56754,85.1625327 645.57,83.6632488 645.57,82 C645.57,79.7340092 643.735984,77.8955134 641.47,77.8899877 Z"
          id="Path"
        />
        <Path
          d="M601.43,76.78 C598.547074,76.78 596.21,79.1170736 596.21,82 C596.21,84.8829264 598.547074,87.22 601.43,87.22 C604.312926,87.22 606.65,84.8829264 606.65,82 C606.65,79.1170736 604.312926,76.78 601.43,76.78 L601.43,76.78 Z"
          id="Path"
        />
        <Circle id="Oval" cx={561.4} cy={82} r={6.11} />
        <Circle id="Oval" cx={521.36} cy={82} r={6.77} />
        <Circle id="Oval" cx={481.33} cy={82} r={7.33} />
        <Path
          d="M321.19,75.78 C317.754789,75.78 314.97,78.5647889 314.97,82 C314.97,85.4352111 317.754789,88.22 321.19,88.22 C324.625211,88.22 327.41,85.4352111 327.41,82 C327.404494,78.5670721 324.622928,75.7855059 321.19,75.78 L321.19,75.78 Z"
          id="Path"
        />
        <Path
          d="M362.28,75.78 C358.844789,75.78 356.06,78.5647889 356.06,82 C356.06,85.4352111 358.844789,88.22 362.28,88.22 C365.715211,88.22 368.5,85.4352111 368.5,82 C368.5,80.3503534 367.84468,78.7682721 366.678204,77.6017958 C365.511728,76.4353195 363.929647,75.78 362.28,75.78 L362.28,75.78 Z"
          id="Path"
        />
        <Path
          d="M403.38,75.7799919 C400.863339,75.7759539 398.592234,77.2888403 397.626344,79.6127718 C396.660455,81.9367033 397.190141,84.6136747 398.968259,86.3946563 C400.746377,88.1756379 403.422493,88.7096301 405.747975,87.7474812 C408.073458,86.7853323 409.59,84.5166645 409.59,82 C409.59,78.5686903 406.811305,75.7855166 403.38,75.7799919 Z"
          id="Path"
        />
        <Path
          d="M444.47,75.78 C441.034789,75.78 438.25,78.5647889 438.25,82 C438.25,85.4352111 441.034789,88.22 444.47,88.22 C447.905211,88.22 450.69,85.4352111 450.69,82 C450.684494,78.5670721 447.902928,75.7855059 444.47,75.78 L444.47,75.78 Z"
          id="Path"
        />
        <Path
          d="M281.16,76.67 C278.216322,76.67 275.83,79.0563223 275.83,82 C275.83,84.9436777 278.216322,87.33 281.16,87.33 C284.103678,87.33 286.49,84.9436777 286.49,82 C286.49,79.0563223 284.103678,76.67 281.16,76.67 Z"
          id="Path"
        />
        <Path
          d="M241.12,77.78 C238.789358,77.78 236.9,79.6693584 236.9,82 C236.9,84.3306416 238.789358,86.22 241.12,86.22 C243.450642,86.22 245.34,84.3306416 245.34,82 C245.34,80.8807864 244.895394,79.8074129 244.103991,79.0160094 C243.312587,78.2246059 242.239214,77.78 241.12,77.78 Z"
          id="Path"
        />
        <Path
          d="M201.09,78.89 C199.372394,78.89 197.98,80.2823944 197.98,82 C197.98,83.7176056 199.372394,85.11 201.09,85.11 C202.807606,85.11 204.2,83.7176056 204.2,82 C204.2,80.2823944 202.807606,78.89 201.09,78.89 L201.09,78.89 Z"
          id="Path"
        />
        <Path
          d="M161.06,80.1099734 C160.294669,80.1059506 159.602444,80.563858 159.306754,81.2697723 C159.011065,81.9756867 159.170287,82.7902441 159.710028,83.3328558 C160.249769,83.8754674 161.06347,84.0390091 161.770943,83.7470686 C162.478416,83.4551281 162.94,82.7653413 162.94,82 C162.94,80.9600746 162.099911,80.1155023 161.06,80.1099734 L161.06,80.1099734 Z"
          id="Path"
        />
        <Path
          d="M121,81.11 C120.508467,81.11 120.11,81.5084666 120.11,82 C120.11,82.4915334 120.508467,82.89 121,82.89 C121.491533,82.89 121.89,82.4915334 121.89,82 C121.89,81.7639573 121.796232,81.5375823 121.629325,81.370675 C121.462418,81.2037676 121.236043,81.11 121,81.11 L121,81.11 Z"
          id="Path"
        />
        <Path
          d="M721.53,41.52 C721.281472,41.52 721.08,41.7214719 721.08,41.97 C721.08,42.2185281 721.281472,42.42 721.53,42.42 C721.778528,42.42 721.98,42.2185281 721.98,41.97 C721.98,41.8506526 721.932589,41.7361933 721.848198,41.6518019 C721.763807,41.5674106 721.649347,41.52 721.53,41.52 L721.53,41.52 Z"
          id="Path"
        />
        <Path
          d="M281.16,38.86 C279.442394,38.86 278.05,40.2523944 278.05,41.97 C278.05,43.6876056 279.442394,45.08 281.16,45.08 C282.877606,45.08 284.27,43.6876056 284.27,41.97 C284.27,40.2523944 282.877606,38.86 281.16,38.86 Z"
          id="Path"
        />
        <Path
          d="M322.25,38.86 C320.532394,38.86 319.14,40.2523944 319.14,41.97 C319.14,43.6876056 320.532394,45.08 322.25,45.08 C323.967606,45.08 325.36,43.6876056 325.36,41.97 C325.36,40.2523944 323.967606,38.86 322.25,38.86 Z"
          id="Path"
        />
        <Path
          d="M363.34,38.86 C361.622394,38.86 360.23,40.2523944 360.23,41.97 C360.23,43.6876056 361.622394,45.08 363.34,45.08 C365.057606,45.08 366.45,43.6876056 366.45,41.97 C366.45,40.2523944 365.057606,38.86 363.34,38.86 L363.34,38.86 Z"
          id="Path"
        />
        <Path
          d="M241.12,39.6299785 C240.172659,39.6259515 239.316346,40.1935002 238.951005,41.06757 C238.585665,41.9416398 238.783389,42.9497513 239.451831,43.6210616 C240.120272,44.292372 241.127528,44.4944121 242.003154,44.1328183 C242.878781,43.7712245 243.45,42.9173498 243.45,41.97 C243.45,40.6815489 242.408439,39.6355062 241.12,39.6299785 Z"
          id="Path"
        />
        <Path
          d="M201.09,40.52 C200.502637,40.5159491 199.970853,40.8666503 199.743266,41.4081449 C199.515679,41.9496395 199.637255,42.5749434 200.051154,42.9917165 C200.465052,43.4084896 201.0895,43.5343893 201.632557,43.3105554 C202.175613,43.0867215 202.529986,42.5573773 202.53,41.97 C202.532664,41.5863586 202.382127,41.2175147 202.111786,40.9452956 C201.841444,40.6730765 201.473651,40.5199653 201.09,40.52 L201.09,40.52 Z"
          id="Path"
        />
        <Circle id="Oval" cx={161.06} cy={41.97} r={1} />
        <Path
          d="M320.88,1.83 C320.940751,1.83 320.99,1.78075132 320.99,1.72 C320.99,1.65924868 320.940751,1.61 320.88,1.61 C320.819249,1.61 320.77,1.65924868 320.77,1.72 C320.77,1.78075132 320.819249,1.83 320.88,1.83 L320.88,1.83 Z"
          id="Path"
        />
        <Path
          d="M360.91,2.16 C361.130219,2.12689511 361.293105,1.93769384 361.293105,1.715 C361.293105,1.49230616 361.130219,1.30310489 360.91,1.27 C360.689781,1.30310489 360.526895,1.49230616 360.526895,1.715 C360.526895,1.93769384 360.689781,2.12689511 360.91,2.16 Z"
          id="Path"
        />
        <Path
          d="M401,2.49 C401.425259,2.49 401.77,2.14525926 401.77,1.72 C401.774042,1.40273576 401.585499,1.11463962 401.293141,0.991353225 C401.000782,0.868066835 400.662878,0.934162105 400.43852,1.15852001 C400.214162,1.38287791 400.148067,1.72078222 400.271353,2.01314057 C400.39464,2.30549891 400.682736,2.49404207 401,2.49 L401,2.49 Z"
          id="Path"
        />
        <Path
          d="M441,2.83 C441.613036,2.83 442.11,2.33303607 442.11,1.72 C442.11,1.10696393 441.613036,0.61 441,0.61 C440.386964,0.61 439.89,1.10696393 439.89,1.72 C439.89,2.33303607 440.386964,2.83 441,2.83 Z"
          id="Path"
        />
        <Path
          d="M481,2.83 C481.613036,2.83 482.11,2.33303607 482.11,1.72 C482.11,1.10696393 481.613036,0.61 481,0.61 C480.386964,0.61 479.89,1.10696393 479.89,1.72 C479.89,2.33303607 480.386964,2.83 481,2.83 Z"
          id="Path"
        />
        <Path
          d="M521.05,2.83 C521.663036,2.83 522.16,2.33303607 522.16,1.72 C522.16,1.10696393 521.663036,0.61 521.05,0.61 C520.436964,0.61 519.94,1.10696393 519.94,1.72 C519.94,2.33303607 520.436964,2.83 521.05,2.83 Z"
          id="Path"
        />
        <Path
          d="M561.08,2.72 C561.632285,2.72 562.08,2.27228475 562.08,1.72 C562.08,1.16771525 561.632285,0.72 561.08,0.72 C560.527715,0.72 560.08,1.16771525 560.08,1.72 C560.08,2.27228475 560.527715,2.72 561.08,2.72 Z"
          id="Path"
        />
        <Circle id="Oval" cx={601.12} cy={1.72} r={1} />
        <Path
          d="M641.15,2.16 C641.333765,2.16404026 641.501516,2.05591015 641.573719,1.88687532 C641.645922,1.71784048 641.608063,1.5218839 641.478089,1.39191067 C641.348116,1.26193744 641.15216,1.22407755 640.983125,1.29628077 C640.81409,1.368484 640.70596,1.5362345 640.71,1.72 C640.71,1.96300529 640.906995,2.16 641.15,2.16 L641.15,2.16 Z"
          id="Path"
        />
        <Path
          d="M401,44.42 C402.4746,44.42 403.67,43.2246003 403.67,41.75 C403.67,40.2753997 402.4746,39.08 401,39.08 C399.5254,39.08 398.33,40.2753997 398.33,41.75 C398.33,43.2246003 399.5254,44.42 401,44.42 Z"
          id="Path"
        />
        <Path
          d="M441,44.86 C442.717606,44.86 444.11,43.4676056 444.11,41.75 C444.11,40.0323944 442.717606,38.64 441,38.64 C439.282394,38.64 437.89,40.0323944 437.89,41.75 C437.89,43.4676056 439.282394,44.86 441,44.86 Z"
          id="Path"
        />
        <Circle id="Oval" cx={481.01} cy={41.75} r={3.22} />
        <Path
          d="M521.05,45 C522.828357,45 524.27,43.5583569 524.27,41.78 C524.27,40.0016431 522.828357,38.56 521.05,38.56 C519.271643,38.56 517.83,40.0016431 517.83,41.78 C517.83,43.5583569 519.271643,45 521.05,45 Z"
          id="Path"
        />
        <Path
          d="M561.08,44.75 C562.736854,44.75 564.08,43.4068542 564.08,41.75 C564.08,40.0931458 562.736854,38.75 561.08,38.75 C559.423146,38.75 558.08,40.0931458 558.08,41.75 C558.08,43.4068542 559.423146,44.75 561.08,44.75 L561.08,44.75 Z"
          id="Path"
        />
        <Path
          d="M601.12,44.42 C602.5946,44.42 603.79,43.2246003 603.79,41.75 C603.79,40.2753997 602.5946,39.08 601.12,39.08 C599.6454,39.08 598.45,40.2753997 598.45,41.75 C598.45,43.2246003 599.6454,44.42 601.12,44.42 Z"
          id="Path"
        />
        <Path
          d="M641.15,43.86 C642.315321,43.86 643.26,42.9153208 643.26,41.75 C643.26,40.5846792 642.315321,39.64 641.15,39.64 C639.984679,39.64 639.04,40.5846792 639.04,41.75 C639.04,42.9153208 639.984679,43.86 641.15,43.86 Z"
          id="Path"
        />
        <Path
          d="M681.18,43.19 C681.97529,43.19 682.62,42.54529 682.62,41.75 C682.62,40.95471 681.97529,40.31 681.18,40.31 C680.38471,40.31 679.74,40.95471 679.74,41.75 C679.74,42.54529 680.38471,43.19 681.18,43.19 Z"
          id="Path"
        />
      </G>
    </G>
  </Svg>
  )
}

export default EliotCircleBackground
/*Logo.defaultProps = {
  width: 121,
  height: 154,
  color: colors.exisOrange
}

Logo.propTypes = {
  color: PropTypes.string
}*/
