import React from 'react'
import PropTypes from 'prop-types'

import Svg, { Circle, Path } from 'svgs'
import { colors } from '@sart/styles'

const Radio = props => {
  const { color, size, ...otherProps } = props
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle cx="12" cy="12" r="2" />
      <Path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" />
    </Svg>
  )
}

Radio.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Radio.defaultProps = {
  color: colors.feature,
  size: '24'
}

export default Radio
