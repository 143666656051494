import React, { useEffect, useState } from 'react'

import { View, TouchableOpacity, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import {SvgXml} from 'react-native-svg'
import PropTypes from 'prop-types'

import { colors, backgroundColors } from '@sart/styles'

import { Delete, ProductIconType } from '@sart/icons'

import {
  Text,
  Price,
  Space
} from '@sart/components' 

import { getWebSafeProductImage, getProductImage, testID } from '@sart/utilities'

import { client } from '@sart/client'

function typeOfProduct({ localData, localMinutes, localSMS }) {
  if (localData > 0 && !localMinutes && !localSMS) {
    return 'data'
  }

  if (localMinutes > 0 && !localData && !localSMS) {
    return 'minutes'
  }

  if (localSMS > 0 && !localData && !localMinutes) {
    return 'sms'
  }

  return 'hybrid'
}

var CryptoJS = require("crypto-js");

export default function ProductListItem(props) {
  const {
    product,
    disabled,
    onPress,
    style,
    placeholderStyle,
    bodyStyle,
    headerStyle,
    titleStyle,
    priceStyle,
    descriptionStyle,
    basket,
    allowDelete,
    removeProductFunction,
    imageColor
    // ...rest
  } = props
  
  const [image, setImage] = useState(undefined)
  const [imageLoading, setImageLoading] = useState(true)

  useEffect(() => {
    getImage()
  }, [])
  

  async function getImage() {
    try{
      setImageLoading(true)
      const productImage = await getProductImage(displayDetails, 'AltIcon')
      setImage(productImage)
      setImageLoading(false)
    }
    catch(error) {
      setImageLoading(false)
    }
  }

  function getNameTranslation(product) {
    let toReturn = product.productName
    if(product.displayDetails) {
      product.displayDetails.map(detail => {
        if(detail.language === client.cache.language) {
          toReturn = detail.displayName
        }
      })
    }
    return toReturn
  }

  /* 
  Functions to get display information from product's Display Details 
  */
  function getDisplayDetailsForLanguage(product) {
    //Get the display details for the language that is selected in the cache
    let displayDetails = undefined
    
    //identify the correct display details to use based on language
    if (product.displayDetails) {
      product.displayDetails.map(detail => {
        if(detail.language === client.cache.language) {
          displayDetails = detail
        }
      })
    }

    return displayDetails
  }

  function getColourByCategory(displayDetails, category) {
    if (!category){
      return undefined
    }

    let toReturn = undefined
    const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/
    
    //get the relevant font colour based on the category
    if (displayDetails && displayDetails.colors && displayDetails.colors.length) {
      displayDetails.colors.map(color => {
        //we also only accept hex values, so use regex to make sure it matches the pattern
        if (color.category === category && hexCodeRegex.test(color.color)){
          toReturn = color.color
        } else if (color.category === category && color.color === 'transparent') {
          return undefined
        }
      })
    }

    return toReturn
  }

  function getDescriptionTranslation(product) {
    let toReturn = product.description
    if(product.displayDetails) {
      product.displayDetails.map(detail => {
        if(detail.language === client.cache.language) {
          toReturn = detail.displayDescription
        }
      })
    }
    return toReturn
  }

  /* 
  End Display Details Functions 
  */

  const displayDetails = getDisplayDetailsForLanguage(product)
  // const productImage = getProductIcon(displayDetails)
  const type = typeOfProduct(product)
  let imageColorStyle = imageColor ? imageColor : colors.exisOnTint

  const name = getNameTranslation(product)
  const description = getDescriptionTranslation(product)

  const titleColour = getColourByCategory(displayDetails,'AltTitleText')
  const descriptionColour = getColourByCategory(displayDetails,'AltDescriptiveText')
  const backgroundColour = getColourByCategory(displayDetails,'AltBackground')
    
  return (
    <TouchableOpacity
      style={[styles.container, style, backgroundColour ? {backgroundColor: backgroundColour} : {}]}
      onPress={onPress}
      disabled={disabled}
      {...testID("productItem".concat(name.replace(/\s/g, "")))}
    >
        {(image && !imageLoading && Platform.OS !== "web") && 
        <View style={[styles.productImageMobile, placeholderStyle]}>
          <SvgXml xml={image} />
        </View>
        }
        {(image && !imageLoading && Platform.OS === "web") && 
        <View style={[styles.productImageWeb, placeholderStyle]}>
          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} />
        </View>
        }
        {(!image && !imageLoading) &&
        <View style={[styles.placeholder, placeholderStyle]}>
          <ProductIconType type={type} color={imageColorStyle}/>
        </View>
        }
        {imageLoading &&
          <ActivityIndicator size="large" color="blue" style={{ justifyContent: 'center', alignItems: 'center', width: '23%' }} />
        }

      <View style={[styles.body, bodyStyle]}>
        <View style={[styles.header, headerStyle]}>
          <Text style={[styles.title, titleStyle, titleColour ? {color: titleColour}:{}]}>{name}</Text>
          <Price
            value={product.upfrontCost > 0 ? product.upfrontCost : product.recurringCost}
            style={[styles.title, priceStyle, descriptionColour ? {color: descriptionColour}:{}]}
            code={product.currency}
          />
        </View>
        <Space hieght={32} />
        <View style={styles.itemSubHeaderItemStyle}>
        <Text style={[styles.description, descriptionStyle, descriptionColour ? {color: descriptionColour}:{}]}>
          {description}
        </Text>
        {allowDelete &&
          <Delete color={colors.exisDarkCharcoal} size={33} onPress={removeProductFunction}/>
        }
        </View>
      </View>
    </TouchableOpacity>
  )
}

ProductListItem.propTypes = {
  product: PropTypes.object,
  onPress: PropTypes.func
}

const styles = StyleSheet.create({
  container: {
    height: 72,
    flexDirection: 'row',
    borderRadius: 8,
    backgroundColor: backgroundColors.subtle,
    overflow: 'hidden'
  },
  placeholder: {
    // flex: 1,
    width: 72,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColors.exisOrange,
  },
  body: {
    flex: 1,
    padding: 12,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: 'bold',
    color: colors.exisBlue

  },
  description: {
    fontSize: 14,
    lineHeight: 24,
    color: colors.common
  },
  itemSubHeaderItemStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  productImageMobile: {
    marginTop: 12,
    width: 72,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: backgroundColors.exisOrange,
  },
  productImageWeb: {
    // marginTop: 12,
    width: 72,
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor: backgroundColors.exisOrange,
  }
})

