import React from 'react'

import US from './US'
import MX from './MX'

export default function LanguageIconType(props) {
  const { type, ...rest } = props

  switch (type) {
    case 'en':
      return <US {...rest} />

    case 'es':
      return <MX {...rest} />

    default:
      return null
  }
}
