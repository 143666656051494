import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text, Linking } from 'react-native'

import {
  Headline, HorizontalLine, Space,
} from '@sart/components'
import { useContent } from '@sart/content'
import { typography, colors } from '@sart/styles'
import client, { useData } from '@sart/client'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'

import Accordion from 'react-native-collapsible/Accordion';
import { Download, Share } from '@sart/icons/'

export default function Legal(props) {

  const content = useContent()

  const [{ legalItems, error, loading }, setData] = useData({})

  const items = React.useMemo(() => legalItems)

  useEffect(() => {
    getLegalItems()
  }, [])

  function flatMap(array, fn) {
    var result = [];
    for (var i = 0; i < array.length; i++) {
      var mapping = fn(array[i]);
      result = result.concat(mapping);
    }
    return result;
  }

  async function getLegalItems() {
    setData({ loading: true })
    try {
      const legalItems =  await client.callApi('contentCms','getPages',[1, { category: "LEGAL" }]);
      const parsedItems = legalItems.map(item => {
        let excerpt = {}
        try {
          excerpt = JSON.parse(item.excerpt)
        } catch(error) {
          return {}
        }
        // let content = JSON.parse(item.excerpt)
        let parsed = [];
        parsed.rank = excerpt.rank;
        parsed.title = excerpt.title;
        parsed.content = excerpt.description
        return parsed;
      });
      const orderedItems = parsedItems.sort(function(a,b) {
        return a.rank - b.rank;
      })
      setData({
        legalItems: orderedItems,
        loading: false
      })
      client.setCache({
        legalItems: orderedItems
      })
    } catch (error) {
      // TODO: handle error
      setData({ loading: true, error })
    }
  }
  const [activeSections, setActiveSections] = useState([])

  var _renderHeader = section => {
    return (
      <View style={styles.header}>
        <View style={{flexDirection: 'row'}}>
          {activeSections.length ==  0 &&
            <Text style={styles.bulletPoint}>+</Text>
          }
          {activeSections.length ==  1 &&
            <Text style={styles.bulletPoint}>-</Text>
          }
          <Space width={5} height={5} />
          <Text style={styles.headerText}>{section.title}</Text>
        </View>
        <Space height={20} />
      </View>
    );
  };
  
  var _renderContent = section => {
    return (
      <View style={styles.content}>
        {section.content && section.content.map((description) =>
        <View> 
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Space width={20}/>
            {description.downloadIcon &&
              <Download size={25}/>
            }
            {description.shareIcon &&
              <Share size={25}/>
            }
            <Text style={styles.contentText}>{<a dangerouslySetInnerHTML={ { __html: description.link } }></a>}</Text>
          </View>
          <Space height={16}/>
        </View>
        )}
        <Space height={10} />
        <HorizontalLine height={3} color={colors.exisBlue} />
        <Space height={10} />
      </View>
    );
  };

  var _updateSections = activeSections => {
    setActiveSections(activeSections);
  };

  return (
    <Wrapper style={styles.container}>
      <Headline style={styles.title} {...testID('LegalHeader')}>{content.legal.header}</Headline>
      <Space height={48} />
      {legalItems &&
      <Accordion
        sections={legalItems}
        activeSections={activeSections}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
        onChange={_updateSections}
        underlayColor={colors.clear}	
      />
      }
      <Space height={250} />
      <HorizontalLine height={3} color={colors.exisBlue} />
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48
  },
  title: {
    ...typography.bold,
    color: colors.exisBlue,
    fontSize: 45
  },
  headerText: {
    ...typography.bold
  },
  contentText: {
    ...typography.bold,
    paddingLeft: 10
  },
  bulletPoint: {
    color: colors.exisOrange,
    fontSize: 16,
    ...typography.extrabold
  },
  hyperlink: {
    color: 'blue'
  }
})

