import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text, Platform, ActivityIndicator } from 'react-native'

import {
  Space,
  Button
} from '@sart/components'
import {
  colors,
  typography
} from '@sart/styles'
import { useContent } from '@sart/content'
import { EliotCost } from '@sart/icons'
import { getWebSafeProductImage, getProductImage, testID } from '@sart/utilities'
import client from '@sart/client'

import {
  isMobileOnly
} from "react-device-detect";

export default function PlanItemEliotDetailed(props) {
  const { plan, link } = props
  // currency "GBP"
  // localData 1
  // localDataUnit "GB"
  // recurringCost 750
  // validity 24
  // validityUnit "Months"
  // recurringFrequency 30
  // validityUnit "Months"
  useEffect(() => {
    getImages()
  }, [])

  const content = useContent()

  const [image, setImage] = useState(undefined)
  const [imageLoading, setImageLoading] = useState(false)
  const [icon, setIcon] = useState(undefined)
  const [iconLoading, setIconLoading] = useState(true)

  if (plan.localDataUnit === 'MB' && plan.localData >= 1000) {
    plan.localData = plan.localData / 1000
    plan.localDataUnit = 'GB'
  }

  let productDisplayName

  if (plan.productName) {
    productDisplayName = plan.productName.replaceAll("Plan ", "Plan\n")
    productDisplayName = productDisplayName.replaceAll("Recurrente", "\nRecurrente")
  }

  function getDisplayDetailsForLanguage(product) {
    //Get the display details for the language that is selected in the cache
    let displayDetails = undefined

    //identify the correct display details to use based on language
    if (product.displayDetails) {
      product.displayDetails.map(detail => {
        if (detail.language === client.cache.language) {
          displayDetails = detail
        }
      })
    }

    return displayDetails
  }

  function getColourByCategory(displayDetails, category) {
    if (!category) {
      return undefined
    }

    let toReturn = undefined
    const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

    //get the relevant font colour based on the category
    if (displayDetails && displayDetails.colors && displayDetails.colors.length) {
      displayDetails.colors.map(color => {
        //we also only accept hex values, so use regex to make sure it matches the pattern
        if (color.category === category && hexCodeRegex.test(color.color)) {
          toReturn = color.color
        }
      })
    }

    return toReturn
  }

  const displayDetails = getDisplayDetailsForLanguage(plan)


  async function getImages() {
    try{
      setImageLoading(true)
      setIconLoading(true)
      let productIcon = await getProductImage(displayDetails, 'AltIcon')
      //for this page, we want our accent colour removed from the product Icon
      if (productIcon && accentColour) {
        productIcon = productIcon.replaceAll(accentColour, 'transparent')
      }
      setIcon(productIcon)
      setIconLoading(false)
      const productImage = await getWebSafeProductImage(displayDetails, 'AltBackground')
      setImage(productImage)
      setImageLoading(false)
    }
    catch(error) {
      setIconLoading(false)
      setImageLoading(false)
    }
  }

  // const backgroundColour = getColourByCategory(displayDetails, 'Background')
  const accentColour = getColourByCategory(displayDetails, 'AltAccent')


  const actionColour = accentColour || colors.exisOrange

  function handleRouting() {
    window.open('https://hol.com.mx/#planes', '_blank')
  }

  return (
    <View>
      <View style={styles.gridContainer}>
        {imageLoading && 
          <ActivityIndicator size="large" color="#0000ff" style={{ height: 600, width: 250, justifyContent: 'center' }} />
        }
        {(image && image!== undefined &&
          <div
            style={{
              backgroundImage: `url(data:image/svg+xml;charset=utf-8,${image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              height: '600px',
              width: '250px',
              marginLeft: isMobileOnly ? '24%' : '0%',
              marginTop: isMobileOnly ? '12%' : '0%'
              // alignSelf: 'center'
            }}>
            <EliotCost fontFamily={'AvertaExtraBold'} style={{ marginLeft: isMobileOnly ? '80px' : '78px', marginTop: isMobileOnly ? '-3%' : '0%' }} cost={plan.upfrontCost / 100} colour={actionColour} />

            {/* <View style={styles.buttonLinkContainer}>

            <Space height={8} />
          </View> */}
          </div>
        )}
        <View style={styles.planInfoContainer}>

          <View style={styles.planInfoHeader}>

            {(iconLoading) &&
              <View style={[styles.productImage]}>
                <ActivityIndicator size="large" color="#0000ff" style={{ height: 140, justifyContent: 'center' }} />
              </View>
            }

            {(icon && icon!== undefined && Platform.OS === "web") &&
              <View style={[styles.productImage]}>
                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`} height="140" width="140" />
              </View>
            }
            <Space width={48} />
            <Text style={[styles.planNameText, { color: actionColour }]}>{productDisplayName}</Text>

          </View>
          <Space height={12} />
          <View style={styles.planInfoDescription}>
            <Text style={styles.descriptionTitle}>{content.planitem.eliotDescription}</Text>
            <Space height={4} />

            {/* <Button
              title={content.planitem.eliotLink}
              titleStyle={styles.buttonTitle}
              style={[styles.button, { backgroundColor: colors.eliotPurple, width: '45%' }]}
              onPress={props.onPress}
            /> */}
            <Text style={styles.descriptionLink} onPress={() => handleRouting()}>{content.planitem.eliotLink}</Text>
            <Space height={20} />
            <Text style={styles.additionalInfoTitle}>{content.planitem.inAdditionTo}</Text>
            <Space height={16} />
            <View style={styles.additionalInfoContainer}>
              <Text style={styles.additionalInfoItem}>•{content.planitem.portacredencial}</Text>
              <Text style={styles.additionalInfoItem}>•{content.planitem.whatsappExclusive}</Text>
              <Text style={styles.additionalInfoItem}>•{content.planitem.contentCreated}<Text style={styles.communityText}> {content.planitem.community}</Text><Text style={styles.holText}> {content.planitem.hol}</Text></Text>
              <Text style={styles.additionalInfoItem}>•{content.planitem.stickers}</Text>
              <Text style={styles.additionalInfoItem}>•{content.planitem.wallpapers}</Text>
            </View>
          </View>
        </View>
      </View>
      {link &&
        <View>
          <Space height={8} />
          <Text style={[styles.link]} onPress={() => window.open('https://crm.altanredes.com/preactiva/checkimei_websiteone.php', '_blank')}>{content.plans.link}</Text>
          <Space height={8} />
        </View>
      }

      <Button
        title={content.planitem.action}
        titleStyle={styles.buttonTitle}
        style={[styles.button, { backgroundColor: actionColour }]}
        onPress={props.onPress}
        {...testID([plan.productName.replace(/\s/g, ""), 'PlanBreakdown'].join(''))}
        />
    </View>
  )
}

const styles = StyleSheet.create({
  gridContainer: {
    ...(isMobileOnly ?

      { //Mobile Only
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%'
      }
      :
      { //Non-Mobile Only
        flexDirection: 'row',
        justifyContent: 'space-around',
      }
    ),
  },
  link: {
    textDecorationLine: 'underline',
    ...typography.medium,
    textAlign: 'center',
    fontFamily: 'AvertaRegular',
    color: colors.eliotPurple,
  },
  button: {
    borderRadius: 16,
    alignSelf: 'center',
    width: '25%',
  },
  buttonTitle: {
    fontFamily: 'AvertaRegular',
    fontWeight: 'bold'
  },
  planInfoContainer: {
    ...(isMobileOnly ?

      { //Mobile Only
        width: 410,
        height: '40%',
        justifyContent: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }
      :
      { //Non-Mobile Only
        width: 500,
        height: '60%',
        justifyContent: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }
    ),
  },
  planInfoHeader: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  planNameText: {
    fontSize: 30,
    textAlign: 'left',
    alignSelf: 'center',
    fontFamily: 'AvertaExtraBold',
    width: 180,
    transform: [{ rotate: '355deg' }]
  },
  planInfoDescription: {

  },
  descriptionTitle: {
    ...(isMobileOnly ?

      { //Mobile Only
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.eliotPurple,
        fontFamily: 'BebasNeueBold',
        fontSize: 24,
      }
      :
      { //Non-Mobile Only
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.eliotPurple,
        fontFamily: 'BebasNeueBold',
        fontSize: 28,
      }
    ),
  },
  descriptionLink: {
    textAlign: 'center',
    color: colors.eliotPurple,
    textDecorationLine: 'underline',
  },
  additionalInfoTitle: {
    ...(isMobileOnly ?

      { //Mobile Only
        textAlign: 'center',
        color: colors.eliotPurple,
        fontFamily: 'BebasNeueBold',
        fontSize: 24,
      }
      :
      { //Non-Mobile Only
        textAlign: 'center',
        color: colors.eliotPurple,
        fontFamily: 'BebasNeueBold',
        fontSize: 28,
      }
    ),
  },
  productImage: {
    marginTop: 12,
    marginRight: 6,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  additionalInfoContainer: {
    ...(isMobileOnly ?

      { //Mobile Only
        height: 130,
        width: '88%',
        marginLeft: '12%',
      }
      :
      { //Non-Mobile Only
        height: 300,
        width: '100%',
        marginLeft: '10%',
      }
    ),
  },
  additionalInfoItem: {
    color: colors.eliotPurple,
    fontSize: 18,
    fontFamily: 'AvertaRegular',
    ...(isMobileOnly ?

      { //Mobile Only
        color: colors.eliotPurple,
        fontSize: 16,
        fontFamily: 'AvertaRegular',
      }
      :
      { //Non-Mobile Only
        color: colors.eliotPurple,
        fontSize: 18,
        fontFamily: 'AvertaRegular',
      }
    ),
  },
  communityText: {
    color: colors.eliotPurple,
    fontFamily: 'AvertaExtraBold',
  },
  holText: {
    color: colors.eliotYellow,
    fontFamily: 'AvertaExtraBold',
  }
})
