import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { Redirect } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  Text,
  Space,
  Button,
  Subhead,
  HorizontalLineSpacer
} from '@sart/components'
import {
  colors,
  typography
} from '@sart/styles'

import client from '@sart/client'
import { useContent } from '@sart/content'
import { testID } from '@sart/utilities'

import {
  isMobileOnly
} from "react-device-detect";

export default function CheckoutReview(props) {
  const history = props.history

  const content = useContent()

  const {
    selectedPlan,
    customerDetails,
    customerId,
    orderId,
    existingSIM,
  } = client.cache

  const [error,setError] = useState()

  var eliotJourney

  useEffect(() => {
    getEliotJourney()
    submitCheckout()
    // eslint-disable-next-line
  }, [])

  function backToStart() {
    if (existingSIM) {
      history.push('/alreadyhavesim')
    } else {
      history.push('/plans')
    }
  }

  
  async function getEliotJourney() {
    eliotJourney = await AsyncStorage.getItem('eliotJourney')
  }
  
  async function submitCheckout() {
    setError(null)

    try {
      //Sign in with the agent first
      await client.signInWithAgentUser({
        username: 'VADSA-SHOPPING-AGENT',
        password: 'Passw001'
      })
      const order = await client.pollOrderForStatus(customerId, orderId)
      if (!eliotJourney && order && (order.status === 'successful' || order.status === 'awaiting-sim')){
        client.setCache({ orderMessage: order.accountAddItems[0].message })
        history.push('/checkout/confirmation')
      } if(eliotJourney && order && (order.status === 'successful' || order.status === 'awaiting-sim')) {
        client.setCache({ orderMessage: order.accountAddItems[0].message })
        history.push('/hol_confirmation')
      } else {
        setError('order failed')
      }
    } catch (error) {
      setError(error)
    }
  }

  if (!selectedPlan) return <Redirect to="/checkout" />
  if (!customerDetails) return <Redirect to="/checkout/customer" />
  if (!orderId || !customerId) return <Redirect to="/checkout/payment" />

  return (
    <View style={styles.container}>
    <View style={styles.fragment}>
      {!error && 
        <Subhead style={styles.bold} {...testID('processing')}>{content.checkout.processing}</Subhead> 
      }

      <Space height={12} />
      {error &&
      <Text style={styles.demi} {...testID('orderFailedMessage')}>
        {content.checkout.orderProcessingFailed}
      </Text>
      }
      <HorizontalLineSpacer />
      <Space height={12} />

      <Text style={error ? styles.book : styles.demi}>
        {error ? content.checkout.orderProcessingFailedDescription : content.checkout.orderProcessing}
      </Text>

      {error &&
        <React.Fragment>
          <Space height={24} />

          <Button
            title={content.unavailable.action}
            onPress={backToStart}
            style={{ backgroundColor: colors.exisOrange, width: '100%' }}
            titleStyle={styles.bold}
          />
        </React.Fragment>
      }
    </View>
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  fragment: {
    ...(isMobileOnly ? 
      
      { //Mobile Only
        flex: 1,
        marginLeft: '1%',
        marginRight: '1%',
        width: '70%',
        alignSelf: 'center'
      } 
      : 
      { //Non-Mobile Only
      }
    ),
  },
  bold: {
    ...typography.bold
  },
  demi: {
    ...typography.demi
  },
  book: {
    ...typography.book
  }
})
