import { client } from "@sart/client"

export const broadbandBalanceContainer = [{
  balanceDisplayName: 'DATA',
  rank: 1,
  displayUnitType: 'GB',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  breakdown:[]
}]

export const homePhoneBalanceContainer = [{
  balanceDisplayName: 'Minutes',
  rank: 1,
  displayUnitType: 'MINS',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  breakdown:[]
}]

export const prepaidBalanceContainer = [{
  balanceDisplayName: 'Data',
  rank: 1,
  displayUnitType: 'GB',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  suppressDisplay: true,
  breakdown:[]
},
{
  balanceDisplayName: 'Texts',
  rank: 2,
  displayUnitType: 'TEXTS',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  suppressDisplay: true,
  breakdown:[]
},
{
  balanceDisplayName: 'Minutes',
  rank: 3,
  displayUnitType: 'MINS',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  suppressDisplay: true,
  breakdown:[]
},
{
  balanceDisplayName: 'Bonus TalkTime',
  rank: 4,
  displayUnitType: 'BND',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  suppressDisplay: true,
  breakdown:[]
}]

export const postpaidBalanceContainer = [{
  balanceDisplayName: 'Data',
  rank: 1,
  displayUnitType: 'GB',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  breakdown: []
},
{
  balanceDisplayName: 'Text',
  rank: 2,
  displayUnitType: 'TEXTS',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  breakdown: []
},
{
  balanceDisplayName: 'Minutes',
  rank: 3,
  displayUnitType: 'MINS',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 0,
  breakdown: []
},
{
  balanceDisplayName: 'OUT OF PLAN',
  rank: 4,
  displayUnitType: 'BND',
  displayBalanceUnits: 0,
  displayStartingBalanceUnits: 100,
  breakdown: [{
    balanceDisplayName: 'OUT OF PLAN',
    displayUnitType: 'BND',
    displayBalanceUnits: 0,
    displayStartingBalanceUnits: 100
  }]
}]

export function setupBalances(balanceContainer, updatedBalances) {
  var newBalances = balanceContainer
  var balance = undefined
  for(var i in updatedBalances) {
    if(updatedBalances[i].balanceType === 'unitary') {
      if(updatedBalances[i].units.toUpperCase() === 'KB') {
        balance = convertBalance(updatedBalances[i],newBalances[0].displayUnitType,client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[0].displayBalanceUnits = 'unl'
          newBalances[0].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[0].displayBalanceUnits != 'unl' && newBalances[0].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[0].displayBalanceUnits = parseFloat(newBalances[0].displayBalanceUnits) + parseFloat((balance.quantity / balance.conversionRate).toFixed(2))
            newBalances[0].displayStartingBalanceUnits = parseFloat(newBalances[0].displayStartingBalanceUnits) + parseFloat((balance.startingQuantity / balance.conversionRate).toFixed(2))
          } else {
            newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (Math.floor(updatedBalances[i].quantity / 1024))
            newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (Math.floor(updatedBalances[i].startingQuantity / 1024))
          }
        }
        delete newBalances[0].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[0].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'MB',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity / 1024),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity / 1024),
            expiryDateTime: updatedBalances[i].expiryDateTime
          })
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'MB') {
        balance = convertBalance(updatedBalances[i],newBalances[0].displayUnitType,client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[0].displayBalanceUnits = 'unl'
          newBalances[0].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[0].displayBalanceUnits != 'unl' && newBalances[0].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[0].displayBalanceUnits = parseFloat(newBalances[0].displayBalanceUnits) + parseFloat((balance.quantity / balance.conversionRate).toFixed(2))
            newBalances[0].displayStartingBalanceUnits = parseFloat(newBalances[0].displayStartingBalanceUnits) + parseFloat((balance.startingQuantity / balance.conversionRate).toFixed(2))
          } else {
            newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (updatedBalances[i].quantity / 1000)
            newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (updatedBalances[i].startingQuantity / 1000)
          }
        }
        delete newBalances[0].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[0].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'MB',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'BYTES') {
        balance = convertBalance(updatedBalances[i],newBalances[0].displayUnitType,client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[0].displayBalanceUnits = 'unl'
          newBalances[0].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[0].displayBalanceUnits != 'unl' && newBalances[0].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[0].displayBalanceUnits = parseFloat(newBalances[0].displayBalanceUnits) + parseFloat((balance.quantity / balance.conversionRate).toFixed(2))
            newBalances[0].displayStartingBalanceUnits = parseFloat(newBalances[0].displayStartingBalanceUnits) + parseFloat((balance.startingQuantity / balance.conversionRate).toFixed(2))
          } else {
            newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + updatedBalances[i].quantity
            newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
          }
        }
        delete newBalances[0].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[0].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'MB',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity / balance.conversionRate),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity / balance.conversionRate),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'TEXTS') {
        balance = convertBalance(updatedBalances[i],'TEXTS',client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[1].displayBalanceUnits = 'unl'
          newBalances[1].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[1].displayBalanceUnits != 'unl' && newBalances[1].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[1].displayBalanceUnits = newBalances[1].displayBalanceUnits + (Math.floor(balance.quantity / balance.conversionRate))
            newBalances[1].displayStartingBalanceUnits = newBalances[1].displayStartingBalanceUnits + (Math.floor(balance.startingQuantity / balance.conversionRate))
          } else {
            newBalances[1].displayBalanceUnits = newBalances[1].displayBalanceUnits + updatedBalances[i].quantity
            newBalances[1].displayStartingBalanceUnits = newBalances[1].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
          }
        }
        delete newBalances[1].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[1].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'TEXTS',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'ITEMS') {
        balance = convertBalance(updatedBalances[i],'ITEMS',client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[1].displayBalanceUnits = 'unl'
          newBalances[1].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[1].displayBalanceUnits != 'unl' && newBalances[1].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[1].displayBalanceUnits = newBalances[1].displayBalanceUnits + (Math.floor(balance.quantity / balance.conversionRate))
            newBalances[1].displayStartingBalanceUnits = newBalances[1].displayStartingBalanceUnits + (Math.floor(balance.startingQuantity / balance.conversionRate))
          } else {
            newBalances[1].displayBalanceUnits = newBalances[1].displayBalanceUnits + updatedBalances[i].quantity
            newBalances[1].displayStartingBalanceUnits = newBalances[1].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
          }
        }
        delete newBalances[1].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[1].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'TEXTS',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'MINUTES') {
        balance = convertBalance(updatedBalances[i],'MINUTES',client.cache.language)
        if(balance.displayBalanceUnits == -1 && updatedBalances[i].displayStartingBalanceUnits == -1){
          newBalances[2].displayBalanceUnits = 'unl'
          newBalances[2].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[2].displayBalanceUnits != 'unl' && newBalances[2].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[2].displayBalanceUnits = newBalances[2].displayBalanceUnits + (Math.floor(balance.quantity / balance.conversionRate))
            newBalances[2].displayStartingBalanceUnits = newBalances[2].displayStartingBalanceUnits + (Math.floor(balance.startingQuantity / balance.conversionRate))
          } else {
            newBalances[2].displayBalanceUnits = newBalances[2].displayBalanceUnits + updatedBalances[i].quantity
            newBalances[2].displayStartingBalanceUnits = newBalances[2].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
          }
        }
        delete newBalances[2].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[2].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'MINS',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      } else if(updatedBalances[i].units.toUpperCase() === 'SECONDS') {
        balance = convertBalance(updatedBalances[i],'SECONDS',client.cache.language)
        if(balance.displayBalanceUnits == -1 && balance.displayStartingBalanceUnits == -1){
          newBalances[2].displayBalanceUnits = 'unl'
          newBalances[2].displayStartingBalanceUnits = 'unl'
        } else if (newBalances[2].displayBalanceUnits != 'unl' && newBalances[2].displayStartingBalanceUnits != 'unl'){
          if(balance.conversionRate !== undefined) {
            newBalances[2].displayBalanceUnits = newBalances[2].displayBalanceUnits + (Math.floor(balance.quantity / balance.conversionRate))
            newBalances[2].displayStartingBalanceUnits = newBalances[2].displayStartingBalanceUnits + (Math.floor(balance.startingQuantity / balance.conversionRate))
          } else {
            newBalances[2].displayBalanceUnits = newBalances[2].displayBalanceUnits + updatedBalances[i].quantity
            newBalances[2].displayStartingBalanceUnits = newBalances[2].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
          }
        }
        delete newBalances[2].suppressDisplay
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN");
        if (isBreakdown) {
          newBalances[2].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'MINS',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity/60),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity/60),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      }
    } else if (updatedBalances[i].balanceType === 'monetary') {
      balance = convertBalance(updatedBalances[i],'monetary',client.cache.language)
      newBalances[3].displayBalanceUnits = Math.floor(balance.quantity / 100).toFixed(2)
      newBalances[3].displayStartingBalanceUnits = Math.floor(balance.startingQuantity / 100).toFixed(2)
      delete newBalances[3].suppressDisplay
    }
  }
  return newBalances
}

export function setupBroadbandBalance(balanceContainer, updatedBalances) {
  var newBalances = balanceContainer
    for(var i in updatedBalances) {
      if(updatedBalances[i].balanceType === 'unitary') {
        if(updatedBalances[i].units.toUpperCase() === 'BYTES') {
          newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (Math.floor(updatedBalances[i].quantity / 1073741824))
          newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (Math.floor(updatedBalances[i].startingQuantity / 1073741824))
        } else if(updatedBalances[i].units.toUpperCase() === 'KB') {
          newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (Math.floor(updatedBalances[i].quantity / 1048576))
          newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (Math.floor(updatedBalances[i].startingQuantity / 1048576))
        } else if(updatedBalances[i].units.toUpperCase() === 'MB') {
          newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (Math.floor(updatedBalances[i].quantity / 1024))
          newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (Math.floor(updatedBalances[i].startingQuantity / 1024))
        }
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN")
        if (isBreakdown) {
          newBalances[0].breakdown.push({
            balanceDisplayName: updatedBalances[i].balanceDisplayName,
            displayUnitType: 'GB',
            displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity / 1073741824),
            displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity / 1073741824),
            expiryDateTime: updatedBalances[i].expiryDateTime
          }) 
        }
      }
    }
  return newBalances
}

export function setupHomePhoneBalance(balanceContainer, updatedBalances) {
  var newBalances = balanceContainer
    for(var i in updatedBalances) {
      if(updatedBalances[i].balanceType === 'unitary') {
        if(updatedBalances[i].units.toUpperCase() === 'SECONDS') {
          newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + (Math.floor(updatedBalances[i].quantity / 60))
          newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + (Math.floor(updatedBalances[i].startingQuantity / 60))
        } else if(updatedBalances[i].units.toUpperCase() === 'MINUTES') {
          newBalances[0].displayBalanceUnits = newBalances[0].displayBalanceUnits + updatedBalances[i].quantity
          newBalances[0].displayStartingBalanceUnits = newBalances[0].displayStartingBalanceUnits + updatedBalances[i].startingQuantity
        }
        const isBreakdown = updatedBalances[i].tags.find(tag => tag.tag = "BREAKDOWN")
        if (isBreakdown) {
          if(updatedBalances[i].units.toUpperCase() === 'SECONDS'){
            newBalances[0].breakdown.push({
              balanceDisplayName: updatedBalances[i].balanceDisplayName,
              displayUnitType: 'MINS',
              displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : Math.floor(updatedBalances[i].quantity / 60),
              displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : Math.floor(updatedBalances[i].startingQuantity / 60),
              expiryDateTime: updatedBalances[i].expiryDateTime
            })
          } else {
            newBalances[0].breakdown.push({
              balanceDisplayName: updatedBalances[i].balanceDisplayName,
              displayUnitType: 'MINS',
              displayBalanceUnits: updatedBalances[i].quantity === -1 ? 'unl' : updatedBalances[i].quantity,
              displayStartingBalanceUnits: updatedBalances[i].startingQuantity === -1 ? 'unl' : updatedBalances[i].startingQuantity,
              expiryDateTime: updatedBalances[i].expiryDateTime
            })
          }
        }
      }
    }
  return newBalances
}

function convertBalance(balance, balanceType, language) {
  if(balance.balanceDefinition.displayDetails !== undefined) {
    for(var i in balance.balanceDefinition.displayDetails) {
      if(balance.balanceDefinition.displayDetails[i].language == language) {
        balance.balanceDisplayName = balance.balanceDefinition.displayDetails[i].displayName;
      }
    }
  }
  if(balance.balanceDefinition.conversionRates !== undefined) {
    for(var i in balance.balanceDefinition.conversionRates) {
      if(balance.balanceDefinition.conversionRates[i].rate === -1) {
        balance.displayBalanceUnits = -1;
        balance.displayStartingBalanceUnits = -1;
      }
      if(balance.balanceDefinition.conversionRates[i].unit === balanceType) {
        balance.conversionRate = balance.balanceDefinition.conversionRates[i].rate;
      }
      if(balanceType === 'monetary' && balance.balanceDefinition.conversionRates[i].unit === 'BND') {
        balance.conversionRate = balance.balanceDefinition.conversionRates[i].rate;
      }
      if(balanceType === 'monetary' && balance.units === 'cents') {
        balance.displayBalanceUnits = parseFloat(balance.quantity ? balance.quantity / balance.conversionRate : 0).toFixed(2);
      }
    }
  }
  return balance;
}

const toLocaleString = (number, locale) => {
  let result = number;
  if (typeof locale === 'string') {
      result = number.toLocaleString(locale);
  } else if (locale === true) {
      result = number.toLocaleString();
  }

  return result;
};

export function formatBytes(value, balance, options) {
  
  if (value === 0) {
    return ' 0B';
  }

  if ((value || value === undefined) && balance) {
    if (!Number.isFinite(value)) {
      throw new TypeError(`Expected a finite number, got ${typeof value}: ${value}`);
    }
    options = Object.assign({}, options);

    const isNegative = value < 0;
    const prefix = isNegative ? '-' : '';

    if (isNegative) {
      return value;
    }

    const unit = balance.displayUnitType;
    value = Number(value);
    const numberString = toLocaleString(value, options.locale);

    return prefix + numberString + '' + unit;

  }
}

export function getDataBalanceQuantity(balances) {
  if (balances && balances[0] && balances[0].displayBalanceUnits) {
      return balances[0].displayBalanceUnits
  }
  return 0
}

export function getDataBalanceStartingQuantity(balances) {
  if (balances && balances[0] && balances[0].displayStartingBalanceUnits) {
      return balances[0].displayStartingBalanceUnits
  }
  return 0
}

export function getMinutesBalanceQuantity(balances) {
  if (balances && balances[2] && balances[2].displayBalanceUnits && balances[2].displayBalanceUnits==='unl') {
      return balances[2].displayBalanceUnits
  } else if (balances && balances[2] && balances[2].displayBalanceUnits) {
      return parseInt(balances[2].displayBalanceUnits / getMinutesConversionRate(balances))
  }
  return 0
}

export function getMinutesBalanceStartingQuantity(balances) {
  if (balances && balances[2] && balances[2].displayStartingBalanceUnits && balances[2].displayStartingBalanceUnits==='unl') {
      return balances[2].displayStartingBalanceUnits
  } else if(balances && balances[2] && balances[2].displayStartingBalanceUnits) {
      return parseInt(balances[2].displayStartingBalanceUnits / getMinutesConversionRate(balances))
  }
  return 0
}

function getMinutesConversionRate(balances) {
  if(balances && balances.voice && balances.voice.balanceDefinition && balances.voice.balanceDefinition.conversionRates) {
      const conversionRates = balances.voice.balanceDefinition.conversionRates

      for (var i = 0; i < conversionRates.length; i++) {
          if (conversionRates[i].unit === 'Minutes') {
              return conversionRates[i].rate
          }
          return 60
      }
  } else {
      return 60
  }
}

export function getTextBalanceQuantity(balances) {
  if (balances && balances[1] && balances[1].displayBalanceUnits) {
      return balances[1].displayBalanceUnits
  } else {
      return 0
  }
}

export function getTextBalanceStartingQuantity(balances) {
  if (balances && balances[1] && balances[1].displayStartingBalanceUnits) {
      return balances[1].displayStartingBalanceUnits
  } else {
      return 0
  }
}