import React from "react";
import Svg, { Polyline } from "svgs";

export default function ChevronDown(props){
  const { color, width, height } = props;

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Polyline points="6 9 12 15 18 9" />
    </Svg>
  )
}
