import content from '@sart/content'
import moment from 'moment'

export function accountPrettyStatus(active) {
  switch (active) {
    case true:
      return 'Active'
    case false:
    default:
      return 'Idle'
  }
}

export function accountPrettyBillingMode(name) {
  switch (name) {
    case 'PREPAID':
      return content.tariff.prepaid
    case 'POSTPAID':
      return content.tariff.monthly
    case undefined:
      return ''
    default:
      return name
  }
}

export function accountContractEndCaculator(dateTo, dateFrom=new Date()) {
  if (!dateTo) {
    return 'N/A'
  }
  const totalMonths = dateTo.getMonth() - dateFrom.getMonth() +
 (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

 const years = Math.floor(totalMonths / 12)
 const months = Math.floor(totalMonths % 12)

 const yearString = years > 0 ? `${years}Y` : ''
 const monthString = months > 0 ? `${months}M` : ''

 const strings = [yearString, monthString]

 return strings
  .filter(v => !!v)
  .join(' ')
}

export function accountTenureCaculator(dateFrom, dateTo=new Date()) {
  const totalMonths = dateTo.getMonth() - dateFrom.getMonth() +
 (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

 const years = Math.floor(totalMonths / 12)
 const months = Math.floor(totalMonths % 12)

 const yearString = years > 0 ? `${years}Y` : ''
 const monthString = months > 0 ? `${months}M` : ''

 const strings = [yearString, monthString]

 return strings
  .filter(v => !!v)
  .join(' ')
}

export function accountTenureCaculatorFull(dateFrom, dateTo=new Date()) {
  const totalMonths = dateTo.getMonth() - dateFrom.getMonth() +
  (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

  const years = Math.floor(totalMonths / 12)
  const months = Math.floor(totalMonths % 12)

  const yearString = years > 0 ? `${years} Years` : ''
  const monthString = months > 0 ? `${months} Months` : ''

  const strings = [yearString, monthString]

  return strings
    .filter(v => !!v)
    .join(' ')
}



export  function prepareDisplayBalanceUnits(balance) {
  const UNIT_TYPES = getUnitTypes();
  if(isBalanceUnitType(UNIT_TYPES.KB, balance) || isBalanceUnitType(UNIT_TYPES.BYTES, balance)) {
   return  prepareDisplayBalance(balance, UNIT_TYPES.GB);
  } else if(isBalanceUnitType(UNIT_TYPES.Cents, balance)) {
    if(balance.currency == 'GBP'){
      return prepareDisplayBalance(balance, UNIT_TYPES.Pounds);
    } else {
      return prepareDisplayBalance(balance, UNIT_TYPES.Dollars);
    }
  } else if(isBalanceUnitType(UNIT_TYPES.Seconds, balance)) {
    return prepareDisplayBalance(balance, UNIT_TYPES.Minutes);
  } else {
    return  prepareDisplayBalance(balance);
  }

}


export  function prepareDisplayBalance(balance, newUnits) {
  const UNIT_TYPES = getUnitTypes();
  const unitsUsed = getUnitsUsed(balance);
  let balanceToDisplay ={};
  balanceToDisplay.balanceType = balance.balanceType
  balanceToDisplay.quantity = balance.quantity
  balanceToDisplay.startingQuantity = balance.startingQuantity
   
  
  //KB to MB
  if(isBalanceUnitType(UNIT_TYPES.KB, balance) && isSameUnitType(newUnits, UNIT_TYPES.GB)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.MB;
  } else if(isBalanceUnitType(UNIT_TYPES.BYTES, balance) && isSameUnitType(newUnits, UNIT_TYPES.GB)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.MB;
  } else if(isBalanceUnitType(UNIT_TYPES.BYTES, balance) && isSameUnitType(newUnits, UNIT_TYPES.MB)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.MB;
 } else if(isBalanceUnitType(UNIT_TYPES.Cents, balance) && isSameUnitType(newUnits, UNIT_TYPES.Dollars)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.Dollars;
  } else if (isBalanceUnitType(UNIT_TYPES.Cents, balance) && isSameUnitType(newUnits, UNIT_TYPES.Pounds)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.Pounds;
  } else if(isBalanceUnitType(UNIT_TYPES.Seconds, balance) && isSameUnitType(newUnits, UNIT_TYPES.Minutes)) {
    balanceToDisplay.displayUnitType = UNIT_TYPES.Minutes;
 } else if(isBalanceUnitType(UNIT_TYPES.Items, balance) ) {
  balanceToDisplay.displayUnitType = UNIT_TYPES.Items;
  } else {
    balanceToDisplay.displayUnitType = getBalanceUnitType(balance);
    balanceToDisplay.displayBalanceUnits = balance.quantity;
    balanceToDisplay.displayStartingBalanceUnits = balance.startingQuantity;
    balanceToDisplay.displayBalanceUnitsUsed = unitsUsed;
    return balanceToDisplay;
  }

  const rate = getBalanceRate(balance,balanceToDisplay.displayUnitType);
 
  balanceToDisplay.rate = rate
  balanceToDisplay.displayBalanceUnits = balance.quantity > 0 ? Math.floor(balance.quantity / rate) : 0;
  balanceToDisplay.displayStartingBalanceUnits = balance.startingQuantity > 0 ? Math.floor(balance.startingQuantity / rate) : 0;
  balanceToDisplay.displayBalanceUnitsUsed = unitsUsed > 0 ? Math.floor(unitsUsed / rate) : 0;
  return balanceToDisplay;
 function getBalanceUnitType(balance) {
    return balance.units.trim().toUpperCase();
  }

 function getUnitsUsed(balance) {
    if(!balance.startingBalanceUnits || !balance.balanceUnits) {
      return 0;
    } else if(balance.startingBalanceUnits && balance.balanceUnits) {
      return balance.startingBalanceUnits - balance.balanceUnits;
    } else {
      return balance.balanceUnits;
    }

  }

}

function getBalanceRate(balance,type) {
  let rate = 1
  if(balance && type &&  balance.balanceDefinition && balance.balanceDefinition.conversionRates ){
   for (const i in balance.balanceDefinition.conversionRates) {
     if(balance.balanceDefinition.conversionRates[i] && balance.balanceDefinition.conversionRates[i].unit
        && balance.balanceDefinition.conversionRates[i].unit.toUpperCase() === type.toUpperCase()){
        rate = balance.balanceDefinition.conversionRates[i].rate
      } 
    }
  }
  return rate
}
export function getUnitTypes() {
  return {
    BYTES: 'BYTES',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    Minutes: 'Minutes',
    Seconds: 'Seconds',
    Cents: 'cents',
    Dollars: 'BND',
    Pounds: 'GBP',
    Items: 'Items',
    Texts: 'texts'
  };
}
export function isBalanceUnitType(unitType, balance) {
  return isSameUnitType(unitType, balance.units);
}

export function isSameUnitType(unitsA, unitsB) {
  if(!unitsA || !unitsB) {
    return false;
  } else {
    return unitsA.trim().toUpperCase() === unitsB.trim().toUpperCase();
  }
}

export function isMobile(accountType) {
  return accountType.trim().toUpperCase() === "MOBILE";
}

export function isPrepaid(billingMode) {
  return billingMode.trim().toUpperCase() === "PREPAID";
}

export function isPostpaid(billingMode) {
  return billingMode.trim().toUpperCase() === "POSTPAID";
}

export function isFixedLine(accountType) {
  return accountType.trim().toUpperCase() === "FIXEDLINE";
}

export function getCoreBalance(balances) {
  const filtered = balances.filter(balance => balance.balanceType === 'core')
  if(filtered && filtered.length) {
    const coreBalance = filtered.pop()
    const expiry = new Date(coreBalance.expiryDateTime)
    const quantity = parseFloat(coreBalance.quantity).toFixed(2)
    return {
      quantity,
      expiry: moment(expiry).format('DD/MM/YYYY'),
      unit: coreBalance.currency
    }
  }
  return null
}

export function getLatestInvoice(invoices) {
  var toReturn = undefined
  for(var i = 0; i < invoices.length; i++) {
    if((toReturn == undefined || toReturn.invoiceDate < invoices[i].invoiceDate) && invoices[i].category == 'CURRENT') {
      toReturn = invoices[i]
    }
  }

  return toReturn
}

export function convertBalance(balance, balanceType, language) {
  if(balance.balanceDefinition.displayDetails !== undefined) {
    for(var i in balance.balanceDefinition.displayDetails) {
      if(balance.balanceDefinition.displayDetails[i].language == language) {
        balance.balanceDisplayName = balance.balanceDefinition.displayDetails[i].displayName;
      }
    }
  }
  if(balance.balanceDefinition.conversionRates !== undefined) {
    for(var i in balance.balanceDefinition.conversionRates) {
      if(balance.balanceDefinition.conversionRates[i].rate === -1) {
        balance.displayBalanceUnits = -1;
        balance.displayStartingBalanceUnits = -1;
      }
      if(balance.balanceDefinition.conversionRates[i].unit === balanceType) {
        balance.conversionRate = balance.balanceDefinition.conversionRates[i].rate;
      }
      if(balanceType === 'monetary' && balance.balanceDefinition.conversionRates[i].unit === 'BND') {
        balance.conversionRate = balance.balanceDefinition.conversionRates[i].rate;
      }
      if(balanceType === 'monetary' && balance.units === 'cents') {
        balance.displayBalanceUnits = parseFloat(balance.quantity ? balance.quantity / balance.conversionRate : 0).toFixed(2);
      }
    }
  }
  return balance;
}