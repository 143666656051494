import React from 'react'
import Svg, { G, Path, Line } from 'svgs'
import PropTypes from 'prop-types'

import { colors } from '@sart/styles'

const ProductData = ({ color, ...rest }) => (
  <Svg
    width="48"
    height="48"
    stroke={color}
    fill="none"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 48 48"
    {...rest}
  >
    <G transform="translate(5.000000, 5.000000)">
      <Path d="M37.7640696,18.8823652 C37.7640696,29.310887 29.3098957,37.7634087 18.8813739,37.7634087 C8.45450435,37.7634087 0.000330434783,29.310887 0.000330434783,18.8823652 C0.000330434783,8.45384348 8.45450435,-0.000330434783 18.8813739,-0.000330434783 C29.3098957,-0.000330434783 37.7640696,8.45384348 37.7640696,18.8823652 Z" />
      <Path d="M26.4812087,18.8823652 C26.4812087,29.310887 23.0777304,37.7634087 18.8812087,37.7634087 C14.684687,37.7634087 11.2828609,29.310887 11.2828609,18.8823652 C11.2828609,8.45384348 14.684687,-0.000330434783 18.8812087,-0.000330434783 C23.0777304,-0.000330434783 26.4812087,8.45384348 26.4812087,18.8823652 Z" />
      <Line x1={36.5475739} y1={12.2042783} x2={1.21583478} y2={12.2042783} />
      <Line x1={36.7137826} y1={25.099} x2={1.04830435} y2={25.099} />
    </G>
  </Svg>
)

ProductData.propTypes = {
  color: PropTypes.string
}

ProductData.defaultProps = {
  color: colors.feature
}

export default ProductData
