import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { withRouter, matchPath } from 'react-router-dom'

import { colors, typography } from '@sart/styles'
import { Text } from '@sart/components'
import { ChevronRight } from '@sart/icons'

import {
  isMobileOnly
} from "react-device-detect";

function CheckoutNavlink(props) {
  const {
    to,
    title,
    disabled,
    hideChevron,
    history,
    location,
    style,
    activeStyle,
    disabledStyle
  } = props

  const isActive = matchPath(location.pathname, {
    path: to,
    exact: false
  })

  const textStyles = [
    styles.default,
    style,
    isActive && styles.active,
    isActive && activeStyle,
    disabled && styles.disabled,
    disabled && disabledStyle
  ]

  const iconColor = (() => {
    if (disabled) return colors.subtle
    if (isActive) return colors.feature
    else return colors.focus
  })()

  return (
    <TouchableOpacity
      onPress={() => history.push(to)}
      disabled={disabled}
      style={styles.container}
    >
      {!hideChevron &&
        <ChevronRight
          {...iconStyle}
          color={iconColor}
        />
      }
      <Text style={textStyles}>{title}</Text>
    </TouchableOpacity>
  )
}

const iconStyle = {
  color: colors.trivial,
  size: 18,
  strokeWidth: 2.5,
  style: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 4,
    paddingRight: 4
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  default: {
    // fontSize: 13,
    color: colors.focus,
    ...typography.demi,

    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 10
      } 
      : 
      { //Non-Mobile Only
      }
    ),

  },
  active: {
    color: colors.feature,

    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 10
      } 
      : 
      { //Non-Mobile Only
      }
    ),

  },
  disabled: {
    color: colors.subtle,

    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 10
      } 
      : 
      { //Non-Mobile Only
      }
    ),
  }
})

export default withRouter(CheckoutNavlink)
