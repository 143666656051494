import React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "svgs";

export default function MX(props) {
  return (
    <Svg width={30} height={20} viewBox="0 0 36 24" {...props}>
      <Defs>
        <ClipPath id="clipPath">
          <Rect x="0" y="0" rx="4" ry="4" width="36" height="24" />
        </ClipPath>
      </Defs>

      <G clipPath="url(#clipPath)">
        <Path fill="#F0F0F0" d="M.1.2h36v24H.1z" />
        <Path fill="#D80027" d="M25.9.2h10.2v24H25.9z" />
        <Path fill="#6DA544" d="M.1.2h10.2v24H.1zM13.4 12.2c0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7v-1.6h-9.4v1.6z" />
        <Path fill="#FF9811" d="M24.3 9.1h-4.7c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6h-4.7c0 .9.8 1.6 1.6 1.6h-.1c0 .9.7 1.6 1.6 1.6 0 .9.7 1.6 1.6 1.6h3.1c.9 0 1.6-.7 1.6-1.6.9 0 1.6-.7 1.6-1.6h-.1c1 0 1.7-.7 1.7-1.6z" />
      </G>
    </Svg>
  )
}
