const prepaid = 'prepaid'
const postpaid = 'postpaid'
const mobile = 'mobile'
const fixedline = 'fixedline'
const homephone = 'homephone'
const media = 'media' 

export function isPrepaidService(service) {
  let billingMode = service.billingMode.trim().toLowerCase()
  let serviceType = service.serviceType.trim().toLowerCase()
  if(billingMode === prepaid && serviceType === mobile) {
    return true
  } else {
    return false
  }

}

export function isPostpaidService(service) {
  let billingMode = service.billingMode.trim().toLowerCase()
  let serviceType = service.serviceType.trim().toLowerCase()
  if(billingMode === postpaid && serviceType === mobile) {
    return true
  } else {
    return false
  }
}

export function isFixedLineService(service) {
  let billingMode = service.billingMode.trim().toLowerCase()
  let serviceType = service.serviceType.trim().toLowerCase()
  if(billingMode === postpaid && serviceType === fixedline) {
    return true
  } else {
    return false
  }
}

export function isHomePhoneService(service) {
  let billingMode = service.billingMode.trim().toLowerCase()
  let serviceType = service.serviceType.trim().toLowerCase()
  if(billingMode === postpaid && serviceType === homephone) {
    return true
  } else {
    return false
  }
}

export function isMediaService(service) {
  let billingMode = service.billingMode.trim().toLowerCase()
  let serviceType = service.serviceType.trim().toLowerCase()
  if(billingMode === postpaid && serviceType === media) {
    return true
  } else {
    return false
  }
} 