import React from 'react'
import { View, StyleSheet } from 'react-native'

export default function Wrapper(props) {
  const { style, wrapperStyle } = props

  const outerStyles = [styles.outer, wrapperStyle]
  const innerStyles = [styles.inner, style]

  return (
    <View style={outerStyles}>
      <View style={innerStyles}>{props.children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  outer: {
    alignItems: 'center',
  },
  inner: {
    width: '100%',
    // minWidth: 480,
    maxWidth: 840,
    paddingHorizontal: 24
  }
})
