import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SquaredMinus(props) {
  const { style } = props
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 1024 1024"
      style={[style, {
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }]}
      transform="rotate(360)"
      {...props}
    >
      <Path
        d="M328 544h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z"
        fill="#626262"
      />
      <Path
        d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"
        fill="#626262"
      />
      <Path fill="rgba(0, 0, 0, 0)" d="M0 0h1024v1024H0z" />
    </Svg>
  )
}

export default SquaredMinus
