import React from 'react'

import { StyleSheet, View } from 'react-native'
import { Switch, Route } from 'react-router-dom'

import Wrapper from '../Wrapper'
import LostSignIn from './LostSignIn'
import LostForm from './LostForm'
import LostConfirmation from './LostConfirmation'

export default function Lost(props) {
  const { match } = props

  return (
    // Originally using Wrapper with container style. Added styling to individual screens instead so
    // background color can be different without looking off due to the wrapper's inherited styling. 
    <View>
      <Switch>
        <Route exact path={match.url} component={LostSignIn} />
        <Route path={`${match.url}/form`} component={LostForm} />
        <Route path={`${match.url}/confirmation`} component={LostConfirmation} />
      </Switch>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48,
  }
})

