"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Account", {
  enumerable: true,
  get: function get() {
    return _Account["default"];
  }
});
Object.defineProperty(exports, "AccountApi", {
  enumerable: true,
  get: function get() {
    return _AccountApi["default"];
  }
});
Object.defineProperty(exports, "AccountLevelBasketDelItem", {
  enumerable: true,
  get: function get() {
    return _AccountLevelBasketDelItem["default"];
  }
});
Object.defineProperty(exports, "AccountLevelBasketItem", {
  enumerable: true,
  get: function get() {
    return _AccountLevelBasketItem["default"];
  }
});
Object.defineProperty(exports, "AccountLevelOrderAddItem", {
  enumerable: true,
  get: function get() {
    return _AccountLevelOrderAddItem["default"];
  }
});
Object.defineProperty(exports, "AccountLevelOrderDelItem", {
  enumerable: true,
  get: function get() {
    return _AccountLevelOrderDelItem["default"];
  }
});
Object.defineProperty(exports, "AccountLinkDetails", {
  enumerable: true,
  get: function get() {
    return _AccountLinkDetails["default"];
  }
});
Object.defineProperty(exports, "AccountSource", {
  enumerable: true,
  get: function get() {
    return _AccountSource["default"];
  }
});
Object.defineProperty(exports, "AddOn", {
  enumerable: true,
  get: function get() {
    return _AddOn["default"];
  }
});
Object.defineProperty(exports, "AddOnInstance", {
  enumerable: true,
  get: function get() {
    return _AddOnInstance["default"];
  }
});
Object.defineProperty(exports, "Address", {
  enumerable: true,
  get: function get() {
    return _Address["default"];
  }
});
Object.defineProperty(exports, "AgentOrdersApi", {
  enumerable: true,
  get: function get() {
    return _AgentOrdersApi["default"];
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient["default"];
  }
});
Object.defineProperty(exports, "AssignSim", {
  enumerable: true,
  get: function get() {
    return _AssignSim["default"];
  }
});
Object.defineProperty(exports, "AtomLink", {
  enumerable: true,
  get: function get() {
    return _AtomLink["default"];
  }
});
Object.defineProperty(exports, "AuthenticationApi", {
  enumerable: true,
  get: function get() {
    return _AuthenticationApi["default"];
  }
});
Object.defineProperty(exports, "Balance", {
  enumerable: true,
  get: function get() {
    return _Balance["default"];
  }
});
Object.defineProperty(exports, "BalanceDefinition", {
  enumerable: true,
  get: function get() {
    return _BalanceDefinition["default"];
  }
});
Object.defineProperty(exports, "BalanceDefinitionConversionRate", {
  enumerable: true,
  get: function get() {
    return _BalanceDefinitionConversionRate["default"];
  }
});
Object.defineProperty(exports, "BalanceDefinitionDisplayDetail", {
  enumerable: true,
  get: function get() {
    return _BalanceDefinitionDisplayDetail["default"];
  }
});
Object.defineProperty(exports, "BalanceDefinitionTag", {
  enumerable: true,
  get: function get() {
    return _BalanceDefinitionTag["default"];
  }
});
Object.defineProperty(exports, "BalanceTransfer", {
  enumerable: true,
  get: function get() {
    return _BalanceTransfer["default"];
  }
});
Object.defineProperty(exports, "BalanceTransform", {
  enumerable: true,
  get: function get() {
    return _BalanceTransform["default"];
  }
});
Object.defineProperty(exports, "Basket", {
  enumerable: true,
  get: function get() {
    return _Basket["default"];
  }
});
Object.defineProperty(exports, "BasketApi", {
  enumerable: true,
  get: function get() {
    return _BasketApi["default"];
  }
});
Object.defineProperty(exports, "BilledServiceCallDataRecord", {
  enumerable: true,
  get: function get() {
    return _BilledServiceCallDataRecord["default"];
  }
});
Object.defineProperty(exports, "BillingAddress", {
  enumerable: true,
  get: function get() {
    return _BillingAddress["default"];
  }
});
Object.defineProperty(exports, "BooleanValueAddedService", {
  enumerable: true,
  get: function get() {
    return _BooleanValueAddedService["default"];
  }
});
Object.defineProperty(exports, "BooleanValueAddedServiceInstance", {
  enumerable: true,
  get: function get() {
    return _BooleanValueAddedServiceInstance["default"];
  }
});
Object.defineProperty(exports, "Bundle", {
  enumerable: true,
  get: function get() {
    return _Bundle["default"];
  }
});
Object.defineProperty(exports, "BundleInstance", {
  enumerable: true,
  get: function get() {
    return _BundleInstance["default"];
  }
});
Object.defineProperty(exports, "Business", {
  enumerable: true,
  get: function get() {
    return _Business["default"];
  }
});
Object.defineProperty(exports, "BusinessLevel", {
  enumerable: true,
  get: function get() {
    return _BusinessLevel["default"];
  }
});
Object.defineProperty(exports, "Catalog", {
  enumerable: true,
  get: function get() {
    return _Catalog["default"];
  }
});
Object.defineProperty(exports, "CatalogApi", {
  enumerable: true,
  get: function get() {
    return _CatalogApi["default"];
  }
});
Object.defineProperty(exports, "ChannelPreferences", {
  enumerable: true,
  get: function get() {
    return _ChannelPreferences["default"];
  }
});
Object.defineProperty(exports, "Color", {
  enumerable: true,
  get: function get() {
    return _Color["default"];
  }
});
Object.defineProperty(exports, "CommunicationPreferences", {
  enumerable: true,
  get: function get() {
    return _CommunicationPreferences["default"];
  }
});
Object.defineProperty(exports, "ConfirmedBasket", {
  enumerable: true,
  get: function get() {
    return _ConfirmedBasket["default"];
  }
});
Object.defineProperty(exports, "ConfirmedBasketApi", {
  enumerable: true,
  get: function get() {
    return _ConfirmedBasketApi["default"];
  }
});
Object.defineProperty(exports, "ContentCmsApi", {
  enumerable: true,
  get: function get() {
    return _ContentCmsApi["default"];
  }
});
Object.defineProperty(exports, "ContentPage", {
  enumerable: true,
  get: function get() {
    return _ContentPage["default"];
  }
});
Object.defineProperty(exports, "Contract", {
  enumerable: true,
  get: function get() {
    return _Contract["default"];
  }
});
Object.defineProperty(exports, "CreditCard", {
  enumerable: true,
  get: function get() {
    return _CreditCard["default"];
  }
});
Object.defineProperty(exports, "Customer", {
  enumerable: true,
  get: function get() {
    return _Customer["default"];
  }
});
Object.defineProperty(exports, "CustomerApi", {
  enumerable: true,
  get: function get() {
    return _CustomerApi["default"];
  }
});
Object.defineProperty(exports, "CustomerLevelBasketDelItem", {
  enumerable: true,
  get: function get() {
    return _CustomerLevelBasketDelItem["default"];
  }
});
Object.defineProperty(exports, "CustomerLevelBasketItem", {
  enumerable: true,
  get: function get() {
    return _CustomerLevelBasketItem["default"];
  }
});
Object.defineProperty(exports, "CustomerLevelOrderAddItem", {
  enumerable: true,
  get: function get() {
    return _CustomerLevelOrderAddItem["default"];
  }
});
Object.defineProperty(exports, "CustomerLevelOrderDelItem", {
  enumerable: true,
  get: function get() {
    return _CustomerLevelOrderDelItem["default"];
  }
});
Object.defineProperty(exports, "DebitCard", {
  enumerable: true,
  get: function get() {
    return _DebitCard["default"];
  }
});
Object.defineProperty(exports, "DeliveryAddress", {
  enumerable: true,
  get: function get() {
    return _DeliveryAddress["default"];
  }
});
Object.defineProperty(exports, "Device", {
  enumerable: true,
  get: function get() {
    return _Device["default"];
  }
});
Object.defineProperty(exports, "DirectDebit", {
  enumerable: true,
  get: function get() {
    return _DirectDebit["default"];
  }
});
Object.defineProperty(exports, "DirectDebitDetails", {
  enumerable: true,
  get: function get() {
    return _DirectDebitDetails["default"];
  }
});
Object.defineProperty(exports, "DisconnectService", {
  enumerable: true,
  get: function get() {
    return _DisconnectService["default"];
  }
});
Object.defineProperty(exports, "Discount", {
  enumerable: true,
  get: function get() {
    return _Discount["default"];
  }
});
Object.defineProperty(exports, "DiscountInstance", {
  enumerable: true,
  get: function get() {
    return _DiscountInstance["default"];
  }
});
Object.defineProperty(exports, "DiscountedProduct", {
  enumerable: true,
  get: function get() {
    return _DiscountedProduct["default"];
  }
});
Object.defineProperty(exports, "DispatchSim", {
  enumerable: true,
  get: function get() {
    return _DispatchSim["default"];
  }
});
Object.defineProperty(exports, "EShop", {
  enumerable: true,
  get: function get() {
    return _EShop["default"];
  }
});
Object.defineProperty(exports, "ETicket", {
  enumerable: true,
  get: function get() {
    return _ETicket["default"];
  }
});
Object.defineProperty(exports, "Employee", {
  enumerable: true,
  get: function get() {
    return _Employee["default"];
  }
});
Object.defineProperty(exports, "EmployeeApi", {
  enumerable: true,
  get: function get() {
    return _EmployeeApi["default"];
  }
});
Object.defineProperty(exports, "GlobalPreferences", {
  enumerable: true,
  get: function get() {
    return _GlobalPreferences["default"];
  }
});
Object.defineProperty(exports, "Group", {
  enumerable: true,
  get: function get() {
    return _Group["default"];
  }
});
Object.defineProperty(exports, "HomeAddress", {
  enumerable: true,
  get: function get() {
    return _HomeAddress["default"];
  }
});
Object.defineProperty(exports, "HostedPayment", {
  enumerable: true,
  get: function get() {
    return _HostedPayment["default"];
  }
});
Object.defineProperty(exports, "IdentificationDetails", {
  enumerable: true,
  get: function get() {
    return _IdentificationDetails["default"];
  }
});
Object.defineProperty(exports, "Image", {
  enumerable: true,
  get: function get() {
    return _Image["default"];
  }
});
Object.defineProperty(exports, "InboundCommunication", {
  enumerable: true,
  get: function get() {
    return _InboundCommunication["default"];
  }
});
Object.defineProperty(exports, "InboundCommunicationApi", {
  enumerable: true,
  get: function get() {
    return _InboundCommunicationApi["default"];
  }
});
Object.defineProperty(exports, "InstoreCashPayment", {
  enumerable: true,
  get: function get() {
    return _InstoreCashPayment["default"];
  }
});
Object.defineProperty(exports, "Invoice", {
  enumerable: true,
  get: function get() {
    return _Invoice["default"];
  }
});
Object.defineProperty(exports, "InvoiceApi", {
  enumerable: true,
  get: function get() {
    return _InvoiceApi["default"];
  }
});
Object.defineProperty(exports, "InvoiceLineItem", {
  enumerable: true,
  get: function get() {
    return _InvoiceLineItem["default"];
  }
});
Object.defineProperty(exports, "InvoicePayment", {
  enumerable: true,
  get: function get() {
    return _InvoicePayment["default"];
  }
});
Object.defineProperty(exports, "InvoicePdf", {
  enumerable: true,
  get: function get() {
    return _InvoicePdf["default"];
  }
});
Object.defineProperty(exports, "LinkAccount", {
  enumerable: true,
  get: function get() {
    return _LinkAccount["default"];
  }
});
Object.defineProperty(exports, "MediaItem", {
  enumerable: true,
  get: function get() {
    return _MediaItem["default"];
  }
});
Object.defineProperty(exports, "MediaItemContainer", {
  enumerable: true,
  get: function get() {
    return _MediaItemContainer["default"];
  }
});
Object.defineProperty(exports, "MediaItemInstance", {
  enumerable: true,
  get: function get() {
    return _MediaItemInstance["default"];
  }
});
Object.defineProperty(exports, "MediaPayPerView", {
  enumerable: true,
  get: function get() {
    return _MediaPayPerView["default"];
  }
});
Object.defineProperty(exports, "MediaPayPerViewInstance", {
  enumerable: true,
  get: function get() {
    return _MediaPayPerViewInstance["default"];
  }
});
Object.defineProperty(exports, "MediaSource", {
  enumerable: true,
  get: function get() {
    return _MediaSource["default"];
  }
});
Object.defineProperty(exports, "MediaSourceInstance", {
  enumerable: true,
  get: function get() {
    return _MediaSourceInstance["default"];
  }
});
Object.defineProperty(exports, "MediaSourcesContainer", {
  enumerable: true,
  get: function get() {
    return _MediaSourcesContainer["default"];
  }
});
Object.defineProperty(exports, "MediaSubscription", {
  enumerable: true,
  get: function get() {
    return _MediaSubscription["default"];
  }
});
Object.defineProperty(exports, "MediaSubscriptionInstance", {
  enumerable: true,
  get: function get() {
    return _MediaSubscriptionInstance["default"];
  }
});
Object.defineProperty(exports, "Message", {
  enumerable: true,
  get: function get() {
    return _Message["default"];
  }
});
Object.defineProperty(exports, "MobileWallet", {
  enumerable: true,
  get: function get() {
    return _MobileWallet["default"];
  }
});
Object.defineProperty(exports, "MobileWalletDetails", {
  enumerable: true,
  get: function get() {
    return _MobileWalletDetails["default"];
  }
});
Object.defineProperty(exports, "ModifyAccountDetails", {
  enumerable: true,
  get: function get() {
    return _ModifyAccountDetails["default"];
  }
});
Object.defineProperty(exports, "ModifyServiceDetails", {
  enumerable: true,
  get: function get() {
    return _ModifyServiceDetails["default"];
  }
});
Object.defineProperty(exports, "ModifyUserDetails", {
  enumerable: true,
  get: function get() {
    return _ModifyUserDetails["default"];
  }
});
Object.defineProperty(exports, "NoPayment", {
  enumerable: true,
  get: function get() {
    return _NoPayment["default"];
  }
});
Object.defineProperty(exports, "OnlineCashPayment", {
  enumerable: true,
  get: function get() {
    return _OnlineCashPayment["default"];
  }
});
Object.defineProperty(exports, "Operator", {
  enumerable: true,
  get: function get() {
    return _Operator["default"];
  }
});
Object.defineProperty(exports, "OperatorLevel", {
  enumerable: true,
  get: function get() {
    return _OperatorLevel["default"];
  }
});
Object.defineProperty(exports, "Order", {
  enumerable: true,
  get: function get() {
    return _Order["default"];
  }
});
Object.defineProperty(exports, "OrdersApi", {
  enumerable: true,
  get: function get() {
    return _OrdersApi["default"];
  }
});
Object.defineProperty(exports, "OrganizationBusinessApi", {
  enumerable: true,
  get: function get() {
    return _OrganizationBusinessApi["default"];
  }
});
Object.defineProperty(exports, "OrganizationGroupsApi", {
  enumerable: true,
  get: function get() {
    return _OrganizationGroupsApi["default"];
  }
});
Object.defineProperty(exports, "OrganizationOperatorApi", {
  enumerable: true,
  get: function get() {
    return _OrganizationOperatorApi["default"];
  }
});
Object.defineProperty(exports, "OutboundCommunication", {
  enumerable: true,
  get: function get() {
    return _OutboundCommunication["default"];
  }
});
Object.defineProperty(exports, "OutboundCommunicationApi", {
  enumerable: true,
  get: function get() {
    return _OutboundCommunicationApi["default"];
  }
});
Object.defineProperty(exports, "Page", {
  enumerable: true,
  get: function get() {
    return _Page["default"];
  }
});
Object.defineProperty(exports, "PageAccessControl", {
  enumerable: true,
  get: function get() {
    return _PageAccessControl["default"];
  }
});
Object.defineProperty(exports, "PageProductPairing", {
  enumerable: true,
  get: function get() {
    return _PageProductPairing["default"];
  }
});
Object.defineProperty(exports, "ParameterizedValueAddedService", {
  enumerable: true,
  get: function get() {
    return _ParameterizedValueAddedService["default"];
  }
});
Object.defineProperty(exports, "ParameterizedValueAddedServiceInstance", {
  enumerable: true,
  get: function get() {
    return _ParameterizedValueAddedServiceInstance["default"];
  }
});
Object.defineProperty(exports, "PayInvoice", {
  enumerable: true,
  get: function get() {
    return _PayInvoice["default"];
  }
});
Object.defineProperty(exports, "PayOnInvoice", {
  enumerable: true,
  get: function get() {
    return _PayOnInvoice["default"];
  }
});
Object.defineProperty(exports, "PayPal", {
  enumerable: true,
  get: function get() {
    return _PayPal["default"];
  }
});
Object.defineProperty(exports, "PaymentAuthorization", {
  enumerable: true,
  get: function get() {
    return _PaymentAuthorization["default"];
  }
});
Object.defineProperty(exports, "PaymentCardDetails", {
  enumerable: true,
  get: function get() {
    return _PaymentCardDetails["default"];
  }
});
Object.defineProperty(exports, "PaymentPreferences", {
  enumerable: true,
  get: function get() {
    return _PaymentPreferences["default"];
  }
});
Object.defineProperty(exports, "PersonalDetails", {
  enumerable: true,
  get: function get() {
    return _PersonalDetails["default"];
  }
});
Object.defineProperty(exports, "Plan", {
  enumerable: true,
  get: function get() {
    return _Plan["default"];
  }
});
Object.defineProperty(exports, "PlanInstance", {
  enumerable: true,
  get: function get() {
    return _PlanInstance["default"];
  }
});
Object.defineProperty(exports, "PortIn", {
  enumerable: true,
  get: function get() {
    return _PortIn["default"];
  }
});
Object.defineProperty(exports, "Product", {
  enumerable: true,
  get: function get() {
    return _Product["default"];
  }
});
Object.defineProperty(exports, "ProductDisplayDetail", {
  enumerable: true,
  get: function get() {
    return _ProductDisplayDetail["default"];
  }
});
Object.defineProperty(exports, "ProductOption", {
  enumerable: true,
  get: function get() {
    return _ProductOption["default"];
  }
});
Object.defineProperty(exports, "Recharge", {
  enumerable: true,
  get: function get() {
    return _Recharge["default"];
  }
});
Object.defineProperty(exports, "RechargeInstance", {
  enumerable: true,
  get: function get() {
    return _RechargeInstance["default"];
  }
});
Object.defineProperty(exports, "RecurringPaymentDetails", {
  enumerable: true,
  get: function get() {
    return _RecurringPaymentDetails["default"];
  }
});
Object.defineProperty(exports, "RefundAuthorization", {
  enumerable: true,
  get: function get() {
    return _RefundAuthorization["default"];
  }
});
Object.defineProperty(exports, "RegisterUser", {
  enumerable: true,
  get: function get() {
    return _RegisterUser["default"];
  }
});
Object.defineProperty(exports, "RegistrationApi", {
  enumerable: true,
  get: function get() {
    return _RegistrationApi["default"];
  }
});
Object.defineProperty(exports, "RegistrationDetails", {
  enumerable: true,
  get: function get() {
    return _RegistrationDetails["default"];
  }
});
Object.defineProperty(exports, "Role", {
  enumerable: true,
  get: function get() {
    return _Role["default"];
  }
});
Object.defineProperty(exports, "SecurityLock", {
  enumerable: true,
  get: function get() {
    return _SecurityLock["default"];
  }
});
Object.defineProperty(exports, "SecurityUnlock", {
  enumerable: true,
  get: function get() {
    return _SecurityUnlock["default"];
  }
});
Object.defineProperty(exports, "Service", {
  enumerable: true,
  get: function get() {
    return _Service["default"];
  }
});
Object.defineProperty(exports, "ServiceApi", {
  enumerable: true,
  get: function get() {
    return _ServiceApi["default"];
  }
});
Object.defineProperty(exports, "ServiceBalanceTransfer", {
  enumerable: true,
  get: function get() {
    return _ServiceBalanceTransfer["default"];
  }
});
Object.defineProperty(exports, "ServiceLevelBasketDelItem", {
  enumerable: true,
  get: function get() {
    return _ServiceLevelBasketDelItem["default"];
  }
});
Object.defineProperty(exports, "ServiceLevelBasketItem", {
  enumerable: true,
  get: function get() {
    return _ServiceLevelBasketItem["default"];
  }
});
Object.defineProperty(exports, "ServiceLevelOrderAddItem", {
  enumerable: true,
  get: function get() {
    return _ServiceLevelOrderAddItem["default"];
  }
});
Object.defineProperty(exports, "ServiceLevelOrderDelItem", {
  enumerable: true,
  get: function get() {
    return _ServiceLevelOrderDelItem["default"];
  }
});
Object.defineProperty(exports, "ServiceLifeCycle", {
  enumerable: true,
  get: function get() {
    return _ServiceLifeCycle["default"];
  }
});
Object.defineProperty(exports, "SignUpCustomer", {
  enumerable: true,
  get: function get() {
    return _SignUpCustomer["default"];
  }
});
Object.defineProperty(exports, "SignUpPostPaidCustomer", {
  enumerable: true,
  get: function get() {
    return _SignUpPostPaidCustomer["default"];
  }
});
Object.defineProperty(exports, "SignUpPrePaidCustomer", {
  enumerable: true,
  get: function get() {
    return _SignUpPrePaidCustomer["default"];
  }
});
Object.defineProperty(exports, "SimCard", {
  enumerable: true,
  get: function get() {
    return _SimCard["default"];
  }
});
Object.defineProperty(exports, "Tag", {
  enumerable: true,
  get: function get() {
    return _Tag["default"];
  }
});
Object.defineProperty(exports, "TechnicalOffer", {
  enumerable: true,
  get: function get() {
    return _TechnicalOffer["default"];
  }
});
Object.defineProperty(exports, "Token", {
  enumerable: true,
  get: function get() {
    return _Token["default"];
  }
});
Object.defineProperty(exports, "TokenizeCard", {
  enumerable: true,
  get: function get() {
    return _TokenizeCard["default"];
  }
});
Object.defineProperty(exports, "TokenizedCard", {
  enumerable: true,
  get: function get() {
    return _TokenizedCard["default"];
  }
});
Object.defineProperty(exports, "TokenizedCardDetails", {
  enumerable: true,
  get: function get() {
    return _TokenizedCardDetails["default"];
  }
});
Object.defineProperty(exports, "TokenizedCards", {
  enumerable: true,
  get: function get() {
    return _TokenizedCards["default"];
  }
});
Object.defineProperty(exports, "TroubleTicket", {
  enumerable: true,
  get: function get() {
    return _TroubleTicket["default"];
  }
});
Object.defineProperty(exports, "TroubleticketApi", {
  enumerable: true,
  get: function get() {
    return _TroubleticketApi["default"];
  }
});
Object.defineProperty(exports, "TwoFactorAuthPreferences", {
  enumerable: true,
  get: function get() {
    return _TwoFactorAuthPreferences["default"];
  }
});
Object.defineProperty(exports, "UnLinkAccount", {
  enumerable: true,
  get: function get() {
    return _UnLinkAccount["default"];
  }
});
Object.defineProperty(exports, "UnLockCustomer", {
  enumerable: true,
  get: function get() {
    return _UnLockCustomer["default"];
  }
});
Object.defineProperty(exports, "UnRegisterUser", {
  enumerable: true,
  get: function get() {
    return _UnRegisterUser["default"];
  }
});
Object.defineProperty(exports, "UnbilledServiceCallDataRecord", {
  enumerable: true,
  get: function get() {
    return _UnbilledServiceCallDataRecord["default"];
  }
});
Object.defineProperty(exports, "UpdateCustomerAddress", {
  enumerable: true,
  get: function get() {
    return _UpdateCustomerAddress["default"];
  }
});
Object.defineProperty(exports, "UpdateCustomerEmail", {
  enumerable: true,
  get: function get() {
    return _UpdateCustomerEmail["default"];
  }
});
Object.defineProperty(exports, "UpdatePersonalDetails", {
  enumerable: true,
  get: function get() {
    return _UpdatePersonalDetails["default"];
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User["default"];
  }
});
Object.defineProperty(exports, "UserApi", {
  enumerable: true,
  get: function get() {
    return _UserApi["default"];
  }
});
exports["default"] = void 0;
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _Account = _interopRequireDefault(require("./model/Account"));
var _AccountLevelBasketDelItem = _interopRequireDefault(require("./model/AccountLevelBasketDelItem"));
var _AccountLevelBasketItem = _interopRequireDefault(require("./model/AccountLevelBasketItem"));
var _AccountLevelOrderAddItem = _interopRequireDefault(require("./model/AccountLevelOrderAddItem"));
var _AccountLevelOrderDelItem = _interopRequireDefault(require("./model/AccountLevelOrderDelItem"));
var _AccountLinkDetails = _interopRequireDefault(require("./model/AccountLinkDetails"));
var _AccountSource = _interopRequireDefault(require("./model/AccountSource"));
var _AddOn = _interopRequireDefault(require("./model/AddOn"));
var _AddOnInstance = _interopRequireDefault(require("./model/AddOnInstance"));
var _Address = _interopRequireDefault(require("./model/Address"));
var _AssignSim = _interopRequireDefault(require("./model/AssignSim"));
var _AtomLink = _interopRequireDefault(require("./model/AtomLink"));
var _Balance = _interopRequireDefault(require("./model/Balance"));
var _BalanceDefinition = _interopRequireDefault(require("./model/BalanceDefinition"));
var _BalanceDefinitionConversionRate = _interopRequireDefault(require("./model/BalanceDefinitionConversionRate"));
var _BalanceDefinitionDisplayDetail = _interopRequireDefault(require("./model/BalanceDefinitionDisplayDetail"));
var _BalanceDefinitionTag = _interopRequireDefault(require("./model/BalanceDefinitionTag"));
var _BalanceTransfer = _interopRequireDefault(require("./model/BalanceTransfer"));
var _BalanceTransform = _interopRequireDefault(require("./model/BalanceTransform"));
var _Basket = _interopRequireDefault(require("./model/Basket"));
var _BilledServiceCallDataRecord = _interopRequireDefault(require("./model/BilledServiceCallDataRecord"));
var _BillingAddress = _interopRequireDefault(require("./model/BillingAddress"));
var _BooleanValueAddedService = _interopRequireDefault(require("./model/BooleanValueAddedService"));
var _BooleanValueAddedServiceInstance = _interopRequireDefault(require("./model/BooleanValueAddedServiceInstance"));
var _Bundle = _interopRequireDefault(require("./model/Bundle"));
var _BundleInstance = _interopRequireDefault(require("./model/BundleInstance"));
var _Business = _interopRequireDefault(require("./model/Business"));
var _BusinessLevel = _interopRequireDefault(require("./model/BusinessLevel"));
var _Catalog = _interopRequireDefault(require("./model/Catalog"));
var _ChannelPreferences = _interopRequireDefault(require("./model/ChannelPreferences"));
var _Color = _interopRequireDefault(require("./model/Color"));
var _CommunicationPreferences = _interopRequireDefault(require("./model/CommunicationPreferences"));
var _ConfirmedBasket = _interopRequireDefault(require("./model/ConfirmedBasket"));
var _ContentPage = _interopRequireDefault(require("./model/ContentPage"));
var _Contract = _interopRequireDefault(require("./model/Contract"));
var _CreditCard = _interopRequireDefault(require("./model/CreditCard"));
var _Customer = _interopRequireDefault(require("./model/Customer"));
var _CustomerLevelBasketDelItem = _interopRequireDefault(require("./model/CustomerLevelBasketDelItem"));
var _CustomerLevelBasketItem = _interopRequireDefault(require("./model/CustomerLevelBasketItem"));
var _CustomerLevelOrderAddItem = _interopRequireDefault(require("./model/CustomerLevelOrderAddItem"));
var _CustomerLevelOrderDelItem = _interopRequireDefault(require("./model/CustomerLevelOrderDelItem"));
var _DebitCard = _interopRequireDefault(require("./model/DebitCard"));
var _DeliveryAddress = _interopRequireDefault(require("./model/DeliveryAddress"));
var _Device = _interopRequireDefault(require("./model/Device"));
var _DirectDebit = _interopRequireDefault(require("./model/DirectDebit"));
var _DirectDebitDetails = _interopRequireDefault(require("./model/DirectDebitDetails"));
var _DisconnectService = _interopRequireDefault(require("./model/DisconnectService"));
var _Discount = _interopRequireDefault(require("./model/Discount"));
var _DiscountInstance = _interopRequireDefault(require("./model/DiscountInstance"));
var _DiscountedProduct = _interopRequireDefault(require("./model/DiscountedProduct"));
var _DispatchSim = _interopRequireDefault(require("./model/DispatchSim"));
var _EShop = _interopRequireDefault(require("./model/EShop"));
var _ETicket = _interopRequireDefault(require("./model/ETicket"));
var _Employee = _interopRequireDefault(require("./model/Employee"));
var _GlobalPreferences = _interopRequireDefault(require("./model/GlobalPreferences"));
var _Group = _interopRequireDefault(require("./model/Group"));
var _HomeAddress = _interopRequireDefault(require("./model/HomeAddress"));
var _HostedPayment = _interopRequireDefault(require("./model/HostedPayment"));
var _IdentificationDetails = _interopRequireDefault(require("./model/IdentificationDetails"));
var _Image = _interopRequireDefault(require("./model/Image"));
var _InboundCommunication = _interopRequireDefault(require("./model/InboundCommunication"));
var _InstoreCashPayment = _interopRequireDefault(require("./model/InstoreCashPayment"));
var _Invoice = _interopRequireDefault(require("./model/Invoice"));
var _InvoiceLineItem = _interopRequireDefault(require("./model/InvoiceLineItem"));
var _InvoicePayment = _interopRequireDefault(require("./model/InvoicePayment"));
var _InvoicePdf = _interopRequireDefault(require("./model/InvoicePdf"));
var _LinkAccount = _interopRequireDefault(require("./model/LinkAccount"));
var _MediaItem = _interopRequireDefault(require("./model/MediaItem"));
var _MediaItemContainer = _interopRequireDefault(require("./model/MediaItemContainer"));
var _MediaItemInstance = _interopRequireDefault(require("./model/MediaItemInstance"));
var _MediaPayPerView = _interopRequireDefault(require("./model/MediaPayPerView"));
var _MediaPayPerViewInstance = _interopRequireDefault(require("./model/MediaPayPerViewInstance"));
var _MediaSource = _interopRequireDefault(require("./model/MediaSource"));
var _MediaSourceInstance = _interopRequireDefault(require("./model/MediaSourceInstance"));
var _MediaSourcesContainer = _interopRequireDefault(require("./model/MediaSourcesContainer"));
var _MediaSubscription = _interopRequireDefault(require("./model/MediaSubscription"));
var _MediaSubscriptionInstance = _interopRequireDefault(require("./model/MediaSubscriptionInstance"));
var _Message = _interopRequireDefault(require("./model/Message"));
var _MobileWallet = _interopRequireDefault(require("./model/MobileWallet"));
var _MobileWalletDetails = _interopRequireDefault(require("./model/MobileWalletDetails"));
var _ModifyAccountDetails = _interopRequireDefault(require("./model/ModifyAccountDetails"));
var _ModifyServiceDetails = _interopRequireDefault(require("./model/ModifyServiceDetails"));
var _ModifyUserDetails = _interopRequireDefault(require("./model/ModifyUserDetails"));
var _NoPayment = _interopRequireDefault(require("./model/NoPayment"));
var _OnlineCashPayment = _interopRequireDefault(require("./model/OnlineCashPayment"));
var _Operator = _interopRequireDefault(require("./model/Operator"));
var _OperatorLevel = _interopRequireDefault(require("./model/OperatorLevel"));
var _Order = _interopRequireDefault(require("./model/Order"));
var _OutboundCommunication = _interopRequireDefault(require("./model/OutboundCommunication"));
var _Page = _interopRequireDefault(require("./model/Page"));
var _PageAccessControl = _interopRequireDefault(require("./model/PageAccessControl"));
var _PageProductPairing = _interopRequireDefault(require("./model/PageProductPairing"));
var _ParameterizedValueAddedService = _interopRequireDefault(require("./model/ParameterizedValueAddedService"));
var _ParameterizedValueAddedServiceInstance = _interopRequireDefault(require("./model/ParameterizedValueAddedServiceInstance"));
var _PayInvoice = _interopRequireDefault(require("./model/PayInvoice"));
var _PayOnInvoice = _interopRequireDefault(require("./model/PayOnInvoice"));
var _PayPal = _interopRequireDefault(require("./model/PayPal"));
var _PaymentAuthorization = _interopRequireDefault(require("./model/PaymentAuthorization"));
var _PaymentCardDetails = _interopRequireDefault(require("./model/PaymentCardDetails"));
var _PaymentPreferences = _interopRequireDefault(require("./model/PaymentPreferences"));
var _PersonalDetails = _interopRequireDefault(require("./model/PersonalDetails"));
var _Plan = _interopRequireDefault(require("./model/Plan"));
var _PlanInstance = _interopRequireDefault(require("./model/PlanInstance"));
var _PortIn = _interopRequireDefault(require("./model/PortIn"));
var _Product = _interopRequireDefault(require("./model/Product"));
var _ProductDisplayDetail = _interopRequireDefault(require("./model/ProductDisplayDetail"));
var _ProductOption = _interopRequireDefault(require("./model/ProductOption"));
var _Recharge = _interopRequireDefault(require("./model/Recharge"));
var _RechargeInstance = _interopRequireDefault(require("./model/RechargeInstance"));
var _RecurringPaymentDetails = _interopRequireDefault(require("./model/RecurringPaymentDetails"));
var _RefundAuthorization = _interopRequireDefault(require("./model/RefundAuthorization"));
var _RegisterUser = _interopRequireDefault(require("./model/RegisterUser"));
var _RegistrationDetails = _interopRequireDefault(require("./model/RegistrationDetails"));
var _Role = _interopRequireDefault(require("./model/Role"));
var _SecurityLock = _interopRequireDefault(require("./model/SecurityLock"));
var _SecurityUnlock = _interopRequireDefault(require("./model/SecurityUnlock"));
var _Service = _interopRequireDefault(require("./model/Service"));
var _ServiceBalanceTransfer = _interopRequireDefault(require("./model/ServiceBalanceTransfer"));
var _ServiceLevelBasketDelItem = _interopRequireDefault(require("./model/ServiceLevelBasketDelItem"));
var _ServiceLevelBasketItem = _interopRequireDefault(require("./model/ServiceLevelBasketItem"));
var _ServiceLevelOrderAddItem = _interopRequireDefault(require("./model/ServiceLevelOrderAddItem"));
var _ServiceLevelOrderDelItem = _interopRequireDefault(require("./model/ServiceLevelOrderDelItem"));
var _ServiceLifeCycle = _interopRequireDefault(require("./model/ServiceLifeCycle"));
var _SignUpCustomer = _interopRequireDefault(require("./model/SignUpCustomer"));
var _SignUpPostPaidCustomer = _interopRequireDefault(require("./model/SignUpPostPaidCustomer"));
var _SignUpPrePaidCustomer = _interopRequireDefault(require("./model/SignUpPrePaidCustomer"));
var _SimCard = _interopRequireDefault(require("./model/SimCard"));
var _Tag = _interopRequireDefault(require("./model/Tag"));
var _TechnicalOffer = _interopRequireDefault(require("./model/TechnicalOffer"));
var _Token = _interopRequireDefault(require("./model/Token"));
var _TokenizeCard = _interopRequireDefault(require("./model/TokenizeCard"));
var _TokenizedCard = _interopRequireDefault(require("./model/TokenizedCard"));
var _TokenizedCardDetails = _interopRequireDefault(require("./model/TokenizedCardDetails"));
var _TokenizedCards = _interopRequireDefault(require("./model/TokenizedCards"));
var _TroubleTicket = _interopRequireDefault(require("./model/TroubleTicket"));
var _TwoFactorAuthPreferences = _interopRequireDefault(require("./model/TwoFactorAuthPreferences"));
var _UnLinkAccount = _interopRequireDefault(require("./model/UnLinkAccount"));
var _UnLockCustomer = _interopRequireDefault(require("./model/UnLockCustomer"));
var _UnRegisterUser = _interopRequireDefault(require("./model/UnRegisterUser"));
var _UnbilledServiceCallDataRecord = _interopRequireDefault(require("./model/UnbilledServiceCallDataRecord"));
var _UpdateCustomerAddress = _interopRequireDefault(require("./model/UpdateCustomerAddress"));
var _UpdateCustomerEmail = _interopRequireDefault(require("./model/UpdateCustomerEmail"));
var _UpdatePersonalDetails = _interopRequireDefault(require("./model/UpdatePersonalDetails"));
var _User = _interopRequireDefault(require("./model/User"));
var _AccountApi = _interopRequireDefault(require("./api/AccountApi"));
var _AgentOrdersApi = _interopRequireDefault(require("./api/AgentOrdersApi"));
var _AuthenticationApi = _interopRequireDefault(require("./api/AuthenticationApi"));
var _BasketApi = _interopRequireDefault(require("./api/BasketApi"));
var _CatalogApi = _interopRequireDefault(require("./api/CatalogApi"));
var _ConfirmedBasketApi = _interopRequireDefault(require("./api/ConfirmedBasketApi"));
var _ContentCmsApi = _interopRequireDefault(require("./api/ContentCmsApi"));
var _CustomerApi = _interopRequireDefault(require("./api/CustomerApi"));
var _EmployeeApi = _interopRequireDefault(require("./api/EmployeeApi"));
var _InboundCommunicationApi = _interopRequireDefault(require("./api/InboundCommunicationApi"));
var _InvoiceApi = _interopRequireDefault(require("./api/InvoiceApi"));
var _OrdersApi = _interopRequireDefault(require("./api/OrdersApi"));
var _OrganizationBusinessApi = _interopRequireDefault(require("./api/OrganizationBusinessApi"));
var _OrganizationGroupsApi = _interopRequireDefault(require("./api/OrganizationGroupsApi"));
var _OrganizationOperatorApi = _interopRequireDefault(require("./api/OrganizationOperatorApi"));
var _OutboundCommunicationApi = _interopRequireDefault(require("./api/OutboundCommunicationApi"));
var _RegistrationApi = _interopRequireDefault(require("./api/RegistrationApi"));
var _ServiceApi = _interopRequireDefault(require("./api/ServiceApi"));
var _TroubleticketApi = _interopRequireDefault(require("./api/TroubleticketApi"));
var _UserApi = _interopRequireDefault(require("./api/UserApi"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
/**
 * SR-SSB API
 * SR-SSB REST API suite
 *
 * The version of the OpenAPI document: 3.0
 * Contact: steveburns@separatereality.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
/**
* SR_SSB_REST_API_suite.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var SrSsbApi = require('index'); // See note below*.
* var xxxSvc = new SrSsbApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new SrSsbApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new SrSsbApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new SrSsbApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 3.0
*/
var _default = exports;
exports["default"] = _default;