import React, { useEffect } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';

import { colors, typography } from '@sart/styles'
import { useContent } from '@sart/content'
import { Text, Space, Anchor } from '@sart/components'

import { GooglePlay, AppleStore, HolSmallLogoWithSpeechBubble, HolFullLogo, HolSmallLogo, WideUpArrow, EXiSAppIcon } from '@sart/icons'

import ConfirmationTitleWithSIM from '../ConfirmationTitleWithSIM'
import ConfirmationTitleWithoutSIM from '../ConfirmationTitleWithoutSIM'

import ConfirmationTitleWithSIMMobile from '../ConfirmationTitleWithSIMMobile'
import ConfirmationTitleWithoutSIMMobile from '../ConfirmationTitleWithoutSIMMobile';

import client, { useClient } from '@sart/client'

import {
    isMobileOnly
} from "react-device-detect";
import { testID } from '@sart/utilities/src';

export default function CheckoutConfirmationEliot(props) {
    const { history } = props

    const content = useContent()

    const { orderMessage, hasSimOrder, eSIMDispatch } = client.cache

    useEffect(() => {
        client.unsetCache('selectedPlan')
        client.unsetCache('customerDetails')
        client.unsetCache('existingSIM')
        client.unsetCache('promoPlans')
        client.unsetCache('cachedBasket')
        client.unsetCache('referralCode')
        setEliotJourney()
    }, [])

    async function setEliotJourney() {
        try {
            await AsyncStorage.removeItem('eliotJourney')
        } catch(error) {
            //do nothing for now
        }
    }

    function handleReturn() {
        history.push('/')
    }

    function handleRouting() {
        window.open('http://hol.com.mx', '_blank')
    }

    function openLink() {
        window.open('https://exis.mx/eSIM/', '_blank')
    }

    const displayOrderMessage = orderMessage ? orderMessage.substring(12) : ''

    const source = 'https://exis.mx/files/eliotBackgroundImage.png'

    return ([
        // <EliotExternalLinkBar />,
        <div
            style={{
                backgroundImage: `url(${source})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                // width: 1700,
                height: isMobileOnly ? 900 : 750,
            }}>

            <View style={styles.navbarContainer}>

                <HolFullLogo />

                <TouchableOpacity onPress={() => handleRouting()}>
                    <WideUpArrow />
                </TouchableOpacity>

                <HolSmallLogo />

            </View>

            <View style={styles.container}>

                {!hasSimOrder && 
                    <View style={styles.textContainer}>
                        <HolSmallLogoWithSpeechBubble text={"THX"} />
                        <Space height={isMobileOnly ? 24 : 36} />
                        {!isMobileOnly && 
                            <ConfirmationTitleWithoutSIM />
                        }
                        {isMobileOnly &&
                            <ConfirmationTitleWithoutSIMMobile />
                        }
                        <Space height={56} />
                        <Text style={styles.subtitle} {...testID('orderMessage')}>{content.checkout.yourNewNumberIs} {displayOrderMessage}</Text>
                        <Space height={18} />
                        <Text style={styles.message}>

                            {content.checkout.orderPendingShippingInfoeSimHol1}

                            <Anchor
                                title={content.checkout.orderPendingShippingInfoeSimHol2}
                                onPress={() => openLink()}
                                style={styles.yellowLink}
                            />

                            {content.checkout.orderPendingShippingInfoeSimHol3}

                            <Anchor
                                title={content.checkout.orderPendingShippingInfoeSimHol4}
                                style={styles.yellowBold}
                            />

                            {content.checkout.orderPendingShippingInfoeSimHol5}
                        </Text>
                    </View>
                }
                {hasSimOrder &&
                    <View style={styles.textContainer}>
                        <HolSmallLogoWithSpeechBubble text={"THX"} />
                        <Space height={isMobileOnly ? 24 : 36} />
                        {!isMobileOnly &&
                            <ConfirmationTitleWithSIM />
                        }
                        {isMobileOnly &&
                            <ConfirmationTitleWithSIMMobile />
                        }
                        <Space height={56} />
                        <Text style={styles.subtitle} {...testID('orderMessage')}>{content.checkout.yourNewNumberIs} {displayOrderMessage}</Text>
                        <Space height={8} />
                        <Text style={styles.processShortly}>{content.checkout.orderProcessedShortly}</Text>
                        <Space height={8} />
                        <Text style={styles.message}>{content.checkout.orderPendingShippingInfoWithSimHol}</Text>
                    </View>
                }

                <Space height={36} />

                <View style={styles.iconsRow}>

                    <View style={styles.appIcon}>
                        <EXiSAppIcon />
                    </View>

                    <Space width={24} />

                    <View style={styles.iconsColumn}>

                        <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=uk.co.separatereality.vadsa.mobile.myExis', '_blank')}>
                            <GooglePlay />
                        </TouchableOpacity>

                        <Space height={6} />

                        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/us/app/exis/id1540564347', '_blank')}>
                            <AppleStore />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </div>
    ])
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isMobileOnly ? 480 : 600,
        paddingTop: isMobileOnly ? 0 : 0,
        width: '100%',
        // backgroundColor: colors.eliotPurple
    },
    navbarContainer: {
        // backgroundColor: colors.eliotPurple,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '2%',
        height: 50
    },
    iconsRow: {
        flexDirection: 'row'
    },
    iconsColumn: {
        flexDirection: 'column',
        // justifyContent: 'center'
    },
    title: {
        color: '#FCEE21',
        fontSize: isMobileOnly ? 16 : 48,
        fontFamily: 'BebasNeueBold',
        // fontStyle: 'italic',
        // ...typography.bold,
        textAlign: 'center'
    },
    subtitle: {
        color: colors.white,
        fontSize: isMobileOnly ? 36 : 42,
        fontFamily: 'BebasNeueBold',
        textAlign: 'center'
    },
    processShortly: {
        color:'#FCEE21',
        fontSize: isMobileOnly ? 16 : 35,
        fontFamily: 'BebasNeueBold',
        // textAlign: 'center'
    },
    message: {
        fontSize: isMobileOnly ? 18 : 18,
        lineHeight: 24,
        textAlign: 'center',
        color: colors.white,
        fontFamily: 'AvertaRegular',
        // ...typography.bold
    },
    message2: {
        fontSize: isMobileOnly ? 12 : 18,
        lineHeight: 24,
        textAlign: 'center',
        color: colors.white,
        fontFamily: 'AvertaExtraBold',
        // ...typography.bold
    },
    message3: {
        fontSize: isMobileOnly ? 12 : 18,
        lineHeight: 24,
        textAlign: 'center',
        color: colors.eliotYellow,
        fontFamily: 'AvertaExtraBold',
        // ...typography.bold
    },
    appIcon: {
        justifyContent: 'center'
    },
    textContainer: {
        alignItems: 'center',
        width: isMobileOnly ? '90%' : '40%',
        ...(isMobileOnly ?

            { //Mobile Only

            }
            :
            { //Non-Mobile Only
                minWidth: 500
            }
        ),
    },
    link: {
        fontSize:  isMobileOnly ? 18 : 18, 
        lineHeight: 24, 
        color: colors.eliotYellow,
        textDecorationLine: 'underline',
        fontFamily: 'AvertaRegular',
      },
      whiteBold: {
        fontSize:  isMobileOnly ? 18 : 18, 
        lineHeight: 24, 
        color: colors.white,
        fontFamily: 'AvertaExtraBold',
      },
      yellowBold: {
        fontSize:  isMobileOnly ? 18 : 18, 
        lineHeight: 24, 
        color: '#FCEE21',
        fontFamily: 'AvertaExtraBold',
      },
      yellowLink: {
        fontSize:  isMobileOnly ? 18 : 18, 
        lineHeight: 24, 
        color: '#FCEE21',
        fontFamily: 'AvertaExtraBold',
        textDecorationLine: 'underline',
      }
})
