import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { View, StyleSheet } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  Text,
  Space
} from '@sart/components'
import { HorizontalLine } from '@sart/icons'
import { colors, typography } from '@sart/styles'
import { useContent } from '@sart/content'
import client, { useClient } from '@sart/client'

import Wrapper from '../Wrapper'

import CheckoutEmpty from './CheckoutEmpty'
import CheckoutNavlink from './CheckoutNavlink'
import CheckoutCustomer from './CheckoutCustomer'
import CheckoutPayment from './CheckoutPayment'
import CheckoutReview from './CheckoutReview'
import CheckoutProcess from './CheckoutProcess'
import CheckoutConfirmation from './CheckoutConfirmation'
import EliotExternalLinkBar from '../Plans/EliotExternalLinkBar'

import {
  isMobileOnly
} from "react-device-detect";

export default function Checkout(props) {
  const { location, match, history } = props

  const [eliotJourney, setEliotJourney] = useState(false)
  
  useEffect(() => {
    getEliotJourney()
  })
  
  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  const content = useContent()

  const routes = [
    {
      title: content.navigation.customer,
      path: `${match.url}/customer`,
      component: () => <CheckoutCustomer content={content} history={history} />
    },
    {
      title: content.navigation.paymentPortal,
      path: `${match.url}/payment`,
      component: CheckoutPayment
    },
    {
      title: content.navigation.review,
      path: `${match.url}/review`,
      component: CheckoutReview
    },
    {
      title: content.navigation.confirmation,
      path: `${match.url}/confirmation`,
      component: CheckoutConfirmation
    }
  ]

  const viewingConfirmationScreen = (location.pathname == match.url + '/confirmation')

  return (
    [
    (eliotJourney ? <EliotExternalLinkBar /> : <View/>),
    <View style={viewingConfirmationScreen ? styles.confirmation : styles.container}>
    <Space height={24} />
    
      {!viewingConfirmationScreen &&
      <View style={styles.content}>
        <View style={styles.linksContainer}>
          <CheckoutNavlink
            hideChevron
            to="/plans"
            title={content.navigation.backToShop}
          />

          {
            routes.map((route, i, arr) => {
              const nextRoute = i > routes.findIndex(r => r.path === location.pathname)
              const inLastRoute = routes[routes.length - 1].path === location.pathname

              return (
                <CheckoutNavlink
                  key={i}
                  to={route.path}
                  title={route.title}
                  disabled={nextRoute || inLastRoute}
                />
              )
            })
          }
        </View>
        <Text style={styles.title}>
          {content.checkout.header}
        </Text>
      </View>
      }
      {!(viewingConfirmationScreen && isMobileOnly) &&
        <Space height={24} />
      }

      <Switch>
        <Route exact path={match.url} component={CheckoutEmpty} />
        <Route path={`${match.url}/process`} component={CheckoutProcess} />

        {
          routes.map((route, i) =>
            <Route
              key={i}
              path={route.path}
              component={route.component}
            />
          )
        }
      </Switch>

      <Space flex={1} />
      <Space height={48} />

      <HorizontalLine height={3} color={colors.exisBlue} />
    </View>
    ]
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    width: '100%',
    maxWidth: 840,
    paddingHorizontal: 24,
    alignSelf: 'center',
    overflowX: 'hidden'
  },
  confirmation: {
    backgroundColor: colors.exisBlue
  },
  content: {

  },
  title: {
    fontSize: 32,
    lineHeight: 42,
    color: colors.exisBlue,
    ...typography.demi,
    paddingTop: 14,
    marginLeft: isMobileOnly ? '6%' : '0%'
  },
  rows: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  values: {
    color: colors.focus,
    fontWeight: '400',
    lineHeight: 24
  },
  total: {
    color: colors.feature,
    fontWeight: '500',
    lineHeight: 24
  },
  linksContainer: {

    ...(isMobileOnly ? 
      
      { //Mobile Only
        flexDirection: 'row', 
        alignSelf: 'center',
        padding: '0%'
      } 
      : 
      { //Non-Mobile Only
        flexDirection: 'row', 
        alignItems: 'center'  
      }
    ),
    
  }
})
