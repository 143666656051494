export function accountHolderFullname(o) {
  if (!o) {
    return ''
  }
  const names = [
    o.title,
    o.firstName,
    o.middleName,
    o.lastName
  ]

  return names
    .filter(v => !!v)
    .join(' ')
}

export function accountHolderFullAddress(o) {
  if (!o) {
    return ''
  }
  const lines = [
    o.addressLine1,
    o.addressLine2,
    o.city,
    o.state,
    o.zip,
    o.country,
  ]

  return lines
    .filter(v => !!v)
    .join('\n')
}

export function accountHolderDOB(o) {
  if (!o) {
    return ''
  }
  try {
    return o.dob.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    })
  } catch (error) {
    return ''
  }
}
