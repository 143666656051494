import * as React from "react"
import PropTypes from 'prop-types'
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

function Facebook(props) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="prefix__a" d="M0 0h15v14.997H0z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path d="M-897-43H127v224H-897z" fill="#FFF" />
        <G>
          <Mask id="prefix__b" fill="#fff">
            <Use xlinkHref="#prefix__a" />
          </Mask>
          <Path
            d="M15 7.544C15 3.378 11.642 0 7.5 0 3.358 0 0 3.378 0 7.544c0 3.766 2.743 6.887 6.328 7.453V9.725H4.424v-2.18h1.904V5.881c0-1.89 1.12-2.935 2.833-2.935.82 0 1.679.147 1.679.147v1.857h-.946c-.932 0-1.222.581-1.222 1.178v1.415h2.08l-.333 2.181H8.672v5.272C12.257 14.43 15 11.31 15 7.544"
            fill="gray"
            mask="url(#prefix__b)"
          />
        </G>
        <Path
          d="M10.574 9.699l.337-2.155H8.804V6.145c0-.59.294-1.164 1.238-1.164H11V3.146S10.13 3 9.3 3C7.563 3 6.43 4.032 6.43 5.901v1.643H4.5v2.155h1.93v5.21a7.798 7.798 0 002.374 0V9.7h1.77"
          fill="#FFFFFE"
        />
      </G>
    </Svg>
  )
}


Facebook.defaultProps = {
    width: 121,
    height: 154,
  }
  
Facebook.propTypes = {
    color: PropTypes.string
}

export default Facebook
