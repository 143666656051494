// from https://github.com/sindresorhus/pretty-bytes

// 'use strict';

const UNITS = [
	'B',
	'KB',
	'MB',
	'GB',
	'TB',
	'PB',
	'EB',
	'ZB',
	'YB'
];

/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const toLocaleString = (number, locale) => {
	let result = number;
	if (typeof locale === 'string') {
		result = number.toLocaleString(locale);
	} else if (locale === true) {
		result = number.toLocaleString();
	}

	return result;
};

export default (number, options) => {
	if (!Number.isFinite(number)) {
		throw new TypeError(`Expected a finite number, got ${typeof number}: ${number}`);
	}

	options = Object.assign({}, options);

	if (options.signed && number === 0) {
    return {
      value: '0',
      unit: UNITS[0]
    }
	}

	const isNegative = number < 0;
	const prefix = isNegative ? '-' : (options.signed ? '+' : '');

	if (isNegative) {
		number = -number;
	}

	if (number < 1) {
		const numberString = toLocaleString(number, options.locale);

    return {
      value: `${prefix + numberString}`,
      unit: UNITS[0]
    }
	}

	const exponent = Math.min(Math.floor(Math.log10(number) / 3), UNITS.length - 1);
	// eslint-disable-next-line unicorn/prefer-exponentiation-operator
	number = Number((number / Math.pow(1000, exponent)).toPrecision(3));
	const numberString = toLocaleString(number, options.locale);

	return {
    value: `${prefix + numberString}`,
    unit: UNITS[exponent]
  }
}
