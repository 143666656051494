import React, { useState, useEffect } from 'react'

import { StyleSheet, View } from 'react-native'
import { Switch, Route } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import Wrapper from '../Wrapper'
import PortSignIn from './PortSignIn'
import PortForm from './PortForm'
import PortConfirmation from './PortConfirmation'

import EliotExternalLinkBar from '../Plans/EliotExternalLinkBar'


import client, { useClient } from '@sart/client'


export default function Port(props) {
  const { match } = props

  const [eliotJourney, setEliotJourney] = useState(false)
  
  useEffect(() => {
    getEliotJourney()
  })
  
  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  return (
    [
      (eliotJourney ? <EliotExternalLinkBar /> : <View/>),
      // Originally using Wrapper with container style. Added styling to individual screens instead so
      // background color can be different without looking off due to the wrapper's inherited styling. 
      <View>
        <Switch>
          <Route exact path={match.url} component={PortSignIn} />
          <Route path={`${match.url}/form`} component={PortForm} />
          <Route path={`${match.url}/confirmation`} component={PortConfirmation} />
        </Switch>
      </View>
    ]
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48,
  }
})

