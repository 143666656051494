import React from 'react'
import PropTypes from 'prop-types'

import Svg, { Rect } from 'svgs'

import { colors } from '@sart/styles'

const UnknownCard = props => {
  const { color, size, ...otherProps } = props
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={22}
      viewBox="0 0 32 24"
      fill={color}
      stroke="none"
      {...otherProps}
    >
      <Rect width="32" height="24" rx="4" />
    </Svg>
  )
}

UnknownCard.propTypes = {
  color: PropTypes.string
}

UnknownCard.defaultProps = {
  color: colors.subtle
}

export default UnknownCard
