import React from 'react'

import Svg, { Path } from 'svgs'
import PropTypes from 'prop-types'

import { colors } from '@sart/styles'

const ProductMinutes = ({ color, ...rest }) => (
  <Svg
    width="48"
    height="48"
    stroke={color}
    fill="none"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 48 48"
    {...rest}
  >
    <Path d="M29.9792365,29.124454 L28.2618455,32.3051867 C23.0679074,29.4969873 18.766077,25.2504419 15.8892802,20.0984569 L19.0467401,18.3393647 C19.8653409,17.8833037 20.2245228,16.8692782 19.8219049,16.0239784 C18.4586841,13.1639919 17.6100123,10.0116586 17.3945031,6.68558785 C17.3326904,5.7266905 16.4973836,5 15.53511,5 L11.9767028,5 L8.64382834,5 C6.51546643,5 4.80141673,6.82758484 5.01859652,8.94250478 C5.55987537,14.2130991 7.17368825,19.1662884 9.63617291,23.579889 C13.1728622,29.9213077 18.4687078,35.1484677 24.8621466,38.6065121 C29.1656476,40.9335923 33.9669915,42.458807 39.064034,42.9816901 C41.1773604,43.1971914 42.9983294,41.4815336 43,39.3582609 L43,36.0238374 L43,32.4121021 C43,31.4765925 42.2899892,30.6647038 41.3577867,30.6062344 C38.1368434,30.402427 35.076279,29.6055733 32.2880247,28.3225886 C31.434341,27.9300087 30.4252903,28.2992009 29.9792365,29.124454 Z" />
  </Svg>
)

ProductMinutes.propTypes = {
  color: PropTypes.string
}

ProductMinutes.defaultProps = {
  color: colors.feature
}

export default ProductMinutes
