import React from 'react'
import PropTypes from 'prop-types'

import Svg, { Line, Polygon } from 'svgs'
import { colors } from '@sart/styles'

const AlertOctagon = props => {
  const { color, size, ...otherProps } = props
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
      <Line x1="12" y1="8" x2="12" y2="12" />
      <Line x1="12" y1="16" x2="12" y2="16" />
    </Svg>
  )
}

AlertOctagon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

AlertOctagon.defaultProps = {
  color: colors.feature,
  size: '24'
}

export default AlertOctagon
