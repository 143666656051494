import React from 'react'
import { HashRouter as Router, Switch, Route, Redirect, hashHistory } from 'react-router-dom'
import { View, StyleSheet } from 'react-native'

import client, { ClientProvider, useClient } from '@sart/client'
import { Space } from '@sart/components'

import AgentNavbar from './Agent/AgentNavbar'
import AppConfig from './Configuration'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Plans from './Plans'
import PlansEliot from './Plans/PlansEliot'
import PlanEliot from './Plans/PlanEliot'
import Plan from './Plans/Plan'
import Checkout from './Checkout'
import AlreadyHaveSIM from './AlreadyHaveSIM'
import Port from './Port'
import Lost from './Lost'
import Help from './Help'
import Legal from './Legal'
import TermsAndConditions from './TermsAndConditions'
import Privacy from './Privacy'
import AgentSignIn from './Agent/AgentSignIn'
import NotFound from './NotFound'
import Footer from './Footer'
import Barcode from './Barcode'
import Contact from './Contact'
import ADT from './ADT'
import Voxox from './Voxox'
import ScrollToTop from './ScrollToTop'
import CheckoutConfirmationEliot from './Checkout/CheckoutConfirmationEliot'
import EliotExternalLinkBar from './Plans/EliotExternalLinkBar'
import BarcodeEliot from './BarcodeEliot'

function AppRouting() {
  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    loadConfig()
    loginWithAgent()
  }, [])

  // const {
  //   eliotJourney
  // } = client.cache

  // const {
  //   cache: {
  //     eliotJourney,
  //   } = {}
  // } = useClient()
  
  async function loadConfig() {
    client.setConfiguration(AppConfig)
    client.setApiBasePath(client.configuration.basePath)
    client.setHeaders({ Version: client.configuration.apiVersion })
    client.cache.language = AppConfig.defaultLanguage
  }

  async function loginWithAgent() {
    try {
      //create a guest user

      await client.signInWithAgentUser({
        username: 'VADSA-SHOPPING-AGENT',
        password: 'Passw001'
      })

      const dispatchSimProducts = await client.callApi('catalog','getDispatchSimProducts',[client.cache.catalogId])
      const eSimProduct = dispatchSimProducts.find((product) => product.productLegacyId === 'VADSA-DISPATCH-E-SIM')
      const eSimAvailable = !!eSimProduct
      client.setCache({
        eSimAvailable
      })

      setReady(true)
    } catch (error) {

    }
  }

  async function checkEsimAvailable() {
    
  }

  if (!ready) {
    return <Space height={24} />
  }


  return (
    <View style={styles.container}>
      <ScrollToTop />
      <AgentNavbar />

      <Navbar />
      {/* {eliotJourney === true &&
        <EliotExternalLinkBar />
      } */}
      <View style={styles.routesContainer}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Redirect from="/home" to="/"/>
        <Route path="/about" component={About} />
        <Route path="/plans" component={Plans} />
        <Route path="/hol_plans" component={PlansEliot} />
        <Route path="/plan/:id" component={Plan} />
        <Route path="/hol_plan/:id" component={PlanEliot} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/alreadyhavesim" component={AlreadyHaveSIM} />
        <Route path="/port" component={Port} />
        <Route path="/lost" component={Lost} />

        <Route path="/help" component={Help} />
        <Route path="/legal" component={Legal} />
        <Route path="/termsandconditions" component={TermsAndConditions} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/contactus" component={Contact} />

        <Route path="/agentsignin" component={AgentSignIn} />
        <Route path="/barcode" component={Barcode} />
        {/* <Route path="/adt" component={ADT} /> */}
        {/* <Route path="/voxox" component={Voxox} /> */}
        <Route path="/hol_barcode" component={BarcodeEliot} />
        <Route path="/hol_confirmation" component={CheckoutConfirmationEliot} />
        <Route component={NotFound} />
      </Switch>
      </View>
      <Footer />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100%'
  },
  routesContainer: {
    zIndex: -1
  }
})

export default function App() {
  return (
    <ClientProvider>
      <Router history={hashHistory}>
        <AppRouting />
      </Router>
    </ClientProvider>
  )
}
