import * as React from "react"
import PropTypes from 'prop-types';
import Svg, { Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function HelpIcon(props) {
  const { size, color } = props;

  return (
    <Svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      fill={color}
      {...props}
    >
      <Path
        className="prefix__st0"
        d="M49.8 5.1c-24.9 0-45 20.1-45 45s20.1 45 45 45 45-20.1 45-45c-.1-24.8-20.2-44.9-45-45zm0 84c-21.5 0-39-17.5-39-39s17.5-39 39-39 39 17.5 39 39c-.1 21.6-17.5 39-39 39z"
      />
      <Path
        className="prefix__st0"
        d="M49 75.2c1.3 0 2.5-.5 3.4-1.4.9-.9 1.4-2.1 1.4-3.4 0-1.3-.5-2.4-1.4-3.4-.9-.9-2.1-1.4-3.4-1.4s-2.5.5-3.4 1.4c-.9.9-1.4 2.1-1.4 3.4 0 1.3.5 2.5 1.4 3.4 1 1 2.1 1.4 3.4 1.4zM58.3 49.8c.9-.7 1.9-1.4 2.9-2.2 1-.8 1.8-1.6 2.6-2.6.8-.9 1.4-1.9 1.9-3.1.5-1.1.7-2.4.7-3.8 0-2.3-.5-4.3-1.4-5.9-.9-1.6-2.2-3-3.8-4-1.6-1.1-3.4-1.9-5.5-2.4-4-1-8.4-1.1-12.2.2-1.9.7-3.5 1.6-5 2.7-1.4 1.1-2.6 2.4-3.4 3.9-.8 1.4-1.2 2.9-1.2 4.5 0 1.1.3 1.9 1 2.4.7.6 1.6.9 2.6.9.9 0 1.6-.2 2.1-.5.5-.4 1-.9 1.3-1.5.4-.6.7-1.3 1.1-2.1.4-.9.9-1.7 1.4-2.5.6-.8 1.4-1.5 2.4-2 1-.6 2.3-.8 4-.8 2.4 0 4.3.6 5.6 1.8 1.4 1.3 2.1 3 2.1 5.2 0 1.4-.4 2.6-1.1 3.7-.7 1-1.5 1.9-2.4 2.8-.9.8-2 1.7-3 2.5-1 .7-2 1.6-2.9 2.4-.8.8-1.6 1.7-2.1 2.7-.5.9-.8 2-.8 3.2 0 .9.3 1.8.9 2.6.6.8 1.4 1.1 2.6 1.1 1.2 0 1.8-.2 2.1-.4.4-.3.8-.6 1-1.1.3-.5.5-1.1.7-1.7.2-.8.6-1.6 1.1-2.3.4-.7 1.1-1.3 2-1.9l2.7-1.8z"
      />
    </Svg>
  )
}

HelpIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
};

HelpIcon.defaultProps = {
  size: '16',
  color: '#999999'
}
export default HelpIcon
