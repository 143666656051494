import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { isMobileOnly } from 'react-device-detect'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import delay from 'delay'

import {
  // Label,
  Space,
  Button,
  TextInput
} from '@sart/components'
import { colors, typography } from '@sart/styles'

import client from '@sart/client'
import { useContent } from '@sart/content'
import { testID } from '@sart/utilities'

export default function DiscountCode(props) {
  // const cache = useClient()
  const content = useContent()

  const DiscountCodeCode = (client.cache.agent && client.cache.agent.referalCode)
  const referalCode = undefined

  const [locked, setLocked] = useState(referalCode || DiscountCodeCode ? true : false)

  const formik = useFormik({
    initialValues: {
      referal: referalCode || DiscountCodeCode,
    },
    validationSchema: Yup.object().shape({
      referal: Yup.string().trim()
    }),
    onSubmit: handleSubmitReferal
  })

  async function handleSubmitReferal(values, form) {
    if (locked) {
      // client.unsetCache('referralCode')
      form.resetForm({ values: { referal: '' } })
      setLocked(false)
      return
    }

    try {
      // TODO:

      // validate referal
      await delay(1000)

      // if valid cache and lock value
      // client.setCache({ referralCode: values.referal })

      setLocked(true)
    } catch (error) {

    }
  }

  return (
    <View style={styles.container}>
      {/* <Label>{"Referral Code"}</Label> */}
      {/* <Space height={8} /> */}

      <View style={styles.inputRow}>
        <TextInput
          value={formik.values.referal}
          placeholder={content.checkout.referalCode}
          onChangeText={formik.handleChange('referal')}
          onBlur={formik.handleBlur('referal')}
          error={formik.touched.referal && formik.errors.referal}
          editable={!locked}
          style={styles.inputStyle}
          {...testID("discountCodeInput")}
        />
        <Space width={isMobileOnly ? 4 : 12} />
        <Button
          title={
            locked
              ? content.common.reset
              // : formik.isSubmitting
              //   ? content.common.submiting
                : content.common.apply
          }
          disabled={!formik.isValid || formik.isSubmitting}
          onPress={formik.handleSubmit}
          style={styles.button}
          titleStyle={styles.buttonTitle}
          {...testID("discountCodeButton")}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...(isMobileOnly ? 
      { //Mobile Only
        width:'100%', 
        paddingHorizontal:6
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  inputRow: {
    flexDirection: 'row',
    ...(isMobileOnly ? 
      { //Mobile Only
        flex:1
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  inputStyle: {
    flex: 1,
    ...typography.book,
    ...(isMobileOnly ? 
      { //Mobile Only
        width: 200,
        fontSize: 14
      } 
      : 
      { //Non-Mobile Only
        
      }
    )  },
  button: {
    backgroundColor: colors.exisOrange,
    ...(isMobileOnly ? 
      { //Mobile Only
        flex:1
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  buttonTitle: {
    ...typography.bold, 

    ...(isMobileOnly ? 
      { //Mobile Only
        fontSize: 12
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  }
})

