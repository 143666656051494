import React from 'react'
import { View, StyleSheet } from 'react-native'

import {
  Headline,
  Subhead,
  Space
} from '@sart/components'
import { GuruSad } from '@sart/icons'
import { useContent } from '@sart/content'
import {
  colors,
  typography
} from '@sart/styles'

import { testID } from '@sart/utilities'

export default function LostConfirmaton(props) {
  const { history } = props

  const content = useContent()

  return (
    <View style={styles.content}>
      <Space height={40} />

      <GuruSad width={220} height={181} />

      <Space height={24} />

      <Headline style={styles.headlineText} {...testID("lostCofirmationText")}>{content.lost.confirmationTitle}</Headline>

      {/* <Space height={24} /> */}

      <Subhead style={styles.centerText}>{content.lost.confirmationInstruction}</Subhead>

      <Space height={200} />

      {/* <Button
        title={content.navigation.home}
        onPress={() => history.push('/')}
        style={{ backgroundColor: colors.exisOrange }}
        titleStyle={{...typography.extrabold, fontSize: 18}}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    backgroundColor: colors.exisBlue,
    minHeight: 540,
    paddingTop: 48,
    width: '100%'
  },
  headlineText: {
    textAlign: 'center',  
    color: colors.black,
    ...typography.bold,
    fontSize: 20
  },
  centerText: {
    textAlign: 'center',
    color: colors.white,
    ...typography.lightbold,
    fontSize: 14
  }
})

