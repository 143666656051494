import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Redirect } from 'react-router-dom'

import client from '@sart/client'
import { useContent } from '@sart/content'
import { Headline, Subhead, Space, Text, Button } from '@sart/components'
import { backgroundColors, typography, colors } from '@sart/styles'
import { EliotCircleBackground } from '@sart/icons';
import { testID } from '@sart/utilities';

import PlanItemEliotDetailed from './PlanItemEliotDetailed'

import {
  isMobileOnly
} from "react-device-detect";
import EliotExternalLinkBar from './EliotExternalLinkBar'
import EliotBottomBar from './EliotBottomBar'

export default function PlanEliot(props) {
  const { history, match } = props

  const content = useContent()

  const { params: { id: productLegacyId } = {} } = match

  const plans = client.cache.plans

  async function submitPlanToBasket(plan) {
    client.setCache({ selectedPlan: plan, promoPlan: promoPlan })
    await client.createNCABasket(plan)

    history.push('/checkout/customer')
  }


  if (!productLegacyId) {
    return <Redirect from={match.url} to="/hol_plans" />
  }

  if (!plans) {
    return <Redirect from={match.url} to="/hol_plans" />
  }

  const allPlans = [
    ...plans.plans,
    // ...plans.oneoff
  ]

  const plan = allPlans.find(
    plan => plan.productLegacyId === productLegacyId
  )

  if (!plan) {
    return <Redirect from={match.url} to="/hol_plans" />
  }

  var promoPlan = []

  return ([
    <EliotExternalLinkBar />,
    <View style={styles.container}>
      <EliotCircleBackground width={isMobileOnly ? "390px" : "900px"} height={"700px"} style={{
        position: 'absolute',
        alignSelf: 'center'
      }} />
      <Space height={24} />
      <View style={styles.headers}>
        <Text style={styles.headerText}>{content.plans.eliotHeader1} </Text>
        <Text style={styles.headerTextHighlight}>{content.plans.eliotHeader2} </Text>
        <Text style={styles.headerText}>{content.plans.eliotHeader3}</Text>
        {/* <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead> */}
      </View>
      <Space height={8} />
      <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead>

      <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 18 }}>
        <PlanItemEliotDetailed
          plan={plan}
          onPress={() => submitPlanToBasket(plan)}
          link={true}
          {...testID('PlanItemDetailed')}
        />
      </View>
    </View>,
    <EliotBottomBar />
  ]
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#EDEEF0',
  },
  container: {
    minHeight: 810,
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#EDEEF0',
    paddingTop: 16,
    paddingBottom: 48
  },
  headers: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  headerText: {
    textAlign: 'center',
    fontSize: isMobileOnly ? 22 : 40,
    color: colors.eliotPurple,
    fontFamily: 'AvertaExtraBold'
  },
  headerTextHighlight: {
    textAlign: 'center',
    fontSize: isMobileOnly ? 22 : 40,
    color: colors.eliotYellow,
    fontFamily: 'AvertaExtraBold'
  },
  subheaderText: {
    fontSize: 20,
    textAlign: 'center',
    color: colors.eliotPurple,
    fontFamily: 'AvertaRegular'
  },
})

