// react-feather
export { default as AlertOctagon } from './AlertOctagon'
export { default as Back } from './Back'
export { default as ChevronDown } from './ChevronDown'
export { default as ChevronRight } from './ChevronRight'
export { default as Globe } from './Globe'
export { default as GooglePlay } from './GooglePlay'
export { default as Home } from './Home'
export { default as Maestro } from './Maestro'
export { default as MasterCard } from './MasterCard'
export { default as Menu } from './Menu'
export { default as Phone } from './Phone'
export { default as Internet } from './Internet'
export { default as Radio } from './Radio'
export { default as ShoppingBag } from './ShoppingBag'
export { default as ShoppingCart } from './ShoppingCart'
export { default as VoiceMail } from './VoiceMail'
export { default as AddonIcon } from './AddonIcon'
export { default as Plus } from './Plus'
export { default as Minus } from './Minus'
export { default as Camera } from './Camera'

// react-feather-like
export { default as PhoneDataMessages } from './ProductHybrid'
export { default as ProductIconType } from './ProductIconType'
export { default as ProductHybrid } from './ProductHybrid'
export { default as ProductGrouped } from './ProductGrouped'
// general
export { default as AmericanExpress } from './AmericanExpress'
export { default as AppleStore } from './AppleStore'
export { default as Confirmation } from './Confirmation'
export { default as CreditCard } from './CreditCard'
export { default as CreditCartType } from './CreditCartType'
export { default as Done } from './Done'
export { default as HorizontalLine } from './HorizontalLine'
export { default as Logo } from './Logo'
export { default as SIM } from './SIM'
export { default as Paypal } from './Paypal'
export { default as PayU } from './PayU'
export { default as UnknownCard } from './UnknownCard'
export { default as User } from './User'
export { default as Visa } from './Visa'
export { default as UK } from './UK'
export { default as US } from './US'
export { default as LanguageIconType } from './LanguageIconType'
export { default as CashIcon } from './CashIcon'
export { default as Delete } from './Delete'
export { default as Mobile } from './Mobile'
export { default as Cross } from './Cross'

//Guru
export { default as GuruCallMe } from './GuruCallMe'
export { default as GuruCalm } from './GuruCalm'
export { default as GuruFullBodyCallMe } from './GuruFullBodyCallMe'
export { default as GuruHappy } from './GuruHappy'
export { default as GuruJoy } from './GuruJoy'
export { default as GuruMeditation } from './GuruMeditation'
export { default as GuruPhotoFrame } from './GuruPhotoFrame'
export { default as GuruPointing } from './GuruPointing'
export { default as GuruPointingSmall } from './GuruPointingSmall'
export { default as GuruQuestion } from './GuruQuestion'
export { default as GuruRelaxed } from './GuruRelaxed'
export { default as GuruRelaxedFlipped } from './GuruRelaxedFlipped'
export { default as GuruSad } from './GuruSad'
export { default as GuruSerenity } from './GuruSerenity'
export { default as GuruSmile } from './GuruSmile'
export { default as GuruSmoothCriminal } from './GuruSmoothCriminal'
export { default as GuruThumbPrint } from './GuruThumbPrint'
export { default as GuruThumbsUp } from './GuruThumbsUp'
export { default as GuruVictoryDance } from './GuruVictoryDance'
export { default as GuruWink } from './GuruWink'

export { default as Twitter} from './Twitter'
export { default as Instagram } from './Instagram'
export { default as Facebook } from './Facebook'
export { default as Youtube } from './Youtube'
export { default as SIMCard } from './SIMCard'

export { default as GiftIcon } from './GiftIcon'
export { default as HelpIcon } from './HelpIcon'
export { default as Download } from './Download'
export { default as Share } from './Share'
export { default as SquaredPlus } from './SquaredPlus'
export { default as SquaredMinus } from './SquaredMinus'
export { default as TriangleRight} from './TriangleDown'
export { default as TriangleDown } from './TriangleDown'
export { default as Tick } from './Tick'
export { default as XSocial } from './XSocial'

// EXiS 
export { default as EXiSAppIcon } from './EXiSAppIcon'

// Eliot
export { default as EliotSIMCard } from './EliotSIMCard'
export { default as EliotCircleBackground} from './EliotCircleBackground'
export { default as EliotCost } from './EliotCost'
export { default as EliotPlanName } from './EliotPlanName'
export { default as HolFullLogo } from './HolFullLogo'
export { default as HolSmallLogo } from './HolSmallLogo' 
export { default as HolSmallLogoWithSpeechBubble } from './HolSmallLogoWithSpeechBubble'
export { default as WideUpArrow } from './WideUpArrow'