import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Anchor,
  Headline,
  Subhead,
  Space,
  TextInputExtended,
  Button,
} from '@sart/components'
import { useContent } from '@sart/content'
import { colors, typography } from '@sart/styles'
import client from '@sart/client'
import { prettyErrorMessage, testID } from '@sart/utilities'
import { Linking } from 'react-native'

import Wrapper from '../Wrapper'

const allowedRoles = ['AGENT'];

export default function AgentSignIn(props) {
  const { history } = props

  const content = useContent()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().required(content.forms.required),
      password: Yup.string().trim().required(content.forms.passwordInvalid)
    }),
    onSubmit: handleAgentSignIn
  })

  function getName(customer) {
    if (customer && customer.personalDetails) {
      const { firstName, lastName } = customer.personalDetails;
      return `${firstName} ${lastName}`
    }

    return "";
  }

  function hasValidRole(employee) {
    if (employee) {
      const { role: { roleName } } = employee;
      return allowedRoles.indexOf(roleName) > -1;
    }
    return false;
  }

  async function handleAgentSignIn(values, form) {
    form.setSubmitting(true)

    try {
      const agent = await client.signInWithAgentUser({
        username: values.email,
        password: values.password
      })
      if (!agent && !agent.employeeId){
        throw { response: { body: {messageCode : 'SR0005'} }};
      }
      const agentEmployee = await client.callApi('employee','getOperatorEmployee',[agent.employeeId])

      if (!hasValidRole(agentEmployee)) {
        throw { response: { body: {messageCode : 'SR0005'} }};
      }

      // TODO: fetch agent details

      client.setCache({
        agent: {
          ...agent,
          ...agentEmployee,
          name: getName(agentEmployee),
        },
        referralCode: agentEmployee.user.userName
      })

      history.push('/')
    } catch (error) {
      const message = prettyErrorMessage(error, 'login', content)
      form.setFieldError('password', message)
      form.setSubmitting(false)
    }
  }

  return (
    <Wrapper style={styles.container}>
      <View style={styles.headers}>
        <Headline style={styles.header}>{content.agent.header}</Headline>

        {/* <Space height={24} /> */}

        <Subhead style={styles.subheader}>{content.agent.subheader}</Subhead>

        <Space height={24} />

        <View style={{width: 336}}>
          <TextInputExtended
            label={content.forms.usernameLabel}
            value={formik.values.email}
            placeholder={content.forms.emailPlaceholder}
            keyboardType="email-address"
            {...Platform.select({
              web: { autoComplete: 'email' },
              ios: { textContentType: 'emailAddress' },
              android: { autoCompleteType: 'email' }
            })}
            autoCapitalize="none"
            onChangeText={formik.handleChange('email')}
            onBlur={formik.handleBlur('email')}
            error={formik.touched.email && formik.errors.email}
            style={styles.inputStyles}
            labelStyle={styles.labelStyles}
            onSubmitEditing={formik.handleSubmit}
            {...testID("agentSignInUsername")}
          />

          <Space height={8} />

          <TextInputExtended
            secureTextEntry
            label={content.forms.passwordLabel}
            {...Platform.select({
              web: { autoComplete: 'password' },
              ios: { textContentType: 'password' },
              android: { autoCompleteType: 'password' }
            })}
            placeholder={content.forms.passwordPlaceholder}
            value={formik.values.password}
            onChangeText={formik.handleChange('password')}
            onBlur={formik.handleBlur('password')}
            showErrorAndFooter
            footer={
              <>
                {content.forms.forgotPassword}
                {" "}
                <Anchor
                  title={content.agent.contactManagement}
                  onPress={() => Linking.openURL('mailto:contacto@exis.mx?subject=&body=') }
                  style={styles.anchorStyles}
                />
              </>
            }
            error={formik.touched.password && formik.errors.password}
            style={styles.inputStyles}
            labelStyle={styles.labelStyles}
            footerStyle={styles.footerStyles}
            onSubmitEditing={formik.handleSubmit}
            {...testID("agentSignInPassword")}
          />

          <Space height={24} />

          <Button
            title={
              formik.isSubmitting
                ? content.login.inProgress
                : content.login.action
            }
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onPress={formik.handleSubmit}
            style={styles.enabled}
            disabledStyle={styles.disabled}
            titleStyle={{...typography.extrabold}}
            {...testID("agentSignInSubmit")}
          />
        </View>
      </View>
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    // minWidth: '100%',
    paddingTop: 48,
    // backgroundColor: colors.exisOrange
  },
  headers: {
    alignItems: 'center'
  },
  header: {
    color: colors.exisBlue,
    ...typography.medium
  },
  subheader: {
    color: colors.trivial,
    ...typography.medium
  },
  inputStyles: {
    ...typography.demi
  },
  labelStyles: {
    ...typography.demi
  },
  footerStyles: {
    ...typography.medium
  },
  anchorStyles: {
    ...typography.medium
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  }
})

