import chroma from 'chroma-js'

// iOS
export const iOSSystemTintsLightPink = '#FF2D55'
export const iOSSystemTintsLightBlue = '#007AFF'

// Custom
export const feature = '#5B5E63'
export const focus = '#909397'
export const common = '#A8B1B9'
export const trivial = '#BCC3CA'
export const subtle = '#DDE0E3'
export const clear = '#FEFEFE'
export const black = '#000000'
export const white = '#FFFFFF'

// System
export const error = '#E45353'
export const errorTransparent = '#F5BDBD'
export const success = '#44D887'

//EXIS
export const exisDarkCharcoal = "#4A4A4A"
export const exisCharcoal = '#808080'
export const exisOrange = '#FF5210'
export const exisOrangePlaceholder = '#FF895C'
export const exisBlue = '#009ABA'
export const exisOnTint = 'white'
export const exisOnTintTransparentOnOrange = chroma.mix(exisOnTint, exisOrange, 0.4).hex()
export const exisTextInput = '#5B5E63'
export const brown = '#81280c'
export const lightBrown = '#8b2b0c'
export const exisInactiveTextInput = '#DDE0E3'
export const exisSelection = '#DDDDDD'
export const exisLightBlue = '#C2E7EF'

//ELIOT
export const eliotPurple = '#5D3186'
export const eliotPink = '#FF003D'
export const eliotYellow = '#ECBD00'

