import React  from 'react'

import xbytes from 'xbytes'

export function getFilteredBalances(balances) {
  var results = {}
  
  const data = balances.find((balance) => balance.balanceReferenceId === "TOTAL-DATA")
  const text = balances.find((balance) => balance.balanceReferenceId === "TOTAL-TEXT")
  const voice = balances.find((balance) => balance.balanceReferenceId === "TOTAL-VOICE")
  const monetary = balances.find((balance) => balance.balanceLegacyId === "MainBalance")
  const gifted = balances.filter((balance) => isGift(balance))

  const dataBreakdown = balances.filter((balance) => (!balanceItemIsTotal(balance) && isData(balance)))
  const voiceBreakdown = balances.filter((balance) => (!balanceItemIsTotal(balance) && balance.units === "MINS"))
  const textBreakdown = balances.filter((balance) => (!balanceItemIsTotal(balance) && balance.units === "TEXT"))

  // if (data) {
  //   const units = data.units.toLocaleUpperCase()

  //   results.data = {
  //     unit: 'B',
  //     quantity: xbytes.parseSize(data.quantity + units, { iec: false }),
  //     startingQuantity: xbytes.parseSize(data.startingQuantity + units, { iec: false }),
  //   }
  // }
  
  if (data) results.data = data
  if (text) results.text = text
  if (voice) results.voice = voice
  if (monetary) results.monetary = monetary
  if (dataBreakdown) results.dataBreakdown = dataBreakdown
  if (voiceBreakdown) results.voiceBreakdown = voiceBreakdown
  if (textBreakdown) results.textBreakdown = textBreakdown
  if (gifted) results.gifted = gifted

  return results
}

function isData(balance) {
  var dataTypes = []
  dataTypes = balance.units === "Kb" || balance.units === "Mb" || balance.units === "Gb" || balance.units === "KB" || balance.units === "MB" || balance.units === "GB" || balance.units === "DATAKB" || balance.units === "DATAMB" || balance.units === "DATAGB"
  
  return dataTypes
}

export function getLatestInvoiceId(invoices) {
  var toReturn = undefined
  for(var i = 0; i < invoices.length; i++) {
    if((toReturn == undefined || toReturn.invoiceDate < invoices[i].invoiceDate) && invoices[i].category == 'CURRENT') {
      toReturn = invoices[i]
    }
  }

  return toReturn == undefined ? undefined : toReturn.invoiceId
}

export function useData(initalState) {
  const [state,setState] = React.useState(initalState)

  return [
    state,
    (nextState) => void setState((prevState) => ({ ...prevState, ...nextState }))
  ]
}

export function getCategorisedAddOns(addOns = []) {

  const oneoff = addOns.filter((addOn) => {
    const { tags } = addOn.product;
    return tags.find(tag => tag.tag === "oneoff")
  })

  const recurring = addOns.filter((addOn) => {
    const { tags } = addOn.product;
    return tags.find(tag => tag.tag === "recurring")
  })

  return { oneoff, recurring }
}

function balanceItemIsTotal(balance){
  return (balance.tags !== undefined && balance.tags !== null && balance.tags.find(tag => tag.tag === "TOTAL") !== undefined)
}

function isGift(balance) {
  return (balance.tags !== undefined && balance.tags !== null && balance.tags.find(tag => tag.tag === "GIFT_BALANCE") !== undefined)
}

