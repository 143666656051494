import React from 'react'
import Svg, { G, Path, Rect } from 'svgs'
import PropTypes from 'prop-types'

import { colors } from '@sart/styles'

const ProductGrouped = ({ color, ...rest }) => (
    <Svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill="none" fillRule="evenodd">
        <G transform="translate(-17 -17)">
          <Rect fill="#F1F3F5" x={36} width={302} height={72} rx={8} />
          <Path d="M8 0h64v72H8a8 8 0 01-8-8V8a8 8 0 018-8z" fill="#FF5210" />
        </G>
        <G fill="#FFF" fillRule="nonzero">
          <Path d="M16.418 21.28a1.885 1.885 0 00-2.658 0l-9.99 9.99V22.62a1.885 1.885 0 00-3.77 0v13.194c0 1.042.844 1.885 1.885 1.885h13.194a1.885 1.885 0 000-3.77H6.428l9.97-9.97a1.885 1.885 0 00.02-2.677zM35.813 0H22.62a1.885 1.885 0 000 3.77h8.652l-9.99 9.971a1.885 1.885 0 102.657 2.658l9.99-9.971v8.651a1.885 1.885 0 003.77 0V1.885A1.885 1.885 0 0035.813 0z" />
        </G>
      </G>
    </Svg>
)

ProductGrouped.propTypes = {
  color: PropTypes.string
}

ProductGrouped.defaultProps = {
  color: colors.feature
}

export default ProductGrouped
