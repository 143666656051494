import React from 'react'
import { View, Text } from 'react-native'

export default function NotFound() {
  return (
    <View style={{flex: 1}}>
      <Text>404 Not Found</Text>
    </View>
  )
}
