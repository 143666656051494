import React from 'react'

import ProductHybrid from './ProductHybrid'
import ProductMinutes from './ProductMinutes'
import ProductData from './ProductData'
import ProductSMS from './ProductSMS'
import ProductGrouped from './ProductGrouped'

export default function ProductIconType(props) {
  const { type, ...rest } = props

  switch (type) {
    case 'hybrid':
      return <ProductHybrid {...rest} />

    case 'data':
      return <ProductData {...rest} />

    case 'minutes':
      return <ProductMinutes {...rest} />

    case 'sms':
      return <ProductSMS {...rest} />

    case 'grouped': 
      return <ProductGrouped {...rest} />

    default:
      return <ProductHybrid {...rest} />
  }
}
