import content from '@sart/content'

export default function billingModePrettyName(name) {
  switch (name) {
    case 'PREPAID':
      return content.tariff.prepaid
    case 'POSTPAID':
      return content.tariff.monthly
    case undefined:
      return ''
    default:
      return name
  }
}
