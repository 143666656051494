import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Redirect } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Headline,
  Subhead,
  Space,
  Select,
  Button,
  SelectExtended,
} from '@sart/components'
import { useContent } from '@sart/content'
import client from '@sart/client'
import { prettyErrorMessage, testID } from '@sart/utilities'
import { colors, typography } from '@sart/styles'

import PersonalDetailsSummary from '../Agent/PersonalDetailsSummary'

export default function LostForm(props) {
  const { history } = props

  const content = useContent()

  const personalDetails = client.cache.customer && client.cache.customer.personalDetails

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().trim().required(content.forms.required),
    }),
    onSubmit: handleSubmit
  })

  async function handleSubmit(values, form) {
    form.setSubmitting(true)

    try {
      const extendedData = values
      
      const dispatchSimProducts = await client.callApi('catalog','getDispatchSimProducts',[client.cache.catalogId])
      const dispatchSimProduct = dispatchSimProducts.find((product) => product.productLegacyId === "VADSA-REPLACEMENT-SIM")
      

      if (!dispatchSimProduct){
        throw new Error("not found dispatch sim product")
      }

      var order = await client.checkoutServiceProduct(dispatchSimProduct, extendedData)

      if (order.status === "successful"){
        history.push('/lost/confirmation')
      } else {
        //order failed, throw an exception to be caught
        throw new Error(content.menu.orderFailed)
      }
    } catch (error) {
      const message = prettyErrorMessage(error, 'lost', content)
      form.setFieldError('reason', message)
      form.setSubmitting(false)
    }
  }

  if (!personalDetails) {
    return <Redirect to="/port" />
  }

  return (
    <View style={styles.container}>
      <View>
        <Headline style={styles.header}>{content.lost.header}</Headline>
        <Subhead style={styles.subheader}>{content.lost.subheader}</Subhead>
      </View>

      <Space height={24} />

      <PersonalDetailsSummary
        personalDetails={personalDetails}
      />

      <Space height={24} />

      <View style={styles.content}>
        <SelectExtended
          label={content.lost.reasonLabel}
          value={formik.values.reason}
          placeholder={content.lost.reasonPlaceholder}
          options={[
            { label: content.lost.reasonLost, value: 'lost' },
            { label: content.lost.reasonStolen, value: 'stolen' },
            { label: content.lost.reasonDamaged, value: 'damaged' },
          ]}
          onOptionChange={option => {
            formik.handleChange('reason')(option.value)
          }}
          onBlur={formik.handleBlur('reason')}
          error={formik.touched.reason && formik.errors.reason}
          labelStyle={styles.labelStyles}
          styles={selectStyle}     
          selectId={"lostFormSelect"}     
        />

        <Space height={24} />

        <Button
          title={
            formik.isSubmitting
              ? content.common.sending
              : content.common.submit
          }
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          onPress={formik.handleSubmit}
          style={styles.enabled}
          disabledStyle={styles.disabled}
          titleStyle={{...typography.extrabold, fontSize: 18}}
          {...testID("lostFormSubmit")}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 600,
    paddingTop: 48,
    width: '100%',
    minWidth: 480,
    maxWidth: 840,
    paddingHorizontal: 24,
    alignSelf: 'center'
  },
  content: {
    width: 336,
    alignSelf: 'center'
  },
  header: {
    textAlign: 'center',
    color: colors.exisBlue,
    ...typography.demi
  },
  subheader: {
    textAlign: 'center',
  },
  center: {
    alignItems: 'center'
  },
  details: {
    fontSize: 15,
    lineHeight: 24,
    fontWeight: '500',
    color: colors.feature,
  },
  labelStyles: {
    ...typography.demi
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  }
})


const selectStyle = {
  control: (base) => ({
    ...base,
    ...typography.medium,
  }),
  menu: (base) => ({
    ...base,
    ...typography.medium,
  })
};
