import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

export default function Camera(props) {
  const { color } = props;
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={color} fillRule="nonzero">
        <Path d="M0 0h24v24H0V0z" fill="none" />
        <Path d="M14.12 4l1.83 2H20v12H4V6h4.05l1.83-2h4.24M15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2zm-3 7c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z" />
      </G>
    </Svg>
  )
}