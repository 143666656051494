import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import { colors, typography } from '@sart/styles'
import { useContent } from '@sart/content'
import { Text, Space, Anchor } from '@sart/components'

import { GooglePlay, AppleStore, GuruThumbsUp, GuruJoy } from '@sart/icons'

import client, { useClient } from '@sart/client'
import { testID } from '@sart/utilities'


import {
  isMobileOnly
} from "react-device-detect";

export default function CheckoutConfirmation(props) {
  const { history } = props

  const content = useContent()

  const { orderMessage, hasSimOrder, eSIMDispatch } = client.cache

  const [eSimJourney, setESimJourney] = useState(false)

  useEffect(() => {
    setESimJourney(eSIMDispatch)

    client.unsetCache('selectedPlan')
    client.unsetCache('customerDetails')
    client.unsetCache('existingSIM')
    client.unsetCache('promoPlans')
    client.unsetCache('cachedBasket')
    client.unsetCache('referralCode')
    client.unsetCache('eSIMDispatch')
  }, [])

  function handleReturn() {
    history.push('/')
  }

  function openLink() {
    window.open('https://exis.mx/eSIM/', '_blank')
  }
  
  function openYoutube() {
    window.open('https://www.youtube.com/@exis_mx', '_blank')
  }
  
  const displayOrderMessage = orderMessage ? orderMessage.substring(12) : ''
  return (
    <View style={styles.container} {...testID("checkoutConfirmation")}>
      {hasSimOrder &&
        <View style={styles.textContainer}>
          <Text style={styles.title}>{content.checkout.orderPendingShippingWithSim}</Text>
          <Text {...testID('orderMessageWithSim')} style={styles.subtitle}>{content.checkout.yourNewNumberIs} {displayOrderMessage}</Text>
          <GuruThumbsUp width={isMobileOnly ? 170 : 220} height={isMobileOnly ? 150 : 181} />
          <Space height={24}/>
          <Text style={styles.subtitle}>{content.checkout.orderProcessedShortly}</Text>
          <Space height={8}/>
          <Text style={styles.message}>
            
            {content.checkout.orderPendingShippingInfoWithSim1}
            <Anchor
              title={content.checkout.orderPendingShippingInfoWithSim2}
              onPress={() => openYoutube()}
              style={styles.youtubeLink}
            />
            {content.checkout.orderPendingShippingInfoWithSim3}
            <Anchor
              title={content.checkout.orderPendingShippingInfoWithSim4}
              onPress={() => openLink()}
              style={styles.link}
            />
            {content.checkout.orderPendingShippingInfoWithSim5}
            {content.checkout.orderPendingShippingInfoWithSim6}

          </Text>
        </View>
      }
      {!hasSimOrder && eSimJourney &&
      <View style={styles.textContainer}>
        <GuruJoy width={isMobileOnly ? 170 : 220} height={isMobileOnly ? 150 : 181} />
        <Space height={isMobileOnly ? 24 : 48} />
        <Text style={styles.title}>{content.checkout.orderPendingShippingNoSim}</Text>
        <Space height={4} />
        <Text style={styles.subtitle} {...testID('orderMessage')}>{content.checkout.yourNewNumberIs} {displayOrderMessage}</Text>
        <Space height={12} />
        <Text style={styles.message}>{content.checkout.orderPendingShippingInfoeSim}</Text>
      </View>
      }

      {!hasSimOrder && !eSimJourney &&
        <View style={styles.textContainer}>
          <GuruJoy width={isMobileOnly ? 170 : 220} height={isMobileOnly ? 150 : 181} />
          <Space height={isMobileOnly ? 24 : 48} />
          <Text style={styles.title}>{content.checkout.orderPendingShippingNoSim}</Text>
          <Space height={4} />
          <Text style={styles.subtitle} {...testID('orderMessage')}>{content.checkout.yourNewNumberIs} {displayOrderMessage}</Text>
          <Space height={12} />
          <Text style={styles.message}>{content.checkout.orderPendingShippingInfoNoSim}</Text>
        </View>
      }

      <Space height={isMobileOnly ? 16 : 48} />
  
      <View style={styles.iconsrow}>
        <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=uk.co.separatereality.vadsa.mobile.myExis', '_blank')}>
          <GooglePlay />
        </TouchableOpacity>
        <Space width={6} />
        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/us/app/exis/id1540564347', '_blank')}>
          <AppleStore />
        </TouchableOpacity>
      </View>

      <Space height={isMobileOnly ? 0 : 48} />

      {/* <View style={styles.footer}>
        <Anchor title={content.navigation.returnHome} onPress={handleReturn} />
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    fontSize: isMobileOnly ? 16 : 20,
    ...typography.bold,
    textAlign: 'center'
  },
  subtitle: {
    color: colors.black,
    fontSize:  isMobileOnly ? 16 :20,
    ...typography.bold,
    // textAlign: 'center'
  },
  container: { 
    flex: 1, 
    alignItems: 'center',
    minHeight: isMobileOnly ? 480 : 540,
    paddingTop: isMobileOnly ? 0 : 48,
    width: '100%',
    backgroundColor: colors.exisBlue
  },
  iconsrow: { 
    flexDirection: 'row', 
    justifyContent: 'center' 
  },
  message: { 
    fontSize:  isMobileOnly ? 12 : 15, 
    lineHeight: 24, 
    textAlign: 'center',
    color: colors.white,
    ...typography.lightbold 
  },
  footer: { 
    alignItems: 'center' 
  },
  textContainer: {
    alignItems: 'center', 
    width: isMobileOnly ? '90%' : '90%',
    ...(isMobileOnly ? 
      
      { //Mobile Only
        
      } 
      : 
      { //Non-Mobile Only
         minWidth: 500
      }
    ),  
  },
  youtubeLink: {
    fontSize:  isMobileOnly ? 12 : 16, 
    lineHeight: 24, 
    textAlign: 'center',
    color: colors.white,
    ...typography.demi,
  },
  link: {
    fontSize:  isMobileOnly ? 12 : 16, 
    lineHeight: 24, 
    textAlign: 'center',
    color: colors.white,
    ...typography.demi,
    textDecorationLine: 'underline'
  }
})
