import React from 'react'
import Svg, { Rect } from "svgs"
import PropTypes from 'prop-types'

import { backgroundColors } from '@sart/styles'

export default function HorizontalLine(props) {
  const { color, thickness, ...rest } = props;

  return (
    <Svg
      viewBox="0 0 96 1"
      preserveAspectRatio="none"
      width="100%"
      height={thickness}
      stroke={color}
      {...rest}
    >
      <Rect
        x="0"
        y="0"
        width="96"
        height="1"
      />
    </Svg>
  )
}

HorizontalLine.defaultProps = {
  thickness: 1,
  color: backgroundColors.subtle
}

HorizontalLine.propTypes = {
  thickness: PropTypes.number,
  color: PropTypes.string
}
