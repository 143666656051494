import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Redirect } from 'react-router-dom'

import client from '@sart/client'
import { useContent } from '@sart/content'
import { Headline, Subhead } from '@sart/components'
import { backgroundColors, typography } from '@sart/styles'
import { testID } from '@sart/utilities';

import Wrapper from '../Wrapper'
import PlanItem from './PlanItem'

import {
  isMobileOnly
} from "react-device-detect";

export default function Plan(props) {
  const { history, match } = props

  const content = useContent()

  const { params: { id: productLegacyId } = {} } = match

  const plans = client.cache.plans

  async function submitPlanToBasket(plan) {
    client.setCache({ selectedPlan: plan, promoPlan: promoPlan })
    await client.createNCABasket(plan)
    history.push('/checkout/customer')
  }


  if (!productLegacyId) {
    return <Redirect from={match.url} to="/plans" />
  }

  if (!plans) {
    return <Redirect from={match.url} to="/plans" />
  }

  const allPlans = [
    ...plans.recurring,
    ...plans.oneoff
  ]

  const plan = allPlans.find(
    plan => plan.productLegacyId === productLegacyId
  )

  if (!plan) {
    return <Redirect from={match.url} to="/plans" />
  }
  
  let promoPlan = []

  return (
    <Wrapper wrapperStyle={styles.wrapper} style={styles.container}>
      <View style={styles.headers}>
        <Headline style={styles.headerText}>{content.plans.header}</Headline>
        <Subhead style={styles.subheaderText}>{content.plans.subheader}</Subhead>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <PlanItem
          plan={plan}
          onPress={() => submitPlanToBasket(plan)}
          link={true}
          {...testID('PlanItem')}
        />
      </View>
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: backgroundColors.exisBlue
  },
  container: {
    minHeight: 720,
    paddingTop: 48,
  },
  headers: {
    alignItems: 'center',
  },
  headerText: {
    ...typography.bold,
    fontSize: isMobileOnly ? 26 : 36,
    textAlign: 'center'
  },
  subheaderText: {
    ...typography.bold,
    fontSize: isMobileOnly ? 15 : 24,
    textAlign: 'center'
  }
})

