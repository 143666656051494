import * as React from "react";
import Svg, { G, Polyline } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

const WideUpArrow = (props) => (
  <Svg
    width="71px"
    height="18px"
    viewBox="0 0 71 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <G
      id="Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <G
        id="Planes-02"
        transform="translate(-1099.000000, 1.000000)"
        stroke="#FFFFFF"
        strokeWidth={2.521}
      >
        <G id="Header_Eliot_2" transform="translate(122.000000, 0.000000)">
          <Polyline
            id="Path"
            points="1046.122 15.5 1012.579 0.5 979.037 15.5"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default WideUpArrow;
