import moment from 'moment'
import ISO6391 from 'iso-639-1'

export function userFullname(u) {
  const names = [
    u.title,
    u.firstName,
    u.middleName,
    u.lastName
  ]

  return names
    .filter(v => !!v)
    .join(' ')
}

export function userFullAddress(u) {
  const lines = [
    u.address1,
    u.address2,
    u.city,
    u.county,
    u.postcode,
    u.countryOfResidence,
  ]

  return lines
    .filter(v => !!v)
    .join('\n')
}

export function userGenderFormated(u) {
  switch (u.gender) {
    case 'M':
      return 'Male'
    case 'F':
      return 'Female'
    case 'N':
    default:
      return 'Prefer not to say'
  }
}

export function userLanguageFormated(u) {
  return u.language ? ISO6391.getName(u.language) : 'English'
}

export function userDocumentFormated(u) {
  const lines = [
    u.idType,
    u.idNumber,
  ]

  return lines
    .filter(v => !!v)
    .join(', ')
}

export function userLongDOB(u) {
  try {
    return u.dob.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    })
  } catch (error) {
    return ''
  }
}

export function userShortDOB(u) {
  // 6/8/1984
  try {
    const date = new Date(u.dobYear, u.dobMonth, u.dobDay)

    return moment(date).format('DD/MM/YYYY')
  } catch (error) {
    return ''
  }
}

export function userHumanShortDOB(u) {
  // 6 Aug 1984
  try {
    const date = new Date(u.dob)

    return moment(date).format('D MMM YYYY')
  } catch (error) {
    return ''
  }
}


