import currencyJS from 'currency.js'

/*
import cc from 'currency-codes'
import getSymbol from 'currency-symbol-map'

function findCurrency(code) {
  try {
    const { digits, ...rest } = cc.code(code)
    const symbol = getSymbol(code)
    const precision = digits
    const format = symbol + (precision > 0 ? '.' : '') + '0'.repeat(precision)

    return {
      ...rest,
      symbol,
      precision,
      format
    }
  } catch (error) {
    return
  }
}
*/

// TODO: retrive this from API
// export const currentCurrency = {
//   code: 'MXN',
//   countries: [],
//   currency: 'Mexican Peso',
//   decimals: 2,
//   precision: 0,
//   format: '$0.00',
//   number: '484',
//   symbol: '$'
// }

const MXN = {
  code: 'MXN', 
  countries: [], 
  currency: 'Mexican Peso', 
  decimals: 2, 
  precision: 0,
  number: '484', 
  symbol: '$'
}

const BND = {
  code: 'BND', 
  countries: [], 
  currency: 'Bruneian Dollar', 
  decimals: 2, 
  precision: 2, 
  number: '484', 
  symbol: 'BND '
}

const GBP = {
  code: 'GBP', 
  countries: [], 
  currency: 'Pound Sterling', 
  decimals: 2, 
  precision: 2, 
  number: '484', 
  symbol: '£'
}


const USD = {
  code: 'USD', 
  countries: [], 
  currency: 'United States Dollar', 
  decimals: 2, 
  precision: 2, 
  number: '484', 
  symbol: '$'
}

export function formatCurrencyToString(value, currency) {
  var product = {}
  if(currency === 'MXN') {
    product = MXN
  } else if(currency === 'BND') {
    product = BND
  } else if(currency === 'GBP') {
    product = GBP
  } else if(currency === 'USD') {
    product = USD
  } else {
    
  }
  const { formated } = formatCurrencyToObject(value, product.code)

  return formated
}

export function formatCurrencyToObject(integer, currency) {

  var product = {}
  if(currency === 'MXN') {
    product = MXN
  } else if(currency === 'BND') {
    product = BND
  } else if(currency === 'GBP') {
    product = GBP
  } else if(currency === 'USD') {
    product = USD
  } else {
    product = BND
  }

  const diviser = Math.pow(10, product.decimals)
  const float = integer / diviser
  const formated = currencyJS(float, product).format()
  
  return {
    ...product,
    integer: integer,
    float: float,
    formated: formated
  }
}





