import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View, } from 'react-native'
import { withRouter } from 'react-router-dom'

import { useClient, client } from '@sart/client'
import { useContent } from '@sart/content'
import { HolFullLogo, HolSmallLogo, WideUpArrow } from '@sart/icons'
import { colors, backgroundColors, typography } from '@sart/styles'
import chroma from 'chroma-js'

import LinearGradient from 'react-native-linear-gradient';

import {
    isMobileOnly
} from "react-device-detect";

function EliotExternalLinkBar(props) {
    const { history } = props

    const { cache } = useClient()

    const content = useContent()

    function handleRouting() {
        window.open('http://hol.com.mx', '_blank')
    }

    return (
        <LinearGradient style={styles.container} locations={[0.75,1]} colors={[colors.eliotPurple, '#000000']} start={{x: 0, y: 0}} end={{x: 1, y: 0}}>

            <HolFullLogo />

            <TouchableOpacity onPress={() => handleRouting()}>
                <WideUpArrow />
            </TouchableOpacity>   

            <HolSmallLogo />

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    container: {
        // backgroundColor: colors.eliotPurple,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingHorizontal: '2%',
        height: 50
    },
})

export default withRouter(EliotExternalLinkBar)