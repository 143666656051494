import React from 'react'
import PropTypes from 'prop-types'

import Svg, { Line, Circle } from 'svgs'
import { colors } from '@sart/styles'

const Voicemail = props => {
  const { color, size, ...otherProps } = props
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle cx="5.5" cy="11.5" r="4.5" />
      <Circle cx="18.5" cy="11.5" r="4.5" />
      <Line x1="5.5" y1="16" x2="18.5" y2="16" />
    </Svg>
  )
}

Voicemail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Voicemail.defaultProps = {
  color: colors.feature,
  size: '24'
}

export default Voicemail
