import React from 'react'

// import PropTypes from 'prop-types'
import Svg, { Path, Rect } from 'svgs';

import { colors, backgroundColors } from '@sart/styles'

export default function SIM(props) {
  const { active } = props

  return (
    <Svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill={active ? '#FFD79A' : colors.subtle}
      stroke={active ? '#434343' : colors.trivial}
      {...props}
    >
      <Rect x=".5" y=".5" width="31" height="23" rx="4" />
      <Path fill="none" d="M21.929 17.87v5.63H10.328v-5.63M12.869 15.268h6.518M10.328 6.268V.5h11.6v5.768M19.388 8.869h-6.519"/>
      <Path fill="none" d="M21.595 6.268H31.5v11.601h-9.905a2.207 2.207 0 01-2.208-2.207V8.475c0-1.219.989-2.207 2.208-2.207zM19.388 12.069L31.5 12M.5 6.268h10.162c1.219 0 2.207.988 2.207 2.207v7.187a2.207 2.207 0 01-2.207 2.207H.5V6.268zM.5 12l12.369.069"/>
    </Svg>
  )
}

SIM.defaultProps = {
  active: false
}
