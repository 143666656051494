import { useContent } from '@sart/content'

export default function formatSingularOrPluralDateUnit(unitAmount, dateUnit) {

    const content = useContent()

    var number = parseInt(unitAmount);
// || dateUnit === "Days" || dateUnit === "Weeks")
    if ((number > 1) && (dateUnit === "Days")) {
        return content.planitem.days;
    } else if ((number > 1) && (dateUnit === "Weeks")) {
        return content.planitem.weeks;
    } else if ((number > 1) && (dateUnit === "Months")) {
        return content.planitem.months;
    }  else if (dateUnit === "Days"){
        return content.planitem.day;
    } else if (dateUnit === "Weeks") {
        return content.planitem.week
    } else if (dateUnit === "Months"){
        return content.planitem.month;
    }
}
