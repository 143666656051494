import React from 'react'

// import CreditCard from './CreditCard'
import UnknownCard from './UnknownCard'

import Visa from './Visa'
import Maestro from './Maestro'
import MasterCard from './MasterCard'
import AmericanExpress from './AmericanExpress'
import SIM from './SIM'
import Paypal from './Paypal'
import CashIcon from './CashIcon'

export default function CreditCartType(props) {
  const { type, color, ...rest } = props

  switch (type) {
    case 'paypal':
      return <Paypal width="28" height="22" {...rest} />

    case 'visa':
      return <Visa width="28" height="22" {...rest} />

    case 'CREDITO/BANCO EXTRANJERO/Visa':
      return <Visa width="28" height="22" {...rest} />

    case 'maestro':
      return <Maestro width="28" height="22" {...rest} />

    case 'mastercard':
      return <MasterCard width="28" height="22" {...rest} />

    case 'CREDITO/BANCO EXTRANJERO/MasterCard':
      return <MasterCard width="28" height="22" {...rest} />

    case 'american-express':
      return <AmericanExpress width="28" height="22" {...rest} />

    case 'balance':
    case 'sim':
        return <SIM {...rest} width="28" height="22" {...rest}/>

    case 'cash':
        return <CashIcon {...rest} width="28" height="22" {...rest} />

    default:
      return <UnknownCard color={color} {...rest} />
  }
}
