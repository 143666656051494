import { Dimensions } from 'react-native'
// import { colors } from '@sart/styles'

/*
TODO: adaptive text

window < 312    Month
window > 312    Exp Month
window > 480    Expiration Month
*/

export const small = 'small'
export const medium = 'medium'
export const large = 'large'
export const extralarge = 'extralarge'
export const other = 'other'
export const extrasmall = 'extrasmall'

export const list = [small, medium, large, extralarge, other, extrasmall]

export const defaultSize = (() => {
  const window = Dimensions.get('window')

  if (window.scale >= 3) {
    return large
  }

  if (window.scale > 1 || window.scale < 3) {
    return medium
  }

  if (window.scale <= 1) {
    return small
  }
})()

export const defaultSizePortal = (() => {
  const window = Dimensions.get('window')
  if (window.width > 1900) {
    return large
  }

  if (window.width <= 1900 && window.width >= 1500) {
    return medium
  }

  if (window.width < 1500) {
    return small
  }
})()

export const defaultHeight = (() => {
  const window = Dimensions.get('window')

  if (window.height > 820) {
    return extralarge
  }

  if (window.height > 700 && window.height < 820) {
    return large
  }

  if (window.height > 600 && window.height < 700) {
    return medium
  }

  if (window.height > 490 && window.height <= 600) {
    return small
  }

  if(window.height <= 490) {
    return extrasmall
  }

})()

export const defaultWidth = (() => {
  const window = Dimensions.get('window')

  if (window.width >= 380) {
    return large
  }

  if (window.width > 330 && window.width < 380) {
    return medium
  }

  if (window.width < 330) {
    return small
  }
})()

//these measurements are in UIKit Size (points) see
//https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/Displays/Displays.html
export const iphoneDeviceHeight = (() => {
  const window = Dimensions.get('window')
// 11 Pro Max, 11
  if (window.height == 896) {
    return extralarge
  }
// iPhone X, 11 Pro
  if (window.height == 812) {
    return large
  }  
// iPhone 6s Plus, 7 Plus, 8 Plus
  if (window.height == 736) {
    return medium
  }
// iPhone 5s, SE
  if (window.height == 568) {
    return small
  }

  else {
    return other
  }
})()

export function selectStyleByDefaultSize(styles) {
  return styles[defaultSize]
}

export function selectStyleByDefaultHeight(styles) {
  return styles[defaultHeight]
}

export function selectStyleByDefaultDeviceHeight(styles) {
  return styles[iphoneDeviceHeight]
}

export function selectStyleByDefaultPortalSize(styles) {
  return styles[defaultSizePortal]
}

export function selectStyleByDefaultWidth(styles) {
  return styles[defaultWidth]
}

export const wrapperPadding = selectStyleByDefaultSize({
  small: {
    paddingLeft: 8,
    paddingRight: 8
  },
  medium: {
    paddingLeft: 12,
    paddingRight: 12
  },
  large: {
    paddingLeft: 24,
    paddingRight: 24
  },
  extralarge: {
    paddingLeft: 24,
    paddingRight: 24
  }
})

export const planItemHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 80
  },
  small: {
    height: 85,
  },
  medium: {
    height: 160,
  },
  large: {
    height: 120,
  },
  extralarge: {
    height: 240
  }
})

export const planItemUnitPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 0
  },
  small: {
    paddingTop: 0
  },
  medium: {
    paddingTop: 20
  },
  large: {
    paddingTop: '5%'
  },
  extralarge: {
    paddingTop: 60
  }
})

export const planItemLabelPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 0
  },
  small: {
    paddingTop: 5
  },
  medium: {
    paddingTop: 15
  },
  large: {
    paddingTop: '10%'
  },
  extralarge: {
    paddingTop: 40
  }
})

export const planItemPercentage = selectStyleByDefaultHeight({
  extrasmall: {
    height: '100%'
  },
  small: {
    height: '100%',
  },
  medium: {
    height: '100%',
  },
  large: {
    height: '100%',
  },
  extralarge: {
    height: '100%',
  }
})

export const planItemFontSize = selectStyleByDefaultHeight({
  extrasmall: {
    fontSize: 36
  },
  small: {
    fontSize: 45,
  },
  medium: {
    fontSize: 70,
  },
  large: {
    fontSize: 70,
  },
  extralarge: {
    fontSize: 77
  }
})

export const planItemPricePadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 0,
    paddingBottom: 0  
  },
  small: {
    paddingTop: 0,
    paddingBottom: 0
  },
  medium: {
    paddingTop: 0,
    paddingBottom: 0
  },
  large: {
    paddingTop: 0,
    paddingBottom: 0
  },
  extralarge: {
    paddingTop: 35,
    paddingBottom: 10
  }
})

export const spaceHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 4
  },
  large: {
    height: 20
  },
  extralarge: {
    height: 22
  }
})

export const spaceHeightHelp = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 20
  },
  large: {
    height: 32
  },
  extralarge: {
    height: 32
  }
})

export const spaceHeightTwo = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 0
  },
  large: {
    height: 20
  },
  extralarge: {
    height: 22
  }
})

export const spaceHeightRegisterScreenOne = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 0
  },
  large: {
    height: 0
  }, 
  extralarge: {
    height: 80
  }
})

export const spaceHeightRegisterScreenTwo = selectStyleByDefaultHeight({
  extrasmall: {
    height: 5
  },
  small: {
    height: 5
  },
  medium: {
    height: 0
  },
  large: {
    height: 10
  }, 
  extralarge: {
    height: 30
  }
})

export const spaceHeightRegisterScreenThree = selectStyleByDefaultHeight({
  extrasmall: {
    height: 5
  },
  small: {
    height: 5
  },
  medium: {
    height: 10
  },
  large: {
    height: 20
  }, 
  extralarge: {
    height: 24
  }
})

export const spaceHeightLoginScreenOne = selectStyleByDefaultHeight({
  extrasmall: {
    height: 8
  },
  small: {
    height: 8
  },
  medium: {
    height: 8
  },
  large: {
    height: 24
  }, 
  extralarge: {
    height: 40
  }
})

export const spaceHeightLoginScreenTwo = selectStyleByDefaultHeight({
  small: {
    height: 5
  },
  medium: {
    height: 10
  },
  large: {
    height: 24
  }, 
  extralarge: {
    height: 30
  }
})

export const spaceHeightLoginScreenTop = selectStyleByDefaultHeight({
  extrasmall: {
    height: 10
  },
  small: {
    height: 10
  },
  medium: {
    height: 0
  },
  large: {
    height: 0
  }, 
  extralarge: {
    height: 0
  }
})

export const planItemSpace = selectStyleByDefaultHeight({
  extrasmall: {
    height: '0%'
  },
  small: {
    height: '0%'
  },
  medium: {
    height: '2%'
  },
  large: {
    height: '6%'
  }, 
  extralarge: {
    height: '6%'
  }
})

export const planTitleSpace = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 0
  },
  large: {
    height: 0
  }, 
  extralarge: {
    height: 8
  }
})

export const logoSplashScreenSize = selectStyleByDefaultDeviceHeight({
  extralarge: {
    width: '36%',
    height: '36%',
    // color: colors.exisOnTint,
  },
  large: {
    width: '37%',
    height: '37%',
    // color: colors.exisOnTint,
  },
  medium: {
    width: '33%',
    height: '33%',
    // color: colors.exisOnTint,
  },
  small: {
    width: '34%',
    height: '34%',
    // color: colors.exisOnTint,
  },
  other: {
    height: '33%',
    width: '33%',
    // color: colors.exisOnTint,
  }
})

export const logoSize = selectStyleByDefaultHeight({
  extralarge: {
    height: '25%',
    width:  '25%',
  },
  large: {
    height: '25%',
    width: '25%'
  },
  medium: {
    height: '33%',
    width: '33%'
  }, 
  small: {
    height: '25%',
    width: '25%'
  },
  extrasmall: {
    height: '20%',
    width: '20%'
  }
})

export const logoPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 10
  },
  small: {
    paddingTop: 10
  },
  medium: {
    paddingTop: 40 
  },
  large: {
    paddingTop: 40
  },
  extralarge: {
    paddingTop: 40
  }
})

export const contentHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 580
  },
  small: {
    height: 650
  },
  medium: {
    height: 800
  },
  large: {
    height: 850
  },
  extralarge: {
    height: 950
  }
})

export const loginContentHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 600
  },
  small: {
    height: 640
  },
  medium: {
    height: 650
  },
  large: {
    height: 740
  },
  extralarge: {
    height: 800
  }
})

export const passwordContentHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 500
  },
  small: {
    height: 500
  },
  medium: {
    height: 550
  },
  large: {
    height: 580
  },
  extralarge: {
    height: 600
  }
})

export const navigationBarFontSize = selectStyleByDefaultHeight({
  extrasmall: {
    fontSize: 14
  },
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 14,
  },
  large: {
    fontSize: 15,
  },
  extralarge: {
    fontSize: 15,
  }
})

export const webViewWidth = selectStyleByDefaultHeight({
  extrasmall: {
    height: 320
  },
  small: {
    width: 320
  },
  medium: {
    width: 325
  },
  large: {
    width: 345
  },
  extralarge: {
    width: 360
  }
})

export const bottomNavigationBarHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 80
  },
  small: {
    height: 80
  },
  medium: {
    height: 80
  },
  large: {
    height: 90
  },
  extralarge: {
    height: 95
  }
})

export const tabsFontSize = selectStyleByDefaultHeight({
  extrasmall: {
    fontSize: 16
  },
  small: {
    fontSize: 16
  },
  medium: {
    fontSize: 16
  },
  large: {
    fontSize: 16
  },
  extralarge: {
    fontSize: 16
  }
})

export const portalSpaceWidth = selectStyleByDefaultPortalSize({
  small: {
    width: '6%'
  },
  medium: {
    width: '8%'
  },
  large: {
    width: '9%'
  },
})

export const plansTopPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 0
  },
  small: {
    paddingTop: 0
  },
  medium: {
    paddingTop: 0
  },
  large: {
    paddingTop: 12
  },
  extralarge: {
    paddingTop: 12
  }
})

export const switchBottomPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingBottom: 0
  },
  small: {
    paddingBottom: 0
  },
  medium: {
    paddingBottom: 0
  },
  large: {
    paddingBottom: 20
  },
  extralarge: {
    paddingBottom: 20
  }
})

export const menuSpaceHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    height: 0
  },
  medium: {
    height: 0
  },
  large: {
    height: 0
  },
  extralarge: {
    height: 80
  }
})

export const menuPaddingTop = selectStyleByDefaultHeight({
  extrasmall: {
    height: 0
  },
  small: {
    paddingTop: 0
  },
  medium: {
    paddingTop: 0
  },
  large: {
    paddingTop: 72
  }, 
  extralarge: {
    paddingTop: 72
  }
})

export const plansHeightPercentage = selectStyleByDefaultHeight({
  small: {
    height: '0%'
  },
  medium: {
    height: '1%'
  },
  large: {
    height: '1%'
  }, 
  extralarge: {
    height: '4%'
  }
})

export const fontSize = selectStyleByDefaultWidth({
  small: {
    fontSize: 11,
  },
  medium: {
    fontSize: 11.5,
  },
  large: {
    fontSize: 13,
  }, 
})

export const guruSize = selectStyleByDefaultHeight({
  extrasmall: {
    height: 110,
    width:  '25%',
  },
  small: {
    height: 150,
    width:  '25%',  
  },
  medium: {
    height: 200,
    width:  '25%',
  },
  large: {
    height: 200,
    width:  '25%',
  }, 
  extralarge: {
    height: 200,
    width:  '25%',
  }
})

export const noPlanContainerHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: 150
  },
  // small: {
  //   height: 200  
  // },
  // medium: {
  //   height: 200 
  // },
  // large: {
  //   height: 200
  // }, 
  // extralarge: {
  //   height: 200
  // }
})

export const planTitleLineHeight = selectStyleByDefaultHeight({
  extrasmall: {
    lineHeight: 0
  },
  small: {
    lineHeight: 36  
  },
  medium: {
    lineHeight: 36 
  },
  large: {
    lineHeight: 36
  }, 
  extralarge: {
    lineHeight: 36
  }
})

export const switchContainerPadding = selectStyleByDefaultHeight({
  extrasmall: {
    paddingTop: 0,
    paddingBottom: 0
  },
  small: {
    paddingTop: 5,
    paddingBottom: 0
  },
  medium: {
    paddingTop: 5 ,
    paddingBottom: 0 
  },
  large: {
    paddingTop: 5,
    paddingBottom: 20
  }, 
  extralarge: {
    paddingTop: 5,
    paddingBottom: 20
  }
})

export const planButtonHeight = selectStyleByDefaultHeight({
  extrasmall: {
    height: '12%'
  },
  // small: {
  //   height: 200  
  // },
  // medium: {
  //   height: 200 
  // },
  // large: {
  //   height: 200
  // }, 
  // extralarge: {
  //   height: 200
  // }
})

export const switchLabelFontSize = selectStyleByDefaultHeight({
  extrasmall: {
    fontSize: 14
  },
  small: {
    fontSize: 18
  },
  medium: {
    fontSize: 18
  },
  large: {
    fontSize: 18
  }, 
  extralarge: {
    fontSize: 18
  }
})