import { default as defaultContent} from '@sart/content'

import get from 'lodash/get'

export default function prettyErrorFromOrder(order, content = defaultContent) {

  const messageCode = order.messageCode
  const orderMessage = order.message
  
  // search for the message code in content, then use the mapping to get the translation
  if(messageCode) {
    const mapping = get(content,['errorMapping',messageCode])
    const message = get(content, mapping)

    if(message) {
      return message
    } else {
      //there's (currently) no mapping for this error log it out and return the message on the order
      console.error(`No mapping found for order fail code: ${messageCode} orderMessage: ${orderMessage}`)
    }
  }

  //Maybe in the future we could go through the items on the order looking for errror messages?
  //There might be error codes on each of the items on the order (customer,account and service level)
  // This is probably more of a nice to have though right now

  return orderMessage

}