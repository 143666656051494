import React, { useState, useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'

import {
  Text,
  Space,
  Button
} from '@sart/components'
import {
  colors,
  typography
} from '@sart/styles'
import { useContent } from '@sart/content'
import { EliotPlanName } from '@sart/icons'
import { getWebSafeProductImage, testID } from '@sart/utilities'
import client from '@sart/client'

import {
  isMobileOnly
} from "react-device-detect";

export default function PlanItemEliot(props) {
  const { plan, link } = props
  // currency "GBP"
  // localData 1
  // localDataUnit "GB"
  // recurringCost 750
  // validity 24
  // validityUnit "Months"
  // recurringFrequency 30
  // validityUnit "Months"
  useEffect(() => {
    getImage()
  }, [])
  
  const content = useContent()
  const [image, setImage] = useState(undefined)
  const [imageLoading, setImageLoading] = useState(false)
  let productDisplayName

  if (plan.localDataUnit === 'MB' && plan.localData >= 1000) {
    plan.localData = plan.localData / 1000
    plan.localDataUnit = 'GB'
  }

  if (plan.productName) {
    productDisplayName = plan.productName.replaceAll("Plan", "Plan\n")
    productDisplayName = productDisplayName.replaceAll("Recurrente", "\nRecurrente")
  }

  function getDisplayDetailsForLanguage(product) {
    //Get the display details for the language that is selected in the cache
    let displayDetails = undefined

    //identify the correct display details to use based on language
    if (product.displayDetails) {
      product.displayDetails.map(detail => {
        if (detail.language === client.cache.language) {
          displayDetails = detail
        }
      })
    }

    return displayDetails
  }

  function getColourByCategory(displayDetails, category) {
    if (!category) {
      return undefined
    }

    let toReturn = undefined
    const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

    //get the relevant font colour based on the category
    if (displayDetails && displayDetails.colors && displayDetails.colors.length) {
      displayDetails.colors.map(color => {
        //we also only accept hex values, so use regex to make sure it matches the pattern
        if (color.category === category && hexCodeRegex.test(color.color)) {
          toReturn = color.color
        }
      })
    }

    return toReturn
  }

  const displayDetails = getDisplayDetailsForLanguage(plan)

  async function getImage() {
    try{
      setImageLoading(true)
      const productImage = await getWebSafeProductImage(displayDetails, 'AltBackground')
      setImage(productImage)
      setImageLoading(false)
    }
    catch(error) {
      setImageLoading(false)
    }
  }

  // const backgroundColour = getColourByCategory(displayDetails, 'Background')
  const accentColour = getColourByCategory(displayDetails, 'AltAccent')

  const actionColour = accentColour || colors.exisOrange

  return ([
    <View>
      {/* {!imageLoading && */}
      {/* <Button
              title={content.planitem.action}
              titleStyle={styles.buttonTitle}
              style={[styles.button, { backgroundColor: actionColour }]}
              onPress={props.onPress}
              {...testID('planItemEliotButton')}
            /> */}
        <div
          style={{
            backgroundImage: !imageLoading ? `url(data:image/svg+xml;charset=utf-8,${image})` : <View/>,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            height: '100%',
            width: '250px',
            marginLeft: isMobileOnly && !link ? '14%' : '0%'
          }}>

          {/* <EliotCost fontFamily={'AvertaRegular'} style={{marginLeft: '80px', marginTop: '8%'}} cost={plan.upfrontCost / 100} colour={actionColour} /> */}
          <EliotPlanName style={{ marginLeft: '80px', marginTop: '12%' }} colour={actionColour} name={productDisplayName} />
          {imageLoading &&
            <ActivityIndicator size="large" color="blue" style={{ height: 300, width: 300, justifyContent: 'center' }} />
          }
          <View style={styles.buttonLinkContainer}>
            {link &&
              <View>
                <Space height={8} />
                <Text style={[styles.link, { color: actionColour }]} onPress={() => window.open('https://crm.altanredes.com/preactiva/checkimei_websiteone.php', '_blank')}>{content.plans.link}</Text>
                <Space height={8} />
              </View>
            }

            <Button
              title={content.planitem.action}
              titleStyle={styles.buttonTitle}
              style={[styles.button, { backgroundColor: actionColour }]}
              onPress={props.onPress}
              {...testID([plan.productName.replace(/\s/g, ""), 'Plan'].join(''))}
              />

            <Space height={8} />

            <Text style={styles.moreBenefitsText}>{content.planitem.plusBenefits}<Text style={styles.communityText}> {content.planitem.community}</Text><Text style={styles.holText}> {content.planitem.hol}</Text></Text>

            <Space height={8} />

          </View>


        </div>
      {/* } */}
    </View>
  ])
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    maxWidth: isMobileOnly ? '100%' : 280,
    alignItems: 'center',
    minWidth: 192,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8,
    height: '100%',
    width: '100%'
  },
  text: {
    ...typography.book
  },
  container: {
    flex: 1,
    maxWidth: 280,
    alignItems: 'center',
    minWidth: 192,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8,
    backgroundColor: colors.exisOnTint,
  },
  label: { lineHeight: 24, color: colors.exisDarkCharcoal },
  value: {
    fontSize: 108,
    fontWeight: '700',
    lineHeight: 96,
    color: colors.exisOnTint
  },
  unit: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 8,
    width: 30,
    color: colors.exisOnTint
  },
  duration: {
    lineHeight: 24,
    color: colors.exisDarkCharcoal,
    marginTop: 20,
    ...typography.medium
  },
  price: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 24,
    color: colors.exisOnTint,
    ...typography.bold
  },
  productName: {
    lineHeight: 24,
    color: colors.black,
    fontWeight: 'bold',
    ...typography.demi
  },
  link: {
    textDecorationLine: 'underline',
    ...typography.medium,
    textAlign: 'center',
    fontFamily: 'AvertaRegular',
  },
  button: {
    borderRadius: 16,
    alignSelf: 'center',
    width: '50%',
  },
  buttonTitle: {
    fontFamily: 'AvertaRegular',
    fontWeight: 'bold'
  },
  buttonLinkContainer: {
    ...(isMobileOnly ?

      { //Mobile Only
        alignSelf: 'center',
        marginTop: '190%',
        // marginLeft: '25%',
      }
      :
      { //Non-Mobile Only
        alignSelf: 'center',
        marginTop: '190%',
        // marginLeft: '25%'
      }
    ),
  },
  moreBenefitsText: {
    color: colors.eliotPurple,
    fontSize: 16,
    fontFamily: 'AvertaRegular',
    textAlign: 'center'
  },
  communityText: {
    color: colors.eliotPurple,
    fontFamily: 'AvertaExtraBold',
    fontSize: 16,
  },
  holText: {
    color: colors.eliotYellow,
    fontFamily: 'AvertaExtraBold',
    fontSize: 16,
  }
})
