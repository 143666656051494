import React from 'react'
import Svg, { Path, Rect } from 'svgs'

export default function MasterCard(props) {
  return (
    <Svg wviewBox="0 0 32 24" width={28} height={22} {...props}>
      <Rect fill="#FFF" width="32" height="24" rx="4" />
      <Path
        fill="#FF5F00"
        d="M13.15 6.476h6.041v10.857H13.15z"
      />
      <Path
        d="M13.533 11.903a6.893 6.893 0 0 1 2.637-5.427 6.904 6.904 0 1 0 0 10.857 6.893 6.893 0 0 1-2.637-5.43z"
        fill="#EB001B"
      />
      <Path
        d="M27.34 11.903a6.904 6.904 0 0 1-11.17 5.43 6.904 6.904 0 0 0 0-10.857 6.904 6.904 0 0 1 11.17 5.427z"
        fill="#F79E1B"
      />
    </Svg>
  )
}
