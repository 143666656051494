import React from 'react'
import PropTypes from 'prop-types'
import Svg, { Defs, Path, G, Mask, Use } from 'svgs'


export default function Twitter(props) {
  return (
    <Svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="prefix__a" d="M0 .035h14.69v14.147H0z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M-896-2302H128V94H-896z" />
        <Path d="M-897-66H127v224H-897z" fill="#FFF" />
        <G transform="translate(0 .096)">
          <Mask id="prefix__b" fill="#fff">
            <Use xlinkHref="#prefix__a" />
          </Mask>
          <Path
            d="M7.345.035c4.057 0 7.346 3.167 7.346 7.074 0 3.906-3.29 7.073-7.346 7.073S0 11.015 0 7.109C0 3.202 3.289.035 7.345.035"
            fill="gray"
            mask="url(#prefix__b)"
          />
        </G>
        <Path
          d="M1.946 10.618a6.47 6.47 0 003.381.942c3.493 0 6.324-2.726 6.324-6.09a6.36 6.36 0 00-.005-.25c.126-.095.907-.698 1.099-1.086 0 0-.635.254-1.256.314h-.004l.004-.002c.057-.037.857-.561.965-1.186 0 0-.448.23-1.077.432a7.47 7.47 0 01-.325.097 2.253 2.253 0 00-1.62-.676c-1.226 0-2.219.956-2.219 2.135 0 .167.02.329.057.484-.17-.006-2.78-.147-4.554-2.217 0 0-1.06 1.394.632 2.828 0 0-.515-.02-.958-.275 0 0-.164 1.69 1.753 2.121 0 0-.377.138-.978.04 0 0 .336 1.364 2.039 1.492 0 0-1.347 1.171-3.258.897z"
          fill="#FFF"
        />
      </G>
    </Svg>
  )
}

Twitter.defaultProps = {
  width: 121,
  height: 154,
  color: '#3BA9EE'
}

Twitter.propTypes = {
  color: PropTypes.string
}
