import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import client from '@sart/client'
import en from './en'
import es from './es'

const languages = {
  en,
  es,
}

function useContent() {
  const language = useSelector(state => state.language)

  const content = useMemo(() => languages[language], [language])

  return content
}

function getContentHookless() {
  const language = client.cache.language

  const content = languages[language]
  return content
}

function getContentByLanguage(language) {
  const content = languages[language]
  return content
}

export { useContent, getContentHookless, getContentByLanguage }
