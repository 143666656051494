import { createStore /* , applyMiddleware */ } from 'redux'

// React Hooks: How to create and update Context.Provider
// https://dev.to/oieduardorabelo/react-hooks-how-to-create-and-update-contextprovider-1f68

// Getting Started with Redux
// https://redux.js.org/introduction/getting-started

// import * as stubs from './stubbing'

// Actions Types
const INIT = 'INIT'
const ADD = 'ADD'
const REPLACE = 'REPLACE'
const REMOVE = 'REMOVE'
const CLEAR = 'CLEAR'

// Initial State
export const initialState = {
  loading: true,
  language: 'en',
  balances: {
    text: {
      quantity: 0,
      startingQuantity: 0,
    },
    data: {
      quantity: 0,
      startingQuantity: 0,
    },
    voice: {
      quantity: 0,
      startingQuantity: 0,
    }
  },
  provisionedOneOffAddOns: [],
  provisionedRecurringAddOns: []
}

// Reducer
function cacheReducer(state = initialState, action) {
  switch (action.type) {
    case ADD:
      return {
        ...state,
        ...action.payload
      }

    case REMOVE: {
      const {
        [action.payload]: _omitted,
        ...rest
      } = state

      return rest
    }

    case REPLACE:
      return action.payload

    case CLEAR:
      return initialState

    default:
      return state
  }
}

// Store { subscribe, dispatch, getState }
export const store = createStore(cacheReducer)
// export const store = process.env.NODE_ENV === "development"
//   ? createStore(cacheReducer, applyMiddleware(require('redux-logger').default))
//   : createStore(cacheReducer)

// Actions
export function initCache(object) {
  store.dispatch({
    type: INIT,
    payload: object
  })
}

export function addToCache(object) {
  store.dispatch({
    type: ADD,
    payload: object
  })
}

export function removeFromCache(key) {
  store.dispatch({
    type: REMOVE,
    payload: key
  })
}

export function replaceCache(object) {
  store.dispatch({
    type: REPLACE,
    payload: object
  })
}

export function clearCache() {
  store.dispatch({
    type: CLEAR
  })
}

export const subscribe = store.subscribe
export const getState = store.getState

export default store

// For debugging in development mode
if (process.env.NODE_ENV === "development") {
  if (window) window.store = store
}

