import React from 'react'
import { View, StyleSheet } from 'react-native'

import {
  Text,
  Space,
} from '@sart/components'
import { colors, backgroundColors, typography } from '@sart/styles'
import {
  accountHolderFullAddress,
  accountHolderFullname,
  accountHolderDOB,
} from '@sart/utilities'
import { useContent } from '@sart/content'

export default function PersonalDetailsSummary(props) {
  const { personalDetails } = props

    const content = useContent()

    const accountHolder = accountHolderFullname(personalDetails)
    const dob = accountHolderDOB(personalDetails)
    //     const phoneNumber = personalDetails.primaryContactNumberCountryCode + ' ' + personalDetails.primaryContactNumber
    const phoneNumber = '+52' + ' ' + personalDetails.primaryContactNumber
    const emailAddress = personalDetails.emailAddress
    const fullAddress = accountHolderFullAddress(personalDetails)

  return (
    <View style={styles.container}>
      <View style={styles.item}>
        <Text style={styles.details}>{content.forms.accountHolderLabel}:</Text>
        <Text style={styles.details}>{accountHolder}</Text>

        <Space height={24} />

        <Text style={styles.details}>{content.forms.dobLabel}:</Text>
        <Text style={styles.details}>{dob}</Text>
      </View>

      <Space width={24} />

      <View style={styles.item}>
        <Text style={styles.details}>{content.forms.phoneNumberLabel}:</Text>
        <Text style={styles.details}>{phoneNumber}</Text>

        <Space height={24} />

        <Text style={styles.details}>{content.forms.emailLabel}:</Text>
        <Text style={styles.details}>{emailAddress}</Text>
      </View>

      <Space width={24} />

      <View style={styles.item}>
        <Text style={styles.details}>{content.forms.addressLabel}:</Text>
        <Text style={styles.details}>{fullAddress}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // justifyContent: '',
    backgroundColor: backgroundColors.subtle,
    padding: 24,
    borderRadius: 8
  },
  details: {
    fontSize: 15,
    lineHeight: 24,
    color: colors.feature,
    ...typography.book
  },
  item: {
    flex: 1
  }
})
