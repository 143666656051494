
import React from 'react'
import Svg, { Path } from 'svgs'

import { colors } from '@sart/styles'

export default function Done(props) {
  return (
    <Svg width={96} height={96} {...props}>
      <Path
        fill="#BCC3CA"
        d="M48 96C21.49 96 0 74.51 0 48S21.49 0 48 0s48 21.49 48 48-21.49 48-48 48zm0-5.486c23.48 0 42.514-19.034 42.514-42.514C90.514 24.52 71.48 5.486 48 5.486 24.52 5.486 5.486 24.52 5.486 48 5.486 71.48 24.52 90.514 48 90.514zm17.26-58.168a2.743 2.743 0 0 1 3.88 3.88L41.71 63.653a2.743 2.743 0 0 1-3.878 0L25.489 51.31a2.743 2.743 0 0 1 3.88-3.88L39.77 57.836l25.49-25.489z"
      />
    </Svg>
  )
}

Done.defaultProps = {
  width: 96,
  height: 96,
  color: colors.trivial
}
