import React from 'react'
import PropTypes from 'prop-types'
import Svg, { Path} from 'svgs'


export default function XSocial(props) {
  return (
    <Svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    {...props}
  >
    <Path 
    fill="gray"
    d="m7.5,0C3.36,0,0,3.36,0,7.5s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S11.64,0,7.5,0Zm4.21,11.8h-2.51l-2.29-3.34-2.87,3.34h-.74l3.29-3.82L3.29,3.2h2.51l2.17,3.16,2.72-3.16h.74l-3.13,3.64h0l3.41,4.96Zm-2.16-.53h1.14L5.44,3.76h-1.14l5.25,7.51Z" />
  </Svg>
  )
}

XSocial.defaultProps = {
  width: 121,
  height: 154,
  color: '#3BA9EE'
}

XSocial.propTypes = {
  color: PropTypes.string
}
