import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { isMobileOnly } from 'react-device-detect'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import delay from 'delay'

import {
  // Label,
  Space,
  Button,
  TextInput,
  ErrorMessage,
} from '@sart/components'
import { colors, typography } from '@sart/styles'

import client, { useClient } from '@sart/client'
import { useContent } from '@sart/content'
import { testID } from '@sart/utilities'

export default function AgentCode(props) {
  const content = useContent()
  const {
    cache: {
      basket,
      selectedPlan
    } = {}
  } = useClient()
  const agentUsername = (client.cache.agent && client.cache.agent.user.userName)

  const referralCode = client.cache.referralCode
  const [locked, setLocked] = useState(referralCode || agentUsername ? true : false)
  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues: {
        referralCode: referralCode || agentUsername
    },
    validationSchema: Yup.object().shape({
        referralCode: Yup.string().trim()
    }),
    onSubmit: handleSubmit
  })

  async function handleSubmit(values, form) {
    if (locked) {
      client.unsetCache('referralCode')
      client.unsetCache('promoPlans')
			client.removePromoFromNCABasket()
      form.resetForm({ values: { referralCode: '' } })
      setLocked(false)
      return
    }

    form.setSubmitting(true)

    try {
        var AgentFormat = new RegExp(/^[a-zA-Z]+$/) //any length only Alpha characters
        var PromoCodeFormat = new RegExp(/^(?=[a-zA-Z\d]*\d)[a-zA-Z\d]{8,}$/) //minimum of 8 Alphanumeric characters and at least 1 digit

        if(AgentFormat.test(values.referralCode)){
          //Apply as Agent referral code
          client.setCache({ referralCode: values.referralCode })
          client.unsetCache('promoPlan')
					client.removePromoFromNCABasket()
        } else if (PromoCodeFormat.test(values.referralCode)) {
          //Check for product tag in catalog
          values.referralCode = values.referralCode.toLowerCase()
          client.setCache({ referralCode: values.referralCode })
          let promoPlans =  await client.callApi('catalog','getProducts',[client.cache.catalogId, {
            tags: values.referralCode,
						includeExpired:true
          }])
          
          if(promoPlans.length == 0) {
            setError(content.agent.invalidReferralCode)
          } else if (promoPlans.length > 0 ) {
            //Add to basket here
            setError(null)
            client.setCache({ promoPlans })
            client.addPromoToNCABasket(promoPlans)
          }
        } else {
          //Tell user invalid code
          setError(content.agent.invalidReferralCode)
          form.setSubmitting(false)
        }

        form.setSubmitting(false)
        setLocked(true)

    } catch(error) {
     
    }
  }

  return (
    <View style={styles.container}> 
      <View style={styles.inputRow}>
          <TextInput
              value={formik.values.referralCode}
              placeholder={content.agent.referralCode}
              style={styles.referralCode}
              onChangeText={formik.handleChange('referralCode')}
              onBlur={formik.handleBlur('referralCode')}
              error={formik.touched.referralCode && formik.errors.referralCode}
              editable={!locked}
              {...testID("agentCodeInput")}
              />
          <Space width={isMobileOnly ? 4 : 12} />
          <Button
              title={
                locked ? content.common.reset : content.common.apply
              }
              disabled={!formik.isValid || formik.isSubmitting}
              onPress={formik.handleSubmit}
              style={styles.button}
              titleStyle={styles.buttonTitle}
              {...testID("agentCodeButton")}
              />
      </View>
      {( error !== '' ) &&
          <View>
            <Space height={16} />
            <ErrorMessage style={styles.error} key={0} {...testID("errorMessage")}>{error}</ErrorMessage>
          </View>
        }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...(isMobileOnly ? 
      { //Mobile Only
        width:'100%', 
        paddingHorizontal:6
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  inputRow: {
    flexDirection: 'row',
    ...(isMobileOnly ? 
      { //Mobile Only
        flex:1
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  referralCode: {
    flex: 1,
    ...typography.book,
    ...(isMobileOnly ? 
      { //Mobile Only
        width: 200,
        fontSize: 14
      } 
      : 
      { //Non-Mobile Only
        
      }
    )  },
  button: {
    backgroundColor: colors.exisOrange,
    ...(isMobileOnly ? 
      { //Mobile Only
        flex:1
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  error: {
    ...typography.book
  },
  buttonTitle: {
    ...typography.bold, 

    ...(isMobileOnly ? 
      { //Mobile Only
        fontSize: 12
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  }
})


