

import creditCardType from 'credit-card-type'

export default function guessCardType(numberCard) {
  if (numberCard.length >= 4) {
    try {
      const cards = creditCardType(numberCard)
      // values.type = cards[0].type
      return cards[0].type
    } catch (error) {
      return ''
    }
  }
}
