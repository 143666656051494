import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

function Minus(props) {
  const { color, size, } = props

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill={color} stroke={color} fillRule="evenodd">
        <Path d="M37.5 15.5v7H.5v-7z" />
      </G>
    </Svg>
  )
}

export default Minus
