import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Redirect } from 'react-router-dom'

import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Anchor,
  Space,
  TextInputExtended,
  Button,
} from '@sart/components'
import { useContent } from '@sart/content'
import { client, useClient } from '@sart/client'
import { prettyErrorMessage, testID } from '@sart/utilities'
import {
  colors,
  typography
} from '@sart/styles'

export default function AgentSignIn(props) {
  const { history } = props
  const { onLoginSuccess, submittingTitle, title, disabledStyle, enabledStyle, onPressFooter } = props

  const {
    cache: {
      agent,
    } = {}
  } = useClient()
  const content = useContent()

  async function handleCustomerSignIn(values, form) {
    form.setSubmitting(true)

    try {
      values.email = values.email.trim().toLowerCase()
      const auth = await client.signInWithPasswordAndEmail({
        email: values.email,
        password: values.password
      })

      const customer = await client.callApi('customer','getCustomer',[auth.customerId])

      client.setCache({ customer })

      if (onLoginSuccess) onLoginSuccess(customer)
    } catch (error) {
      const message = prettyErrorMessage(error, 'login', content)

      form.setFieldError('password', message)
      form.setSubmitting(false)
    }
  }

  async function handleAgentCustomerSign(values, form) {
    form.setSubmitting(true)

    try {
      const userCustomers = await client.callApi('user','getUserCustomers',[agent.userId, {lineIdentifier:values.number}])
      const userCustomer = userCustomers.find(item => item && item.twoFactorAuthPreferences && item.twoFactorAuthPreferences.preferredNumber === values.number)
      
      if (!userCustomer){
        form.setFieldError('number', content.errors.customerNotFound)
        form.setSubmitting(false)
        return
      }

      const customer = await client.callApi('customer','getCustomer',[userCustomer.customerId])

      const accounts = await client.callApi('customer', 'getAccounts', [customer.customerId])
      const { accountId } = accounts.shift()

      // Service
      const services = await client.callApi('account','getServices',[accountId])
      const { serviceId, serviceLegacyId }  = services.shift()

      client.setCache({ 
        customer, 
        customerId: customer.customerId,
        accountId,
        serviceId,
        serviceLegacyId 
      })

      if (onLoginSuccess) onLoginSuccess(customer)
    } catch (error) {
      const message = prettyErrorMessage(error, 'login', content)

      form.setFieldError('number', message)
      form.setSubmitting(false)
    }
  }
  function validNumber(value) {
    if(value) {
      return value.match(/\d{10}/)
    } else {
      return true
    }
  }

  return agent ? (
    <Formik
      initialValues={{
        number: '',
      }}
      validationSchema={Yup.object().shape({
        number: Yup.string().trim().required(content.forms.required).test('number-check', content.register_number.validNumber, value => validNumber(value)).length(10, content.register_number.numberLength), // content.register_number.validateErrorMessage
      })}
      onSubmit={handleAgentCustomerSign}
    >
      {formik =>
        <View style={styles.container}>
          <TextInputExtended
            label={content.register_number.phoneNumberLabel}
            value={formik.values.number}
            placeholder={content.register_number.phoneNumberPlaceholderTwo}
            keyboardType="phone-pad"
            onChangeText={formik.handleChange('number')}
            onBlur={formik.handleBlur('number')}
            error={formik.touched.number && formik.errors.number}
            style={{...typography.bold}}
            labelStyle={{...typography.bold}}
            footerStyle={{...typography.medium}}
            onSubmitEditing={formik.handleSubmit}
            maxLength={10}
            {...testID("signInSwitcherAgentNumber")}
          />

          <Space height={24} />

          <Button
            title={formik.isSubmitting ? submittingTitle : title}
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onPress={formik.handleSubmit}
            disabledStyle={disabledStyle}
            style={enabledStyle}
            titleStyle={{...typography.bold}}
            {...testID("signInSwitcherAgentButton")}
          />
        </View>
      }
    </Formik>
  ):(
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().trim().required(content.forms.required).email(content.forms.emailInvalid),
        password: Yup.string().trim().required(content.forms.passwordInvalid)
      })}
      onSubmit={handleCustomerSignIn}
    >
      {formik =>
        <View style={styles.container}>
          <TextInputExtended
            label={content.forms.emailLabel}
            value={formik.values.email}
            placeholder={content.forms.emailPlaceholder}
            keyboardType="email-address"
            {...Platform.select({
              web: { autoComplete: 'email' },
              ios: { textContentType: 'emailAddress' },
              android: { autoCompleteType: 'email' }
            })}
            autoCapitalize="none"
            onChangeText={formik.handleChange('email')}
            onBlur={formik.handleBlur('email')}
            error={formik.touched.email && formik.errors.email}
            style={{...typography.demi}}
            labelStyle={{...typography.demi}}
            footerErrorStyle={{...typography.book}}
            onSubmitEditing={formik.handleSubmit}
            {...testID("signInSwitcherEmail")}
          />

          <Space height={8} />

          <TextInputExtended
            secureTextEntry
            label={content.forms.passwordLabel}
            {...Platform.select({
              web: { autoComplete: 'password' },
              ios: { textContentType: 'password' },
              android: { autoCompleteType: 'password' }
            })}
            placeholder={content.forms.passwordPlaceholder}
            value={formik.values.password}
            onChangeText={formik.handleChange('password')}
            onBlur={formik.handleBlur('password')}
            showErrorAndFooter
            footer={
              <>
                {/* {content.forms.forgotPassword} */}
                {" "}
                <Anchor
                  title={content.common.help}
                  onPress={onPressFooter}
                  // /help works on SIM screen
                  style={{...typography.book}}
                />
              </>
            }
            error={formik.touched.password && formik.errors.password}
            style={{...typography.demi}}
            labelStyle={{...typography.demi}}
            footerErrorStyle={{...typography.book}}
            onSubmitEditing={formik.handleSubmit}
            {...testID("signInSwitcherPassword")}
          />

          <Space height={24} />

          <Button
            title={
              formik.isSubmitting
                ? content.login.inProgress
                : content.login.action
            }
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onPress={formik.handleSubmit}
            disabledStyle={disabledStyle}
            style={enabledStyle}
            titleStyle={{...typography.bold}}
            {...testID("signInSwitcherCustomerButton")}
          />
        </View>
      }
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 336
  }
})