import React, { useState, useEffect, useRef } from 'react'
import { View, ScrollView, StyleSheet, Text, Linking } from 'react-native'
import { useLocation } from 'react-router-dom'
import Linkify from 'linkify-react';

import {
  Headline, HorizontalLine, Space,
} from '@sart/components'
import { useContent } from '@sart/content'
import { typography, colors } from '@sart/styles'
import client, { useData } from '@sart/client'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'

import Accordion from 'react-native-collapsible/Accordion';

export default function FAQs(props) {

  const content = useContent()

  const [activeSections, setActiveSections] = useState([])

  const [{ FAQItems, error, loading }, setData] = useData({})
	let location = useLocation();
	const myScroll = useRef();
  const items = React.useMemo(() => FAQItems)

  const options = {
    validate: {
      url: (value) => /^https?:\/\//.test(value),
    },
  };

  useEffect(() => {
    getFAQItems()
  }, [client.cache.language])

  var language;

  if (client.cache.language === 'en') {
    language = 'en-gb'
  } else if (client.cache.language === 'es') {
    language = 'es-MX'
  }

  function flatMap(array, fn) {
    var result = [];
    for (var i = 0; i < array.length; i++) {
      var mapping = fn(array[i]);
      result = result.concat(mapping);
    }
    return result;
  }

  async function getFAQItems() {
		let searchParams = new URLSearchParams(location.search)
    setData({ loading: true })
    try {
      const FAQItems =  await client.callApi('contentCms','getPages',[1, { category: "FAQ", lang: language }]); 
      const parsedItems = FAQItems.map(item => {
        let excerpt = {}
        try {
          excerpt = JSON.parse(item.excerpt)

        } catch(error) {
          return {}
        }
        // let excerpt = JSON.parse(item.excerpt)
        // let content = JSON.parse(item.excerpt)
        let parsed = {};
        parsed.rank = excerpt.rank;
        parsed.title = excerpt.title;
				parsed.legacyId = item.legacyId
        if (excerpt.description.includes("Consúltala aquí")) {
          excerpt.description = flatMap(excerpt.description.split(/Consúltala aquí/g), function (part) {
            return [part, <Text onPress={() => window.open("https://exis.mx/files/datacalc/sliders.html", '_blank')} style={{color: 'blue'}}>Consúltala aquí</Text>]
          })
          excerpt.description.pop();
          parsed.content = excerpt.description
        } else if (excerpt.description.includes("Consulta el estatus de tu trámite")) {
          excerpt.description = flatMap(excerpt.description.split(/Consulta el estatus de tu trámite/g), function (part) {
            return [part, <Text onPress={() => window.open("https://portabilidad.mx/", '_blank')} style={{color: 'blue'}}>Consulta el estatus de tu trámite</Text>]
          })
          excerpt.description.pop();
          parsed.content = excerpt.description
        } else {
          parsed.content = excerpt.description;
        }        
        return parsed;
      });
      const orderedItems = parsedItems.sort(function(a,b) {
        return a.rank - b.rank;
      })
			if(searchParams.get("selected") != undefined) {
				for(let i = 0; i < orderedItems.length; i++) {
					if(orderedItems[i].legacyId.toUpperCase() === (searchParams.get("selected").toUpperCase())) {
						setActiveSections([i])
						setTimeout(() => {
							myScroll.current.scrollTo({x: 0, y: (i * 48) , animated: true}) 
						}, 500);
						break
					}
				}
			}
      setData({
        FAQItems: orderedItems,
        loading: false
      })
      client.setCache({
        FAQItems: orderedItems
      })
    } catch (error) {
      // TODO: handle error
      setData({ loading: true, error })
    }
  }

  var _renderHeader = section => {
    return (
      <View style={styles.header}>
        <View style={{flexDirection: 'row'}}>
          {activeSections.length ==  0 &&
            <Text style={styles.bulletPoint}>+</Text>
          }
          {activeSections.length ==  1 &&
            <Text style={styles.bulletPoint}>-</Text>
          }
          <Space width={5} height={5} />
          <Text style={styles.headerText}>{section.title}</Text>
        </View>
        <Space height={20} />
      </View>
    );
  };

  var _renderContent = section => {
    return (
      <View style={styles.content}>
        <Linkify options={options}><Text style={styles.contentText}>{section.content}</Text></Linkify>
        <Space height={10} />
        <HorizontalLine height={3} color={colors.exisBlue} />
        <Space height={10} />
      </View>
    );
  };

  var _updateSections = activeSections => {
    setActiveSections(activeSections);
  };

  return (
    <Wrapper style={styles.container}>
      <Headline style={styles.title} {...testID('FAQHeader')}>FAQs</Headline>
      {FAQItems &&
			<ScrollView style={{height:500}} ref={myScroll} showsVerticalScrollIndicator={false}>
				<Space height={48} />
				<Accordion
					sections={FAQItems}
					activeSections={activeSections}
					renderHeader={_renderHeader}
					renderContent={_renderContent}
					onChange={_updateSections}
					underlayColor={colors.clear}	
				/>
			</ScrollView>
      }
      <Space height={50} />
      <HorizontalLine height={3} color={colors.exisBlue} />
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48
  },
  title: {
    ...typography.bold,
    color: colors.exisBlue,
    fontSize: 45
  },
	header: {
		height:48
	},
  headerText: {
    ...typography.bold
  },
  contentText: {
    ...typography.book
  },
  bulletPoint: {
    color: colors.exisOrange,
    ...typography.bold
  }
})

