import React, { useState, useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'

import {
  Text,
  Space,
  Button,
  Price,
} from '@sart/components'
import {
  colors,
  theme,
  typography
} from '@sart/styles'
import { useContent, formatString, formatSingularOrPluralDateUnit } from '@sart/content'
import client from '@sart/client'

import {
  isMobileOnly
} from "react-device-detect";
import { getProductImage, getWebSafeProductImage, testID } from '@sart/utilities'

export default function PlanItem(props) {
  const { plan, disabled, link } = props
  // currency "GBP"
  // localData 1
  // localDataUnit "GB"
  // recurringCost 750
  // validity 24
  // validityUnit "Months"
  // recurringFrequency 30
  // validityUnit "Months"

  const [image, setImage] = useState(undefined)
  const [imageLoading, setImageLoading] = useState(false)

  const content = useContent()

  useEffect(() => {
    getImage()
  }, [])

  if (plan.localDataUnit === 'MB' && plan.localData >= 1000) {
    plan.localData = plan.localData / 1000
    plan.localDataUnit = 'GB'
  }

  function convertDataToMb(data, unit) {
    if (unit === "GB") {
      data = data * 1000
    } else if (unit === "KB") {
      data = data / 1024
    } else if (unit === "B") {
      data = data / (1024 * 1024)
    }

    return data
  }

  function getTranslatedUnit() {
    if (plan.localMinutesUnit === 'Minutes') {
      return content.planitem.minutes
    }
    return plan.localMinutesUnit
  }

  async function getImage() {
    try{
      setImageLoading(true)
      const productImage = await getWebSafeProductImage(displayDetails, 'AltBackground')
      setImage(productImage)
      setImageLoading(false)
    }
    catch(error) {
      setImageLoading(false)
    }
  }

  function getDisplayDetailsForLanguage(product) {
    //Get the display details for the language that is selected in the cache
    let displayDetails = undefined
    
    //identify the correct display details to use based on language
    if (product.displayDetails) {
      product.displayDetails.map(detail => {
        if(detail.language === client.cache.language) {
          displayDetails = detail
        }
      })
    }

    return displayDetails
  }

  function getColourByCategory(displayDetails, category) {
    if (!category){
      return undefined
    }

    let toReturn = undefined
    const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/
    
    //get the relevant font colour based on the category
    if (displayDetails && displayDetails.colors && displayDetails.colors.length) {
      displayDetails.colors.map(color => {
        //we also only accept hex values, so use regex to make sure it matches the pattern
        if (color.category === category && (hexCodeRegex.test(color.color) || color.color === 'transparent')){
          toReturn = color.color
        }
      })
    }

    return toReturn
  }

  const displayDetails = getDisplayDetailsForLanguage(plan)
  const titleColour = getColourByCategory(displayDetails,'AltTitleText')
  const descriptionColour = getColourByCategory(displayDetails,'AltDescriptiveText')
  const backgroundColour = getColourByCategory(displayDetails,'AltBackground')
  // const backgroundImage = getProductBackgroundImage(displayDetails)
  const accentColour = getColourByCategory(displayDetails,'AltAccent')

  return (
    <View>
      <div
          style={{
            backgroundImage: !imageLoading && image ? `url(data:image/svg+xml;charset=utf-8,${image})` : <View/>,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            height: '100%',
            width: '250px',
            marginLeft: isMobileOnly && !link ? '14%' : '0%'
          }}>
    <View
      style={[styles.content,  backgroundColour ? {backgroundColor: backgroundColour} : {}, image ? {backgroundColor: 'transparent', borderColor: 'transparent', shadowOpacity: 0, elevation: 0} : {}]}
    >
      <Text style={[styles.productName, titleColour ? {color: titleColour}:{}]}>{plan.productName}</Text>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={[{
            fontSize: 20,
            // fontWeight: '700',
            marginTop: 8,
            width: 20,
            color: colors.exisOnTint,
            opacity: 0, // HACK,
            ...typography.bold
          }, titleColour ? {color: titleColour}:{}]}
        >
          {plan.localDataUnit}
        </Text>
        <Text
          style={[{
            fontSize: 70,
            // fontWeight: '700',
            lineHeight: 96,
            color: colors.exisOnTint,
            ...typography.bold
          }, titleColour ? {color: titleColour}:{}]}
        >
          {plan.localData}
        </Text>
        <Space width={4} />
        <Text
          style={[{
            fontSize: 20,
            fontWeight: '700',
            marginTop: 8,
            width: 30,
            color: colors.exisOnTint,
            ...typography.bold
          }, titleColour ? {color: titleColour}:{}]}
        >
          {plan.localDataUnit}
        </Text>
      </View>
      {plan.recurring &&
        <Text style={[styles.text, descriptionColour ? { color: descriptionColour } : {}]}>{content.planitem.autorenew}</Text>
      }
      {plan.localData > 0 &&
        <Text style={[styles.text, descriptionColour ? { color: descriptionColour } : {}]}>{convertDataToMb(plan.localData, plan.localDataUnit)} MB</Text>
      }
      {plan.localMinutes > 0 ?
        <Text style={[styles.text, descriptionColour ? { color: descriptionColour } : {}]}>{plan.localMinutes} {getTranslatedUnit()}</Text>
        :
        <Space height={16} />
      }
      {plan.localSMS > 0 ?
        <Text style={[styles.text, descriptionColour ? { color: descriptionColour } : {}]}>{plan.localSMS} SMS</Text>
        :
        <Space height={16} />
      }
      {plan.tags.find((tag) => tag.tag === 'GIFT/SWAP') &&
        <Text style={[styles.text, descriptionColour ? { color: descriptionColour } : {}]}>{content.planitem.allowsGiftSwap}</Text>
      }
      {!plan.tags.find((tag) => tag.tag === 'GIFT/SWAP') &&
        <Space height={16} />
      }
      <Text
        // style={[styles.duration, theme.planItem.duration]}
        style={[styles.duration, descriptionColour ? { color: descriptionColour } : {}]}
      >
        {formatString(
          content.planitem.contract, {
          validity: plan.recurring ? plan.recurringFrequency : plan.validity,
          validityUnit: plan.recurring ? formatSingularOrPluralDateUnit(plan.recurringFrequency, plan.recurringFrequencyUnit) : formatSingularOrPluralDateUnit(plan.validity, plan.validityUnit),
          recurring: plan.recurring ? " " + content.plans.recurring : ""
        })}
      </Text>

      <Price
        value={plan.recurring ? plan.recurringCost : plan.upfrontCost}
        // style={[styles.price, theme.planItem.price]}
        style={[styles.price, titleColour ? {color: titleColour}:{}]}
        code={plan.currency}
      />

      <Space height={24} />
      {link &&
        <View>
          <Text style={styles.link} onPress={() => window.open('https://crm.altanredes.com/preactiva/checkimei_websiteone.php', '_blank')}>{content.plans.link}</Text>
          <Space height={8} />
        </View>
      }
      <Button
        title={content.planitem.action}
        style={[styles.button, accentColour ? {backgroundColor: accentColour} : {}]}
        titleStyle={{ ...typography.bold }}
        onPress={props.onPress}
        disabled={disabled}
        {...testID([plan.productName.replace(/\s/g, ""), 'Plan'].join(''))}
        />
    </View>
    </div>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    maxWidth: isMobileOnly ? '100%' : 280,
    alignItems: 'center',
    minWidth: 192,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8
  },
  text: {
    ...typography.book
  },
  container: {
    flex: 1,
    maxWidth: 280,
    alignItems: 'center',
    minWidth: 192,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 8,
    backgroundColor: colors.exisOnTint,
  },
  label: { lineHeight: 24, color: colors.exisDarkCharcoal },
  value: {
    fontSize: 108,
    fontWeight: '700',
    lineHeight: 96,
    color: colors.exisOnTint
  },
  unit: {
    fontSize: 20,
    fontWeight: '700',
    marginTop: 8,
    width: 30,
    color: colors.exisOnTint
  },
  duration: {
    lineHeight: 24,
    color: colors.exisDarkCharcoal,
    marginTop: 20,
    ...typography.medium
  },
  price: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 24,
    color: colors.exisOnTint,
    ...typography.bold
  },
  productName: {
    lineHeight: 24,
    color: colors.black,
    fontWeight: 'bold',
    ...typography.demi
  },
  link: {
    textDecorationLine: 'underline',
    ...typography.medium,
    textAlign: 'center'
  },
  button: {
    ...(isMobileOnly ?

      { //Mobile Only
        alignSelf: 'center',
        width: '70%',
        backgroundColor: colors.exisOrange
      }
      :
      { //Non-Mobile Only
        alignSelf: 'stretch',
        backgroundColor: colors.exisOrange
      }
    ),
  }
})
