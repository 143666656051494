import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function Cross(props) {
  const { size, color, backgroundColor } = props
  
  return (
    <Svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path fill={backgroundColor} d="M-315-25H60v384h-375z" />
        <Path
          d="M24.587 4.013c.78-.78.784-2.042-.001-2.827l.228.228a2.001 2.001 0 00-2.827 0L13 10.4 4.013 1.413a1.996 1.996 0 00-2.827.001l.228-.228a2.001 2.001 0 000 2.827L10.4 13l-8.987 8.987a1.996 1.996 0 00.001 2.827l-.228-.228c.78.78 2.049.78 2.827 0L13 15.6l8.987 8.987c.78.78 2.042.784 2.827-.001l-.228.228a2.001 2.001 0 000-2.827L15.6 13l8.987-8.987z"
          fill={color}
        />
      </G>
    </Svg>
  )
}

export default Cross
