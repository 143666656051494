import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View, } from 'react-native'
import { withRouter } from 'react-router-dom'

import { useClient, client } from '@sart/client'
import { useContent } from '@sart/content'
import { HolFullLogo, HolSmallLogo, WideUpArrow } from '@sart/icons'
import { colors, backgroundColors, typography } from '@sart/styles'
import chroma from 'chroma-js'

import {
    isMobileOnly
} from "react-device-detect";

import LinearGradient from 'react-native-linear-gradient';

function EliotExternalLinkBar(props) {
    const { history } = props

    const { cache } = useClient()

    const content = useContent()

    return (
        <LinearGradient style={styles.container} locations={[0.5,0.8,1]} colors={['#B306DB',colors.eliotPurple, '#000000']} start={{x: 0, y: 0}} end={{x: 1, y: 0}}>

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.eliotPurple,
        height: 10,
        width: '100%'
    },
})

export default withRouter(EliotExternalLinkBar)