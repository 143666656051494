import React from 'react'
import { StyleSheet } from 'react-native'

import { Text, Space, Button } from '@sart/components'
import { colors, backgroundColors, typography } from '@sart/styles'
import { useContent } from '@sart/content'
import client, { useClient } from '@sart/client'
import { testID } from '@sart/utilities'

import Wrapper from '../Wrapper'

function AgentNavbar(props) {
  const content = useContent()

  const {
    cache: {
      agent,
    } = {}
  } = useClient()

  async function handleLogout() {
    try {
      await client.signOutAgent()
    } catch (error) {}
  }

  return agent ? (
    <Wrapper wrapperStyle={styles.container} style={styles.content}>
      <Text style={styles.textStyle}>{content.agent.navbarTitle}</Text>
      <Space width={24} />
      <Text style={styles.textStyle}>{agent.name}</Text>
      <Space flex={1} width={24} />
      <Button
        title={content.navigation.logout}
        style={styles.button}
        titleStyle={styles.buttonTitle}
        onPress={handleLogout}
        style={{ backgroundColor: colors.exisOrange }}
        {...testID("agentLogoutButton")}
      />
    </Wrapper>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: backgroundColors.feature
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 8,
  },
  button: {
    height: 32,
    backgroundColor: colors.feature
  },
  buttonTitle: {
    fontSize: 14,
    ...typography.demi,
  },
  textStyle: {
    ...typography.demi,
    color: colors.white
  }
})

export default AgentNavbar
