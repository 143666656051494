import React from 'react'
import { View, StyleSheet, Linking } from 'react-native'

import {
    Space,
    Text,
    Button
} from '@sart/components'
import { useContent } from '@sart/content'
import { colors, typography } from '@sart/styles'
import { testID } from '@sart/utilities'
import { GuruCallMe, GuruMeditation, Phone, Internet } from '@sart/icons'
import AppConfig from './Configuration'

import Wrapper from './Wrapper'

export default function Contact(props) {
  const content = useContent()
  const { history } = props

  return (
    <View style={styles.container}>
        <Space height={40}/>
        <View style={styles.content}>
            <View style={styles.text}>
                <Text style={styles.header} {...testID('ContactUsHeader')}>{content.contactus.header}</Text>
                <Text style={styles.subheader}>{content.contactus.subheader}</Text>
                <Space height={40}/>
                {/* <View style={styles.guru}>
                    <GuruMeditation height={270} width={200}/>
                </View> */}
                <Text style={styles.faqText}>{content.contactus.faqs}</Text>
                <View style={styles.faqs}>
                    <Text style={styles.faqText}>{content.contactus.faqsSecondLine}</Text>
                    <Text style={styles.faqLink} onPress={() => history.push('/help')}>{content.contactus.faq}</Text>
                </View>
                <Space height={40}/>
                <Text style={styles.support}>{content.contactus.support}</Text>
                <Space height={10}/>
                <View style={styles.numbers}>
                    <View style={styles.first}>            
                        <Phone size={24} color={colors.white}/>
                        <Space height={10}/>
                        <Phone size={24} color={colors.white}/>
                        <Space height={10}/>
                        <Internet size={24} color={colors.white}/>
                    </View>
                    <View style={styles.second}>
                        <Text style={styles.number}>{content.contactus.number}</Text>
                        <Text style={styles.number}>{content.contactus.telephoneNumber}</Text>
                        <Text style={styles.number}>{content.contactus.internetNumber}</Text>
                    </View>
                    <View style={styles.third}>
                        <Text style={styles.numberText}>{content.contactus.exisNumber}</Text>
                        <Text style={styles.numberText}>{content.contactus.telephone}</Text>
                        <Text style={styles.numberText}>{content.contactus.internet}</Text>
                    </View>
                </View>
                <Space height={24}/>
                <View style={styles.buttons}>
                    <Button
                        title={content.contactus.whatsapp}
                        style={styles.button}
                        titleStyle={{...typography.bold}}
                        onPress={() => window.open(`https://wa.me/${AppConfig.whatsAppNumber}`, "_blank")}
                    />
                    <Space width={100}/>
                    <Button
                        title={content.contactus.email}
                        style={styles.button}
                        titleStyle={{...typography.bold}}
                        onPress={() => Linking.openURL('mailto:contacto@exis.mx?subject=&body=') }
                        />
                </View>
                <Space height={48}/>
            </View>
            <View style={styles.guru}>
                <GuruCallMe size={400}/>
            </View>
        </View>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.exisBlue
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    text: {
        justifyContent: 'flex-start'
    },
    header: {
        color: colors.white,
        fontSize: 60,
        ...typography.lightbold
    },
    subheader: {
        color: colors.black,
        fontSize: 40,
        ...typography.lightbold,
    },
    guru: {
        justifyContent: 'flex-start'
    },
    faqs: {
        flexDirection: 'row',
    },
    faqText: {
        color: colors.white,
        ...typography.demi,
        fontSize: 20
    },
    faqLink: {
        color: colors.white,
        textDecorationLine: 'underline',
        alignSelf: 'flex-end',
        ...typography.demi,
        fontSize: 20
    },
    support: {
        color: colors.black,
        ...typography.demi,
        fontSize: 20,
    },
    numbers: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '80%',
        // backgroundColor: 'magenta'
    },
    first: {
        justifyContent: 'center',
        width: 25,
        // backgroundColor: 'teal',
    },
    second: {
        // backgroundColor: 'pink',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: 200,
        marginRight: 30
    },
    third: {
        // backgroundColor: 'red',
        justifyContent: 'center'
    },
    number: {
        color: colors.white,
        ...typography.bold,
        fontSize: 28
    },
    numberText: {
        color: colors.white,
        ...typography.demi,
        fontSize: 24,
    },
    buttons: {
        flexDirection: 'row',
    },
    button: {
        width: 250,
        backgroundColor: colors.exisOrange,
        borderRadius: 15    
    }
})
