import React from 'react'

import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { withRouter } from 'react-router-dom'

import { backgroundColors, colors, typography } from '@sart/styles'

import { Anchor, Space } from '@sart/components'
import { Instagram, Logo, XSocial, Facebook, Youtube } from '@sart/icons'
import { useContent } from '@sart/content'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'
import { isMobileOnly } from "react-device-detect"

function Footer(props) {
  const { history } = props

  const content = useContent()

  function handleRouting(link) {
    if (link.path) {
      return history.push(link.path)
    }

    if (link.url) {
      window.open(link.url, '_blank')
    }
  }

  const sitelinks = [
    [
      // {id: 'termsandconditions', title: content.navigation.termsAndConditions, path: '/termsandconditions' },
      // {id: 'legal'           , title: content.legal.header, path: '/legal' },
      // {id: 'accessibility'     , title: content.navigation.accessibility      , path: '/legal#Accessibility' },
      // {id: 'cookies'           , title: content.navigation.cookies            , path: '/legal#Cookies Policy' },
      {id: 'coverage', title: content.navigation.coverage, url: 'https://exis.mx/cobertura/'},
      {id: 'compatible', title: content.navigation.compatible, url: 'https://crm.altanredes.com/preactiva/checkimei_websiteone.php'},
      {id: 'eSIM', title: content.navigation.eSIM, url: 'https://exis.mx/esim/'},
      { id: 'cuenta', title: content.navigation.selfcare, url: 'https://exis.mx/cuenta/' }
    ],
    [
      { id: 'port', title: content.navigation.port, path: '/port' },
      { id: 'alreadyHaveSim', title: content.navigation.alreadyHaveSim, path: '/alreadyhavesim' },
      { id: 'loginAsAgent', title: content.navigation.loginAsAgent, path: '/agentsignin' },
      {id: 'loginAsAmbassador', title: content.navigation.loginAsAmbassador, url: 'https://v15erp.vadsa-mx.com/my/commissions'},
    ],
    [
      { id: 'faqs', title: content.navigation.faq, path: '/help' },
      { id: 'lostFoundAndStolen', title: content.navigation.lostFoundAndStolen, path: '/lost' },
      { id: 'contactus', title: content.contactus.header, path: '/contactus' }

      //   {id: 'shipping' , title: content.navigation.shipping },
      //   {id: 'support'  , title: content.navigation.support  },
      //   {id: 'contact'  , title: content.navigation.contact  },
    ],
    // [
    // //   {id: 'faq'      , title: content.navigation.faq      , path: '/help' },
    // //   {id: 'facebook' , title: content.socialMedia.facebook,  url: 'https://facebook.com' },
    // {id: 'twitter', title: <Twitter height={24}/>, url: 'https://twitter.com/exis_mx'},
    // {id: 'instagram', title: <Instagram height={24}/>, url: 'https://www.instagram.com/exis_mx/'},
    // //   {id: 'youtube'  , title: content.socialMedia.youtube,   url: 'https://youtube.com' },

    // ]
  ]

  const sociallinks = [
    [
      //   {id: 'faq'      , title: content.navigation.faq      , path: '/help' },
      { id: 'facebook', title: <Facebook height={24} width={24}/>, url: 'https://www.facebook.com/exismexico' },
      { id: 'twitter', title: <XSocial height={24} width={24}/>, url: 'https://twitter.com/exis_mx' },
      { id: 'instagram', title: <Instagram height={24} width={24}/>, url: 'https://www.instagram.com/exis_mx/' },
      {id: 'youtube'  , title: <Youtube height={24} width={24} />,   url: 'https://www.youtube.com/@exis_mx' },

    ]
  ]
  let legallinks = [
    [
      { id: 'termsandconditions', title: content.navigation.webAndApp, path: '/termsandconditions' },
      { id: 'legal', title: content.legal.headerCaps, path: '/legal' },
      { id: 'dataProtection', title: content.navigation.dataProtectionCaps, path: '/privacy' },
      ...isMobileOnly ? [{ id: 'limitedMobile', title: content.checkout.mobileView }] : [],
      { id: 'copyright', title: "© 2021 EXiS Telecom, S.A. de C.V. " }
    ],
  ]

  return (
    <Wrapper style={styles.container}>
      <View style={styles.linksContainer}>
        {!isMobileOnly && [
          <View style={styles.links}>
            {sitelinks.map((links, i, arr) =>
              <View key={i} style={{ alignItems: 'flex-start' }}>
                {links.map((link) =>
                  <Anchor
                    key={link.id}
                    style={styles.text}
                    title={link.title}
                    // href={link.path}
                    onPress={() => void handleRouting(link)}
                    {...testID("footerLink".concat(link.id.charAt(0).toUpperCase() + link.id.slice(1)))}
                  />
                )}
              </View>
            )}
          </View>
        ]}
        {isMobileOnly && [
          <View style={styles.socialContainer}>
            {sociallinks.map((links, i, arr) =>
              <View key={i} style={styles.social}>
                {links.map((link) =>
                  <Anchor
                    key={link.id}
                    style={styles.socialText}
                    title={link.title}
                    // href={link.path}
                    onPress={() => void handleRouting(link)}
                    // {...testID("footerLink".concat(link.id.charAt(0).toUpperCase() + link.id.slice(1)))}
                  />
                )}
              </View>
            )}

          </View>,
          <Space height={10} />,
          <Logo height={35} />
        ]}

        <View style={styles.links}>
          {legallinks.map((links, i, arr) =>
            <View key={i} style={styles.links}>
              {links.map((link) =>
                <Anchor
                  key={link.id}
                  style={styles.legaltext}
                  title={link.title}
                  // href={link.path}
                  onPress={() => void handleRouting(link)}
                  {...testID("footerLink".concat(link.id.charAt(0).toUpperCase() + link.id.slice(1)))}
                />
              )}
              {/* <Logo width="31" height="31" /> */}
            </View>
          )}
        </View>
      </View>

      {!isMobileOnly &&
        <View style={styles.icons}>
          <View style={styles.social}>
          {sociallinks.map((links, i, arr) =>
            <View key={i} style={styles.social}>
              {links.map((link) =>
                <Anchor
                  key={link.id}
                  style={styles.socialText}
                  title={link.title}
                  // href={link.path}
                  onPress={() => void handleRouting(link)}
                  // {...testID("footerLink".concat(link.id.charAt(0).toUpperCase() + link.id.slice(1)))}
                />
              )}
            </View>
            )}
          </View>
          <View style={styles.reverseColumn}>
            <Logo height={31} />
          </View>
        </View>      
      }
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: isMobileOnly ? 24 : 48,
    paddingBottom: isMobileOnly ? 0 : 72,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    ...(isMobileOnly ?
      { //Mobile Only
        maxWidth: '100%',
      }
      :
      { //Non-Mobile Only
      }
    )
  },
  links: {
    flex: 1,
    flexDirection: isMobileOnly ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    ...(isMobileOnly ?

      { //Mobile Only
        paddingBottom: 12
      }
      :
      { //Non-Mobile Only
      }
    )
  },
  text: {
    fontSize: 13,
    lineHeight: 24,
    fontWeight: '600',
    color: colors.exisBlue,
    ...typography.bold,

  },
  legaltext: {
    fontSize: 10,
    lineHeight: 10,
    color: colors.feature,
    ...typography.medium,
    ...(isMobileOnly ?
      { //Mobile Only
        paddingVertical: 5,
        textAlign: 'center'
      }
      :
      { //Non-Mobile Only
        paddingHorizontal: 20
      }
    )
  },
  linksContainer: {
    flexDirection: 'column',
    ...(isMobileOnly ?
      { //Mobile Only
        alignItems: 'center',
        maxWidth: '100%',
        paddingHorizontal: 0,
      }
      :
      { //Non-Mobile Only
      }
    )
  },
  social: {
    flexDirection: isMobileOnly ? 'row' : 'column',
    alignItems: 'center',
    ...(isMobileOnly ?
    {
        justifyContent:'space-evenly',
        width:'100%'
    }
    :
    { //Non-Mobile Only
    }
    )

  },
  socialContainer: {
    flexDirection: isMobileOnly ? 'row' : 'column',
    ...(isMobileOnly ?
      {
          width:'100%'
      }
      :
      { //Non-Mobile Only
      }
    )
    
  },
  socialText: {
    paddingBottom: 6
  },
  reverseColumn: {
    flexDirection: 'column-reverse'
  },
  icons: {
    flexDirection: 'row',
    height: 135,
    paddingLeft: 24
  }
})

export default withRouter(Footer)