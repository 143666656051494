import * as React from "react"
import Svg, { G, Rect, Path, Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

function GiftIcon(props) {
  return (
    <Svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="none" fillRule="evenodd">
        <Rect
          stroke="#FEFEFE"
          strokeWidth={4}
          fill="#FF5210"
          x={2}
          y={2}
          width={68}
          height={68}
          // rx={8}
        />
        <Path
          d="M61.4 15.358c-1.272-1.27-2.723-1.102-3.785-.975-.969.113-1.343.12-1.725-.261-.383-.383-.377-.757-.263-1.728.124-1.06.293-2.513-.98-3.786-1.272-1.272-2.724-1.103-3.786-.979-.972.114-1.345.12-1.728-.263a1.25 1.25 0 10-1.767 1.768c.92.92 1.933 1.082 2.825 1.057.342-.01.667-.045.961-.08.972-.112 1.346-.117 1.728.265.383.382.377.757.263 1.728-.034.293-.068.619-.078.96-.025.893.138 1.905 1.057 2.825.92.92 1.932 1.082 2.823 1.056.342-.01.668-.045.961-.08.971-.113 1.344-.119 1.725.261.38.381.375.755.26 1.724-.034.294-.07.62-.08.962-.026.892.136 1.903 1.055 2.822.244.244.564.366.884.366a1.25 1.25 0 00.884-2.133c-.381-.381-.375-.755-.26-1.725.126-1.06.297-2.513-.975-3.784zM13.055 62.39c.866-1.059.652-2.15.51-2.873-.121-.617-.109-.71 0-.842.108-.133.196-.162.813-.15.722.016 1.81.039 2.678-1.02.867-1.06.654-2.152.512-2.875-.121-.618-.108-.713 0-.846.419-.51.36-1.266-.133-1.686a1.152 1.152 0 00-1.647.163c-.746.91-.692 1.844-.572 2.547.02.115.04.225.06.326.121.619.108.714-.001.846-.108.132-.197.162-.813.149-.101-.002-.21-.004-.325-.003-.699.004-1.608.114-2.353 1.025-.745.91-.69 1.842-.57 2.546.02.115.04.225.06.326.121.617.108.71 0 .842-.107.13-.195.16-.81.148-.1-.003-.21-.004-.325-.003-.699.003-1.607.112-2.352 1.022-.418.51-.358 1.266.134 1.687.129.11.274.185.425.229.43.123.913-.015 1.222-.392.108-.131.195-.161.81-.148.721.016 1.81.04 2.677-1.019zM63 62.344L60.157 59.5 63 56.657l-.828-.829-.829-.828-2.843 2.843L55.656 55l-.828.828-.828.829 2.843 2.843L54 62.344 55.656 64l2.844-2.843L61.343 64zM38.343 6L35.5 8.843 32.657 6 31 7.657l2.843 2.843L31 13.344 32.657 15l2.843-2.843L38.343 15 40 13.344 37.156 10.5 40 7.657z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <Circle fill="#FFF" fillRule="nonzero" cx={62.5} cy={38.5} r={2.5} />
        <Circle fill="#FFF" fillRule="nonzero" cx={8.5} cy={36.5} r={2.5} />
        <Path
          d="M12 10c-2.757 0-5 2.243-5 5 0 2.758 2.243 5 5 5s5-2.242 5-5c0-2.757-2.243-5-5-5zm0 7.62A2.622 2.622 0 019.38 15 2.623 2.623 0 0112 12.38 2.623 2.623 0 0114.62 15 2.622 2.622 0 0112 17.62zM52.5 24.242h-7.755c1.065-3.105 0-6.166-2.354-6.943a4.155 4.155 0 00-3.825.791 7.122 7.122 0 00-2.325 3.223 5.75 5.75 0 00-.24.864 5.75 5.75 0 00-.24-.864 7.014 7.014 0 00-2.355-3.355 4.155 4.155 0 00-3.825-.79 4.012 4.012 0 00-2.55 2.929 6.93 6.93 0 00.21 4.072h-7.74c-.41 0-.804.163-1.087.454-.284.291-.433.683-.412 1.084v8.788c0 .809.671 1.464 1.5 1.464h1.5v17.576c0 .81.671 1.465 1.5 1.465H49.5c.829 0 1.5-.656 1.5-1.465V35.96h1.5c.828 0 1.5-.655 1.5-1.464v-8.788c0-.81-.672-1.465-1.5-1.465zm-13.298-2.058a4.131 4.131 0 011.252-1.858c.24-.246.576-.362.91-.316.5.179.909 1.486.408 3.054a5.331 5.331 0 01-.343.798.546.546 0 000 .138h-2.398a4.3 4.3 0 01.171-1.816zm-8.66 1.626a5.15 5.15 0 01-.352-.787 3.999 3.999 0 01-.068-2.199 1.14 1.14 0 01.555-.814c.343-.046.688.07.935.312.566.487.988 1.12 1.219 1.832.172.6.213 1.23.122 1.846h-2.37a.517.517 0 00-.041-.19zM21 27h10.111L28.8 28.8c-.306.239-.51.594-.563.988-.054.394.044.794.274 1.112.273.378.701.6 1.156.6.312 0 .616-.105.866-.3L34 28.5V33H21v-6zm3 9h10v16H24V36zm24 16H38V36h10v16zm3-19H38v-4.5l3.467 2.7c.25.195.554.3.866.3.455 0 .883-.222 1.156-.6.23-.318.328-.718.274-1.112a1.512 1.512 0 00-.563-.988L40.889 27H51v6z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default GiftIcon
