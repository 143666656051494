import utf8 from 'utf8'
import { Buffer } from 'buffer'
import AsyncStorage from '@react-native-async-storage/async-storage'
import delay from 'delay'
import axios from 'axios'


import apis from '@sart/apis'
import content from '@sart/content'
import { encryptSensitiveString } from '@sart/utilities'
import config  from './config'

//IMPORTANT: DO NOT DELETE THIS LINE!
//The import is used at run-time on the ConsumerPortal and the
//release process for the portal breaks without it.
//Again: DO NOT REMOVE THE BELOW LINE
import 'regenerator-runtime/runtime'

import {
  getFilteredBalances,
  getLatestInvoiceId,
  getCategorisedAddOns
} from './utils'

import {
  validateEmailAddress,
  validatePassword,
  validateToken,
} from './validations'

import cacheStore, {
  // initCache,
  addToCache,
  removeFromCache,
  replaceCache,
  clearCache,
} from './cache'

const postpaidSignUpLegId = "VADSA-POSTPAID-CUST-SIGNUP"
const prepaidSignUpLegId = "VADSA-PREPAID-CUST-SIGNUP"
const appSignUpLegId = "VADSA-NCA-APP-CUST-SIGNUP"
const dispatchSimLegacyId = "VADSA-DISPATCH-SIM"
const dispatchESimLegacyId = "VADSA-DISPATCH-E-SIM"
const assignSimLegacyId = "VADSA-ASSIGN-SIM"
const registerUserLegacyId = "VADSA-REGISTER-USER"
const prepaidBillingMode = "PREPAID"
const clientVersion = "V2.0.36"
const basePrepaidPlan = "PAYG"
const eliotSignUpLegacyId = "ELIOT-PREPAID-CUST-SIGNUP"

export default class Client {
  static api

  static orderRefreshTime = 5000
  static orderRefreshTimeout = 600000
  static apiTimeout = 60000
  static timeoutReachedCode = 408

  static balanceTimeStamp = Date.now()

  static setupApi(api) {
    this.api = api instanceof apis.ApiClient ? api : new apis.ApiClient()
    this.setHeaders({
      Version: clientVersion
    })
  }

  static setApiBasePath(basePath){
    if (this.api !== null && this.api !== undefined && this.api instanceof apis.ApiClient){
      this.api.basePath = basePath
    }
  }

  static setHeaders(obj) {
    this.api.defaultHeaders = {
      ...this.api.defaultHeaders,
      ...obj
    }
  }

  // Configurating (permanent per app)

  static configuration

  static setConfiguration(configuration) {
      this.configuration = configuration
  }

  static getConfiguration() {
      return this.configuration
  }

  // Caching (temporary per user session)

  static get cache() {
    return cacheStore.getState()
  }

  // static initCache() {
  //   cache.init()
  // }

  static setCache(obj) {
    addToCache(obj)
  }

  static replaceCache(obj) {
    replaceCache(obj)
  }

  static unsetCache(key) {
    removeFromCache(key)
  }

  static clearCache() {
    clearCache()
  }

  static clearBasketCache(obj) {
    const {
      basketUserId,
      basketId,
      promoPlans,
      selectedPlan,
      customerDetails,
      cardDetails,
      cachedPayment,
      referralcode,
      // orderId,
      // order,
      ...rest
    } = this.cache

    this.replaceCache({
      ...rest,
      ...obj
    })
  }

  static get isPostpaid() {
    return this.cache.billingMode === 'POSTPAID'
  }

  static get isPrepaid() {
    return this.cache.billingMode === 'PREPAID'
  }

  static encodeCredentials(string) {
    const safeString = utf8.encode(string)
    const base64EncodedString = Buffer.from(safeString).toString('base64')

    return base64EncodedString
  }

  static setupHeaderBasicAuth({ username, password }) {
    // Validate Fields
    if (!validatePassword(password)) {
      throw new Error(content.forms.passwordInvalid)
    }

    try {
      const credentials = username + ':' + password
      const encodedCredentials = this.encodeCredentials(credentials)

      this.setHeaders({
        Authorization: 'Basic ' + encodedCredentials
      })
    } catch(error) {
      throw error
    }
  }

  static setupHeaderStoredBasicAuth (auth) {
    try {
      this.setHeaders({
        Authorization: 'Basic ' + auth
      })
    } catch(error) {
      throw error
    }
  }

  static setupHeaderBearerAuth(token) {
    try {
      if (!(validateToken(token))) {
        throw new Error(content.login.validateToken)
      }

      this.setHeaders({
        Authorization: 'Bearer ' + token
      })
    } catch (error) {
      throw error
    }
  }

  static async testServerIsAvailable() {
    try {
      await this.authentication.createToken()
    } catch (error) {
      if (error.status === 405 || error.status === undefined) {
        throw error
      }
    }
  }
  

  static async signInWithPasswordAndEmail({ email, password }) {
    try {
      if (!validateEmailAddress(email)) {
        throw new Error(content.forms.emailInvalidCharacters)
      }
      this.setupHeaderBasicAuth({ username: email, password })
      await this.storeBasicAuthInformation({ username: email, password })
      //const auth = await this.authentication.createToken()
      const auth = await this.callApi('authentication', 'createToken')

      await this.storeAuthInformation(auth)
      await this.cacheAuthInformation(auth)
      this.setupHeaderBearerAuth(this.cache.token)
      await this.cacheUserAccountInformation(auth.customerId)
      await this.cacheUserSettings(auth.userId)
      return auth
    } catch (error) {
      throw error
    }
  }

  static async signInWithUsernameAndPassword({ username, password }) {
    try {
      this.setupHeaderBasicAuth({ username, password })

      //const auth = await this.authentication.createToken()
      const auth = await this.callApi('authentication', 'createToken')

      await this.storeAuthInformation(auth)
      await this.cacheAuthInformation(auth)

      this.setupHeaderBearerAuth(this.cache.token)

      await this.cacheUserAccountInformation(auth.customerId)

      return auth
    } catch (error) {
      throw error
    }
  }

  static async signInWithMobileAndPassword({ username, password }) {
    try {
      this.setupHeaderBasicAuth({ username, password })
      await this.storeBasicAuthInformation({ username, password })
      //const auth = await this.authentication.createToken()
      const auth = await this.callApi('authentication', 'createToken')
      await this.storeAuthInformation(auth)
      await this.cacheAuthInformation(auth)

      this.setupHeaderBearerAuth(this.cache.token)

      return auth
    } catch (error) {
      throw error
    }
  }

  static async signInWithAgentUser({ username, password }) {
    try {
      this.setupHeaderBasicAuth({ username, password })

      //const auth = await this.authentication.createToken()
      const auth = await this.callApi('authentication', 'createToken')

      await this.storeAuthInformation(auth)
      await this.cacheAuthInformation(auth)
      await this.storeBasicAuthInformation({ username, password })

      this.setupHeaderBearerAuth(this.cache.token)

      //Only re-get the catalog if we do not have the catalog information
      if (!this.cache.catalogId){
        await this.cacheCatalogInformation()
      }

      return auth
    } catch (error) {
      throw error
    }
  }

  static async signInWithVerificationCode({ email, code }) {
    try {
      await this.setupHeaderBasicAuth({ username: email, password: code })

      //const auth  = await this.authentication.createToken()
      const auth = await this.callApi('authentication', 'createToken')

      this.setupHeaderBearerAuth(auth.tokenid || auth.tokenId)

      return auth
    } catch (error) {
      throw error
    }
  }

  static async signInWithStoredToken() {
    try {
      const auth = await this.retriveStoredAuthInformation()

      if (auth && auth.token) {
        this.cacheAuthInformation(auth)

        this.setupHeaderBearerAuth(this.cache.token)

        if (auth.customerId) {
          await this.cacheUserAccountInformation(auth.customerId)
          await this.cacheUserSettings(auth.userId)
        } else {
          return undefined
        }
      }

      return auth
    } catch (error) {
      throw error
    }
  }

  static async refreshToken(startTime) {
    const basicAuth = await this.retriveStoredBasicAuthInformation()
    if (!basicAuth){
      return
    }
    this.setupHeaderStoredBasicAuth(basicAuth)

    const auth = await this.callApi('authentication', 'createToken',[],startTime)
    await this.storeAuthInformation(auth)
    await this.cacheAuthInformation(auth)

    this.setupHeaderBearerAuth(this.cache.token)

  }

  static async signOutAndForget() {
    try {
      this.clearCache()
      await this.deleteStoredAuthInformation()
      await this.deleteStoredBasicAuthInformation()
    } catch (error) {
      throw error
    }
  }

  static async signOutAgent() {
    try {
      this.unsetCache('agent')
      this.unsetCache('referralCode')
    } catch (error) {
      throw error
    }
  }

  static async cacheAuthInformation(auth) {
    try {
      const cache = {}

      if (auth.token || auth.tokenid || auth.tokenId) cache.token = auth.token || auth.tokenid || auth.tokenId
      if (auth.userId)                cache.userId = auth.userId
      if (auth.customerId)            cache.customerId = auth.customerId

      this.setCache(cache)
    } catch (error) {
      throw error
    }
  }

  static async storeAuthInformation(auth) {
    try {
      if (auth.token || auth.tokenid || auth.tokenId) await AsyncStorage.setItem('token', `${auth.token || auth.tokenid || auth.tokenId}`)
      if (auth.userId)                await AsyncStorage.setItem('userId', `${auth.userId}`)
      if (auth.customerId)            await AsyncStorage.setItem('customerId', `${auth.customerId}`)
      if (auth.employeeId)            await AsyncStorage.setItem('employeeId', `${auth.employeeId}`)
    } catch (error) {
      throw error
    }
  }

  static async storeBasicAuthInformation({ username, password }) {
   
    try {
      if (username && password){
        const credentials = username + ':' + password
        const encodedCredentials = this.encodeCredentials(credentials)
        await AsyncStorage.setItem('basicAuthInfo', encodedCredentials)
      }
    } catch(error) {
      throw error
    }
  }

  static async retriveStoredAuthInformation(auth) {
    try {
      const results = {}

      const token = await AsyncStorage.getItem('token')
      const userId = await AsyncStorage.getItem('userId')
      const customerId = await AsyncStorage.getItem('customerId')

      if (token) results.token = token
      if (userId) results.userId = userId
      if (customerId) results.customerId = customerId

      return results
    } catch (error) {
      throw error
    }
  }

  static async retriveStoredBasicAuthInformation() {
    try {

      return await AsyncStorage.getItem('basicAuthInfo')
      
    } catch (error) {
      throw error
    }
  }

  static async deleteStoredAuthInformation() {
    try {
      await AsyncStorage.removeItem('token')
      await AsyncStorage.removeItem('userId')
      await AsyncStorage.removeItem('customerId')
      await AsyncStorage.removeItem('employeeId')
    } catch (error) {
      throw error
    }
  }

  static async deleteStoredBasicAuthInformation() {
    try {
      await AsyncStorage.removeItem('basicAuthInfo')
    } catch (error) {
      throw error
    }
  }

  static async getAvailableAddons(account) {
    var result = {}

    const addons = await this.callApi('catalog','getAddOnsProducts',[this.cache.catalogId, {
      accountId: account.accountId,
      serviceId: account.services[0].serviceId,
    }])

    result.addons = addons.slice().sort((a, b) => a.rank - b.rank)

    this.setCache({...result})

}

  static async getADTAddons() {
    try {
      let ADTAddons = await this.callApi('catalog','getAddOnsProducts',[this.cache.catalogId, {
        tags: "ADT"
      }])
      ADTAddons = this.filterOutExpiredOffers(ADTAddons)
      ADTAddons.slice().sort((a, b) => a.rank - b.rank)
      
      this.setCache({ADTAddons})
    } catch(error) {

    }
  }

  static async cacheUserAccountInformation(customerId) {
    try {
      // Account
      //const accountResponse = await this.customer.getAccounts(customerId)
      const accountResponse = await this.callApi('customer', 'getAccounts', [customerId])
      const account = accountResponse[0]
      const accountId = account.accountId
      const billingMode = account.billingMode
      const accounts = []
      let serviceId,serviceLegacyId
      // do the sort so that the mobile accounts are returned first
      accountResponse.sort((a,b) => (a.accountType < b.accountType) ? 1 : -1)

      // Service
      //const servicesResponse = await this.account.getServices(accountId)
      for(let i in accountResponse) {
        const account = accountResponse[i]
        const services = await this.callApi('account','getServices',[account.accountId])
        const service = services[0]
        account.services = services
        accounts.push(account)
        if (service.status === 'Disconnected'){
          throw {message: content.errors.serviceDisconnected, status: 500}
        }
        //At the moment this will just use the very last service
        //TODO - we should improve this throughout the front ends
        serviceId = service.serviceId
        serviceLegacyId = service.serviceLegacyId
      }



      // Catalog
      //only get the catalog if we do not have one already
      if (!this.cache.catalogId){
        const catalogs = await this.callApi('catalog', 'getCatalogs',[{operatorCode: this.configuration.catalogName}])
        const catalog = catalogs[0]
        const catalogId = catalog.catalogId
        this.setCache({catalogId})
      }
      
      this.setCache({
        accountId,
        billingMode,
        serviceId,
        serviceLegacyId,
        accounts
      })
    } catch (error) {
      throw error
    }
  }

  static async cacheUserInformation(customerId, filter) {
    try {
      // Account
      //const accountResponse = await this.customer.getAccounts(customerId)
      let options = {}
      options.accountType = filter
      const accountResponse = await this.callApi('customer', 'getAccounts', [customerId, options])
      const account = accountResponse[0]
      const accountId = account !== undefined ? account.accountId : ''
      const billingMode = account !== undefined ? account.billingMode : ''
      const accounts = this.cache.accounts !== undefined ? this.cache.accounts : []
      const services = this.cache.services !== undefined ? this.cache.services : []
      // do the sort so that the mobile accounts are returned first
      accountResponse.sort((a,b) => (a.accountType < b.accountType) ? 1 : -1)

      // Service
      if(accountResponse.length > 0) {
        for(let i in accountResponse) {
          const account = accountResponse[i]
          let loadAccount = true
          for(let j in accounts) {
            if(account.primaryIdentifier === accounts[j].primaryIdentifier) {
              loadAccount = false
            }
          }
          if(loadAccount) {
            const account = accountResponse[i]
            const servicesResponse = await this.callApi('account','getServices',[account.accountId])
            account.services = servicesResponse
            accounts.push(account)
            servicesResponse.map(service => {
              services.push(service)
            })
          }
        }
      }

      // Catalog
      const catalogs = await this.callApi('catalog', 'getCatalogs',[{operatorCode: this.configuration.catalogName}])
      const catalog = catalogs[0]
      const catalogId = catalog.catalogId

      this.setCache({
        accountId,
        billingMode,
        catalogId, 
        accounts,
        services
      })
    } catch (error) {
      throw error
    }
  }

  static async cacheUserSettings(userId){

    try {
      //const user = await this.user.getUser(userId)
      const user = await this.callApi('user','getUser',[userId])
      //currently just storing language, but may do more in future
      if (user.language){
        const language = user.language
        this.setCache({
          language
        })
      }
      if(user.userName) {
        const username = user.userName
        this.setCache({
          username
        })
      }
    } catch (error){
        throw error
     }
  }

  static async updateLanguage(userId, language){

    this.setCache({
      language
    })
    //important that we do NOT await here, we want this call to be async
    this.updateLanguageOnServer(userId, language)
  }

  static async updateLanguageInCache(language){
    this.setCache({
      language
    })
  }

  static async updateLanguageOnServer(userId, language){
    try {
      //let user = await this.user.getUser(userId)
      let user = await this.callApi('user','getUser',[userId])

      user.language = language;

      await this.callApi('user','modifyUser',[userId, user])
      
      this.setCache({
        language
      })

    } catch (error){
        throw error
     }
  }

  static async cacheCatalogInformation() {
    // Catalog
    try {
      //const catalogs = await this.catalog.getCatalogs("VADSA")
      const catalogs = await this.callApi('catalog','getCatalogs',[{operatorCode: this.configuration.catalogName}])
      const catalog = catalogs[0]
      const catalogId = catalog.catalogId

      this.setCache({
        catalogId
      })
    } catch (error) {
      throw error
    }
  }

  static sortProducts(productArray) {
    productArray.sort(function(productA, productB) {
      return productA.rank - productB.rank
    })
    return productArray
  }

  static async getProvisionedProducts(account, service) {
    const planProducts = await this.callApi('service', 'getServicePlanProductInstances',[account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
    var toReturn = {
      planProduct: planProducts[0]
    }

    //toReturn.planProduct = planProducts[0]

    const productInstances = await this.callApi('service', 'getServiceAddOnProductInstances',[account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
    //arrange the product instances in rank order so the primary add-on for prepaid is first
    toReturn.productInstances = productInstances.slice().sort((a,b) => a.rank - b.rank)

    return toReturn
  }

  static async getMediaProducts(account, service) {
    const mediaSubscriptions = await this.callApi('service', 'getServiceMediaSubscriptionProductInstances',[account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
    var toReturn = {
      mediaSubscriptions
    }

    //toReturn.planProduct = planProducts[0]

    const mediaPPVs = await this.callApi('service', 'getServiceMediaPayPerViewProductInstances',[account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
    //arrange the product instances in rank order so the primary add-on for prepaid is first
    toReturn.mediaPPVs = mediaPPVs.slice().sort((a,b) => a.rank - b.rank)

    return toReturn
  }

  static async getAccountBalances(account, service) {
    const balances = await this.callApi('service', 'getServiceBalances', [account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
    var toReturn = {
      balances
    }

    if(account.billingMode.toUpperCase() === 'POSTPAID') {
      //get the latest invoice
      const invoicesResponse = await this.callApi('invoice','getServiceInvoices',[account.accountId, service != undefined ? service.serviceId : account.services[0].serviceId])
      var latestInvoiceId = getLatestInvoiceId(invoicesResponse)
      if(latestInvoiceId) {
        const latestInvoice = await this.callApi('invoice','getServiceInvoice',[
          account.accountId,
          service != undefined ? service.serviceId : account.services[0].serviceId,
          latestInvoiceId
        ])
        toReturn.latestInvoice = latestInvoice
      }

    }
    return toReturn
  }

  static async getProvisionedProductsForService(account, service) {
    const planProducts = await this.callApi('service', 'getServicePlanProductInstances',[account.accountId, service.serviceId])
    var toReturn = {
      planProduct: planProducts[0]
    }

    //toReturn.planProduct = planProducts[0]


    const provisionedAddOns = await this.callApi('service','getServiceAddOnProductInstances',[
        account.accountId,
        service.serviceId
      ])

      const { oneoff: provisionedOneOffAddOns, recurring: provisionedRecurringAddOns } = getCategorisedAddOns(provisionedAddOns)

      toReturn.provisionedOneOffAddOns = provisionedOneOffAddOns.slice().sort((a,b) => a.rank - b.rank)
      toReturn.provisionedRecurringAddOns = provisionedRecurringAddOns

    return toReturn
  }

  static async getAccountBalancesForService(account, service) {
    const balances = await this.callApi('service', 'getServiceBalances', [account.accountId, service.serviceId])
    var toReturn = {
      balances
    }

    if(account.billingMode.toUpperCase() === 'POSTPAID') {
      //get the latest invoice
      const invoicesResponse = await this.callApi('invoice','getServiceInvoices',[account.accountId, service.serviceId])
      var latestInvoiceId = getLatestInvoiceId(invoicesResponse)
      if(latestInvoiceId) {
        const latestInvoice = await this.callApi('invoice','getServiceInvoice',[
          account.accountId,
          service.serviceId,
          latestInvoiceId
        ])
        toReturn.latestInvoice = latestInvoice
      }

    }
    return toReturn
  }
  
  static filterOutExpiredOffers(products) {
    if (products){
      return products.filter((product) => product.saleExpiryDate >= new Date())
    }
  }

  static async loadBalancesAndInvoices(account) {
    try {

      var result = {}

      // Plan
      // const planResponse = await this.service.getServicePlanProductInstances(
      //   this.cache.accountId,
      //   this.cache.serviceId
      // )

      // const plan = planResponse[0]

      // result.plan = plan

      // Balances
      //const balancesResponse = await this.service.getServiceBalances(this.cache.accountId, this.cache.serviceId)
      const balancesResponse = await this.callApi('service','getServiceBalances',[account.accountId,account.services[0].serviceId])
      const balances = getFilteredBalances(balancesResponse)

      if (Object.values(balances).length > 0) {


        if((balances && balances.dataBreakdown && balances.dataBreakdown.map((balance) => {
          if (!balance.quantity) {
            balance.quantity = 0
          }
        })))

        if((balances && balances.textBreakdown && balances.textBreakdown.map((balance) => {
          if (!balance.quantity) {
            balance.quantity = 0
          }
        })))

        if((balances && balances.voiceBreakdown && balances.voiceBreakdown.map((balance) => {
          if (!balance.quantity) {
            balance.quantity = 0
          }
        })))

        result.balances = balances
      }

      if (this.isPostpaid) {
        // Invoices
        //const invoicesResponse = await this.invoice.getServiceInvoices(this.cache.accountId, this.cache.serviceId)
        const invoicesResponse = await this.callApi('invoice','getServiceInvoices',[account.accountId,account.services[0].serviceId])

        // set bill estimation
        const estimatedInvoice = invoicesResponse.find(
          invoice => invoice.category === 'ESTIMATED'
        )

        result.estimatedInvoice = estimatedInvoice

        // user might not have a bill ready
        // const latestInvoiceId = getLatestInvoiceId(invoicesResponse)
        this.cache.latestInvoiceId = getLatestInvoiceId(invoicesResponse)

        if (this.cache.latestInvoiceId) {
          /*const latestInvoice = await this.invoice.getServiceInvoice(
            this.cache.accountId,
            this.cache.serviceId,
            this.cache.latestInvoiceId
          ) */
          const latestInvoice = await this.callApi('invoice','getServiceInvoice',[
            account.accountId,
            account.services[0].serviceId,
            this.cache.latestInvoiceId
          ])

          result.latestInvoice = latestInvoice
        }
      }

      /*const provisionedAddOns = await this.service.getServiceAddOnProductInstances(
        this.cache.accountId,
        this.cache.serviceId
      ) */
      const provisionedAddOns = await this.callApi('service','getServiceAddOnProductInstances',[
        account.accountId,
        account.services[0].serviceId
      ])

      const { oneoff: provisionedOneOffAddOns, recurring: provisionedRecurringAddOns } = getCategorisedAddOns(provisionedAddOns)

      result.provisionedOneOffAddOns = provisionedOneOffAddOns.slice().sort((a,b) => a.rank - b.rank)
      result.provisionedRecurringAddOns = provisionedRecurringAddOns

      /*const oneOffPlans = await this.catalog.getAddOnsProducts(this.cache.catalogId, {
        tags: "package,oneoff"
      }) */

      const oneOffPlans = await this.callApi('catalog','getAddOnsProducts',[this.cache.catalogId, {
        accountId: account.accountId,
        serviceId: account.services[0].serviceId,
        tags: "package,oneoff"
      }])

      result.oneOffPlans = oneOffPlans.slice().sort((a, b) => a.rank - b.rank)

      /*const recurringPlans =  await this.catalog.getAddOnsProducts(this.cache.catalogId, {
        tags: "package,recurring"
      }) */
      const recurringPlans =  await this.callApi('catalog','getAddOnsProducts',[this.cache.catalogId, {
        accountId: account.accountId,
        serviceId: account.services[0].serviceId,
        tags: "package,recurring"
      }])
      
      result.recurringPlans = recurringPlans.slice().sort((a, b) => a.rank - b.rank)

      //const recharges = await this.catalog.getRechargesProducts(this.cache.catalogId)
      const recharges = await this.callApi('catalog','getRechargesProducts',[this.cache.catalogId])

      result.recharges = recharges.slice().sort((a, b) => a.rank - b.rank)

      /*const addons = await this.catalog.getAddOnsProducts(this.cache.catalogId, {
        accountId: this.cache.accountId,
        serviceId: this.cache.serviceId,
        tags: "bolton,oneoff"
      })*/
      const addons = await this.callApi('catalog','getAddOnsProducts',[this.cache.catalogId, {
        accountId: account.accountId,
        serviceId: account.services[0].serviceId,
        tags: "bolton,oneoff"
      }])

      result.addons = addons.slice().sort((a, b) => a.rank - b.rank)

      //const openCashPayments = await this.confirmedBasket.getConfirmedBaskets(this.cache.userId, {status: "awaiting-cash-payment"})
      const openCashPayments = await this.callApi('confirmedBasket','getConfirmedBaskets',[this.cache.userId, {status: "awaiting-cash-payment"}])
      let activeCashPayments = []
      if (openCashPayments.length){
          activeCashPayments = openCashPayments.filter((confirmedBasket) => {
          let  paymentExpiryDate = new Date(confirmedBasket.creationDate)
          paymentExpiryDate.setDate(paymentExpiryDate.getDate() + 1)
          return paymentExpiryDate >= new Date()
        })
      }
      if (activeCashPayments) {
        activeCashPayments.sort(function(confirmedBasketA, confirmedBasketB) {
          return confirmedBasketB.creationDate - confirmedBasketA.creationDate
        })
      }
      result.openCashPayments = activeCashPayments

      //const vasOptions = await this.catalog.getBooleanValueAddedServicesProducts(this.cache.catalogId)
      let vasOptions = await this.callApi('catalog','getBooleanValueAddedServicesProducts',[this.cache.catalogId])
      vasOptions = this.filterOutExpiredOffers(vasOptions)
      result.vasOptions = vasOptions
      
      this.setCache({ 
        loading:false,
        ...result 
      })
    } catch (error) {
      throw error
    }
  }

  static async getTransformProducts() {
    return await this.callApi('catalog','getBalanceTransformProducts',[this.cache.catalogId])
  }

  static async loadPersonalDetails() {
    try {
      var result = {}
      const customer = await this.callApi('customer','getCustomer',[this.cache.customerId])
      result.customer = customer
      this.setCache({...result})
    } catch(error) {
      throw error
    }
  }
  static async loadPaymentPreference() {
    try {
      var result = {}
      const paymentPreferences = await this.callApi('customer','getPaymentPreferences',[this.cache.customerId])
      if(paymentPreferences !== undefined) {
        result.storedPayment = paymentPreferences[0]
      }
      this.setCache({...result})
    } catch(error) {
      throw error
    }
  }

  static async loadProductsCatalog() {
    try {



    } catch (error) {

    }
  }


  static async createBasket() {
    try {
      //const newBasket = await this.basket.createBasket(this.cache.userId)
      const newBasket = await this.callApi('basket','createBasket',[this.cache.userId])

      this.setCache({
        currentBasketId: newBasket.basketId,
				basket: newBasket
      })

      return newBasket
    } catch (error) {
      throw error
    }
  }

  static async removeBasket(basketId) {
    try {
      await this.callApi('basket','removeBasket',[this.cache.userId, basketId])
    } catch (error) {
      throw error
    }
  }

  static async addServiceProductToBasket(basket, product, extendedData, extra = {}) {
    //build up a basket item from the product
    const basketItem = this.createBasketItemFromProduct(product, true, true, extendedData, extra)
    basket.serviceItems.push(basketItem)
    //Set the extra fields on the basket
    basket.channel = this.configuration.channelName
    basket.currency = product.currency
    basket.customerId = this.cache.customerId
    basket.message = "Purchasing Product " + product.category
    basket.subTotal = basket.subTotal != null ? basket.subTotal + product.upfrontCost : product.upfrontCost
    basket.total = basket.total != null ? basket.total + product.upfrontCost : product.upfrontCost
    basket.unit = product.costUnit
    basket.vat = basket.vat != null ? basket.vat + product.upfrontVat : product.upfrontVat

    //const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
    const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])

    
    return updatedBasket
  }

  static async addAccountProductToBasket(basket, product, extendedData) {
    //build up a basket item from the product
    const basketItem = this.createBasketItemFromProduct(product, false, true, extendedData)
    basket.accountItems.push(basketItem)
    //Set the extra fields on the basket
    basket.channel = this.configuration.channelName
    basket.currency = product.currency
    basket.customerId = this.cache.customerId
    basket.message = "Purchasing Product " + product.category
    basket.subTotal = basket.subTotal != null ? basket.subTotal + product.upfrontCost : 0
    basket.total = basket.total != null ? basket.total + product.upfrontCost : 0
    basket.unit = product.costUnit
    basket.vat += product.upfrontVat

    //const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
    const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])

    return updatedBasket
  }

  static async addCustomerProductToBasket(basket, product, extendedData) {
    //build up a basket item from the product
    const basketItem = this.createBasketItemFromProduct(product, false, false, extendedData)
    basket.customerItems.push(basketItem)
    //Set the extra fields on the basket
    basket.channel = this.configuration.channelName
    basket.currency = product.currency
    basket.customerId = this.cache.customerId
    basket.message = "Purchasing Product " + product.category
    basket.subTotal = basket.subTotal != null ? basket.subTotal + product.upfrontCost : product.upfrontCost
    basket.total = basket.total != null ? basket.total + product.upfrontCost : product.upfrontCost
    basket.unit = product.costUnit
    basket.vat = basket.vat != null ? basket.vat + product.upfrontVat : product.upfrontVat

    //const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
    const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])


    return updatedBasket
  }

  static async addExtendedDataToAccountItem(basket, productId, extendedData){

    try {
      const item = basket.accountItems.find((item) => item.productId === productId)

      if (!item){
        throw new Error('No Item found')
      }

      if( (extendedData !== undefined && extendedData !== {})) {
        item.extendedData = JSON.stringify(extendedData)
      }
      //const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
      const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])
      return updatedBasket
    } catch (error) {

    }
  }

  static async addExtendedDataToServiceItem(basket, productId, extendedData){

    try {
      const item = basket.serviceItems.find((item) => item.productId === productId)

      if (!item){
        throw new Error('No Item found')
      }
      if( (extendedData !== undefined && extendedData !== {})) {
        item.extendedData = JSON.stringify(extendedData)
      }
      return basket
    } catch (error) {

    }
  }

  static async addExtendedDataToServiceDelItem(basket, productId, extendedData){

    try {
      const item = basket.serviceDelItems.find((item) => item.productId === productId)

      if (!item){
        throw new Error('No Item found')
      }

      if( (extendedData !== undefined && extendedData !== {})) {
        item.extendedData = JSON.stringify(extendedData)
      }
      //const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
      const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])
      return updatedBasket
    } catch (error) {

    }
  }

  static createBasketItemFromProduct(product, isServiceLevel, isAccountLevel, extendedData, extra = {}) {
    return {
      ...(isAccountLevel ? {accountId: this.cache.accountId} : {}),
      catalogId: this.cache.catalogId,
      category: product.category,
      currency: product.currency,
      productId: product.productId,
      ...(isServiceLevel ? {serviceId: this.cache.serviceId} : {}),
      subTotal: product.upfrontCost,
      total:  product.upfrontCost,
      unit: product.costUnit,
      vat: product.upfrontVat,
      ...( (extendedData !== undefined && extendedData !== {}) ? {extendedData: JSON.stringify(extendedData)} : undefined),
      ...extra
    }
  }

  static async removeServiceProductFromBasket(basket, product) {
    for(var i = 0; i<basket.serviceItems.length; i++) {
      if(basket.serviceItems[i].productId === product.productId) {
        basket.serviceItems.splice(i,1)
        basket.subTotal = basket.subTotal != null ? basket.subTotal - product.subTotal : 0
        basket.total = basket.total != null ? basket.total - product.total : 0
        //break out of the for loop so only one product is removed, not multiple
        break
      }
    }
    const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])
    return updatedBasket
  }

  static async confirmBasket(basket) {
    const toBeConfirmed = await this.callApi('basket','getBasket',[basket.userId,basket.basketId])
    //Just submit the exact basket as it is.
    const partialConfirmedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, toBeConfirmed])

    //get the full confirmed basket object from the API
    const confirmedBasket = await this.callApi('confirmedBasket','getConfirmedBasket',[partialConfirmedBasket.userId, partialConfirmedBasket.basketId])
    return confirmedBasket
  }

  static async payForBasketWithCreditCard(confirmedBasket, cardDetails) {
    const creditCardPayment = await this.callApi('confirmedBasket','createCreditCardPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])

    creditCardPayment.amount = confirmedBasket.total
    creditCardPayment.currency = confirmedBasket.currency
    creditCardPayment.cardDetails = {
      cardNumber: cardDetails.cardNumber,
      cvv: cardDetails.cvv,
      expiryDateMonth: cardDetails.expiryDateMonth,
      expiryDateYear: cardDetails.expiryDateYear,
      nameOnCard: cardDetails.nameOnCard
    }

    //const order = await this.confirmedBasket.modifyCreditCardPaymentMthd(confirmedBasket.userId, confirmedBasket.basketId,
    //                                                                       creditCardPayment.paymentId, creditCardPayment)
    const order = await this.callApi('confirmedBasket','modifyCreditCardPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId,
      creditCardPayment.paymentId, creditCardPayment])

    return order
  }

  static async payForBasketWithInstoreCash(confirmedBasket, cashDetails) {
    const instoreCashPayment = await this.callApi('confirmedBasket','createInstoreCashPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])
    instoreCashPayment.amount = confirmedBasket.total
    instoreCashPayment.currency = confirmedBasket.currency
    instoreCashPayment.recieptNumber = cashDetails.recieptNumber

    const order = await this.callApi('confirmedBasket','modifyInstoreCashPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId,
      instoreCashPayment.paymentId, instoreCashPayment])

    return order
  }

  static async payForBasketWithBalance(confirmedBasket) {
    //const balancePayment = await this.confirmedBasket.createServiceBalanceTransferPaymentMthd(confirmedBasket.userId, confirmedBasket.basketId)
    const balancePayment = await this.callApi('confirmedBasket','createServiceBalanceTransferPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])

    balancePayment.amount = confirmedBasket.total
    balancePayment.currency = confirmedBasket.currency
    balancePayment.accountId = this.cache.accountId
    balancePayment.serviceId = this.cache.serviceId

    //const order = await this.confirmedBasket.modifyServiceBalanceTransferPaymentMthd(confirmedBasket.userId, confirmedBasket.basketId,
    //                                                                       balancePayment.paymentId, balancePayment)
    const order = await this.callApi('confirmedBasket','modifyServiceBalanceTransferPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId,
      balancePayment.paymentId, balancePayment])

    return order
  }

  static async payForBasketWithCash(confirmedBasket) {
      const cashPayment = await this.callApi('confirmedBasket','createOnlineCashPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])
      return cashPayment
  }

  static async payForBasketWithHostedPayment(confirmedBasket) {
    try {
      const hostedPayment = await this.callApi('confirmedBasket','createHostedPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])
      return { basketRefId: confirmedBasket.basketRefId , gatewayURL: hostedPayment.gatewayURL }
    } catch(e) {
      //do nothing for now
    }
  }


  static async getOrder(basketRefId) {
    try {
      //await delay(this.orderRefreshTime)
      const orders = await this.callApi('orders','getOrders',[this.cache.customerId, { basketRefId }])
      if (orders && orders.length > 0) {
        return orders[0]
      }
      return null
    } catch(error) {
      return null
    }
  }

  static async getOrders(basketRefId, MAX_RETRIES = 120) {
    try {
      let retry = 0
      let orders = await this.callApi('orders','getOrders',[this.cache.customerId, { basketRefId }])
      let finishedOrder = false
      while (!finishedOrder && retry < MAX_RETRIES) {
        await delay(this.orderRefreshTime)
        orders = await this.callApi('orders','getOrders',[this.cache.customerId, { basketRefId }])
        retry++
        if (orders && orders.length > 0) {
          finishedOrder = orders[0].status === 'successful' 
                          || orders[0].status === 'failed'
                          || orders[0].status === 'payment processed' 
        }
      }
      if (orders && orders.length > 0) {
        return orders[0]
      }
      return null
    } catch(error) {
      if (error.response && error.response.body &&
        (error.response.body.messageCode === "SR00043" || error.response.body.code === 'DATA_SYNCHRONISING')){
          //Data is Syncing
          await delay(this.orderRefreshTime)
          return this.getOrders(basketRefId)
      } else {
        throw error
      }
    }
  }

  static async payForBasketWithStoredCard(confirmedBasket, tokenisedCard) {
    const storedCardPayment = await this.callApi('confirmedBasket','createTokenizedCardPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])
    
    storedCardPayment.gateway = this.configuration.paymentGateway
    var tokenizedCardDetails = {}
    tokenizedCardDetails.cardToken = tokenisedCard.cardToken
    tokenizedCardDetails.expMonth = tokenisedCard.expMonth
    tokenizedCardDetails.expYear = tokenisedCard.expYear
    tokenizedCardDetails.last4Digits = tokenisedCard.last4Digits
    tokenizedCardDetails.cardType = tokenisedCard.cardType
    storedCardPayment.tokenizedCardDetails = tokenizedCardDetails
    const order = await this.callApi('confirmedBasket','modifyTokenizedCardPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId,
      storedCardPayment.paymentId, storedCardPayment])

    return order
  }

  static async processConfirmedBasketNoPayment(confirmedBasket) {
    //const order = await this.confirmedBasket.modifyConfirmedBasket(confirmedBasket.userId, confirmedBasket.basketId, confirmedBasket)
    const noPaymentMethod = await this.callApi('confirmedBasket','createNoPaymentPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId])

    const order = await this.callApi('confirmedBasket','modifyNoPaymentPaymentMthd',[confirmedBasket.userId, confirmedBasket.basketId, noPaymentMethod.paymentId, noPaymentMethod])

    return order
  }

  static async pollOrderForStatus(customerId, orderId, startTime) {
    if (startTime === undefined){
      startTime = Date.now()
    }

    try {
      //var order = await this.orders.getOrder(customerId, orderId)
      // TODO Need to change this to use the GuestOrder API
      var order = await this.callApi('orders','getOrder',[customerId, orderId])
      
      if (order.status === "successful" || order.status === "awaiting-sim"){
        // returning order success OR failed
        return order;
      } else if (order.status === "failed"){
        // returning order success OR failed
        return order;
      } else if (startTime + this.orderRefreshTimeout < Date.now()) {
        // timeout reached
        order.status = content.errors.timeoutReached
        if (order.status === content.errors.timeoutReached){
          const timeoutError = new Error(order.status)
          timeoutError.code = this.timeoutReachedCode
          throw timeoutError
        }
      } else {
        // calling recursively
        await delay(this.orderRefreshTime)
        return this.pollOrderForStatus(customerId, orderId, startTime)
      }
    } catch(error){
      if (error.response && error.response.body &&
          (error.response.body.messageCode === "SR00043" || error.response.body.code === 'DATA_SYNCHRONISING')){
            // console.log(error);
        if (startTime + this.orderRefreshTimeout < Date.now()){
          // timeout reached
          return {status: content.errors.timeoutReached, code: this.timeoutReachedCode}
        } else {
          // calling recursively
          //this.configuration.orderRefreshTime
          await delay(this.orderRefreshTime)
          return this.pollOrderForStatus(customerId, orderId, startTime)
        }
      } else {
        throw error
      }
    }
  }

  static async pollGuestOrderForStatus(orderId) {
    await this.signInWithAgentUser({
      username: this.configuration.agentUsername,
      password: this.configuration.agentPassword
    })
    var order = await this.guestOrders.getOrder(orderId)

    if (order.status === "successful" || order.status === "failed"){
      // returning order success OR failed
      return order;
    } else if (order.creationDate + this.configuration.orderRefreshTimeout < Date.now()) {
      // timeout reached
      order.status = content.general.timeoutReached
      return order
    } else {
      // calling recursively
      await delay(this.configuration.orderRefreshTime)
      return this.pollGuestOrderForStatus(orderId)
    } 
  }

  static async pollConfirmedBasketForCashPaymentStatus(confirmedBasket, startTime) {
    if (startTime === undefined){
      startTime = Date.now()
    }
    try {
      //var order = await this.orders.getOrder(customerId, orderId)
      var toReturn = await this.callApi('confirmedBasket','getConfirmedBasket', [confirmedBasket.userId, confirmedBasket.basketId,])
      if (toReturn.processingStatus === "processed" || toReturn.processingStatus === "awaiting-cash-payment"){
        // returning order success OR failed
        return toReturn;
      } else if (toReturn.processingStatus === "failed"){
        // returning order success OR failed
        return toReturn;
      } else if (startTime + this.orderRefreshTimeout < Date.now()) {
        // timeout reached
        toReturn.status = content.errors.timeoutReached
        if (toReturn.status === content.errors.timeoutReached){
          throw error
        }
      } else {
        // calling recursively
        await delay(this.orderRefreshTime)
        return this.pollConfirmedBasketForCashPaymentStatus(confirmedBasket, startTime)
      }
    } catch(error){
      if (error.response && error.response.body &&
          (error.response.body.messageCode === "SR00043" || error.response.body.code === 'DATA_SYNCHRONISING')){
            // console.log(error);
        if (startTime + this.orderRefreshTimeout < Date.now()){
          // timeout reached
          return {status: content.errors.timeoutReached}
        } else {
          // calling recursively
          await delay(this.orderRefreshTime)
          return this.pollConfirmedBasketForCashPaymentStatus(confirmedBasket, startTime)
        }
      } else {
        throw error
      }
    }
  }

  static async getRegionOptions() {
    
    const {
      catalogId,
      selectedPlan
    } = this.cache

    const planBillingMode = selectedPlan.billingMode
    const signUpProducts = await (() => {
      //if (planBillingMode === this.configuration.prepaidBillingMode){
      if (planBillingMode === prepaidBillingMode){
        // return this.catalog.getSignUpPrePaidCustomerProducts(catalogId)
        return this.callApi('catalog','getSignUpPrePaidCustomerProducts',[catalogId])
      } else {
        // return this.catalog.getSignUpPostPaidCustomerProducts(catalogId)
        return this.callApi('catalog','getSignUpPostPaidCustomerProducts',[catalogId])
      }
    })()

    const signUpProduct = (() => {
      //if (planBillingMode === this.configuration.prepaidBillingMode){
      if (planBillingMode === prepaidBillingMode){
        return signUpProducts.find((product) => product.productLegacyId === prepaidSignUpLegId)
      } else {
        return signUpProducts.find((product) => product.productLegacyId === postpaidSignUpLegId)
      }
    })()
    const { link: { href: url } } = signUpProduct.options.find(item => item.code === "NUMBER_PREFIXES");

    const options = await this.retrieveDataFromUrl(url);

    return options
  }

  static async getRegionOptionsWithoutPlan() {
    
    const {
      catalogId,
    } = this.cache
    const signUpProducts = await (() => {
      //if (planBillingMode === this.configuration.prepaidBillingMode){
      // if (planBillingMode === prepaidBillingMode){
        // return this.catalog.getSignUpPrePaidCustomerProducts(catalogId)
        return this.callApi('catalog','getSignUpPrePaidCustomerProducts',[catalogId])
      // } else {
      //   // return this.catalog.getSignUpPostPaidCustomerProducts(catalogId)
      //   return this.callApi('catalog','getSignUpPostPaidCustomerProducts',[catalogId])
      // }
    })()

    const signUpProduct = (() => {
      //if (planBillingMode === this.configuration.prepaidBillingMode){
      // if (planBillingMode === prepaidBillingMode){
        return signUpProducts.find((product) => product.productLegacyId === prepaidSignUpLegId)
      // } else {
      //   return signUpProducts.find((product) => product.productLegacyId === postpaidSignUpLegId)
      // }
    })()

    const { link: { href: url } } = signUpProduct.options.find(item => item.code === "NUMBER_PREFIXES");

    const options = await this.retrieveDataFromUrl(url);

    return options
  }

  static async retrieveDataFromUrl(url) {
    try {
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.cache.token}`
        }
      });
  
      return response.data;
    } catch (error) {
      if (error.status === 401 && error.response !== undefined && 
        error.response.body !== undefined && (error.response.body.messageCode === 'SR0012' || error.response.body.code === 'TOKEN_EXPIRED')){
          await this.refreshToken();
          return this.retrieveDataFromUrl(url)
      }
    }
  }

  static async retrieveDataFromUrlWithoutTokenRefresh(url) {
    const response = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.cache.token}`
      }
    });

    return response.data;
  }

  static isSignedIn() {
    try {
      return this.cache.accountId !== undefined && this.cache.serviceId !== undefined && this.cache.token !== undefined
    } catch (error) {
      return false
    }
  }

  static async createNCABasket(selectedPlan) {
    let basket
    if (!this.isSignedIn()){
      await this.signInWithUsernameAndPassword({
        username: 'VADSA-GUEST-ROOT',
        password: 'Passw001'
      })
    }

    try {
      const {
        userId,
      } = this.cache

      // Create a new basket for the guest user
      basket = await this.callApi('basket','createBasket',[userId])

      //Add the selected plan to the basket (without extended data)
			if(selectedPlan) {
				basket = await this.addServiceProductToBasket(basket, selectedPlan)
			}
      
      this.setCache({cachedBasket: basket})

      return basket

    } catch (error) {
      throw error
    }
  }

  static async addPromoToNCABasket(promoPlans) {
    let basket
    const {
      userId,
      cachedBasket,
      selectedPlan,
    } = this.cache
    if (!this.isSignedIn()){
      await this.signInWithUsernameAndPassword({
        username: 'VADSA-GUEST-ROOT',
        password: 'Passw001'
      })
      await createNCABasket(selectedPlan)
    }

    basket = await this.callApi('basket','getBasket',[userId,cachedBasket.basketId])

    if (promoPlans && promoPlans.length) {
      basket = await this.addPromoPlansToBasket(cachedBasket.basketId, userId, promoPlans);
    }

  }

	static async removePromoFromNCABasket() {
		let basket
    const {
      userId,
      cachedBasket,
      selectedPlan,
    } = this.cache
		if (!this.isSignedIn()){
      await this.signInWithUsernameAndPassword({
        username: 'VADSA-GUEST-ROOT',
        password: 'Passw001'
      })
      await createNCABasket(selectedPlan)
    }

		basket = await this.callApi('basket','getBasket',[userId,cachedBasket.basketId])
		for(var i = 0; i < basket.serviceItems.length; i++) {
			if(basket.serviceItems[i].productId !== selectedPlan.productId) {
				this.removeServiceProductFromBasket(basket, basket.serviceItems[i])
			}
		}

	}


  static async updateNCABasket() {
    let basket
    const {
      userId,
      catalogId,
      cachedBasket,
      selectedPlan,
      existingSIM,
      eSIMDispatch
    } = this.cache

    if (!this.isSignedIn()){
      await this.signInWithUsernameAndPassword({
        username: 'VADSA-GUEST-ROOT',
        password: 'Passw001'
      })
      await createNCABasket(selectedPlan)
    }

    try {
      this.unsetCache('hasSimOrder')

      // retrieve existing basket or create a new basket
      // basket = await this.basket.createBasket(userId)
      basket = await this.callApi('basket','getBasket',[cachedBasket.userId,cachedBasket.basketId])

      //add the base plan (PayG) to the basket
      const basePlan = await this.getBasePlan(catalogId)

      let extendedDataServLevel = {}
      extendedDataServLevel.receiptRequired = false;
      extendedDataServLevel.agentReferalCode = "";

      if (existingSIM){
        this.setCache({hasSimOrder: true})
        const assignSimProducts = await this.callApi('catalog','getAssignSimProducts',[catalogId])
        const assignSimProduct = assignSimProducts.find((product) => product.productLegacyId === assignSimLegacyId)
        const assignExtendedData = {
          ICCID: existingSIM
        }
        basket = await this.addServiceProductToBasket(basket, assignSimProduct, assignExtendedData)
      }

      if (this.cache.referralCode) {
        extendedDataServLevel.agentReferalCode = this.cache.referralCode
      }

      // add the bundle add on to the basket at the service level
      if (selectedPlan) {
        basket = await this.addExtendedDataToServiceItem(basket, selectedPlan.productId, extendedDataServLevel)
      }
      
      if (basePlan !== undefined || !registerUser){
        basket = await this.addServiceProductToBasket(basket, basePlan, extendedDataServLevel)
      }

      // Add the dispatchSIM product to the basket if necessary
      if (!existingSIM){
        const dispatchSimProducts = await this.callApi('catalog','getDispatchSimProducts',[catalogId])
        const dispatchSimProduct = dispatchSimProducts.find((product) => eSIMDispatch ? product.productLegacyId === dispatchESimLegacyId : product.productLegacyId === dispatchSimLegacyId)

        const dispatchSimExtendedData = {
          reason: "NewCustomer"
        }
        basket = await this.addServiceProductToBasket(basket, dispatchSimProduct, dispatchSimExtendedData)
      }

      basket = await this.callApi('basket','getBasket',[basket.userId, basket.basketId])

      return { basketId: basket.basketId, basketUserId: basket.userId }
      
    } catch (error) {
      throw error
    }
  }

  static async addPromoPlansToBasket(basketId, basketUserId, promoPlans) {
    try {
      let basket = await this.callApi('basket','getBasket',[basketUserId, basketId])

    // promtional addon gets added to the basket at the service level
    if(promoPlans) {
      for(var i = 0; i < promoPlans.length; i++) {
        basket = await this.addServiceProductToBasket(basket, promoPlans[i])
      }
    }

    basket = await this.callApi('basket','getBasket',[basketUserId, basket.basketId])

    return basket
    
  } catch (error) {
    throw error
  }

  }

  static async submitNCABasket(params = {}) {
      try {
        const {
          basketUserId,
          basketId,
          existingSIM,
          customerDetails,
          catalogId,
          selectedPlan,
          registerUser,
					promoPlans
        } = this.cache
      let basket = await this.callApi('basket','getBasket',[basketUserId, basketId])

      // based on the plan that was selected we either need the postpaid or prepaid sign up product
      // TODO currently hardcoded to postpaid item
      const planBillingMode = selectedPlan && selectedPlan.billingMode

      const details = {
        "region": customerDetails.region,
        "personalDetails": {
          "firstName": customerDetails.firstName,
          "lastName": customerDetails.lastName,
          "dob": new Date(customerDetails.dobYear + '-' + customerDetails.dobMonth + '-' + customerDetails.dobDay).toISOString(),
          "emailAddress": customerDetails.email,
          "contactNumber": customerDetails.phoneNumber,
          "title" : customerDetails.title,
          "taxCode": customerDetails.taxCode
        },
        "deliveryAddress": {
          "name": customerDetails.firstName + ' ' + customerDetails.lastName,
          "addressLine1": customerDetails.address1,
          "addressLine2": customerDetails.address2,
          "city": customerDetails.city,
          "state": customerDetails.county,
          "country": "Mexico",
          "zip": customerDetails.postcode,
          "phoneNumber": customerDetails.phoneNumber
        },
        "identificationDetails": {
          "identificationNumber": customerDetails.idNumber,
          "identificationType": "ID"
        }
      }
      
      let eliotJourney = params && params.partner === 'ELIOT'

      const prepaidSignUpProductLegacyId = eliotJourney ? eliotSignUpLegacyId : prepaidSignUpLegId

      const signUpProducts = await (() => {
        //if (planBillingMode === this.configuration.prepaidBillingMode){
        if (registerUser) {
          return this.callApi('catalog','getSignUpCustomerTechnicalOffers',[catalogId])
        } else if (!planBillingMode || (planBillingMode === prepaidBillingMode)){
          // return this.catalog.getSignUpPrePaidCustomerProducts(catalogId)
          return this.callApi('catalog','getSignUpPrePaidCustomerProducts',[catalogId])
        } else {
          return this.callApi('catalog','getSignUpPostPaidCustomerProducts',[catalogId])
          // return this.catalog.getSignUpPostPaidCustomerProducts(catalogId)
        }
      })()

      const signUpProduct = (() => {
        //if (planBillingMode === this.configuration.prepaidBillingMode){
        if (registerUser) {
          return signUpProducts.find((product) => product.productLegacyId === appSignUpLegId)
        } else if (!planBillingMode || (planBillingMode === prepaidBillingMode)){
          return signUpProducts.find((product) => product.productLegacyId === prepaidSignUpProductLegacyId)
        } else {
          return signUpProducts.find((product) => product.productLegacyId === postpaidSignUpLegId)
        }
      })()

      // basket = await this.basket.getBasket(userId, basket.basketId)
      basket = await this.callApi('basket','getBasket',[basketUserId, basket.basketId])

      const extendedData = {
        // ...signUpProduct,
        accountNumber: details.region,
        personalDetails: details.personalDetails,
        deliveryAddress: details.deliveryAddress,
        // recurringPaymentDetails: details.recurringPaymentDetails,
        identificationDetails: details.identificationDetails,
        //TODO this needs to come from the cache or passed into this function
        receiptRequired: false
      }

      basket = await this.addAccountProductToBasket(basket, signUpProduct, extendedData)

      if(registerUser) {
        const registerUserProducts = await this.callApi('catalog','getRegisterUserTechnicalOffers',[catalogId])
        const registerUserProduct = registerUserProducts.find((product) => product.productLegacyId === registerUserLegacyId)
        const registerExtendedData = {
          registrationDetails: {
            username: customerDetails.email,
            operatorCode: 'VADSA',
            dob:new Date(customerDetails.dobYear + '-' + customerDetails.dobMonth + '-' + customerDetails.dobDay).toISOString(),
            firstName: customerDetails.firstName,
            lastName: customerDetails.lastName,
            emailAddress: customerDetails.email,
            gender: customerDetails.title === 'Hombre' ? 'M' : 'F',
            guest: false,
            password: customerDetails.password,
            primaryContactNumberCountryCode: '+52',
            twoFactorAuthNumberCountryCode: '+52'
          }
        }
        basket = await this.addCustomerProductToBasket(basket,registerUserProduct,registerExtendedData)

        const activationProducts =  await this.callApi('catalog','getModifyServiceDetailsProducts',[this.cache.catalogId])
        const activationProduct = activationProducts.find((product) => product.productLegacyId === this.configuration.activationProduct)

        basket = await this.addServiceProductToBasket(basket, activationProduct)
      }

      basket = await this.callApi('basket','getBasket',[basketUserId, basket.basketId])
      basket = await this.confirmBasket(basket)

      return basket
      
    } catch (error) {
      throw error
    }
  }

  static async submitNCABasketWithoutPlan(params = {}) {
    try {
			const {
				basketUserId,
				basketId,
				existingSIM,
				customerDetails,
				catalogId,
				selectedPlan,
				registerUser,
				promoPlans
			} = this.cache

			// promtional addon gets added to the basket at the service level
			let basket = await this.callApi('basket','getBasket',[basketUserId, basketId])

			//add the base plan (PayG) to the basket
			const basePlan = await this.getBasePlan(catalogId)
			let extendedDataServLevel = {}
			if (existingSIM){
				this.setCache({hasSimOrder: true})
        const assignSimProducts = await this.callApi('catalog','getAssignSimProducts',[catalogId])
        const assignSimProduct = assignSimProducts.find((product) => product.productLegacyId === assignSimLegacyId)
				extendedDataServLevel = {
					ICCID: existingSIM
				}

        basket = await this.addServiceProductToBasket(basket, assignSimProduct, extendedDataServLevel)
			}

			if (this.cache.referralCode) {
				extendedDataServLevel.agentReferalCode = this.cache.referralCode
			}
			
			if (basePlan !== undefined){
				basket = await this.addServiceProductToBasket(basket, basePlan, extendedDataServLevel)
			}

			// based on the plan that was selected we either need the postpaid or prepaid sign up product
			// TODO currently hardcoded to postpaid item
			const planBillingMode = selectedPlan && selectedPlan.billingMode

			const details = {
				"region": customerDetails.region,
				"personalDetails": {
					"firstName": customerDetails.firstName,
					"lastName": customerDetails.lastName,
					"dob": new Date(customerDetails.dobYear + '-' + customerDetails.dobMonth + '-' + customerDetails.dobDay).toISOString(),
					"emailAddress": customerDetails.email,
					"contactNumber": customerDetails.phoneNumber,
					"title" : customerDetails.title,
					"taxCode": customerDetails.taxCode
				},
				"deliveryAddress": {
					"name": customerDetails.firstName + ' ' + customerDetails.lastName,
					"addressLine1": customerDetails.address1,
					"addressLine2": customerDetails.address2,
					"city": customerDetails.city,
					"state": customerDetails.county,
					"country": "Mexico",
					"zip": customerDetails.postcode,
					"phoneNumber": customerDetails.phoneNumber
				},
				"identificationDetails": {
					"identificationNumber": customerDetails.idNumber,
					"identificationType": "ID"
				}
			}
			
			let eliotJourney = params && params.partner === 'ELIOT'

			const prepaidSignUpProductLegacyId = eliotJourney ? eliotSignUpLegacyId : prepaidSignUpLegId

			const signUpProducts = await (() => {
				//if (planBillingMode === this.configuration.prepaidBillingMode){
				if (registerUser) {
					return this.callApi('catalog','getSignUpCustomerTechnicalOffers',[catalogId])
				} else if (!planBillingMode || (planBillingMode === prepaidBillingMode)){
					// return this.catalog.getSignUpPrePaidCustomerProducts(catalogId)
					return this.callApi('catalog','getSignUpPrePaidCustomerProducts',[catalogId])
				} else {
					return this.callApi('catalog','getSignUpPostPaidCustomerProducts',[catalogId])
					// return this.catalog.getSignUpPostPaidCustomerProducts(catalogId)
				}
			})()

			const signUpProduct = (() => {
				//if (planBillingMode === this.configuration.prepaidBillingMode){
				if (registerUser) {
					return signUpProducts.find((product) => product.productLegacyId === appSignUpLegId)
				} else if (!planBillingMode || (planBillingMode === prepaidBillingMode)){
					return signUpProducts.find((product) => product.productLegacyId === prepaidSignUpProductLegacyId)
				} else {
					return signUpProducts.find((product) => product.productLegacyId === postpaidSignUpLegId)
				}
			})()

			// basket = await this.basket.getBasket(userId, basket.basketId)
			basket = await this.callApi('basket','getBasket',[basketUserId, basket.basketId])

			const extendedData = {
				// ...signUpProduct,
				accountNumber: details.region,
				personalDetails: details.personalDetails,
				deliveryAddress: details.deliveryAddress,
				// recurringPaymentDetails: details.recurringPaymentDetails,
				identificationDetails: details.identificationDetails,
				//TODO this needs to come from the cache or passed into this function
				receiptRequired: false
			}

			basket = await this.addAccountProductToBasket(basket, signUpProduct, extendedData)

			if(registerUser) {
				const registerUserProducts = await this.callApi('catalog','getRegisterUserTechnicalOffers',[catalogId])
				const registerUserProduct = registerUserProducts.find((product) => product.productLegacyId === registerUserLegacyId)
				const registerExtendedData = {
					registrationDetails: {
						username: customerDetails.email,
						operatorCode: 'VADSA',
						dob:new Date(customerDetails.dobYear + '-' + customerDetails.dobMonth + '-' + customerDetails.dobDay).toISOString(),
						firstName: customerDetails.firstName,
						lastName: customerDetails.lastName,
						emailAddress: customerDetails.email,
						gender: customerDetails.title === 'Hombre' ? 'M' : 'F',
						guest: false,
						password: customerDetails.password,
						primaryContactNumberCountryCode: '+52',
						twoFactorAuthNumberCountryCode: '+52'
					}
				}
				basket = await this.addCustomerProductToBasket(basket,registerUserProduct,registerExtendedData)

				const activationProducts =  await this.callApi('catalog','getModifyServiceDetailsProducts',[this.cache.catalogId])
				const activationProduct = activationProducts.find((product) => product.productLegacyId === this.configuration.activationProduct)

				basket = await this.addServiceProductToBasket(basket, activationProduct)
			}

			if(promoPlans) {
				basket = await this.addPromoPlansToBasket(basket.basketId, basket.userId, promoPlans)
			}

			basket = await this.callApi('basket','getBasket',[basketUserId, basket.basketId])
			basket = await this.confirmBasket(basket)

			return basket
			
		} catch (error) {
			throw error
		}
  }

  static async submitNCAHostedPaymentBasket(params = {}) {
    try {
      if (this.cache.basketSubmitted) {
        const { basketId, basketUserId } = await this.createNCABasket();
        this.setCache({ basketId, basketUserId })
      }
      
      const basket = await this.submitNCABasket(params)
      this.setCache({
        basketSubmitted: true
      })

      return  await this.payForBasketWithHostedPayment(basket)
    } catch(error) {
      throw error
    }
  }

  static async submitNCACashPaymentBasket(params = {}) {
    try {
      if (this.cache.basketSubmitted) {
        const { basketId, basketUserId } = await this.createNCABasket();
        this.setCache({ basketId, basketUserId })
      }
      
      const basket = await this.submitNCABasket(params)
      this.setCache({
        basketSubmitted: true
      })

      const cashPayment = await this.payForBasketWithCash(basket)
      return {basket, 
              cashPayment}
    } catch(error) {
      throw error
    }
  }

  static async submitNCANoPaymentBasket(params = {}) {
    let basket;

    try {
      basket = await this.createNCABasket()
      this.setCache({
        basketUserId: basket.userId,
        basketId: basket.basketId
      })

      basket = await this.submitNCABasketWithoutPlan(params)
      
      const order = await this.processConfirmedBasketNoPayment(basket)

      return order
    } catch(error) {
      throw error
    }
  }

  static async checkoutNewCustomerWithPlan(cardDetails) {
    let basket
    let order

      await this.signInWithUsernameAndPassword({
        username: 'VADSA-GUEST-ROOT',
        password: 'Passw001'
      })

      try {
        const {
          userId,
          customerId,
          catalogId,
          selectedPlan,
          customerDetails,
          existingSIM
        } = this.cache

        this.unsetCache('hasSimOrder')

      // retrieve existing basket or create a new basket
      // basket = await this.basket.createBasket(userId)
      basket = await this.callApi('basket','createBasket',[userId])

      //add the base plan (PayG) to the basket
      const basePlan = await this.getBasePlan(catalogId)
      let extendedDataServLevel = {}
      if (existingSIM){
        this.setCache({hasSimOrder: true})
        extendedDataServLevel = {
          ICCID: existingSIM
        }
      }

      if (this.cache.agent && this.cache.agent.user) {
        extendedDataServLevel.agentReferalCode = this.cache.agent.user.userName
      }
      
      if (basePlan !== undefined){
        basket = await this.addServiceProductToBasket(basket, basePlan, extendedDataServLevel)
      }

      // Add the dispatchSIM product to the basket if necessary
      if (!existingSIM){
        const dispatchSimProducts = await this.callApi('catalog','getDispatchSimProducts',[catalogId])
        const dispatchSimProduct = dispatchSimProducts.find((product) => product.productLegacyId === dispatchSimLegacyId)

        const dispatchSimExtendedData = {
          reason: "NewCustomer"
        }
        basket = await this.addServiceProductToBasket(basket, dispatchSimProduct, dispatchSimExtendedData)
      }

      //if we have a recurring plan, then add the card details to the item in the basket
      //so that they can be correctly tokenised and sent through.
      if (selectedPlan.recurring){
        extendedDataServLevel.CreditCard = { cardDetails: {
          cardNumber: encryptSensitiveString(cardDetails.cardNumber, this.configuration.encryptionKey),
          expiryDateMonth: cardDetails.expiryDateMonth,
          expiryDateYear: cardDetails.expiryDateYear,
          nameOnCard: cardDetails.nameOnCard,
          last4Digits: cardDetails.cardNumber.substring(cardDetails.cardNumber.length-4)
        } }
      }
      // add the bundle add on to the basket at the service level
      basket = await this.addServiceProductToBasket(basket, selectedPlan, extendedDataServLevel)

      // based on the plan that was selected we either need the postpaid or prepaid sign up product
      // TODO currently hardcoded to postpaid item
      const planBillingMode = selectedPlan.billingMode

      const details = {
        "region": customerDetails.region,
        "personalDetails": {
          "firstName": customerDetails.firstName,
          "lastName": customerDetails.lastName,
          "dob": new Date(customerDetails.dobYear + '-' + customerDetails.dobMonth + '-' + customerDetails.dobDay).toISOString(),
          "emailAddress": customerDetails.email,
          "contactNumber": customerDetails.phoneNumber,
          "title" : customerDetails.title,
          "taxCode": customerDetails.taxCode
        },
        "deliveryAddress": {
          "name": customerDetails.firstName + ' ' + customerDetails.lastName,
          "addressLine1": customerDetails.address1,
          "addressLine2": customerDetails.address2,
          "city": customerDetails.city,
          "state": customerDetails.county,
          "country": "Mexico",
          "zip": customerDetails.postcode,
          "phoneNumber": customerDetails.phoneNumber
        },
        "recurringPaymentDetails": planBillingMode === prepaidBillingMode ? {} : {
          "directDebitDetails": {
            "bankAccountNumber": "01068009",
            "branchSortCode": "804718",
            "nameOnAccount": cardDetails.nameOnCard
          }
        },
        "oneOffPaymentDetails" : {
          "cardDetails": {
            "cardNumber": cardDetails.cardNumber
          }
        },
        "identificationDetails": {
          "identificationNumber": customerDetails.idNumber,
          "identificationType": "ID"
        }
      }

      const signUpProducts = await (() => {
        //if (planBillingMode === this.configuration.prepaidBillingMode){
        if (planBillingMode === prepaidBillingMode){
          // return this.catalog.getSignUpPrePaidCustomerProducts(catalogId)
          return this.callApi('catalog','getSignUpPrePaidCustomerProducts',[catalogId])
        } else {
          // return this.catalog.getSignUpPostPaidCustomerProducts(catalogId)
          return this.callApi('catalog','getSignUpPostPaidCustomerProducts',[catalogId])
        }
      })()

      const signUpProduct = (() => {
        //if (planBillingMode === this.configuration.prepaidBillingMode){
        if (planBillingMode === prepaidBillingMode){
          return signUpProducts.find((product) => product.productLegacyId === prepaidSignUpLegId)
        } else {
          return signUpProducts.find((product) => product.productLegacyId === postpaidSignUpLegId)
        }
      })()

      // basket = await this.basket.getBasket(userId, basket.basketId)
      basket = await this.callApi('basket','getBasket',[userId, basket.basketId])
      basket = await this.addAccountProductToBasket(basket, signUpProduct)

      const extendedData = {
        ...signUpProduct,
        accountNumber: details.region,
        personalDetails: details.personalDetails,
        deliveryAddress: details.deliveryAddress,
        recurringPaymentDetails: details.recurringPaymentDetails,
        identificationDetails: details.identificationDetails,
        //TODO this needs to come from the cache or passed into this function
        receiptRequired: false
      }
      basket = await this.addExtendedDataToAccountItem(basket, signUpProduct.productId, extendedData)
      // basket = await this.basket.getBasket(userId, basket.basketId)
      basket = await this.callApi('basket','getBasket',[userId, basket.basketId])
      basket = await this.confirmBasket(basket)
      order = await this.payForBasketWithCreditCard(basket, cardDetails)
      order = await this.pollOrderForStatus(customerId, order.orderId)

      //only clear cache if order was successful, to give the customer the chance to retry otherwise.
      if (order && (order.status === 'successful' || order.status === 'awaiting-sim')){
        this.clearBasketCache({
          orderId: order.orderId,
          order: order
        })
        this.unsetCache('existingSIM')
      }
      return order
    } catch (error) {
      throw error
    }
  }

  static async checkoutServiceProduct(product, extendedData) {
    let basket
    let order
    try {
      basket = await this.createBasket()
      basket = await  this.addServiceProductToBasket(basket,product, extendedData)
      basket = await this.confirmBasket(basket)

      order = await this.processConfirmedBasketNoPayment(basket)
      order = await this.pollOrderForStatus(this.cache.customerId, order.orderId)

      this.unsetCache('currentBasketId')
      this.unsetCache('basket')

      return order
    } catch (error) {
      throw error
    }
  }


  static async checkoutServiceDelProduct(product, extendedData) {
    let basket
    let order
    try {
      basket = await this.createBasket()
      basket = await  this.addDeleteServiceProductToBasket(basket,product,extendedData)
      basket = await this.confirmBasket(basket)

      order = await this.processConfirmedBasketNoPayment(basket)
      order = await this.pollOrderForStatus(this.cache.customerId, order.orderId)

      this.unsetCache('currentBasketId')
      this.unsetCache('basket')

      return order
    } catch (error) {
      throw error
    }
  }
  static async checkoutCustomerProduct(product, extendedData) {
    let basket
    let order
    try {
      basket = await this.createBasket()
      basket = await  this.addCustomerProductToBasket(basket,product, extendedData)
      basket = await this.confirmBasket(basket)

      order = await this.processConfirmedBasketNoPayment(basket)
      order = await this.pollOrderForStatus(this.cache.customerId, order.orderId)

      this.unsetCache('currentBasketId')
      this.unsetCache('basket')

      return order
    } catch (error) {
      throw error
    }
  }


  static async checkoutAccountProduct(product, extendedData) {
    let basket
    let order
    try {
      basket = await this.createBasket()
     
      basket = await  this.addAccountProductToBasket(basket,product, extendedData)

      basket = await this.confirmBasket(basket)
     
      order = await this.processConfirmedBasketNoPayment(basket)
     
      order = await this.pollOrderForStatus(this.cache.customerId, order.orderId)

      this.unsetCache('currentBasketId')
      this.unsetCache('basket')

      return order
    } catch (error) {
     
      throw error
    }
  }

  static async getBasePlan(catalogId) {
    // const plans = await this.catalog.getPlansProducts(catalogId)
    const plans = await this.callApi('catalog','getPlansProducts',[catalogId])

    return plans.find(
      plan => plan.productLegacyId === basePrepaidPlan
    )
  }
  static async getBasePlan(catalogId) {
    // const plans = await this.catalog.getPlansProducts(catalogId)
    const plans = await this.callApi('catalog','getPlansProducts',[catalogId])

    return plans.find(
      plan => plan.productLegacyId === basePrepaidPlan
    )
  }

  static async addDeleteServiceProductToBasket(basket, product, extendedData) {
    //build up a basket item from the product
    const basketItem = this.createBasketItemFromProduct(product, true, true, extendedData)
    basket.serviceDelItems.push(basketItem)
    //Set the extra fields on the basket
    basket.channel = this.configuration.channelName
    basket.currency = product.currency
    basket.customerId = this.cache.customerId
    basket.message = "Deleting Product " + product.category
    basket.subTotal = basket.subTotal || 0
    basket.total = basket.total || 0
    basket.unit = product.costUnit
    basket.vat = basket.vat || 0

    // const updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
    const updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])

    return updatedBasket
  }

  static async checkoutServiceProducts(productsWithExtendedData) {
    let basket
    let order
    
    try {
      basket = await this.createBasket()
      
      for (var i in productsWithExtendedData) {
        var { product, extendedData } = productsWithExtendedData[i];
        const basketItem = this.createBasketItemFromProduct(product, true, true, extendedData)
        basket.serviceItems.push(basketItem)
      }

      // enrich the basket
      basket.channel = this.configuration.channelName
      basket.customerId = this.cache.customerId
      basket.message = "Purchasing Products"
      basket.subTotal = 0
      basket.total = 0
      basket.vat = 0
      
      // var updatedBasket = await this.basket.modifyBasket(basket.userId, basket.basketId, basket)
      var updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])
      var confirmedBasket = await this.confirmBasket(updatedBasket)
  
      order = await this.processConfirmedBasketNoPayment(confirmedBasket)
      order = await this.pollOrderForStatus(this.cache.customerId, order.orderId)

      this.unsetCache('currentBasketId')
      this.unsetCache('basket')
  
      return order
    } catch (error) {
      throw error
    }
  }

  //Activates a service - ONLY USED DURING REGISTRATION
  static async activateService(customerId) {
    //get the account and service from the customer
    await this.cacheUserAccountInformation(customerId)

    //Get the relevant activate product
    const activationProducts =  await this.callApi('catalog','getModifyServiceDetailsProducts',[this.cache.catalogId])
    const activationProduct = activationProducts.find((product) => product.productLegacyId === this.configuration.activationProduct)

    const order = await this.checkoutServiceProduct(activationProduct)
    return order
  }

  //Create a cache basket so we can handle multiple items
  static async addToCacheBasket(product) {
    const {
      basket
    } = this.cache
    let updatedBasket
    if(basket === undefined || basket.serviceItems === undefined || basket.serviceItems.length === 0) {
      // create the cached basket before adding product
      updatedBasket = await this.createBasket()
      updatedBasket = await this.addServiceProductToBasket(updatedBasket,product)
    } else {
      //Cached basket exists, so just add the product
      updatedBasket = await this.addServiceProductToBasket(basket,product)
    }
    this.setCache({basket:updatedBasket})
    return updatedBasket
  }

  //Clear the cache basket
  static async clearCacheBasket() {
    const {
      basket
    } = this.cache

    if(basket !== undefined && basket.serviceItems.length > 0) {
      basket.serviceItems=[]
      let updatedBasket = await this.callApi('basket','modifyBasket',[basket.userId, basket.basketId, basket])
      this.setCache({basket:updatedBasket})
    }
  }

	static async recreateBasket(oldBasket) {
		let newBasket = await this.createBasket()

		for(var i = 0; i < oldBasket.serviceItems.length; i++) {
			newBasket = await this.addToCacheBasket(oldBasket.serviceItems[i])
		}
		return newBasket
	}

  static async removeProductFromBasket(product) {
    const {
      basket
    } = this.cache
    for(var i = 0; i < this.cache.basket.serviceItems.length; i++) {
      if(this.cache.basket[i] === product) {
        this.cache.basket.splice(i,1)
      }
    }
  }

  //Add cached payment method
  static setCachedPayment(paymentProduct) {
    if(this.cache.cachedPayment !== undefined) {
      this.cache.cachedPayment = paymentProduct
    } else {
      var cache = {}
      cache.cachedPayment = paymentProduct
      this.setCache(cache)
    }
  }

  //Clear the cache basket
  static clearCachedPayment() {
    this.cache.cachedPayment = undefined
  }
  
  //Wrapper function to make API calls and handle generic errors (token expired, Upgrade needed)
  static async callApi(api, apiName, parameters = [], startTime) {
    if (startTime === undefined){
      startTime = Date.now()
    }
    if (typeof this[api][apiName] === "function") {
      try {
        let response = await this[api][apiName](...parameters)
        return response
      } catch(error) {
        if (startTime + this.apiTimeout < Date.now()) {
          // timeout reached
            throw {response: { body: {messageCode: 'SR9999', message:'Timeout Reached'}}}
        } else if (error.status === 401 && error.response !== undefined && 
          error.response.body !== undefined && (error.response.body.messageCode === 'SR0012' || error.response.body.code === 'TOKEN_EXPIRED')){
            // probably a bit ugly, but we need to check if the reason the token is expired
            // is because the user is now locked (lost/damaged/stolen), in which case, they need to be
            // logged out
            try {
              //Token has expired, get a new one
              await this.refreshToken(startTime)
              let response = await this.callApi(api, apiName, parameters, startTime)
              return response
            } catch(error) {
              if(error.response !== undefined && error.response.body !== undefined && 
                (error.response.body.messageCode === 'SR0004' || error.response.body.code === 'USER_LOCKED')) {
                if(this.configuration.logoutFunction && typeof this.configuration.logoutFunction === "function") {
                  this.configuration.logoutFunction()
                }
              }
            }
        } else if(error.response && error.response.body &&
            (error.response.body.messageCode === "SR0043" || error.response.body.code === 'API_CLIENT_VERSION_NOT_SUPPORTED')) {
              //Need upgrade, log customer out with error message
              if(this.configuration.logoutFunction && typeof this.configuration.logoutFunction === "function") {
                this.configuration.logoutFunction()
              }
              throw error
        } else if (error.response && error.response.body &&
          (error.response.body.messageCode === "SR00043" || error.response.body.code === 'DATA_SYNCHRONISING')){
            // Customer syncing, try again
            await delay(this.customerRefreshTime)
            let response = await this.callApi(api, apiName, parameters, startTime)
            return response
        } else {
          throw error
        }
      }
    } 
  }

}


// Extend Client class with generated client classes
// e.g. var api = new client.ApiClient()
// Object.assign(Client, apis)

// Filter only the api calls e.g. CustomerApi
// Filter deprecated api calls e.g. ZTheCustomerAPIApi
const apiKeys = Object.keys(apis)
	.filter(key => key.substring(key.length - 3, key.length) === 'Api')
	.filter(key => key.substring(0, 4) !== 'ZThe')

// Extend the class with a reflection of clients api calls
/* .e.g:

  var accounts = client.accounts.getAccounts({})

 * Same as:

  var api = new ClientApi()
  var account = new AccountApi(api)
  var accounts = await account.getAccounts({})

 *
 */

for (const key of apiKeys) {
  const name = key.slice(0, key.length - 3)
  const camelName = name.charAt(0).toLowerCase() + name.slice(1)

  Object.defineProperty(Client, camelName, {
    enumerable: true,
    get() {
    	return new apis[key](Client.api)
    },
  })
}

Client.setupApi()

// For debugging in development mode
if (process.env.NODE_ENV === "development") {
  if (window) window.client = Client
}