import * as React from "react"
import PropTypes from 'prop-types';

import Svg, { G, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function Download(props) {
  const { size } = props;

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G fill="#019ABC" fillRule="nonzero">
        <Path d="M19.677 6.086L13.355.172a.425.425 0 00-.301-.129H1.247C.56.043 0 .603 0 1.29v22.344c0 .689.56 1.248 1.247 1.248h17.29c.689 0 1.248-.56 1.248-1.248V6.43c.043-.129-.022-.258-.108-.344zm-5.892-.258v-3.14l3.355 3.14h-3.355zm-1.097 1.57h5.57v15.957H1.57V1.59h10.667v5.334c-.022.258.215.473.451.473z" />
        <Path d="M10.688 9.333a.477.477 0 00-.473-.473h-.624a.477.477 0 00-.473.473v9.29l-3.44-3.462c-.194-.193-.474-.193-.646 0l-.473.43a.488.488 0 00-.129.344.61.61 0 00.13.345l5.01 5.01a.488.488 0 00.344.13.61.61 0 00.344-.13l5.01-5.01c.194-.194.194-.474 0-.646l-.43-.43c-.193-.193-.472-.193-.644 0l-3.506 3.398V9.333z" />
      </G>
    </Svg>
  )
}


Download.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  
Download.defaultProps = {
    size: '16'
}

export default Download
