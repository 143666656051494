import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Redirect } from 'react-router-dom'

import client from '@sart/client'
import {
  Subhead,
  Space,
  HorizontalLineSpacer,
  CustomerDetailsForm
} from '@sart/components'
import { colors, typography, backgroundColors, theme } from '@sart/styles'

import {
  isMobileOnly
} from "react-device-detect";

import CheckoutSummary from './CheckoutSummary'

class CheckoutCustomer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { regions: [] }
    this.submitCustomerDetails = this.submitCustomerDetails.bind(this)
  }

  async componentDidMount() {
    const { selectedPlan } = client.cache
    if (selectedPlan) {
      const { values } = await client.getRegionOptions()
      const regions = values.map(value => {
        const transformed = {
          label: value.displayValue,
          value: value.key
        }
        return transformed
      })
      this.setState({
        regions
      })
    }
  }

  async submitCustomerDetails(values, form) {
    const { history, content } = this.props
    values.email = values.email.toLowerCase()
    var dob = new Date(values.year + "-" + values.month + "-" + values.day)
    var minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 18)
    var maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() - 150)

    if (dob < maxYear || dob > minYear) {
      form.setFieldError('year', content.forms.dobInvalid)
      form.setFieldError('day', content.forms.dobInvalid)
      form.setFieldError('month', content.forms.dobInvalid)
      return
    }
    if(values.eSIM && values.eSIMDevice) {
      client.setCache({eSIMDispatch: true})
    }
    const { basketId, basketUserId } = await client.updateNCABasket();
    client.setCache({ basketId, basketUserId, basketSubmitted: false })

    client.setCache({ customerDetails: values })

    history.push('/checkout/payment')
  }

  render() {
    const { content } = this.props
    const { selectedPlan, eSimAvailable } = client.cache
    var { regions } = this.state

    regions.sort(function(a, b) {
      if(a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0
    });

    if (!selectedPlan) return <Redirect to="/checkout" />

    return (
      <View style={styles.container}>
        <View style={styles.formContainer}>
          <Subhead style={styles.subHeader}>{content.checkout.customerInformation}</Subhead>

          <Space height={12} />
          <HorizontalLineSpacer />
          <Space height={12} />

          <CustomerDetailsForm
            initialValues={client.cache.customerDetails}
            onFormSubmit={this.submitCustomerDetails}
            // style={styles}
            regions={regions}
            style={styles.inputStyle}
            labelStyle={styles.labelStyles}
            selectStyle={selectStyle}
            disabledStyle={styles.disabled}
            enabledStyle={styles.enabled}
            titleStyle={styles.button}
            selectTheme={{ ...theme.selectIDStyleBackgroundColour }}
            textInputTheme={{ ...theme.textInput }}
            buttonTheme={{ ...theme.button }}
            eSimAvailable={eSimAvailable}
          />
        </View>

        <Space flex={0.1} />

        <CheckoutSummary />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    minHeight: 540,
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center'
  },
  button: {
    ...typography.bold
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  },
  subHeader: {
    ...typography.demi
  },
  formTextInputExtended: {

  },
  inputStyle: {
    ...typography.demi,

    ...(isMobileOnly ? 
      { //Mobile Only
        fontSize: 14
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  labelStyles: {
    ...typography.demi
  },
  formContainer: {

    ...(isMobileOnly ? 
      
      { //Mobile Only
        flex: 1,
        alignSelf: 'center', 
        marginLeft: '6%',
      } 
      : 
      { //Non-Mobile Only
        flex: 0.5
      }
    ),

  }
})
const selectStyle = {
  control: (base) => ({
    ...base,
    ...typography.demi,

    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 12
      } 
      : 
      { //Non-Mobile Only
        
      }
    ),  
  }),
  menu: (base) => ({
    ...base,
    ...typography.demi,

    ...(isMobileOnly ? 
      
      { //Mobile Only
        fontSize: 12
      } 
      : 
      { //Non-Mobile Only
        
      }
    ),  
  })
};
export default CheckoutCustomer
