import { Platform } from 'react-native'

export function testID(id) {
  id = ['test:id/', id].join('')
  if (Platform.OS === 'android') {
    return {accessible: true, accessibilityLabel: id}
  } else if (Platform.OS === 'ios') {
    return { testID: id }
  } else if (Platform.OS === 'web') {
    return { nativeID: id }
  }
}

export function tabBarTestID(id) {
  id = ['test:id/', id].join('')
  return Platform.OS === 'android'
    ? { tabBarAccessibilityLabel: id }
    : { tabBarTestID: id }
}

export function selectTestID(id) {
  id = ['test:id/', id].join('')
  if (Platform.OS === 'android') {
    return {accessible: true, accessibilityLabel: id}
  } else if (Platform.OS === 'ios') {
    return { testID: id }
  } else if (Platform.OS === 'web') {
    return { selectId: id }
  }
}
export function concatStringID(id) {
  id = ['test:id/', id].join('')
  if (Platform.OS === 'web') {
    return { id: id }
  }
}