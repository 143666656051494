import React from 'react'
import Svg, { Line } from 'svgs'
import PropTypes from 'prop-types';

import { colors } from '@sart/styles'

// function Menu(props) {
//   const { color, size, ...otherProps } = props;

//   return (
//     <Svg width="24" height="24"  viewBox="0 0 38 24" fill={color} {...otherProps}>
//         <Rect y="4" width="38" height="4" rx="1" />
//         <Rect y="14" width="38" height="4" rx="1" />
//         <Rect y="24" width="38" height="4" rx="1" />
//     </Svg>
//   )
// }

const Menu = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Line x1="3" y1="12" x2="21" y2="12" />
      <Line x1="3" y1="6" x2="21" y2="6" />
      <Line x1="3" y1="18" x2="21" y2="18" />
    </Svg>
  );
};

Menu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Menu.defaultProps = {
  color: colors.feature,
  size: '24',
};

export default Menu;
