import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { withRouter, matchPath } from 'react-router-dom'

import { colors, typography } from '@sart/styles'
import { Text } from '@sart/components'

function NavbarLink(props) {
  const { to, url, title, style, activeStyle, history, location, ...rest } = props

  const isActive = matchPath(location.pathname, {
    path: to,
    exact: false,
  })

  const textStyles = [styles.default, style, isActive && styles.active, isActive && activeStyle]

  function handleRouting() {
    if (to) {
      return history.push(to)
    } else if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <TouchableOpacity
      onPress={() => handleRouting()}
      {...rest}
    >
      <Text
        style={textStyles}
      >{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  default: {
    fontSize: 13,
    fontWeight: '700',
    color: colors.clear,
    ...typography.bold
  },
  active: {
    color: colors.exisDarkCharcoal
  }
})

export default withRouter(NavbarLink)



