import React from 'react'
import Svg, { Path, Rect } from 'svgs'

export default function Visa(props) {
  return (
    <Svg viewBox="0 0 32 24" width={28} height={22}  {...props}>
    <Rect fill="#A9B7C2" width={32} height={24} rx={4} />
    <Rect fill="#FFF" width={32} height={24} rx={4} />
    <Path
      fill="#00589F"
      fillRule="nonzero"
      d="M11.644 15.779l1.39-7.555h2.208l-1.391 7.555zM21.863 8.447a6.488 6.488 0 0 0-1.967-.313c-2.207 0-3.743 1.028-3.743 2.504 0 1.073 1.104 1.698 1.92 2.056.863.358 1.151.626 1.151.939 0 .492-.672.76-1.343.76-.864 0-1.344-.134-2.063-.402l-.288-.18-.288 1.7c.528.223 1.487.402 2.447.402 2.303 0 3.838-1.028 3.838-2.593 0-.85-.576-1.52-1.871-2.056-.768-.358-1.248-.582-1.248-.94 0-.312.384-.67 1.248-.67.72 0 1.247.134 1.631.268l.192.09.384-1.565M27.524 8.268h-1.727c-.528 0-.912.134-1.151.626l-3.263 6.93h2.303s.384-.94.48-1.118h2.83c.048.268.288 1.118.288 1.118h2.063l-1.823-7.556zm-2.734 4.828c.191-.447.863-2.1.863-2.1s.192-.448.288-.716l.144.67.528 2.191H24.79v-.045zM9.772 8.268L7.613 13.41l-.24-1.028c-.383-1.207-1.679-2.503-3.07-3.174l2.015 6.572h2.35l3.503-7.555H9.772"
    />
    <Path
      d="M5.598 8.224h-3.55L2 8.402c2.783.626 4.606 2.146 5.374 3.934l-.768-3.442c-.096-.492-.528-.626-1.008-.67"
      fill="#F9A51A"
      fillRule="nonzero"
    />
    <Path d="M4 0h24a4 4 0 0 1 4 4v1H0V4a4 4 0 0 1 4-4z" fill="#09599E" />
    <Path d="M0 19h32v1a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-1z" fill="#E59703" />
  </Svg>
  )
}
