import * as spacing from './spacing'
import * as typography from './typography'
import * as colors from './colors'
import * as backgroundColors from './backgroundColors'
import * as opacity from './opacity'
import * as radius from './radius'
import * as shadows from './shadows'
import * as sizes from './sizes'
import * as mobile from './mobile'
import * as adaptive from './adaptive'

import { default as theme } from './theme'

export {
  spacing,
  typography,
  colors,
  backgroundColors,
  opacity,
  radius,
  shadows,
  sizes,
  mobile,
  adaptive,
  theme
}
