export function roundFloat(num, precision = 1) {
  const p = Math.pow(10, precision);

  return num * p / p;
}

export function generateRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export function generateRandomFloat(min, max, precision = 0) {
  const num = generateRandomNumber(min, max);

  return num;
}

export function generateRandomInt(min, max, precision = 0) {
  const num = generateRandomNumber(min, max);

  return Math.round(num);
}
