export default {
  id: 'en',
  languages: {
    en: 'English',
    es: 'Español'
  },
  general: {
    name: 'Exis',
    businesName: 'Exis',
    channel: "SART APP",
  },
  common: {
    loading: "Loading",
    change: 'Change',
    choose: 'Choose',
    send: "Send",
    sending: "Sending...",
    continue: 'Continue',
    processing: 'Processing...',
    apply: "Apply",
    submit: "Submit",
    submitting: "Submitting...",
    cancel: "Cancel",
    cancelled: "Cancelled",
    back: "Back",
    reset: "Reset",
    clear: "Clear",
    delete: "Delete",
    remove: "Remove",
    help: 'Need help?',
    save: "Submit",
    confirm: "Confirm",
    holName: "hol_",
    infinity: "∞"
  },
  forms: {
    titleLabel: "Title",
    titlePlaceholder: "Title",
    sex: "Gender",
    sexTwo: "Sex",
    firstNameLabel: "First Name",
    firstNamePlaceholder: "First Name",
    middleNameLabel: "Middle Name",
    middleNamePlaceholder: "Middle Name",
    lastNameLabel: "Last Name",
    lastNamePlaceholder: "Last Name",
    lastNameOptionTwo: "Last Name",
    lastNameOptionTwoPlaceholder: "Last Name",
    accountHolderLabel: "Account Holder",
    IdTypeLabel: "ID Type",
    IdTypePlaceholder: "Select type of ID",
    idTypePassport: "Passport",
    idTypeIdCard: "ID Card",
    idTypeDriversLicense: "Driver's License",
    idTypeOther: "Other",
    idNumberLabel: "ID Number",
    idNumberPlaceholder: "ID Number",
    idNumberFooter: "This will be verified by an independent company",
    vatLabel: "RFC (In Case You Need Invoice)",
    vatPlaceholder: "RFC",
    vatFooter: "It will be used to generate your receipts",
    dobLabel: "Date of Birth",
    dobInvalid: "Please enter a valid DOB (over 18s only)",
    dayLabel: "Day",
    dayPlaceholder: "DD",
    monthLabel: "Month",
    monthPlaceholder: "MM",
    monthInvalid:'Month Invalid',
    yearLabel: "Year",
    yearPlaceholder: "YYYY",
    yearPlaceHolderTwoDigit: "YY",
    emailLabel: "Email",
    usernameLabel: "Username",
    emailPlaceholder: "Email",
    emailInvalid: "Please enter a valid email address",
    emailInvalidCharacters: "Please enter a valid email address \nonly lowercase characters and no special characters",
    passwordLabel: "Password",
    passwordConfirmationLabel: "Password Confirmation",
    passwordPlaceholder: "Enter Password",
    passwordInvalid: "Please enter a valid password",
    usernameOrPasswordInvalid: "Incorrect Username and/or Password",
    forgotPassword: "Forgot Password?",
    yearTwoCharacters: "Year must be exactly 2 characters",
    cvvMinCharacters: "CVV must be at least 3 characters",
    addressLabel: "Delivery Address",
    addressLabelNonDelivery: "Address",
    address1Label: "Street, Number, Interior",
    address1Placeholder: "Street, Number, Interior",
    address2Label: "Colony",
    address2Placeholder: "Colony",
    cityLabel: "Municipality",
    cityPlaceholder: "Municipality",
    countyLabel: "State",
    countyPlaceholder: "State",
    postcodeLabel: "Zip Code",
    postcodePlaceholder: "Zip Code",
    countryLabel: "Country",
    countryPlaceholder: "Country",
    phoneNumberLabel: "Contact Phone Number",
    phoneNumberPlaceholder: "Recipient's phone number",
    customerDetailsPhonePlaceholder: "Enter your Phone Number",
    phoneNumberInvalid: "Invalid Phone Number.",
    verificationCodeInvalid: "Invalid Verification Code",
    companyLabel: "Company",
    companyPlaceholder: "Company (optional)",
    genderLabel: "Gender",
    genderPlaceholder: "Select gender",
    genderSubtext: "For marketing research",
    editDetails: "Edit Details",
    nameOnCardLabel: "Name on Card",
    nameOnCardPlaceholder: "Enter Name on Card",
    cardNumberLabel: "Card Number",
    cardNumberPlaceholder: "0000 0000 0000 0000",
    cardNumberInvalid: 'Credit Card number is invalid',
    cvvLabel: "CCV",
    cvvPlaceholder: "CCV",
    saveCardLabel: "Save Card Details",
    saveCardSubtext: "This will become the payment card for recurring purchases",
    removeCardTitle: "Remove card details",
    editPayment: "Edit Payment",
    isRequired: '{label} is required',
    required: 'Required',
    regionLabel: "Area Code",
    regionPlaceholder: "Select Your EXiS Number Region",
    helpPoint: "This area code will be used in your new EXiS number",
    dataObtain: "Find your CURP",
    validCurp: "Must be 18 alpha-numeric characters",
    uppercase: 'Must be uppercase characters only',
    deliveryInfo: "In case you still don´t have your SIM this will be the address to which it will be sent."
  },
  storedPayment: {
    title: "Payment Method",
    selectPayment: 'Select Payment',
    storedCard: "Stored Card"
  },
  navigation: {
    dashboard: "Home",
    dashboardBack: "Back",
    minutes: 'Minutes',
    data: 'Data',
    texts: 'Texts',
    topup: "Topup",
    addons: "Add-Ons",
    profile: "Profile",
    bill: 'Profile',
    menu: "Menu",
    menuBack: "Back",
    payment: "Payment",
    paymentPortal: "Payment",
    checkout: "Checkout",
    confirmation: "Confirmation",
    receipt: "Update Receipt Number",
    logout: "Logout",
    home: "Home",
    about: "About",
    plans: "Plans",
    plansPortal: "Plans",
    alreadyHaveSim: "Already have a SIM?",
    port: "Switching",
    termsAndConditions: "Terms and Conditions",
    termsAndConditionsCaps: "TERMS AND CONDITIONS",
    dataProtectionCaps: "PRIVACY AND DATA PROTECTION",
    privacy: "Privacy policy",
    accessibility: "Accessibility",
    cookies: "Cookies policy",
    shipping: 'Shipping',
    support: 'Support',
    contact: 'Contact us',
    faq: 'FAQs',
    siteMap: "Site Map",
    lostFoundAndStolen: "Lost, Damaged or Stolen",
    loginAsAgent: "Login as Agent",
    loginAsAmbassador: "Login as Ambassador",
    customer: 'Customer',
    review: 'Review',
    backToShop: "Back to Shop",
    returnHome: "Return Home",
    seeHelp: "See help section",
    gift: 'Gift',
    swap: "Swap",
    helpAndFAQS: "Help and FAQs",
    switch: 'Switch Number',
    identification: 'Identification',
    address: 'Edit Address',
    coverage: "Coverage",
    compatible: "Compatible Devices",
    webAndApp: "WEB AND APP USE",
    activateSIM: "Activate your SIM",
    deleteProfile: "Delete Profile",
    eSIM: "eSIM",
    selfcare: "Manage your Account",
    account: "Account"
  },
  socialMedia: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    youtube: 'YouTube'
  },
  welcome: {
    title: "Welcome to our App",
    instructions: "Glad to have you aboard!\n Let’s get you started by signing in",
    action: "Get Started",
    footer: "Need Help?"
  },
  unavailable: {
    title: "Server Unavailable",
    instructions: "The server is not responding.\n You might need to download the latest version of the EXiS App.\n Try again, once your update is complete.\n\n If you keep seeing this message, check your settings and make sure you are connected to a network.",
    action: "Try again"
  },
  contactus: {
    header: "Contact",
    subheader: "Don't worry, we're \nhere to help",
    faqs: "The answer you are looking for may be",
    faqsSecondLine: 'in our ',
    faq: "Frequently Asked Questions.",
    support: "Customer Service and 24 hour support",
    telephoneNumber: "55 8996 0111",
    internetNumber: "55 8996 0060",
    number: "*11",
    telephone: "Mobile",
    internet: "Internet",
    exisNumber: "From your EXiS number",
    whatsapp: "Contact via WhatsApp",
    email: "Contact via Email",
  },
  login: {
    title: "Login",
    instructions: "Please login to manage your account\n or register if you haven't already",
    actionLost: "Submit",
    action: "Login",
    inProgress: "Logging in...",
    forgottenPassword: "Forgot password?",
    signUpLabel: "Don't have an account?",
    signUpLink: "  Signup",
    inProgressLost: "Submitting",
    validateToken: "Invalid Token",
    actionPort: "Switch",
    inProgressPort: "Switching",
  },
  registerHome: {
    title: "Let's get started",
    login: 'Login with your existing account',
    newSIM: 'Register a new SIM',
    register: 'Register in the app with your EXiS number'
  },
  register_number: {
    title: "Register Number",
    instructions: "Please enter the phone number\n attached to the SIM card",
    note: "We will send you a verification code",
    action: "Send",
    inProgress: "Sending...",
    validateErrorMessage: "Please enter a valid email address \nLower case and non-accented letters only",
    phoneNumberLabel: "Phone Number",
    phoneNumberPlaceholder: "Enter Phone",
    phoneNumberPlaceholderTwo: "Enter Phone Number",
    emailLabel: "Email Address",
    emailPlaceholder: "Enter email",
    emailSubtext: "This will be your username",
    numberLength: 'Number must be 10 digits',
    numberDigits: 'Number must contain only numbers',
    validNumber: 'Invalid Number'
  },
  register_new_customer: {
    signup: 'Register your SIM',
    noEXiSNumber: 'Don\'t have an EXiS number?',
    getOneHere: 'Get one here',
    buttonTitle: 'Go to Dashboard',
    giveUs5Minutes: 'Activating your account may take\n up to 5 minutes. \nIf you don\'t see the updated data on the dashboard, \ngive us a moment and reload the screen\n by pulling it down',
    registerEXiSNumber: "Si ya tienes un número EXiS,",
    register: "register",
    end: "para administrar tu cuenta",
    registerCaps: "Register",
    unrecognisedId: "Document type is unrecognised",
    withSIM: "con tu SIM EXiS",
    tryAgainLater1: "Your registration is taking longer than expected. \n\nPlease send a WhatsApp message to",
    tryAgainLaterNumber: "+52 449 676 0000",
    tryAgainLater2: " and we can clear it up immediately. Or give us at call at +52 55 8996 0111 \n\n",
    backToLogin: 'Back To Login'
  },
  camera_components: {
    cancel_scanning: 'Cancel Scanning',
    loading_camera: 'Loading Camera',
    no_access: 'No access to camera'
  },
  verify_number: {
    title: "Verification",
    instructions: "You should receive a text message and email\n with a verification code",
    action: "Verify",
    inProgress: "Verifying...",
    verificationCodeLabel:"Verification Code",
    verificationCodePlaceholder: "Enter Code",
    resendLabel: "Resend",
    cancel: "Cancel",
    resendMessageTemplate: "RESEND-AUTHCODE"
  },
  register_details: {
    title: "Registration",
    instructions: "Fill in your personal details",
    dob: "Date of Birth",
    action: 'Continue',
    firstName: "First Name",
    middleName: "Middle name",
    lastName: "Surname",
    postcode: "Postcode",
    day:"DD",
    month:"MM",
    year: "YYYY",
    genderLabel:"Gender",
    genderPlaceholder:"Select gender",
    genderSubtext:"For marketing research",
    cancel:"Cancel",
    noSpecialCharacters: 'Name must not contain any special characters',
    validPostcode: 'Postcode must contain 5 digits',
    invalidDay: 'Day is invalid',
    invalidMonth: 'Month must be between 1-12',
    invalidYear: 'Year You must be over 18 to purchase an account',
    invalidYear2: 'Age above 100 is not allowed',
    dayLength: 'Day should be 2 digits max',
    monthLength: 'Month should be 2 digits max',
    yearLength: 'Year should be 4 digits max',
    dayRequired: 'Day is required',
    monthRequired: 'Month is required',
    yearRequired: 'Year is required',
    male: 'Male',
    female: 'Female',
    preferNotToSay: 'Prefer not to say'
  },
  dashboard: {
    title: "Dashboard",
    instructions: "We have reached the dashboard! Yay!",
    action: "Sign Out",
    footer: "Need Help?",
    setup: 'Setup',
    setupDescription: 'Setup a recurring plan so you never run out of service.',
    texts: 'Texts',
    data: 'Data',
    minutes: 'Minutes',
    pendingPayment: 'Pending Payment',
    description: 'Description',
    cashPurchase: 'Cash Purchase',
    payUntil: 'Pay Until',
    buttonTitle: 'Show Barcode',
    button: 'Setup',
    planDescription: 'Setup a recurring plan so you never run out of service',
    gift: "GIFT",
    cancelDescription: "Are you sure you would like to cancel your recurring plan?",
    cancelSuccessful: "has been successfully cancelled",
    cancelFailed: "failed cancellation",
    exp: "Exp: "
  },
  balance: {
    loading_title: 'Loading',
    loading_legend: 'Waiting on server',
    postpaid_title: 'Estimated',
    postpaid_legend: 'For this Month',
    prepaid_title: 'Balance',
    prepaid_legend: 'Active Service',
    active: 'Active service',
    disabled: 'Service is disabled',
    unlimited: 'Unlimited'
  },
  tariff: {
    title: 'Tariff',
    prepaid: 'Prepaid',
    postpoad: 'Monthly'
  },
  topup: {
    title: 'Topup',
    instructions: 'How much you want to top-up?'
  },
  addons: {
    title: 'Extras',
    instructions: 'Choose extra add-ons',
    adtTitle: 'ADT Extras',
    adtDescription: 'Subscribe to ADT services',
    startingFrom: 'From',
    adtInstructions: 'Choose extra addons'
  },
  adt: {
    services: "Services",
    adtAlert: "ADT Alert"
  },
  voxox: {
    title: "Voxox"
  },
  profile: {
    title: 'Profile',
    instructions: 'Your profile preferences',
    accountLabel: 'Profile Summary',
    settingsLabel: 'Service Settings',
    switchLabel: 'Switch Number',
  },
  profile_summary: {
    description: 'Modify your information',
    id: 'Identification',
    idDescription: 'Your details',
    idEdit: 'Edit details',
    address: 'Address',
    addressDescription: 'Your address',
    addressEdit: 'Edit address',
    payment: 'Payment',
    paymentDescription: 'Your recurring payment',
    paymentEdit: 'Edit payment',
    deleteProfile: 'Delete Profile'
  },
  profile_identification: {
    description: 'Edit your identification information',
    noSpecialCharacters: 'Name must not contain special characters',
    rfcValid: 'RFC must be 12-13 alpha-numeric digits',
    title: 'Edit Identification'
  },
  profile_address: {
    description: 'Edit your address information',
    noSpecialCharacters: 'Address must not contain special characters',
    validPostcode: 'Postcode must contain 5 digits',
    title: 'Edit Address',
    submit: 'Submit'
  },
  profile_payment: {
    updateSuccess: 'Congratulations! Your card ending in',
    updateSuccessTwo: "has been saved successfully.",
    updateFailed: 'Unfortunately updating your saved card has failed, please try again.',
    action: 'Back to Your Profile'
  },
  profile_delete: {
    description: 'Are you sure you want to delete your app profile?',
    point1: 'Your EXiS App user account and information will be deleted. You will no longer be able to log into the EXiS App unless you register again using your EXiS number.',
    point2: 'Deleting your EXiS App user account does not terminate your EXiS service(s).',
    point3: 'Call *11 to cancel your EXiS Services.',
    submit: 'Delete User Account'
  },
  profile_deleted: {
    title: 'User Account Deleted',
    description: 'Your EXiS app user account is being deleted.\n\nThis process can take a few minutes. \n We are sorry you do not want to use the app any more, should you change your mind, you can register again at any time and regain access to your EXiS services.',
    action: 'Back to Login'
  },
  service_settings: {
    description: 'Modify your service settings',
    title: 'Settings',
    numberNotValid: 'Number must be 10 digits',
    invalidPAC: 'Check you have entered a valid PAC code',
    invalidNumber: 'Number not valid',
    portInProduct: 'Port-In Product not Found',
    switch: 'Switch',
    pacInvalid: 'Number must be 4 digits'
  },
  switch_number: {
    description: 'Change your number to EXiS',
    numberNotValid: 'Number must be least 10 characters',
    invalidPAC: 'Check you have entered a valid PAC code',
    invalidNumber: 'Number not valid',
    portInProduct: 'Port-In Product not Found',
    switch: 'Switch',
    pacInvalid: 'Number must be 4 digits'
  },
  set_password: {
    title: "Create Password",
    instructions: "This password will be used for future\n access to your account",
    action: 'Continue',
    inProgress: "Submitting",
    passwordLabel: "Password",
    passwordPlaceholder: "Enter Password",
    passwordConfirmLabel: "Password Confirmation",
    passwordConfirmPlaceholder: "Password Confirmation",
    passwordsMatchError: "Passwords do not match",
    passwordRequirements: "8 Minimum characters \n1 Lowercase \n1 Uppercase \n1 Number",
    passwordRequirementsError: "Password must contain: \n8 Minimum characters \n1 Lowercase \n1 Uppercase \n1 Number"
  },
  forgotten_password: {
    title: "Forgotten Password",
    instructions: "Please enter the email address\n associated with the account",
    note: "We will send you a verification code via SMS",
    action: "Send",
    inProgress: "Sending...",
    validateErrorMessage: "Please enter a valid email address \nLower case and non-accented letters only",
    emailLabel: "Email Address",
    emailPlaceholder: "Enter email"
  },
  forgotten_password_auth: {
    title: "Verification",
    instructions: "You should receive a text message\n with a verification code",
    action: "Verify",
    inProgress: "Verifying...",
    verificationCodeLabel:"Verification Code",
    verificationCodePlaceholder: "Enter Verification Code",
    resendLabel: "Resend",
    resendLabelActive: "Resending...",
    cancel: "Cancel",
    resendMessageTemplate: "FORGOTTEN-PASSWORD"
  },
  forgotten_password_new_password: {
    title: "Reset Password",
    instructions: "This password will be used for future\n access to your account",
    action: 'Continue',
    actionInProgress: "Continuing",
    inProgress: "Submitting...",
    passwordLabel: "Password",
    passwordPlaceholder: "Enter Password",
    passwordConfirmLabel: "Password Confirmation",
    passwordConfirmPlaceholder: "Password Confirmation",
    validateErrorMessage: "Passwords do not match"
  },
  bill_item: {
    title: "Billing",
    vat_included: "(Incl. VAT)",
    due_date: "Due date",
    over_due: "Over due",
    pay_bill: "Pay Bill"
  },
  pay_bill: {
    title: "Pay Bill",
    balance: "Bill Balance",
    loading: "Loading...",
    fullAmount: "Full Amount",
    partialAmount: "Partial Amount",
    fullAmountDescription: "Clear the bill's full balance and renew your data allowance.",
    partialAmountDescription: "Paying a partial amount might rollover the remaining balance to the next bill and incur late pay charges.",
    partialPaymentLabel: "Enter a different amount (£)",
    partialAmountPlaceholder: "Enter Amount",
    makingPayment: "Making Payment...",
    buttonApology: "Payment Not Available Yet",
    apologyError: "Cannot make payment yet, story VAD-58 is not complete, sorry :(",
    invalidAmount: "Cannot pay more than the ammount due",
    tooLow: "Cannot pay less that £1"
  },
  payment_method: {
    title: "Payment Method",
    instructions: "Please enter your payment details",
    storedPaymentInstructions: "Pay for the order using your stored card",
    creditCard: "Credit/Debit Card",
    creditCardInstructions: "Safe money transfer using your bank account. Visa, Maestro, Discover, American Express.",
    cash: "Pay in Store",
    cashInstructions: "A payment code will be generated, take it to your nearest OXXO merchant, once paid we will process your order",
    balance: "Balance",
    balanceInstructions: "Purchase using your available monetary balance.",
    bill: "Add to Bill",
    billInstructions: "Add the payment ammount to your next bill.",
    yearValid: 'Year must be valid',
    cvvValid: 'CVV must be valid',
    refundedPayment: "Make a payment of",
    refundedPaymentTwo: "that will be refunded so you can use these details for future payments.",
    saveCardDetails: "Save Card Details"
  },
  add_on_checkout: {
    title: 'Checkout',
    recurringTitle:'Make it recurring',
    recurringText:"Set this product to recur so that you don't have to remember each month.",
    submit: "Purchase",
    submitting: "Purchasing...",
    submitFailedButton: 'Purchasing Not Possible...',
    submitFailedFakeError: 'Purchasing not available until Steven gets the basket flow done!',
    cancel: 'Cancel'
  },
  payment_confirm: {
    done: "Done!",
    almostDone: "Almost done!",
    success: "Successfully Paid!",
    cashSuccess: "Successfully Ordered!",
    title: "Payment Confirmed!",
    action: "Return to Home",
    topUp: "You have successfully topped up your balance by ",
    addOn1: "You have added: ",
    addOn2: " to your service.",
    payBill1: "You have now paid off ",
    payBill2: " your balance.",
    cash1: "Thanks for your purchase, your reference is: ",
    cashDescription: "Take your phone to an OXXO merchant and scan the pending payment found on the Dashboard.",
    currencyCode: "MX",
    saveAndReturn: "Return to Home",
    adtInstruction: "Please login to the ADT website and proceed to follow the instructions"
  },
  paymentOptions: {
    title:"Payment Options",
    instructions: "Select what you would like to do: "
  },
  methods:{
    cash: "Cash",
    balance: "Prepaid Balance",
    bill: "Add To Invoice"
  },
  menu: {
    language: 'Language',
    whoMayUseThisService: 'Who May Use This Service',
    privacy: 'Privacy',
    contenTonService: 'Content on this Service',
    usingTheServices: 'Using the Services',
    disclaimer: 'Disclaimers and Limitations of Liability',
    general: 'General',
    voicemailStatus: "Processing",
    orderFailed: 'Order failed',
    gift: 'Gift',
    swap: 'Swap',
    giftLabel: 'Gift',
    swapLabel: 'Transform',
    transform: "Transform", 
    termsAndConditions: 'Web and App Use',
    privacy: "Privacy and Data Protection",
    faqs: 'FAQs',
    preferences: 'Preferences',
    help: 'Help'
  },
  transfer: {
    transferFooter: 'Search the phone number',
    giftTo: 'Gift',
    giftAmount: 'Gift',
    send: 'Send',
    to: 'to',
    button: 'Transfer'
  },
  home: {
    title: 'You have total control!',
    action: 'Choose your plan',
    firstPoint: "Download and install the app",
    secondTitle: "Contrata fácilmente.",
    button: "The process begins",
    subheader: "We are a company dedicated to keeping everyone connected in a simple and hassle-free way. We have 2 services, get to know them:",
    maintenance: "We are currently undergoing maintenance"
  },
  about: {
    header: "EXiS is a \nhigh speed 4G \nmobile network.",
    subheader: "We focus on the service of you, a user who wants to control their \nplans from an app. Modify your data, minutes, and texts consumed and \nmake payments from the palm of your hands.",
    buttonText: "Choose your Plan",
  },
  plans: {
    header: "Choose the best plan for you",
    subheader: "No contracts, cancel anytime",
    single: "Single",
    recurring: "Recurring",
    singleMobile: "Single",
    singleWeb: "Single",
    recurringWeb: "Recurring",
    link: "Verify that your device is compatible",
    checkout: "Checkout our",
    termsAndConditions: "terms and conditions",
    forMoreDetails: "for more details",
    eliotHeader1: "CHOOSE THE BEST",
    eliotHeader2: "PLAN",
    eliotHeader3: "FOR YOU"
  },
  plan: {
    header: "Plan Features",
    subheader: "Inclusive unlimited calls and texts",
  },
  planitem: {
    action: "Choose",
    contract: "{validity} {validityUnit}{recurring}",
    day: "Day",
    days: "Days",
    week: "Week",
    weeks: "Weeks",
    month: "Month",
    months: "Months",
    year: "Year",
    years: "Years",
    autorenew: "Renews Automatically",
    allowsGiftSwap: "Allows gift/swap",
    minutes: "Minutes",
    eliotDescription: "¡OBTENDRÁS CONTENIDOS EXCLUSIVOS PARA TI \nDE ELIOT MEDIA!",
    eliotLink: "(da click para conocer todos los beneficios)",
    inAdditionTo: "ADEMÁS DE:",
    portacredencial: "Portacredencial",
    whatsappExclusive: "Whatsapp exclusivo con Eliot",
    contentCreated: "Contenidos creados para la",
    community: "Comunidad",
    hol: "hol_",
    stickers: "Unpublished whatsapp stickers",
    wallpapers: "Amazing wallpapers and downloadables",
    plusBenefits: "Más todos los beneficios por ser parte de la",
    unlimitedData: "UNLIMITED GB"
  },
  alreadyHaveSim: {
    header: "Activate your SIM",
    subheader: "Please enter the first 19 digits of the 20 character SIM serial number (Only numbers).",
    charactersRequired: "Please enter exactly 19 digits",
    validSIM: 'Please check you have entered the sim number correctly',
    checkSIM: 'Check you have entered a valid sim number',
    label: 'SIM Number',
    placeholder: 'Enter SIM Number',
    getSIM: "Don't have a SIM? Get one here!",
    withoutLetter: "Only the 19 digits, without the letter",
    valid: "Please enter your SIM number",
    eSIM1: "If you have a physical SIM but prefer an eSIM, make your plan selection",
    eSIM2: "here",
    eSIM3: "without registering the SIM you have in hand and select eSIM when filling your data in the shopping cart."
  },
  port: {
    header: "Switch Your Number",
    subheader: "Always stay in control",
    thirdHeader: "Changing your line to EXiS is super simple, just fill out this form.",
    oldNumberLabel: "Telephone number",
    oldNumberPlaceholder: "10 digits",
    oldNumberLabelTwo: "Number you'd like to keep",
    pacCodeLabel: "NIP",
    pacCodePlaceholder: "Enter your NIP",
    pacCodeFooter: "Provided by your previous network",
    confirmationTitle: "Switch in Progress",
    confirmationInstruction: "We’ve started your switching process, \nyou might experience temporarily disconnection while we switch\n your number, which can take up to 48 hours",
    enterDetails: "Enter your details",
    yourNames: "Your Names",
    names: "Name(s)",
    forename: "Paternal Last Name",
    surname: "Maternal Last Name",
    fathersName: "Paternal Last Name",
    mothersName: "Maternal Last Name",
    curp: "CURP",
    curpPlaceholder: "Unique Population Registry Code",
    curpFooter: "Don't know your CURP? We'll help you",
    stateLabel: "Where were you born?",
    stateFooter: "Select 'Ciudad de México' if you were born in D.F.",
    federalDistrict: "CIUDAD DE MÉXICO",
    curpButton: "Find my CURP",
    helpPoint: "Must be obtained using the SIM originally associated to that number by sending an SMS with the word NIP to 051.",
    noID: 'If you don\'t have or would rather use a different ID, call 55 8996 0111 so we can process the portability for you.'
  },
  lost: {
    header: "Lost, damaged or stolen",
    subheader: "Pause your service and get a replacement SIM",
    reasonLabel: 'Reason',
    reasonPlaceholder: 'Select your reason',
    reasonLost: 'Lost',
    reasonStolen: 'Stolen',
    reasonDamaged: 'Damaged',
    reasonOther: 'Other',
    confirmationTitle: "Suspended account",
    confirmationInstruction: "Everything will be fine. \nYour service has been temporarily canceled, \na new SIM will be sent to you. \nOnce you activate it, your account will resume."
  },
  help: {
    title: "We're here to help",
    header: "FAQs",
    subheader: "Browser our most frequent questions and answers",
    learnAboutUs: "Learn more about us",
    learnAboutUsDescription: "EXiS is a company dedicated to keeping everyone connected in a simple and hassle-free way.",
    contactCallCenterTitle: "Contact Customer Service",
    contactWhatsAppTitle: "Contact Us Via WhatsApp",
    visitWebsite: "Visit our website",
    cancel: "Call *11 To delete/change your account"
  },
  legal: {
    header: "Legal",
    headerCaps: "LEGAL",
    subheader: "Browser our legal terms and conditions",
  },
  checkout: {
    header: "Checkout",
    customerInformation: "Customer Information",
    paymentMethod: "Payment Method",
    review: "Review",
    processing: "Processing",
    summary: "Summary",
    cartIsEmpty: "Your cart is empty",
    emptyCart: "Empty Cart",
    discount: "Discount",
    shipping: "Shipping",
    free: "Free",
    tax: "Tax",
    subtotal: "Subtotal",
    total: "Total",
    purchase: "Purchase",
    orderProcessing: "Processing your order...",
    orderProcessingFailed: "Your order failed. Don´t worry! We haven´t charged your card.",
    orderProcessingFailedDescription: "Please try again and if you still receive this error, please contact us from any line at 55 8996 0111, or from your EXiS line at *11. You can also email us at contacto@exis.mx to support you.",
    orderPendingShippingNoSim: "Your order is on the way!",
    orderPendingShippingWithSim: "Thanks for your purchase!",
    orderProcessedShortly: "Your order will be processed shortly",
    orderPendingShippingInfoNoSim: 'Your SIM has been ordered, \nyou will recieve an email once it has been dispatched. \nOnce it is paired you will be able to setup your account,\nin the mean time you can download the app here.',
    orderPendingShippingInfoeSim: 'Your eSIM will arrive in a moment to the email address you registered. We will also be sending you an email \nwith the details for the activation of your account (check your email inbox and SPAM... just in case). \n\nYou need to download the app, your key to full control of your EXiS account:',
    orderPendingShippingInfoeSimHol1: 'If you selected a physical SIM, it will arrive soon via courier. Follow the instructions in the email you will receive to set up your account (check your inbox and SPAM folder... just in case). \n\nIf you requested an eSIM, your QR code will arrive in your email inbox. Need help activating? click ',
    orderPendingShippingInfoeSimHol2: 'here',
    orderPendingShippingInfoeSimHol3: '\n\nDownload the EXiS App from the following links so that you can activate and set up your ',
    orderPendingShippingInfoeSimHol4: 'hol_',
    orderPendingShippingInfoeSimHol5: ' account:',
    orderPendingShippingInfoeSimHol6: 'hol_:',
    orderPendingShippingInfoNoSimHol: 'Your SIM has been ordered, \nyou will recieve an email once it has been dispatched. \nOnce it is paired you will be able to setup your account,\nin the mean time you can download the app here.',
    orderPendingShippingInfoNoSimHol2: ' App EXiS, ',
    orderPendingShippingInfoNoSimHol3: 'tu llave para todo el control de tu cuenta',
    orderPendingShippingInfoNoSimHol4: ' hol_.',
    orderPendingShippingInfoWithSim1: 'To activate your service, see the following link\n',
    orderPendingShippingInfoWithSim2: 'https://www.youtube.com/@exis_mx\n\n',
    orderPendingShippingInfoWithSim3: "You'll get an email with details. \n(check your email inbox and SPAM... just in case) \nIf you registed an eSIM, click ",
    orderPendingShippingInfoWithSim4: 'here',
    orderPendingShippingInfoWithSim5: ' for activation instructions.',
    orderPendingShippingInfoWithSim6: '\n\nDownload the app from the following links so that you can\n activate and set up your account:',
    orderPendingShippingInfoWithSimHol: 'Revisa tu email para más detalles. Descarga la App EXiS desde los siguientes enlaces para que puedas activar tu SIM y configurar tu cuenta.',
    orderPendingPairing: "Your order will be handled soon!",
    orderPendingPairingInfo: 'Your SIM should be paired soon, \nonce it is paired you will be able to setup your account, \nin the mean time you can download the app.',
    orderStillProcessing: 'Your order is taking longer than usual to process.\n It is still processing and you will receive an email when it is done.\n Please check back in 2 hours and if your order has still not completed then contact the call centre on *11',
    receiptWanted: "Do you need a Receipt?",
    changeRFC: "Change RFC",
    noRFC: "No RFC Number",
    total: "Total",
    numberDigits: 'Number must only contain digits',
    numberLength: 'Number must be 10 digits',
    referalCode: "Please enter discount code",
    saveCardEnglish: "Save card details for number ending in ",
    areYouSure: "Are You Sure?",
    areYouSureLong: "Are you sure you want to save the \ncard ending in ",
    yourNewNumberIs: "Your new number is",
    preview: "You do not have a saved card. Please add one to make future purchases easier",
    preview2: "Select a Payment Method",
    validDevice: "My device is compatible",
    checkHere: "(Check here)",
    paymentPollingFailed: "Payment was not successful within the permitted time. Please try again.",
    imeiRequired: "Must be a valid device",
    termsAndConditionsRequired: "Terms and conditions must be read",
    privacyPolicyRequired: "Privacy policy must be read",
    mustBeSelected: "Must be selected",
    readAndAccept: "I have read and accept",
    readAndAcceptTwo: "I have read and accept",
    termsFull: "Terms and Conditions of Service",
    privacyFull: "Privacy Policy",
    loading: "Loading",
    mobileView: 'To access all EXiS user services, please log on to the app or view this page on desktop',
    choosePaymentMethod: "Change Payment Method",
    eSIM: "I'd like to get an eSIM",
    moreDetails: "(more details here)",
    eSIMCompatible: "my device is eSIM compatible",
    checkHere: "(check here)",
    doesntApply: "Doesn’t apply to users who already have or require a physical SIM.",
    instantlyConnect: "Instantly connect with an eSIM!",
    instantlyConnectNewLine: "Instantly connect with \nan eSIM!"
  },
  update_receipt_number: {
    title: 'Update Your RFC Code',
    label: "RFC Code",
    validateRFC: 'RFC code must not contain any special characters',
    failed: 'Failed'
  },
  agent: {
    navbarTitle: "Acting as Agent",
    header: "Agent Portal",
    subheader: "This sign in page is only for sales agents, \nenter your credentials",
    contactManagement: "Contact Management",
    multipleAccounts: 'multiple accounts with this number exist',
    referralCode: "Referral code",
    invalidReferralCode: "Invalid referral code"
  },
  product: {
    plan: 'Plan',
    renewDate: 'Renew Date',
    expiryDate: 'Expires On',
    price: 'Price',
    switch: 'Switch',
    makeRecurring: 'Make Recurring',
    expiryShort: 'Exp'
  },
  gift: {
    confirm: 'Gifted',
    validnumber: 'Number must contain 10 digits',
    numberNotValid: 'Number not valid',
    send: 'Send',
    to: 'to',
    notFoundBalanceTransfer: 'not found balance transfer product',
    ofYourOwn: 'of your own allowance have been sent to',
    asGift: 'as gift allowance',
    transferAllowances: 'Transfer your allowances',
    giftInstructions: "Share data, minutes or sms of your plan with others. Enter the phone number you want to send a gift",
    noCredits: "You don´t have enough applicable credits for this gift "
  },
  swap: {
    confirm: 'Swapped',
    description: 'Modify your plan',
    secondDescription: 'Change data, minutes and SMS',
    submit: 'Transform',
    texts: 'Texts',
    data: 'Data',
    minutes: 'Minutes',
    textsDisplayUnits: 'Texts',
    minutesDisplayUnits: 'Minutes',
    noApplicableBalances: 'No applicable balances',
    notFoundBalance: 'Not found balance transform product',
    incorrectBalanceInput: 'Balance not inputted correctly',
    incorrectBalanceInputData: "Data must include units. (MB)",
    from: 'From',
    to: 'To',
    transform: 'Transform',
    into: 'into',
    minimum: 'Minimum',
    maximum: 'Maximum',
    swapped: 'Swapped',
    ofYourOwn: 'of your own allowance have been swapped into',
    textsSuffix: "Text(s)",
    minutesSuffix: "Minute(s)",
    dataSuffix: "of Data"
  },
  errors: {
    unknown: "Unknown Error",
    timeoutReached: "The server encountered a temporary error and could not complete your request. Please try again later",
    SMSCountExceeded: "Maximum number of requests exceeded\n Please contact customer services",
    userLocked: "User Locked\nPlease contact customer services",
    notAllowed: "You are not allowed to access this page",
    failedPurchase: "Purchase Failed",
    invalidEmailAddress: "This email address does not belong to a registered user",
    alreadyRegistered: "This email or phone number is already registered",
    networkUnavailable: "You're offline. Please make sure you're online and try again",
    customerLoginAsAgent: "You must be an agent to login",
    validDOB: "Please enter a valid date of birth",
    pendingOrders: "Customer has pending orders",
    emailNotExist: "This email doesn't exist",
    serviceDisconnected: "Service Disconnected. Please contact call centre",
    customerNotFound: "Customer does not exist",
    statusInvalid: "Status invalid",
    paymentFailed: "Payment Failed",
    mustUpdate: "Please update your app to the latest version in the App Store",
    orderFailed: "Order Failed",
    invalidSimLength: "The ICCID is an invalid length",
    simInUse:"The ICCID is already in use",
    productNotLoaded: "Product Not Loaded",
    payments: {
      paymentError01: "Call your bank (01)",
      paymentError02: "Call your bank (02)",
      paymentError06: "Invalid card (06)",
      paymentError07: "Invalid card (07)",
      paymentError13: "Invalid amount(13)",
      paymentError14: "Invalid card (14)",
      paymentError41: "Card reported as lost. Call your bank (41)",
      paymentError57: "Transaction is not allowed (57)",
      paymentError62: "Invalid card (62)",
      paymentError78: "Reserved code (78)",
      paymentError84: "Reserved code (84)",
      paymentError100: "Denied (100)",
      paymentError101: "Card is expired (101)",
      paymentError107: "Call your bank (107)",
      paymentError200: "Invalid card. Call your bank (200)",
      paymentErrorD99: "Invalid format (D99)",
      paymentError15: "We do not recognize the issuing bank (15)",
      paymentError45: "Reserved code (45)",
      paymentError46: "Reserved code (46)",
      paymentError48: "Reserved code (48)",
      paymentError80: "Denied (80)",
      paymentError82: "Invalid card (82)",
      paymentError83: "Invalid card (83)",
      paymentError87: "Invalid card (87)",
      paymentError94: "Duplicate transaction (94)",
      paymentErrorN0: "Try again (N0)",
      paymentErrorR1: "Denied (R1)",
      paymentErrorT5: "Inactive card (T5)",
      paymentError03: "Invalid transaction (03)",
      paymentError05: "Invalid transaction (05)",
      paymentError12: "Transaction not allowed (12)",
      paymentError30: "Format error (30)",
      paymentError51: "Insufficient funds (51)",
      paymentError52: "Account is incorrect (52)",
      paymentError61: "Limit exceeded (61)",
      paymentError65: "Limit exceeded (65)",
      paymentErrorN8: "Denied (N8)",
      paymentErrorO8: "Invalid card (O8)",
      paymentErrorP1: "Transaction not allowed (P1)",
      paymentErrorT4: "Denied (T4)",
      paymentError04: "Denied (04)",
      paymentError34: "Transaction denied. Contact your bank (34)",
      paymentError35: "Transaction denied. Contact your bank (35)",
      paymentError36: "Card denied. Contact your bank (36)",
      paymentError37: "Transaction denied. Contact your bank (37)",
      paymentError43: "Transaction denied. Contact your bank (43)",
      paymentError56: "Card used is not valid. Contact your bank (56)",
      paymentErrorQ5: "Transaction denied. Contact your bank (Q5)",
      paymentErrorN7: "Recurring charge denied. Contact your bank (N7)",
      paymentErrorN6: "Recurring charge denied. Contact your bank (N6)",
    }
  },
  errorMapping: {
    SR0001:{
      authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
    },
    SR0002: {
      login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
    },
    SR0004: {
      login: "errors.userLocked",
    },
    SR0005: {
      login: "errors.notAllowed",
    },
    SR0009: {
      forgottenPassword: "Unauthorized"
    },
    SR0014: {
      registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
    },
    SR0017: {
      registration: "errors.alreadyRegistered"
    },
    SR0020:{
      forgottenPassword: "errors.invalidEmailAddress"
    },
    SR0022:{
      forgottenPassword: "forms.phoneNumberInvalid",
      registration: "forms.phoneNumberInvalid",
    },
    SR0041: {
      registration: "errors.statusInvalid"
    },
    SR0042: {
      forgottenPassword: "errors.SMSCountExceeded",
      registration: "errors.SMSCountExceeded"
    },
    SR0043: {
      login: "errors.mustUpdate",
      registration: "errors.mustUpdate",
      forgottenPassword: "errors.mustUpdate"
    },
    SR0101: {
      lost: "errors.pendingOrders",
      gift: "errors.pendingOrders",
      checkout: "errors.pendingOrders"
    },
    SR9999: {
      login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
    },
    SR00172: {
      login: "errors.customerNotFound"
    },
    1000:"errors.orderFailed",
    1002:"errors.alreadyRegistered",
    1003:"errors.invalidSimLength",
    1004:"errors.simInUse",
		CREDENTIALS_INVALID: {
			authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
		},
		AUTHENTICATION_ERROR: {
			authcode: "forms.verificationCodeInvalid",
      registration: "forms.verificationCodeInvalid",
      login: "forms.usernameOrPasswordInvalid",
      forgottenPassword: "forms.verificationCodeInvalid",
      invalidEmailAddress: "errors.emailNotExist"
		},
		USER_LOCKED: {
			login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
		},
		MAX_PASSWORD_ATTEMPTS: {
			login: "errors.userLocked",
      forgottenPassword: "errors.userLocked"
		},
		SECURITY_CHECK_INVALID: {
			forgottenPassword: "errors.unauthorised"
		},
		NOT_FOUND: {
			forgottenPassword: "errors.unauthorised"
		},
		UNSPECIFIED_ERROR: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		USER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		NO_USER_EXISTS: {
			login: "errors.customerNotFound"
		},
		CUSTOMER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		FILTER_INVALID: {
			registration: "forms.phoneNumberInvalid",
      login: "errors.unknown",
      checkout: "errors.failedPurchase",
      forgottenPassword: "errors.unknown",
      profileAddress: "errors.unknown"
		},
		CONFIRMED_BASKET_INVALID: {
      checkout: "errors.failedPurchase"
		},
		ALREADY_REGISTERED: {
			registration: "errors.alreadyRegistered"
		},
		USERNAME_UNAVAILABLE: {
			registration: "errors.alreadyRegistered"
		},
		USER_INVALID: {
			forgottenPassword: "errors.invalidEmailAddress"
		},
		SERVICE_INVALID: {
			forgottenPassword: "forms.phoneNumberInvalid",
      registration: "forms.phoneNumberInvalid",
			login: "errors.customerNotFound",
		},
		IS_POOLED: {
			registration: "errors.statusInvalid"
		},
		IS_BLACKLISTED: {
			registration: "errors.statusInvalid"
		},
		REGISTRATION_INVALID: {
			registration: "errors.statusInvalid"
		},
		MAX_NUMBER_OF_AUTHSMS_SENDS: {
			forgottenPassword: "errors.SMSCountExceeded",
      registration: "errors.SMSCountExceeded"
		},
		API_CLIENT_VERSION_NOT_SUPPORTED: {
			login: "errors.mustUpdate",
      registration: "errors.mustUpdate",
      forgottenPassword: "errors.mustUpdate"
		},
		ORDERS_PENDING: {
			lost: "errors.pendingOrders",
      gift: "errors.pendingOrders",
      checkout: "errors.pendingOrders"
		},
		CONNECTION_TIMEOUT: {
			login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
		},
		TIMEOUT: {
			login: "errors.timeoutReached",
      registration: "errors.timeoutReached"
		}
  }
}


