import React from 'react'
import PropTypes from 'prop-types'

import Svg, { Path, G } from 'svgs'

import { colors } from '@sart/styles'

const Internet = props => {
  const { color, size, ...otherProps } = props
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke={color} strokeWidth={2} fill="none" fillRule="evenodd">
        <Path d="M18.888 9.944a8.944 8.944 0 01-8.944 8.944 8.944 8.944 0 118.944-8.944z" />
        <Path d="M13.544 9.944c0 4.94-1.612 8.944-3.6 8.944s-3.6-4.004-3.6-8.944C6.345 5.004 7.957 1 9.945 1s3.6 4.004 3.6 8.944zM18.312 6.781H1.576M18.391 12.889H1.497" />
      </G>
    </Svg>
  )
}

Internet.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Internet.defaultProps = {
  color: colors.feature,
  size: '24'
}

export default Internet
