import React from 'react'
import Svg, { Path } from 'svgs'
import PropTypes from 'prop-types'

import { colors } from '@sart/styles'

const ProductHybrid = ({ color, ...rest }) => (
  <Svg
    width={48}
    height={46}
    fill="none"
    stroke={color}
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 48 46"
    {...rest}
  >
    <Path d="M10.044 18.222c1.433-6.422 6.855-11.341 13.53-12.02M40.626 20.86c.01.248.018.497.018.748 0 7.537-5.387 13.818-12.519 15.201" />
    <Path d="M20.727 32.496c-1.114-2.797-1.801-6.643-1.801-10.888 0-7.101 1.924-13.088 4.547-14.913.048-.034.098-.066.147-.097M31.263 24.729c-.41 4.988-1.787 9.106-3.623 11.087M23.475 16.132H10.669" />
    <Path d="M39.782 26.707H13.719" />
    <Path d="M16.57 35.88l-1.041 1.926a18.858 18.858 0 01-7.489-7.39l1.911-1.066c.495-.275.713-.89.47-1.401a15.497 15.497 0 01-1.47-5.654c-.038-.58-.543-1.021-1.125-1.021H3.652c-1.288 0-2.326 1.106-2.194 2.386a22.977 22.977 0 002.796 8.863 23.238 23.238 0 009.217 9.1 23.021 23.021 0 008.6 2.648c1.279.13 2.382-.909 2.382-2.194V37.871c0-.566-.428-1.059-.996-1.095a15.426 15.426 0 01-5.49-1.38c-.516-.238-1.128-.015-1.397.485z" />
    <Path d="M31.137 17.225h-1.369a2.576 2.576 0 01-2.575-2.578V4.022a2.575 2.575 0 012.575-2.575h14.077a2.575 2.575 0 012.575 2.575v10.625a2.576 2.576 0 01-2.575 2.578h-7.989l-4.719 4.047v-4.047z" />
    <Path fill={color} stroke="none" d="M33.379 9.41a1.339 1.339 0 11-2.673 0 1.339 1.339 0 012.673 0M38.144 9.41a1.338 1.338 0 11-2.675.002 1.338 1.338 0 012.675-.002M42.91 9.41c0 .739-.599 1.339-1.338 1.339a1.339 1.339 0 111.339-1.339" />
  </Svg>
)

ProductHybrid.propTypes = {
  color: PropTypes.string
}

ProductHybrid.defaultProps = {
  color: colors.feature
}

export default ProductHybrid
