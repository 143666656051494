export default class Config {
    static configuration

    static setConfiguration(configuration) {
        this.configuration = configuration
    }

    static getConfiguration() {
        return this.configuration
    }
}