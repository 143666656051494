import React, { useRef, useState, useEffect } from 'react'
import { View, StyleSheet, Animated, TouchableOpacity, Dimensions } from 'react-native'

import { withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  Text,
  TextInput,
  Subhead,
  Space,
  HorizontalLineSpacer,
  Price,
  StoredSIM,
  Button
} from '@sart/components'
import { colors, typography, adaptive } from '@sart/styles'

import client, { useClient } from '@sart/client'
import { useContent } from '@sart/content'
import { ShoppingBag } from '@sart/icons'
import { testID } from '@sart/utilities'

import DiscountCode from '../Agent/DiscountCode'
import AgentCode from '../Agent/AgentCode'
import ProductListItem from '../ProductListItem'

function CheckoutSummary(props) {
  const { history } = props

  const { cache } = useClient()
  const content = useContent()
  const {
    cache: {
      promoPlans
    } = {}
  } = useClient()
  const selectedPlan = cache.selectedPlan
  const existingSIM = cache.existingSIM
  const basket = cache.cachedBasket
  const [basketVisible, setBasketVisible] = useState(false)
  const animatedValue = useRef(new Animated.Value(100)).current
  const xVal = animatedValue.interpolate({
    inputRange: [0, 0],
    outputRange: [-300, 0],
  })

  const animStyle = isMobileOnly ? {
    transform: [
      {
        translateX: xVal
      }
    ],
  } :
  {}

  const [eliotJourney, setEliotJourney] = useState(false)

  useEffect(() => {
    getEliotJourney()
  })

  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }

  function clearBasket() {
    client.unsetCache('referralCode')
    client.unsetCache('existingSIM')
    client.unsetCache('cachedBasket')
    client.clearBasketCache()
    history.push(eliotJourney ? '/hol_plans' : '/home')
  }

  function selectBasket() {
    //Check if menu is open, and then animate doing the opposite
    if(basketVisible) {
      //hide the menu
      Animated.timing(
        animatedValue,
        {
          toValue:100,
          duration:100,
          useNativeDriver:false
        }).start()
      setBasketVisible(false)
    } else {
      Animated.timing(
        animatedValue,
        {
          toValue:0,
          duration:100,
          useNativeDriver:false
        }).start()
      setBasketVisible(true)
    }
  }

  return (
    <Animated.View style={[styles.container, animStyle]}>
      { isMobileOnly && 
        <TouchableOpacity onPress={selectBasket} style={styles.basketIcon} key={1}>
            <ShoppingBag stroke={colors.white}/>
        </TouchableOpacity>
      }
      { isMobileOnly && 
        <Space height={12} />
      }
      <Button
        title={content.checkout.emptyCart}
        onPress={clearBasket} 
        style={styles.clearBasket}
        titleStyle={styles.buttonTitle}
      />
      <Space height={12} />
      <Subhead style={{...typography.demi}}>{content.checkout.summary}</Subhead>

      <Space height={12} />
      <HorizontalLineSpacer />
      <Space height={12} />
      <View style={styles.addons}>
            <ProductListItem product={selectedPlan} descriptionStyle={styles.productDescription} titleStyle={styles.bold} priceStyle={styles.bold}/>
            <Space height={12} />
           </View>
      {/* <ProductListItem product={selectedPlan} descriptionStyle={styles.productDescription} titleStyle={styles.bold} priceStyle={styles.bold}/> */}
      {/* <Space height={12} /> */}
      {promoPlans && promoPlans.map(plan => (
          <View style={styles.addons}>
            <ProductListItem product={plan} descriptionStyle={styles.productDescription} titleStyle={styles.bold} priceStyle={styles.bold}/>
            <Space height={12} />
           </View>
          ))
        } 
      <Space height={12} />

      <HorizontalLineSpacer />

      {existingSIM &&
        <View {...testID('storedSimView')}>
          <StoredSIM
            value={existingSIM}
            onEditValue={() => void history.push('/alreadyhavesim')}
            labelStyle={styles.labelStyle}
          />

          <HorizontalLineSpacer />
        </View>
      }

      <DiscountCode />

      <Space height={8} />

      <AgentCode />

      <HorizontalLineSpacer thickness={2}/>

      <View style={styles.rows}>
        <Text style={styles.values}>{content.checkout.shipping}</Text>
        <Text style={styles.values}>{content.checkout.free}</Text>
      </View>
      <View style={styles.rows}>
        <Text style={styles.values}>{content.checkout.tax}</Text>
        <Price style={styles.values} value={basket.vat} code={basket.currency}/>
      </View>
      <View style={styles.rows}>
        <Text style={styles.values}>{content.checkout.subtotal}</Text>
        <Price style={styles.values} value={basket.subTotal} code={basket.currency}/>
      </View>

      <HorizontalLineSpacer />

      <View style={styles.rows}>
        <Text style={styles.total}>{content.checkout.total}</Text>
        <Price style={styles.total} value={basket.total} code={basket.currency}/>
      </View>
      { isMobileOnly && 
        <Space height={12} />
      }
    </Animated.View>
  )
}

export default withRouter(CheckoutSummary)

const styles = StyleSheet.create({
  basketIcon: {
    ...(isMobileOnly ? 
      { //Mobile Only
        position: 'absolute',
        backgroundColor: colors.exisOrange,
        top:-2,
        right:297,
        width:50,
        height:50,
        borderTopLeftRadius:10,
        borderBottomLeftRadius:10,
        alignItems:'center',
        justifyContent:'center',
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  container: {
    ...(isMobileOnly ? 
      { //Mobile Only
        borderColor:colors.exisOrange,
        backgroundColor:colors.white,
        borderWidth:2,
        width:300,
        position:'absolute',
        top:-40,
        right:-324,
        alignItems:'center'
      } 
      : 
      { //Non-Mobile Only
        flex:0.4
      }
    )
  },
  title: {
    fontSize: 32,
    lineHeight: 48,
    color: colors.feature
  },
  rows: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width:isMobileOnly ? '90%' : '100%'
  },
  values: {
    color: colors.focus,
    fontWeight: '400',
    lineHeight: 24,
    ...typography.medium
  },
  total: {
    color: colors.feature,
    fontWeight: '500',
    lineHeight: 24,
    ...typography.bold
  },
  productContainer: {
    width: isMobileOnly ? '95%' : '100%'
  },
  productDescription: {
    fontSize:10,
    alignSelf:'center',
    ...typography.medium
  },
  clearBasket: {
    width:'50%',
    backgroundColor: colors.exisOrange
  },
  bold : {
    ...typography.bold
  },
  labelStyle: {
    ...typography.book
  },
  referralCode: {
    flex: 1,
    ...typography.book
  },
  buttonTitle: {
    ...typography.bold, 

    ...(isMobileOnly ? 
      { //Mobile Only
        fontSize: 14
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  },
  addons: {
    ...(isMobileOnly ? 
      { //Mobile Only
        width: '95%'
      } 
      : 
      { //Non-Mobile Only
        
      }
    )
  }
})
