export function validateEmailAddress(value){
  var emailReg = /^([a-z0-9_\-.])+@([a-z0-9_\-.])+\.([a-z])+$/ ;
  return emailReg.test(value)
}

export function validatePassword(value) {
  if (value.trim() === ""){
    return false
  }
  return true
}

export function validateToken(token) {
  return token && token.length > 0
}
