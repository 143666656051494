import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

const HolSmallLogo = (props) => {  
  const { size } = props; 
  return (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 39 38"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G id="Planes-02" transform="translate(-1731.000000, -101.000000)">
        <G id="Header_Eliot_2" transform="translate(122.000000, 94.000000)">
          <G id="Group" transform="translate(1609.000000, 7.000000)">
            <Path
              d="M6.316,28.456 C5.501,22.23 8.66,16.169 15.481,15.276 C20.481,14.622 24.164,17.205 24.854,22.476 C25.68,28.776 23.031,34.735 15.615,35.706 C10.7,36.352 7.012,33.769 6.316,28.456 Z M18.879,23.665 C18.515,20.889 16.772,20.512 15.701,20.652 C13.084,20.995 11.913,24.375 12.301,27.352 C12.601,29.652 14.085,30.507 15.354,30.341 C17.973,30 19.294,26.837 18.879,23.665 L18.879,23.665 Z"
              id="Shape"
              stroke="#000000"
              strokeWidth={3.77}
              strokeLinejoin="round"
            />
            <Path
              d="M34.414,6.571 L34.584,30.226 C34.594715,31.7302872 33.4825911,33.0066778 31.991,33.202 L31.991,33.202 C31.1451442,33.3125627 30.2924735,33.0552047 29.6490323,32.4951335 C29.0055911,31.9350624 28.6331225,31.1260214 28.626,30.273 L28.426,6.622 C28.4137032,5.11635912 29.5261108,3.83792702 31.019,3.642 L31.05,3.642 C31.8956317,3.53208322 32.7478377,3.7897029 33.3909659,4.34966793 C34.034094,4.90963296 34.4065197,5.71828744 34.414,6.571 L34.414,6.571 Z"
              id="Path"
              stroke="#000000"
              strokeWidth={3.77}
              strokeLinejoin="round"
            />
            <Path
              d="M6.913,27.69 C6.097,21.464 9.257,15.403 16.078,14.51 C21.078,13.856 24.76,16.439 25.451,21.71 C26.276,28.016 23.627,33.969 16.211,34.94 C11.294,35.587 7.608,33 6.913,27.69 Z M19.475,22.899 C19.112,20.123 17.368,19.746 16.298,19.887 C13.68,20.229 12.509,23.609 12.898,26.587 C13.198,28.887 14.682,29.742 15.951,29.576 C18.569,29.229 19.89,26.072 19.475,22.9 L19.475,22.899 Z"
              id="Shape"
              fill="#FCEE21"
              fillRule="nonzero"
            />
            <Path
              d="M35.011,5.805 L35.18,29.461 C35.1911666,30.9649042 34.079264,32.2411042 32.588,32.436 L32.588,32.436 C31.7419848,32.5468745 30.8890374,32.2896587 30.2453641,31.7295521 C29.6016908,31.1694454 29.2290867,30.3602201 29.222,29.507 L29.022,5.856 C29.0092509,4.35074223 30.1214374,3.07250011 31.614,2.877 L31.646,2.877 C32.4916843,2.76645723 33.3441905,3.02368569 33.9875962,3.58353464 C34.6310018,4.14338359 35.003593,4.95215371 35.011,5.805 Z"
              id="Path"
              fill="#FCEE21"
              fillRule="nonzero"
            />
            <Path
              d="M38.3,5.528 C38.2744885,4.81205578 38.0816743,4.11203393 37.737,3.484 C37.3945185,2.84557127 36.9292773,2.28109674 36.368,1.823 C36.0887808,1.58364649 35.7831327,1.37697334 35.457,1.207 C35.1085433,1.05349548 34.7516329,0.919946226 34.388,0.807 C34.3780589,0.803134014 34.366866,0.808058882 34.363,0.818000004 C34.359134,0.827941125 34.3640589,0.839134003 34.374,0.843 C34.6944539,1.01312856 34.9918611,1.22349799 35.259,1.469 C35.5293892,1.68889752 35.7858814,1.92535132 36.027,2.177 C36.4689656,2.66405196 36.8372075,3.21321415 37.12,3.807 C37.378622,4.37996983 37.5796114,4.97724325 37.72,5.59 C37.8922981,6.22640824 37.9485296,6.88865242 37.886,7.545 C37.881,7.597 37.963,7.62 37.979,7.567 C38.1916484,6.90822514 38.2999571,6.22024537 38.3,5.528 L38.3,5.528 Z"
              id="Path"
              fill="#FCEE21"
              fillRule="nonzero"
            />
            <Path
              d="M4.584,34.248 C3.78564009,32.3544876 3.24190076,30.3634669 2.967,28.327 C2.85320913,27.3103863 2.83078605,26.2856181 2.9,25.265 C2.93243429,24.0650476 3.2194369,22.8856782 3.742,21.805 C3.756,21.778 3.713,21.755 3.695,21.779 C2.328,23.611 2.168,26.102 2.375,28.309 C2.47067515,29.3781351 2.68636578,30.4331071 3.018,31.454 C3.17656498,31.9433093 3.39027192,32.4129949 3.655,32.854 C3.945,33.331 4.205,33.827 4.538,34.277 C4.54600814,34.2896926 4.56279744,34.2935081 4.57549997,34.2855 C4.58819266,34.2774918 4.59200809,34.2607025 4.584,34.248 L4.584,34.248 Z"
              id="Path"
              fill="#FCEE21"
              fillRule="nonzero"
            />
            <Path
              d="M2.621,33.246 C2.489,32.88 2.33,32.527 2.194,32.163 C2.07719355,31.8065804 1.99416139,31.4399666 1.946,31.068 C1.813,30.321 1.629,29.588 1.54,28.834 C1.45287373,28.0849896 1.41712349,27.3308935 1.433,26.577 C1.41914485,25.8183764 1.4636091,25.0598097 1.566,24.308 C1.5671908,24.2920267 1.55673916,24.2775049 1.5412144,24.2735621 C1.52568963,24.2696193 1.50957532,24.2773942 1.503,24.292 C1.2453776,25.0339308 1.06247056,25.7997284 0.957,26.578 C0.884812564,27.3566452 0.896210874,28.140782 0.991,28.917 C1.05895051,29.6956476 1.2374076,30.4606562 1.521,31.189 C1.673,31.544 1.872,31.879 2.021,32.236 C2.1670946,32.600568 2.34863216,32.9499105 2.563,33.279 C2.586,33.312 2.633,33.279 2.621,33.246 Z"
              id="Path"
              fill="#FCEE21"
              fillRule="nonzero"
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
  )};

HolSmallLogo.defaultProps = {
  size: 38,
}

export default HolSmallLogo;
