import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Platform, TouchableOpacity, Text } from 'react-native'
import { Redirect } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import axios from 'axios'

import {
  Headline,
  Subhead,
  Space,
  TextInputExtended,
  Button,
  Label,
  Anchor,
  SelectExtended,
  HorizontalLine
} from '@sart/components'
import {
  colors, typography
} from '@sart/styles'
import { useContent, formatString } from '@sart/content'
import client from '@sart/client'
import { HelpIcon } from '@sart/icons'
import { testID } from '@sart/utilities'

import AppConfig from '../Configuration'

export default function PortForm(props) {
  const { history } = props

  const content = useContent()
  const[portInProduct, setPortInProduct] = useState(undefined)
  const [regions, setRegions] = useState([])
  const [helpPoint, setHelpPoint] = useState(false)

  useEffect(() => {
    getProducts()
  },[])

  async function getProducts() {
    const portInProducts = await client.callApi('catalog','getPortInProducts',[client.cache.catalogId])
    const pProduct = portInProducts.find((product) => product.productLegacyId === "VADSA-PORT-IN")
    
    setPortInProduct(pProduct)
    
    if (pProduct){
      const productRegionsRaw = getCurpRegionOptions(pProduct)

      productRegionsRaw.then(function(result) {
        const productRegions = result.values.map(value => {
        const transformed = {
          label: value.displayValue,
          value: value.legacyId
        }
        return transformed
      })
        setRegions(productRegions)
      })
    }
  }
  // //NOT in useEffect
  async function getCurpRegionOptions(pProduct) {
    const { link: { href: url } } = pProduct.options.find(item => item.code === "CURP_REGIONS");
    const options = await client.retrieveDataFromUrl(url);

    return options
  }

  const personalDetails = client.cache.customer && client.cache.customer.personalDetails
  const [curp, setCurp] = React.useState('')
  const [visibleCurp, setVisibleCurp] = useState(false)

  var formik = useFormik({
    initialValues: {
      number: '',
      code: '',
      names: '',
      fathersName: '',
      mothersName: '',
      curpField: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      gender: '',
      state: ''
    },
    validationSchema: Yup.object().shape({
      number: Yup.string().trim().required(" ").test('switch-number-check', content.switch_number.numberNotValid, function(value) {
        if(value){
          return value.match(/^\d{10}$/)
        } else {
          return true
        }
      }),
      code: Yup.string().trim().required(" ").test('pacCode-number-check', content.switch_number.pacInvalid, function(value) {
        if(value){
          return value.match(/^\d{4}$/)
        } else {
          return true
        }      
      }),
      names: Yup.string().trim().required(" "),
      fathersName: Yup.string().trim().required(" "),
      mothersName: Yup.string().trim().required(" "),
      curpField: Yup.string().trim().required(" ").matches(/[A-Z0-9]{18}/, { message: content.forms.validCurp, excludeEmptyString: true}),
      dobDay: Yup.string().trim().max(2, content.register_details.dayLength).test('age check 1', content.register_details.invalidDay, function(value) {
        if(formik.values.dobMonth) {
        if (formik.values.dobMonth == 2) {
          if (((formik.values.dobYear % 4 == 0) && (formik.values.dobYear % 100 != 0)) || (formik.values.dobYear % 400 == 0)) {
            if (value > 29 || value <= 0) {
              return false
            } else {
              return true
            }
          } else {
            if (value > 28 || value <= 0) {
              return false
            } else {
              return true
            }
          }
        } else if (formik.values.dobMonth == 4 || formik.values.dobMonth == 6 || formik.values.dobMonth == 9 || formik.values.dobMonth == 11) {
          if (value > 30 || value <= 0) {
            return false
          } else {
            return true
          }
        } else if (formik.values.dobMonth == 1 || formik.values.dobMonth == 3 || formik.values.dobMonth == 5 || formik.values.dobMonth == 7 || formik.values.dobMonth == 8 || formik.values.dobMonth == 10 || formik.values.dobMonth == 12 ) {
          if (value > 31 || value <= 0) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    }),
      dobMonth: Yup.string().trim().max(2, content.register_details.monthLength).test('age check 2', content.register_details.invalidMonth, function(value) {
        if(value > 12 || value <= 0) {
          return false
        } else {
          return true
        }
      }),
      dobYear: Yup.string().trim().length(4, content.register_details.yearLength).test('age check 3', content.register_details.invalidYear, function(value) {
        if (value && formik.values.dobDay && formik.values.dobMonth) {
          var dob = new Date()
          dob.setFullYear(value)
          dob.setMonth(formik.values.dobMonth-1)
          dob.setDate(formik.values.dobDay)
          var minYear = new Date()
          
          minYear.setFullYear(minYear.getFullYear() - 18)
          
          if (dob > minYear) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }).test('age-check-4', content.register_details.invalidYear2, function(value){
        if (value && formik.values.dobDay && formik.values.dobMonth) {
          var dob = new Date()
          dob.setFullYear(value)
          dob.setMonth(formik.values.dobMonth-1)
          dob.setDate(formik.values.dobDay)
          var maxYear = new Date()
          
          maxYear.setFullYear(maxYear.getFullYear() - 100)
          if(dob < maxYear){
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }),
      gender: Yup.string().trim(),
      state: Yup.string().trim(),
    }),
    onSubmit: handleSubmit
  })

  async function submitFieldForValidation() {
    var url = 'https://us-west4-arsus-production.cloudfunctions.net/curp?apiKey=8jjyEoIKDNPY1kKwQ9f3uQVRN0Z2&curp=' + curp

    const result = await axios.get(url)

  }

  function generateCurp() {
    var curp
    var names = formik.values.names.toUpperCase()
    var fathers = formik.values.fathersName.toUpperCase()
    var mothers = formik.values.mothersName.toUpperCase()
    var gender = formik.values.gender.toUpperCase()
    var dob = formik.values.dobDay + '/' + formik.values.dobMonth + '/' + formik.values.dobYear
    var state = formik.values.state
    var remove = new RegExp(/^(DE |DEL |LO |LOS |LA |LAS )+/);
    var avoidNames = new RegExp(/^(MARIA |JOSE )/);
    
    names = names.replace(remove, '')
    names = names.replace(avoidNames, '')
    names = names.replace(remove, '')
    fathers = fathers.replace(remove, '')
    mothers = mothers.replace(remove, '')
    if(mothers === '') {
      mothers = 'X';
    }

    curp = fathers.substring(0,1) + searchVowel(fathers) + mothers.substring(0,1) + names.substring(0,2);

    curp = changeWord(curp)

    curp += dob.substring(8,10) + dob.substring(3,5) + dob.substring(0,2)

    curp += (gender === 'M' ? 'H' : 'M') + state;

    curp += searchConsonant(fathers) + searchConsonant(mothers) + searchConsonant(names)

    curp += dob.substring(6,8) === '19' ? '0' : 'A'

    curp += lastDigitCheck(curp)
    return curp;
  }

  function searchVowel(str) {
    var vowel = 'AEIOU';
    var i;
    var c;

    for(i=1; i<str.length; i++) {
      c = str[i];
      if(vowel.indexOf(c) >= 0) {
        return c;
      }
    }
    return 'X'
  }

  function searchConsonant(str){
    var vowel = 'AEIOU ��.';
    var i;
    var c;

    for(i=1; i<str.length; i++){
      c = str[i];
      if(vowel.indexOf(c) < 0) {
        return c;
      }
    }
    return 'X';
  }

  function changeWord(str) {
    var pal1 = new RegExp(/BUEI|BUEY|CACA|CACO|CAGA|CAGO|CAKA|CAKO|COGE|COJA|COJE|COJI|COJO|CULO|FETO|GUEY/);
    var pal2=new RegExp(/JOTO|KACA|KACO|KAGA|KAGO|KOGE|KOJO|KAKA|KULO|LOCA|LOCO|MAME|MAMO|MEAR|MEAS|MEON/);
    var pal3=new RegExp(/MION|MOCO|MULA|PEDA|PEDO|PENE|PUTA|PUTO|QULO|RATA|RUIN/);
    var val;

    str=str.substring(0,4);

    val = pal1.test(str) || pal2.test(str);
    val = pal3.test(str) || val

    if(val) {
      return str.substring(0,1) + 'X' + str.substring(2,4)
    }
    return str;
  }

  function getStates(state) {
    var states = ['DF','AS','BC','BS','CC','CL','CM','CS','CH','DG','GT','GR','HG','JC','MC','MN',
    'MS','NT','NL','OC','PL','QT','QR','SP','SL','SR','TC','TS','TL','VZ','YN','ZS','NE']

    return states[state]
  }

  function table(i, x) {
    if(i >= '0' && i <= '9') {
      return x-48;
    } else if(i >= 'A' && i<= 'N') {
      return x-55;
    } else if(i >= 'O' && i <= 'Z') {
      return x-54
    } else {
      return 0;
    }
  }

  function lastDigitCheck(curp) {
    var i;
    var c;
    var dv = 0;

    for(i=0; i<curp.length; i++) {
      c=table(curp[i], curp.charCodeAt(i))
      dv += c * (18-i)
    }

    dv %= 10;
    return dv === 0 ? 0 : 10 - dv;
  }

  async function handleSubmit(values, form) {
    form.setSubmitting(true)

    try {
      //{ "NIPCode": "7601", "NewNumber": "779281429909"}
      //check regex for mobile number before submission
      const test = new RegExp(AppConfig.numberRegex, 'g')
      if(!test.test(values.number)){
        formik.setFieldError('number', content.switch_number.invalidNumber)
        throw new Error(content.switch_number.invalidNumber)
      }

      const extendedData = {
        "NIPCode": values.code,
        "NewNumber": values.number,
        "IdType": values.idType,
        "IdNumber": values.idNumber,
        "curp": values.curpField
      }

      const portInProducts = await client.callApi('catalog','getPortInProducts',[client.cache.catalogId])

      const portInProduct = portInProducts.find((product) => product.productLegacyId === "VADSA-PORT-IN")

      if (!portInProduct){
        throw new Error(content.switch_number.portInProduct)
      }

      const order = await client.checkoutServiceProduct(portInProduct, extendedData)
      if (order.status === "successful"){
        history.push('/port/confirmation')
      } else {
        //order failed, throw an exception to be caught
        throw new Error(content.menu.orderFailed)
      }
    } catch (error) {
      form.setSubmitting(false)
      console.error(error)
    }
  }

  if (!personalDetails) {
    return <Redirect to="/port" />
  }

  function selectTitle(option) {
    formik.setFieldValue('gender', option.value)
  }

  function selectRegion(option) {
    formik.setFieldValue('state', option.value)
  }

  return (
    <View style={styles.container}>
      <View style={styles.headers}>
        <Headline style={styles.header}>{content.port.header}</Headline>
        <Subhead style={styles.subheader}>{content.port.subheader}</Subhead>
        <Subhead style={styles.thirdHeader}>{content.port.thirdHeader}</Subhead>
      </View>

      <Space height={24} />

      {/* <PersonalDetailsSummary
        personalDetails={personalDetails}
      /> */}

      <Space height={24} />
      <Label style={styles.label}>{content.port.enterDetails}</Label>
      <Space height={16} />
      <View style={{flexDirection:'row'}}>
        <View style={{flex: 1}}>
          <TextInputExtended
            label={content.port.oldNumberLabelTwo + ' *'}
            value={formik.values.number}
            placeholder={content.port.oldNumberPlaceholder}
            keyboardType="phone-pad"
            onChangeText={formik.handleChange('number')}
            onBlur={formik.handleBlur('number')}
            error={formik.touched.number && formik.errors.number}
            style={{...typography.demi}}
            labelStyle={styles.labelStyles}
            onSubmitEditing={formik.handleSubmit}
            maxLength={10}
            {...testID('portNumber')}
          />
          <Space height={8}/>
 
          <TextInputExtended 
            label={content.port.forename + ' *'}
            value={formik.values.fathersName}
            placeholder={content.port.fathersName}
            onChangeText={formik.handleChange('fathersName')}
            onBlur={formik.handleBlur('fathersName')}
            error={formik.touched.fathersName && formik.errors.fathersName}
            style={{...typography.demi}}
            labelStyle={styles.labelStyles}
            onSubmitEditing={formik.handleSubmit}
            {...testID('portFatherName')}
          />

          <Space height={8}/>

          <TextInputExtended 
            label={content.port.curp + ' *'}
            value={formik.values.curpField.toUpperCase()}
            placeholder={content.port.curpPlaceholder}
            showErrorAndFooter
            footer={
              <>
                {/* {content.forms.forgotPassword} */}
                {" "}
                <Anchor
                  title={content.port.curpFooter}
                  onPress={() => setVisibleCurp(!visibleCurp)}
                  style={styles.curpFooter}
                  {...testID('portCurpGenerator')}
                />
              </>
            }
            onChangeText={formik.handleChange('curpField')}
            onBlur={formik.handleBlur('curpField')}
            error={formik.touched.curpField && formik.errors.curpField}
            style={{...typography.demi}}
            labelStyle={styles.labelStyles}
            onSubmitEditing={formik.handleSubmit}
            {...testID('portCurp')}
          />
        </View>

        <Space width={72} />

        <View style={{flex: 1}}>
          <TextInputExtended
            label={content.port.names + ' *'}
            value={formik.values.names}
            placeholder={content.port.names}
            onChangeText={formik.handleChange('names')}
            onBlur={formik.handleBlur('names')}
            error={formik.touched.names && formik.errors.names}
            style={{...typography.demi}}
            labelStyle={styles.labelStyles}
            onSubmitEditing={formik.handleSubmit}
            {...testID('portNames')}
          />

          <Space height={8}/>

          <TextInputExtended
            label={content.port.surname + ' *'}
            value={formik.values.mothersName}
            placeholder={content.port.mothersName}
            onChangeText={formik.handleChange('mothersName')}
            onBlur={formik.handleBlur('mothersName')}
            error={formik.touched.mothersName && formik.errors.mothersName}
            labelStyle={styles.labelStyles}
            style={{...typography.demi}}
            onSubmitEditing={formik.handleSubmit}
            {...testID('portMotherName')}
          />

          <Space height={8}/>

          <TextInputExtended
            label={<View>
              <View style={{flexDirection: 'row'}}> 
                <Text>{content.port.pacCodeLabel} * </Text>
                <TouchableOpacity style={styles.helpIcon} onPress={() => setHelpPoint(!helpPoint)}>
                  <HelpIcon />
                </TouchableOpacity>
              </View>
              {helpPoint &&
              <View style={styles.helpPointContainer}>
                <Text style={styles.helpPointText}>
                  {content.port.helpPoint}
                </Text>
                <Space height={4} />
              </View>
              }
            </View>
            }
            value={formik.values.code}
            placeholder={content.port.pacCodePlaceholder}
            // keyboardType="phone-pad"
            onChangeText={formik.handleChange('code')}
            onBlur={formik.handleBlur('code')}
            error={formik.touched.code && formik.errors.code}
            footer={content.port.pacCodeFooter}
            style={{...typography.demi}}
            labelStyle={styles.labelStyles}
            footerStyle={{...typography.book}}
            onSubmitEditing={formik.handleSubmit}
            maxLength={4}
            showErrorAndFooter
            {...testID('portNip')}

          />
        </View>
      </View>
      <Space height={8}/>
      <Text style={styles.infoNoId}>{content.port.noID}</Text>

      <Space height={24} />

      <View style={styles.center}>
        <Button
          style={{width: 336, backgroundColor: colors.exisOrange}}
          disabledStyle={{width: 336, backgroundColor: colors.exisOnTintTransparentOnOrange}}
          title={
            formik.isSubmitting
              ? content.common.sending
              : content.common.submit
          }
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          onPress={formik.handleSubmit}
          titleStyle={{...typography.extrabold}}
          {...testID('portSubmit')}
        />
      </View>
      <Space height={8} />

      {visibleCurp &&
        <View style={styles.curpForm}>
          <Label style={styles.label}>{content.forms.dataObtain}</Label>
          <Label style={styles.labelStyles}>{content.forms.dobLabel} *</Label>
          <View style={{flexDirection: 'row'}}>
          <View style={{justifyContent: 'flex-start', flex: 1}}>
            <View style={styles.inputWrapper}>
              <TextInputExtended
                wrapperStyle={styles.nestedInputs}
                value={formik.values.dobDay}
                label={content.forms.dayLabel}
                maxLength={2}
                placeholder={content.forms.dayPlaceholder}
                keyboardType="number-pad"
                {...Platform.select({
                  web: { autoComplete: "bday-day" },
                  // ios: { textContentType: "bday-day" },
                  //android: { autoCompleteType: "bday-day" }
                })}
                onChangeText={formik.handleChange('dobDay')}
                onBlur={formik.handleBlur('dobDay')}
                error={formik.touched.dobDay && formik.errors.dobDay}
                hideError={false}
                style={styles.inputStyle}
                labelStyle={styles.dobLabelStyle}
                onSubmitEditing={formik.handleSubmit}
                {...testID('portDay')}
              />

              <Space width={8} />

              <TextInputExtended
                wrapperStyle={styles.nestedInputs}
                value={formik.values.dobMonth}
                label={content.forms.monthLabel}
                maxLength={2}
                placeholder={content.forms.monthPlaceholder}
                keyboardType="number-pad"
                {...Platform.select({
                  web: { autoComplete: "bday-month" },
                  // ios: { textContentType: "bday-month" },
                  //android: { autoCompleteType: "bday-month" }
                })}
                onChangeText={formik.handleChange('dobMonth')}
                onBlur={formik.handleBlur('dobMonth')}
                error={formik.touched.dobMonth && formik.errors.dobMonth}
                hideError={false}
                style={styles.inputStyle}
                labelStyle={styles.dobLabelStyle}
                onSubmitEditing={formik.handleSubmit}
                {...testID('portMonth')}
              />

              <Space width={8} />

              <TextInputExtended
                wrapperStyle={styles.nestedInputs}
                value={formik.values.dobYear}
                label={content.forms.yearLabel}
                maxLength={4}
                placeholder={content.forms.yearPlaceholder}
                keyboardType="number-pad"
                {...Platform.select({
                  web: { autoComplete: "bday-year" },
                  // ios: { textContentType: "bday-year" },
                  //android: { autoCompleteType: "bday-year" }
                })}
                onChangeText={formik.handleChange('dobYear')}
                onBlur={formik.handleBlur('dobYear')}
                error={formik.touched.dobYear && formik.errors.dobYear}
                hideError={false}
                style={styles.inputStyle}
                labelStyle={styles.dobLabelStyle}
                onSubmitEditing={formik.handleSubmit}
                {...testID('portYear')}
              />
            </View>
            <SelectExtended
              label={content.forms.sexTwo + " * "}
              value={formik.values.gender}
              placeholder={content.forms.sexTwo}
              options={[
                {value:'M', label: content.register_details.male},
                // {value:'MRS',label:'Mrs'},
                // {value:'MISS',label:'Miss'},
                {value:'F',label: content.register_details.female}]}
              onChange={(option) => selectTitle(option)}
              error={formik.touched.gender && formik.errors.gender}
              containerSyle={{width: 336, ...typography.demi}}
              styles={selectStyle}
              labelStyle={styles.labelStyles}
              selectId={"portFormSexSelect"}     
            />
          </View>
          {/* <Space width={100}/> */}
          <View>
            {/* <Space width={80}/> */}
            <SelectExtended
              label={content.port.stateLabel + "*"}
              value={formik.values.state}
              placeholder={content.port.federalDistrict}
              footer={content.port.stateFooter}
              options={regions}
              onChange={(option) => selectRegion(option)}
              error={formik.touched.state && formik.errors.state}
              containerSyle={{width: 336, alignSelf: 'flex-end', ...typography.demi}}
              styles={selectStyle}
              labelStyle={styles.labelStyles}
              footerStyle={{...typography.book}}
              selectId={"portFormStateSelect"}     
            />
            <Space height={75}/>
            <Button
              style={styles.enabledButton}
              disabledStyle={styles.disabledButton}
              title={
                formik.isSubmitting
                  ? content.port.curpButton
                  : content.port.curpButton
              }
              disabled={!formik.values.dobDay || !formik.values.dobMonth || 
                        !formik.values.dobYear || !formik.values.gender || 
                        !formik.values.state || !formik.values.names || 
                        !formik.values.fathersName || !formik.values.mothersName ||
                        formik.errors.dobDay || formik.errors.dobMonth ||
                        formik.errors.dobYear}
              onPress={() => formik.setFieldValue('curpField', generateCurp())}
              titleStyle={{...typography.extrabold}}
              {...testID('portGenerateButton')}
            />
          </View>
        </View>
        </View>
    }
      <Space height={48}/>
      <HorizontalLine height={3} color={colors.exisBlue} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540,
    paddingTop: 48,
    width: '100%',
    minWidth: 480,
    maxWidth: 840,
    paddingHorizontal: 24,
    alignSelf: 'center'
  },
  content: {
    width: 336,
    alignSelf: 'center'
  },
  headers: {
    alignItems: 'flex-start',
  },
  header: {
    textAlign: 'center',
    color: colors.exisBlue,
    ...typography.bold,
    fontSize: 48
  },
  subheader: {
    textAlign: 'center',
    ...typography.extrabold,
    color: colors.black,
    fontSize: 24
  },
  thirdHeader: {
    color: colors.exisCharcoal,
    ...typography.book,
  },  
  center: {
    alignItems: 'center'
  },
  label: {
    color: colors.exisBlue,
    ...typography.bold
  },
  curpFooter: {
    textDecorationLine: 'underline',
    ...typography.demi
  },
  inputStyle: {
    ...typography.demi
  },
  labelStyles: {
    ...typography.bold,
    color: colors.black
  },
  dobLabelStyle: {
    ...typography.demi,
    color: colors.exisCharcoal
  },
  inputWrapper: {
    flexDirection: 'row',
  },
  nestedInputs: {
    flex: 0.15
  },
  curpForm: {
    paddingTop: 48,
    width: '100%',
    minWidth: 480,
    maxWidth: 840,
    // paddingHorizontal: 24,
    alignSelf: 'center'
  },
  enabledButton: {
    width: 336,
    backgroundColor: colors.exisOrange,
    alignSelf: 'flex-end'
  },
  disabledButton: {
    width: 336,
    backgroundColor: colors.exisOnTintTransparentOnOrange
  },
  helpIcon: {
    justifyContent: 'center'
  },
  helpPointContainer: {

  },
  helpPointText: {
    ...typography.medium,
    color: colors.exisCharcoal,
    fontSize: 14
  },
  infoNoId: {
    color: colors.exisCharcoal,
    ...typography.demi,
    width: '45%',
    textAlign: 'justify'
  }
})

const selectStyle = {
  control: (base) => ({
    ...base,
    ...typography.demi,
  }),
  menu: (base) => ({
    ...base,
    ...typography.demi
  })
};

