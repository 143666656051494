import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

export default function CashIcon(props) {
    return (
        <Svg width={32} height={32} viewBox="0 0 32 32" {...props}>
          <G fill="none" fillRule="evenodd">
            <G fill="#009ABC" fillRule="nonzero">
              <Path d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16C31.99 7.168 24.832.01 16 0zm0 29.867C8.342 29.867 2.133 23.658 2.133 16 2.133 8.342 8.342 2.133 16 2.133c7.658 0 13.867 6.209 13.867 13.867-.01 7.654-6.213 13.857-13.867 13.867z" />
              <Path d="M16.316 14.983c-2.677-.839-4.305-1.465-4.305-3.093 0-1.508 2.026-2.407 3.989-2.407a8.846 8.846 0 014.395 1.066l.185.096a1.067 1.067 0 10.952-1.909l-.156-.082a10.76 10.76 0 00-4.31-1.262V5.689a1.067 1.067 0 00-2.133 0v1.728c-2.61.334-5.056 1.877-5.056 4.473 0 3.314 3.2 4.32 5.807 5.127 2.677.839 4.305 1.465 4.305 3.093 0 1.508-2.026 2.407-3.989 2.407a8.846 8.846 0 01-4.395-1.066l-.185-.096a1.067 1.067 0 10-.952 1.909l.156.082c1.332.719 2.8 1.149 4.31 1.262v1.703a1.067 1.067 0 002.133 0v-1.728c2.61-.334 5.056-1.877 5.056-4.473 0-3.314-3.218-4.32-5.807-5.127z" />
            </G>
          </G>
        </Svg>
    )
}