import * as React from "react"
import Svg, { Defs, Path, G, Rect, Mask, Use } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const EXiSAppIcon = (props) => (
  <Svg
    width={75}
    height={75}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <Defs>
      <Path id="exisIconA" d="M.171.019h49.875v63.478H.171z" />
      <Path id="exisIconC" d="M.171.019h49.875v63.478H.171z" />
      <Path
        d="M50.046 18.586C50.046 8.332 38.881.019 25.108.019S.171 8.332.171 18.586c0 .459.026.917.078 1.373.906 18.806 43.094 43.537 43.094 43.537l-26.2-29.162a41.731 41.731 0 0 0 9.667 1.12c13.767 0 23.236-6.613 23.236-16.868Zm-20.128 8.235h2.36V16.584h-2.36v10.237Zm10.916-5.89a2.194 2.194 0 0 0-.409-.724 3.254 3.254 0 0 0-.718-.621 6.959 6.959 0 0 0-1.049-.552l-1.562-.651c-2.216-.94-3.324-2.314-3.325-4.121a3.912 3.912 0 0 1 1.4-3.058 5.032 5.032 0 0 1 3.471-1.24 4.844 4.844 0 0 1 4.373 2.732l-1.982 1.177a3.41 3.41 0 0 0-1.059-1.26 2.46 2.46 0 0 0-1.353-.337 2.5 2.5 0 0 0-1.688.578c-.433.347-.68.875-.671 1.43 0 .792.587 1.43 1.761 1.913l1.615.661a6.9 6.9 0 0 1 2.884 1.95 4.4 4.4 0 0 1 .913 2.822 4.964 4.964 0 0 1-1.49 3.7 5.1 5.1 0 0 1-3.722 1.472 4.969 4.969 0 0 1-3.482-1.252 5.753 5.753 0 0 1-1.688-3.52l2.475-.547a4.21 4.21 0 0 0 .587 1.976 2.786 2.786 0 0 0 4.1.284c.506-.518.779-1.22.755-1.944a2.749 2.749 0 0 0-.136-.868Zm-10.821-8.39a1.467 1.467 0 0 1 1.08-.452c.41-.007.806.157 1.091.452.295.282.458.675.45 1.083.007.41-.156.806-.45 1.093-.281.295-.673.46-1.081.451a1.483 1.483 0 0 1-1.09-.451 1.5 1.5 0 0 1-.451-1.1 1.464 1.464 0 0 1 .451-1.076Zm-14.055.354H9.467v3.9h6.3v2.3h-6.3v5.423h6.491v2.3H7.024V10.593h8.934v2.302Zm7.06 7.714-3.691 6.212h-2.769l5.087-8.482-4.457-7.746h2.779l3.093 5.5 3.251-5.5h2.8l-4.666 7.746 4.9 8.482h-2.737l-3.59-6.212Z"
        id="exisIconE"
      />
      <Path id="exisIconG" d="M.171.019h49.875v63.478H.171z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Rect
        fill="#FF5210"
        fillRule="nonzero"
        x={0.08}
        y={0.671}
        width={74.057}
        height={74.057}
        rx={11.343}
      />
      <G transform="translate(12 7)">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#exisIconA" />
        </Mask>
        <G mask="url(#b)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#exisIconC" />
          </Mask>
          <G mask="url(#d)">
            <Mask id="f" fill="#fff">
              <Use xlinkHref="#exisIconE" />
            </Mask>
            <G mask="url(#f)">
              <Mask id="h" fill="#fff">
                <Use xlinkHref="#exisIconG" />
              </Mask>
              <Path
                fill="#FEFEFE"
                fillRule="nonzero"
                mask="url(#h)"
                d="M-4.364-4.515H54.58v72.546H-4.364z"
              />
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
)

export default EXiSAppIcon
