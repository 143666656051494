import React, { useEffect } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'

import {
  Title,
  Text,
  Headline,
  Subhead,
  Anchor,
  Space,
  Button,
  Description
} from '@sart/components'
import { useContent } from '@sart/content'
import client, { useClient, useData } from '@sart/client'
import { GuruSmoothCriminal } from '@sart/icons'
import { adaptive, colors, typography } from '@sart/styles'
import { concatStringID } from '@sart/utilities'

// import Barcode from 'react-native-barcode-builder';
import { useBarcode } from 'react-barcodes';


import Wrapper from './Wrapper'

import {
  isMobileOnly
} from "react-device-detect";

export default function Help(props) {
  const content = useContent()
  const { history } = props

  const {
    cashBasket
  } = client.cache

  useEffect(() => {
    client.unsetCache('selectedPlan')
    client.unsetCache('customerDetails')
    client.unsetCache('cachedBasket')
    client.unsetCache('existingSIM')
    client.unsetCache('promoPlans')
    client.unsetCache('referralCode')
  }, [])

  var total = cashBasket.total;
  
  function calculateTotalCost(){
    return (total / 100).toFixed(2) 
  }

    const { inputRef } = useBarcode({
      value: client.cache.barCode,
      options: {}
    });

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>        
        <Space height={24} />
        <GuruSmoothCriminal width={250} height={200}/>
        <Space height={24} />        
      
        <Title style={styles.title}>{content.payment_confirm.almostDone}</Title>
        <Space height={24} />        

        <Description style={styles.description}>{content.payment_confirm.cashDescription}</Description>
        
        <Space height={24} />        
        {/* Unsure if 'CODE128' format is correct */}
        {/* <Barcode value={'98000007077160'} format={'CODE128'} text={'98000007077160'} width={styles.barcodeSize.width} /> */}
        {/* <View {...testID('barcode')}> */}
          <svg ref={inputRef} {...concatStringID('barcode')} />
        {/* </View> */}
        <Description style={styles.price}>{content.checkout.total} ${calculateTotalCost()} {content.payment_confirm.currencyCode}</Description>
        <Space height={24} />
        
        <Button
          style={styles.button}
          title={content.payment_confirm.action}
          onPress={() => {history.push('/')}}
          titleStyle={{...typography.extrabold, fontSize: 18}}
        />
        <Space height={24} />        
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.exisBlue,
      ...adaptive.wrapperPadding,
    },
    wrapper: {
      flex: 1,
      backgroundColor: colors.exisBlue,
      ...adaptive.wrapperPadding,
      // justifyContent: 'center',
      alignItems: 'center'  
  },
    title: {
      color: colors.exisOnTint,
      ...typography.bold,
      fontSize: 24
    },
    description: {
      color: colors.black,
      textAlign: 'center',
      ...typography.lightbold
    },
    button: {
        backgroundColor: colors.exisOrange
    },
    barcodeSize: {
      width: 2.48
    },
    price: {
      backgroundColor: colors.exisDarkCharcoal,
      color: colors.clear,
      ...typography.lightbold,
      textAlign: 'center',
      width: 247
    },
    message: { 
      fontSize:  isMobileOnly ? 12 : 15, 
      lineHeight: 24, 
      textAlign: 'center',
      color: colors.white,
      ...typography.bold 
    },
    textContainer: {
      alignItems: 'center', 
    }
  })
