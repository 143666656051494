import { default as defaultContent} from '@sart/content'

import get from 'lodash/get'

export default function prettyErrorMessage(error, flow, content = defaultContent) {
  const response = get(error, 'response')
  const networkUnavailableErrorMessage = 'Request has been terminated\nPossible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.'
  const customerAgentLoginError = "Missing the required parameter 'employeeId' when calling getOperatorEmployee"
  const disconnectedError = "Service Disconnected. Please contact call centre"

  if (error.message === networkUnavailableErrorMessage) {
    return content.errors.networkUnavailable
  }
  if (error.message === customerAgentLoginError){
    return content.errors.customerLoginAsAgent
  }
  if(error.message === disconnectedError) {
    return content.errors.serviceDisconnected
  }
  // There is an API error
  if (response) {
    const code = get(response, 'body.code') !== undefined ? get(response, 'body.code') : get(response, 'body.messageCode')

    // we don't have a code for it, general error.
    if (!code) {
      return content.errors.unknown
    }

    const mapping = get(content, ['errorMapping', code, flow])
    const message = get(content, mapping)

    // we have a code for it and a mapping in content.
    if (message) {
      return message
    }

    // we have a code but no mapping for it.
    else {
      const responseMessage = get(response, 'body.message')

      console.error(`No mapping found for error code: ${code}, flow: ${flow}, message: ${responseMessage}`)

      return content.errors.unknown
    }
  }

  //All other errors
  else {
    return error.message
  }
}
