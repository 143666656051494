import * as React from "react"
import PropTypes from 'prop-types'
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

export default function Instagram(props) {
  return (
    <Svg
      width={150}
      height={150}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="prefix__a" d="M0 0h15v15H0z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M-896-2325H128V71H-896z" />
        <Path d="M-897-89H127v224H-897z" fill="#FFF" />
        <G>
          <Mask id="prefix__b" fill="#fff">
            <Use xlinkHref="#prefix__a" />
          </Mask>
          <Path
            d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
            fill="gray"
            mask="url(#prefix__b)"
          />
        </G>
        <Path
          d="M7.5 2.692c-1.306 0-1.47.005-1.982.028-.512.024-.862.105-1.167.224a2.358 2.358 0 00-.852.555 2.359 2.359 0 00-.555.85c-.118.308-.2.656-.223 1.168-.024.513-.03.677-.03 1.983s.006 1.47.03 1.982c.023.512.105.862.223 1.167.123.317.287.585.555.853.267.266.535.431.852.553.305.12.655.201 1.167.224.512.024.676.03 1.982.03s1.47-.006 1.983-.03c.51-.023.86-.104 1.166-.223a2.36 2.36 0 00.852-.554c.268-.268.432-.537.555-.853.118-.305.2-.655.223-1.167.024-.512.03-.676.03-1.982s-.006-1.47-.03-1.983c-.023-.512-.105-.86-.223-1.167a2.348 2.348 0 00-.555-.851 2.364 2.364 0 00-.852-.555c-.305-.119-.655-.2-1.166-.224-.513-.023-.677-.028-1.983-.028m0 .866c1.284 0 1.436.005 1.943.028.468.021.723.1.893.165.224.088.384.192.553.36.168.168.272.329.36.553.065.17.144.424.165.893.023.507.028.66.028 1.943 0 1.284-.005 1.435-.028 1.943-.021.469-.1.723-.166.893a1.49 1.49 0 01-.36.553 1.492 1.492 0 01-.552.359c-.17.066-.425.144-.893.166-.507.023-.659.028-1.943.028s-1.436-.005-1.942-.028c-.47-.022-.725-.1-.894-.166a1.492 1.492 0 01-.553-.36 1.482 1.482 0 01-.36-.553c-.065-.169-.144-.423-.165-.892-.023-.508-.028-.66-.028-1.943 0-1.284.005-1.436.028-1.943.02-.469.1-.723.166-.893.087-.224.191-.385.36-.553.168-.168.328-.272.552-.36.17-.065.424-.144.894-.165.506-.023.658-.028 1.942-.028"
          fill="#FFF"
        />
        <Path
          d="M7.5 9.103a1.603 1.603 0 110-3.205 1.603 1.603 0 010 3.205m0-4.073a2.47 2.47 0 10.001 4.94 2.47 2.47 0 000-4.94M10.644 4.934a.577.577 0 11-1.154-.001.577.577 0 011.154 0"
          fill="#FFF"
        />
      </G>
    </Svg>
  )
}

Instagram.defaultProps = {
    width: 121,
    height: 154,
  }
  
Instagram.propTypes = {
    color: PropTypes.string
}