import React from 'react'
import { View, StyleSheet } from 'react-native'

import {
  Headline,
  Subhead,
  Space,
} from '@sart/components'
import {
  colors,
  typography
} from '@sart/styles'
import { useContent } from '@sart/content'

import AgentOrUserSignInSwitcher from '../Agent/AgentOrUserSignInSwitcher'

export default function PortSignIn(props) {
  const { history, match } = props

  const content = useContent()

  return (
    <View style={styles.content}>
      <View style={styles.headers}>
        <Headline style={styles.header}>{content.port.header}</Headline>
        <Subhead style={styles.subheader}>{content.port.subheader}</Subhead>
      </View>

      <Space height={24} />

      <AgentOrUserSignInSwitcher
        onLoginSuccess={() => void history.push(`/port/form`)}
        title={content.login.actionPort}
        submittingTitle={content.login.inProgressPort}
        enabledStyle={styles.enabled}
        disabledStyle={styles.disabled}
        onPressFooter={() => void history.push('/help')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    minHeight: 600,
    paddingTop: 60,
  },
  headers: {
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    color: colors.exisBlue,
    ...typography.medium
  },
  subheader: {
    textAlign: 'center',
    color: colors.trivial,
    ...typography.medium
  },
  labelStyle: {
    ...typography.book
  },
  footerStyle: {
    ...typography.book
  },
  inputStyle: {
    ...typography.book
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  }
})

