import React from "react";
import Svg, { Path, G, Defs, Use, Mask, } from "react-native-svg";

const AddonIcon = props => (
  <Svg
  xmlns="http://www.w3.orG/2000/svG"
  xmlnsXlink="http://www.w3.orG/1999/xlink"
  width="354"
  heiGht="231"
  viewBox="0 0 354 231"
>
  <Defs>
    <Path
      id="Path-1"
      d="M12.74.355C6.206.355.911 5.67.911 12.23V202.18c0 6.557 5.295 11.878 11.83 11.878h314.434c6.536 0 11.831-5.32 11.831-11.878V12.23c0-6.56-5.295-11.876-11.831-11.876H12.74z"
    ></Path>
    <Path
      id="Path-3"
      d="M-3.97903932e-13 229.761324L353.98885 229.761324 353.98885 1.42108547e-14 -3.97903932e-13 1.42108547e-14z"
    ></Path>
    <Path
      id="Path-5"
      d="M0 39.9307692L178.442346 39.9307692 178.442346 1.19241538 0 1.19241538z"
    ></Path>
  </Defs>
  <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <G transform="translate(-547 -139)">
      <Path fill="#FFF" d="M0 3H1024V1021H0z"></Path>
      <G transform="translate(554 148)">
        <Mask id="Mask-2" fill="#fff">
          <Use xlinkHref="#Path-1"></Use>
        </Mask>
        <Path
          fill="#FF5210"
          d="M-6.96721311 221.980519L346.956066 221.980519 346.956066 -7.52779221 -6.96721311 -7.52779221z"
          Mask="url(#Mask-2)"
        ></Path>
        <Path
          stroke="#231F20"
          strokeWidth="0.25"
          d="M12.74.373h314.436c6.535 0 11.833 5.318 11.833 11.878V202.2c0 6.557-5.298 11.878-11.833 11.878H12.74c-6.534 0-11.83-5.321-11.83-11.878V12.25C.91 5.691 6.207.373 12.74.373z"
          Mask="url(#Mask-2)"
        ></Path>
      </G>
      <Path
        fill="#009ABA"
        d="M770.645 210.928c-2.288 0-4.147 1.9-4.147 4.244v44.863a4.36 4.36 0 001.222 3.053c1.006 1.033 9.902 10.24 9.902 10.24a4.116 4.116 0 002.921 1.237h85.522c2.293 0 4.15-1.897 4.15-4.24v-55.153c0-2.344-1.857-4.244-4.15-4.244h-95.42z"
      ></Path>
      <Path
        fill="#CCD9E0"
        d="M868.486 222.28a.203.203 0 01-.202-.2v-4.711c0-2.227-1.73-4.004-3.965-4.004h-52.33a.196.196 0 01-.196-.198v-2.772a2.973 2.973 0 012.933-2.973h49.563c5.594 0 9.924 4.542 9.924 9.915v1.933c0 1.68-1.328 3.01-2.962 3.01h-2.765z"
      ></Path>
      <Path
        stroke="#C1D1D9"
        strokeWidth="0.4"
        d="M858.688 217.76l-54.237.18c-1.797 0-3.626 1.882-3.626 3.7l-.035 34.244c0 .941.373 1.781.959 2.372.788.803 7.757 7.951 7.757 7.951a3.231 3.231 0 002.29.963h46.892c1.797 0 3.25-1.478 3.25-3.294v-42.824c0-1.815-1.453-3.292-3.25-3.292z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M853.321635 255.545115L815.792061 255.545115 815.440848 255.519957 815.089635 255.461256 814.755147 255.360626 814.429021 255.234838 814.11962 255.067121 813.843667 254.867258 813.592801 254.64084 813.55099 254.605899 808.834706 249.925192 808.817982 249.918204 808.592202 249.666629 808.391509 249.382907 808.215903 249.062847 808.082108 248.735798 807.973399 248.391978 807.914864 248.048158 807.898139 247.687566 807.898139 223.846572 807.914864 223.477594 807.981761 223.125388 808.082108 222.773182 808.215903 222.45452 808.383147 222.152629 808.592202 221.86751 808.826344 221.607548 809.07721 221.38113 809.369887 221.171484 809.670927 220.995381 809.988691 220.869593 810.339903 220.760577 810.691116 220.701876 811.033966 220.676718 811.12595 220.685104 853.33836 220.685104 853.697935 220.701876 854.056116 220.768962 854.397572 220.869593 854.718124 221.003766 855.019163 221.171484 855.31184 221.38113 855.561313 221.615934 855.795454 221.875896 855.997541 222.152629 856.164785 222.45452 856.306943 222.773182 856.407289 223.117003 856.465824 223.477594 856.488124 223.846572 856.488124 252.375261 856.465824 252.735853 856.407289 253.106228 856.306943 253.440265 856.164785 253.758927 855.997541 254.069204 855.795454 254.354323 855.561313 254.614285 855.31184 254.840703 855.019163 255.050349 854.718124 255.219464 854.397572 255.35224 854.056116 255.454268 853.697935 255.519957z"
      ></Path>
      <Path
        stroke="#CCD9E0"
        strokeWidth="0.25"
        d="M853.321635 255.545115L815.792061 255.545115 815.440848 255.519957 815.089635 255.461256 814.755147 255.360626 814.429021 255.234838 814.11962 255.067121 813.843667 254.867258 813.592801 254.64084 813.55099 254.605899 808.834706 249.925192 808.817982 249.918204 808.592202 249.666629 808.391509 249.382907 808.215903 249.062847 808.082108 248.735798 807.973399 248.391978 807.914864 248.048158 807.898139 247.687566 807.898139 223.846572 807.914864 223.477594 807.981761 223.125388 808.082108 222.773182 808.215903 222.45452 808.383147 222.152629 808.592202 221.86751 808.826344 221.607548 809.07721 221.38113 809.369887 221.171484 809.670927 220.995381 809.988691 220.869593 810.339903 220.760577 810.691116 220.701876 811.033966 220.676718 811.12595 220.685104 853.33836 220.685104 853.697935 220.701876 854.056116 220.768962 854.397572 220.869593 854.718124 221.003766 855.019163 221.171484 855.31184 221.38113 855.561313 221.615934 855.795454 221.875896 855.997541 222.152629 856.164785 222.45452 856.306943 222.773182 856.407289 223.117003 856.465824 223.477594 856.488124 223.846572 856.488124 252.375261 856.465824 252.735853 856.407289 253.106228 856.306943 253.440265 856.164785 253.758927 855.997541 254.069204 855.795454 254.354323 855.561313 254.614285 855.31184 254.840703 855.019163 255.050349 854.718124 255.219464 854.397572 255.35224 854.056116 255.454268 853.697935 255.519957z"
      ></Path>
      <G transform="translate(547 139.85)">
        <Mask fill="#fff">
          <Use xlinkHref="#Path-3"></Use>
        </Mask>
      </G>
      <Path
        fill="#FEFEFE"
        d="M570.226024 345.799447L761.614591 345.799447 761.614591 299.070652 570.226024 299.070652z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M718.677 172.703a4.186 4.186 0 01-4.181 4.193H602.583a4.186 4.186 0 01-4.18-4.193v-6.773a4.186 4.186 0 014.18-4.193h111.913a4.186 4.186 0 014.181 4.193v6.773z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M718.677 196.826a4.188 4.188 0 01-4.181 4.193H602.583a4.188 4.188 0 01-4.18-4.193v-6.775a4.188 4.188 0 014.18-4.193h111.913a4.188 4.188 0 014.181 4.193v6.775z"
      ></Path>
      <Path
        fill="#FF5210"
        d="M852.975 234.11c0-4.072-4.434-7.374-9.901-7.374-5.464 0-9.896 3.302-9.896 7.374 0 .185.01.366.03.545.36 7.47 17.106 17.29 17.106 17.29l-10.401-11.58a16.55 16.55 0 003.837.443c5.466 0 9.225-2.625 9.225-6.698"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M839.444557 231.849336L836.867604 231.849336 836.867604 233.397925 839.369297 233.397925 839.369297 234.311983 836.867604 234.311983 836.867604 236.465751 839.444557 236.465751 839.444557 237.381207 835.897588 237.381207 835.897588 230.935278 839.444557 230.935278z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M841.701098 234.012049L839.929704 230.935837 841.034909 230.935837 842.262759 233.118955 843.553326 230.935837 844.664106 230.935837 842.813271 234.012049 844.75609 237.380368 843.669003 237.380368 842.246035 234.913529 840.781255 237.380368 839.683019 237.380368z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M844.844 232.135c0-.164.06-.306.178-.427a.588.588 0 01.43-.179c.17 0 .313.06.431.18a.58.58 0 01.18.43c0 .17-.06.314-.18.433a.571.571 0 01-.43.18.587.587 0 01-.43-.18.595.595 0 01-.18-.437zm.14 5.245h.937v-4.066h-.938v4.066z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M850.182 231.77l-.786.468c-.148-.256-.289-.422-.421-.5a.993.993 0 00-.538-.135.988.988 0 00-.67.23.71.71 0 00-.267.568c0 .315.233.568.7.76l.641.263c.521.211.905.47 1.144.774.241.305.363.678.363 1.121 0 .593-.197 1.082-.591 1.47-.398.39-.89.584-1.478.584-.559 0-1.018-.165-1.38-.497-.359-.33-.582-.798-.673-1.399l.983-.216c.045.378.123.64.233.784.2.278.492.418.875.418.302 0 .553-.102.753-.305.2-.203.3-.461.3-.773 0-.124-.017-.239-.052-.344a.894.894 0 00-.162-.288 1.296 1.296 0 00-.286-.246 2.719 2.719 0 00-.415-.22l-.623-.258c-.877-.373-1.318-.92-1.318-1.636 0-.484.185-.89.554-1.215.368-.328.828-.492 1.377-.492.742 0 1.32.36 1.737 1.085"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M572.909 169.01h.634c.702 0 1.054-.31 1.054-.929s-.352-.93-1.054-.93h-.634v1.86zm0 4.033h-1.912v-7.452h3.043c.827 0 1.458.218 1.899.652.437.435.657 1.049.657 1.838 0 .791-.22 1.404-.657 1.838-.44.435-1.072.653-1.899.653h-1.131v2.47z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M578.099039 173.04239L580.011197 173.04239 580.011197 165.590158 578.099039 165.590158z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M582.040007 173.04253L582.040007 165.590297 583.952165 165.590297 587.483802 170.146613 587.483802 165.590297 589.386204 165.590297 589.386204 173.04253 587.483802 173.04253 583.952165 168.486214 583.952165 173.04253z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M572.484 193.029h.634c.703 0 1.054-.31 1.054-.93 0-.619-.351-.93-1.054-.93h-.634v1.86zm0 4.032h-1.912v-7.452h3.044c.826 0 1.458.218 1.898.652.438.435.658 1.048.658 1.838 0 .791-.22 1.403-.658 1.838-.44.435-1.072.653-1.898.653h-1.132v2.47z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M579.586 189.609v4.052c0 .218.008.44.025.666.015.228.064.435.146.618.081.184.21.335.385.45.176.116.422.173.743.173.318 0 .563-.057.736-.173.171-.115.3-.266.384-.45.085-.183.136-.39.152-.618.016-.226.024-.448.024-.666v-4.052h1.904v4.319c0 1.16-.264 2.005-.786 2.539-.524.534-1.328.8-2.414.8-1.089 0-1.894-.266-2.42-.8-.528-.534-.791-1.38-.791-2.54v-4.318h1.912z"
      ></Path>
      <Path
        fill="#FEFEFE"
        d="M588.025813 192.682628L590.357474 189.609211 592.718403 189.609211 589.801387 193.136862 592.991569 197.060046 590.513569 197.060046 588.025813 193.828695 588.025813 197.060046 586.113655 197.060046 586.113655 189.609211 588.025813 189.609211z"
      ></Path>
      <Path
        fill="#CCD9E0"
        d="M783.004 272.792c-1.255 0-2.145-.508-2.817-1.13-.036-.033-9.473-9.601-9.572-9.702-.532-.535-1.05-1.59-1.05-2.703v-41.841c0-2.469 1.892-4.052 4.002-4.052h30.157c.125 0 .213-.087.213-.207v-2.707c0-1.695-1.33-3.027-2.967-3.027h-27.456c-5.455 0-9.877 4.434-9.877 9.905v43.578c0 1.549.715 3.156 1.68 4.122.671.675 11.35 11.315 11.35 11.315a5.91 5.91 0 004.75 2.394h19.553a2.971 2.971 0 002.967-2.972v-2.773c0-.114-.082-.2-.208-.2h-20.725z"
      ></Path>
      <Path
        fill="#CCD9E0"
        d="M868.528 263.88a.202.202 0 00-.203.199v4.711c0 2.227-1.73 4.002-3.964 4.002h-52.329a.198.198 0 00-.197.2v2.773a2.973 2.973 0 002.933 2.972h49.563c5.594 0 9.924-4.542 9.924-9.914v-1.933c0-1.682-1.328-3.01-2.961-3.01h-2.766z"
      ></Path>
      <Path
        fill="#231F20"
        d="M608.586941 334.019973L608.566035 334.019973 607.463618 334.616766 607.297768 333.961272 608.683106 333.217725 609.416193 333.217725 609.416193 339.579796 608.586941 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M613.473 336.428c0 1.654.507 2.594 1.29 2.594.877 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.751 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.802 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.099 1.193 2.099 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M620.547681 334.019973L620.526776 334.019973 619.424358 334.616766 619.258508 333.961272 620.643846 333.217725 621.376933 333.217725 621.376933 339.579796 620.547681 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M625.432 336.428c0 1.654.508 2.594 1.29 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.75 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.1 1.193 2.1 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M632.507167 334.019973L632.486261 334.019973 631.383844 334.616766 631.217994 333.961272 632.603332 333.217725 633.336419 333.217725 633.336419 339.579796 632.507167 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M637.393 336.428c0 1.654.508 2.594 1.29 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.75 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.099 1.193 2.099 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M644.467907 334.019973L644.447002 334.019973 643.344584 334.616766 643.178734 333.961272 644.564072 333.217725 645.297159 333.217725 645.297159 339.579796 644.467907 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M649.354 336.428c0 1.654.507 2.594 1.29 2.594.877 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.751 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.802 3.358-2.207 3.358-1.24 0-2.079-1.165-2.098-3.27 0-2.134.917-3.308 2.206-3.308 1.338 0 2.099 1.193 2.099 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M656.427393 334.019973L656.406487 334.019973 655.30407 334.616766 655.13822 333.961272 656.523558 333.217725 657.256645 333.217725 657.256645 339.579796 656.427393 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M661.313 336.428c0 1.654.508 2.594 1.29 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.75 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.1 1.193 2.1 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M668.388133 334.019973L668.367228 334.019973 667.26481 334.616766 667.09896 333.961272 668.484298 333.217725 669.217385 333.217725 669.217385 339.579796 668.388133 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M673.274 336.428c0 1.654.508 2.594 1.29 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.751 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.099 1.193 2.099 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M680.348873 334.019973L680.327968 334.019973 679.22555 334.616766 679.0597 333.961272 680.445039 333.217725 681.178125 333.217725 681.178125 339.579796 680.348873 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M685.234 336.428c0 1.654.507 2.594 1.289 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.287-2.594-.752 0-1.3.92-1.3 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.099-3.27 0-2.134.917-3.308 2.206-3.308 1.338 0 2.1 1.193 2.1 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M692.308359 334.019973L692.287454 334.019973 691.185036 334.616766 691.019186 333.961272 692.404524 333.217725 693.137611 333.217725 693.137611 339.579796 692.308359 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M697.195 336.428c0 1.654.507 2.594 1.289 2.594.878 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.75 0-1.298.92-1.298 2.653m3.446-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.099-3.27 0-2.134.917-3.308 2.206-3.308 1.338 0 2.1 1.193 2.1 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M704.269099 334.019973L704.248194 334.019973 703.145776 334.616766 702.979926 333.961272 704.365265 333.217725 705.098351 333.217725 705.098351 339.579796 704.269099 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M709.155 336.428c0 1.654.508 2.594 1.29 2.594.877 0 1.297-1.028 1.297-2.653 0-1.567-.4-2.594-1.288-2.594-.751 0-1.299.92-1.299 2.653m3.447-.098c0 2.163-.801 3.358-2.206 3.358-1.24 0-2.08-1.165-2.1-3.27 0-2.134.918-3.308 2.207-3.308 1.338 0 2.099 1.193 2.099 3.22"
      ></Path>
      <Path
        fill="#231F20"
        d="M716.228585 334.019973L716.20768 334.019973 715.105262 334.616766 714.939412 333.961272 716.32475 333.217725 717.057837 333.217725 717.057837 339.579796 716.228585 339.579796z"
      ></Path>
      <Path
        fill="#231F20"
        d="M724.248 339.58l-.84-1.458c-.34-.559-.555-.921-.76-1.303h-.02c-.185.382-.372.734-.713 1.311l-.79 1.45h-.976l2.011-3.338-1.933-3.26h.985l.869 1.547c.245.43.43.763.606 1.116h.028c.186-.392.352-.696.596-1.116l.9-1.547h.975l-2.002 3.211 2.05 3.387h-.986z"
      ></Path>
      <G fill="#000" transform="translate(590 307)">
        <Path d="M0 23.7072L1.22344937 23.7072 1.22344937 -0.000124675325 0 -0.000124675325z"></Path>
        <Path d="M1.86572152 23.7072L2.47729114 23.7072 2.47729114 -0.000124675325 1.86572152 -0.000124675325z"></Path>
        <Path d="M3.69981013 23.7072L4.31137975 23.7072 4.31137975 -0.000124675325 3.69981013 -0.000124675325z"></Path>
        <Path d="M6.81999367 23.7072L7.43156329 23.7072 7.43156329 -0.000124675325 6.81999367 -0.000124675325z"></Path>
        <Path d="M11.1465696 23.7072L11.7584494 23.7072 11.7584494 -0.000124675325 11.1465696 -0.000124675325z"></Path>
        <Path d="M17.3208797 23.7072L17.9327595 23.7072 17.9327595 -0.000124675325 17.3208797 -0.000124675325z"></Path>
        <Path d="M20.4078797 23.7072L21.0197595 23.7072 21.0197595 -0.000124675325 20.4078797 -0.000124675325z"></Path>
        <Path d="M25.4465063 23.7072L26.0580759 23.7072 26.0580759 -0.000124675325 25.4465063 -0.000124675325z"></Path>
        <Path d="M31.6232975 23.7072L32.234557 23.7072 32.234557 -0.000124675325 31.6232975 -0.000124675325z"></Path>
        <Path d="M39.6996139 23.7072L40.3114937 23.7072 40.3114937 -0.000124675325 39.6996139 -0.000124675325z"></Path>
        <Path d="M46.5518608 23.7072L47.1637405 23.7072 47.1637405 -0.000124675325 46.5518608 -0.000124675325z"></Path>
        <Path d="M9.32922785 23.7072L10.5526772 23.7072 10.5526772 -0.000124675325 9.32922785 -0.000124675325z"></Path>
        <Path d="M13.6399873 23.7072L15.4902025 23.7072 15.4902025 -0.000124675325 13.6399873 -0.000124675325z"></Path>
        <Path d="M29.159962 23.7072L31.0098671 23.7072 31.0098671 -0.000124675325 29.159962 -0.000124675325z"></Path>
        <Path d="M34.0816709 23.7072L35.9318861 23.7072 35.9318861 -0.000124675325 34.0816709 -0.000124675325z"></Path>
        <Path d="M44.0243291 23.7072L45.8745443 23.7072 45.8745443 -0.000124675325 44.0243291 -0.000124675325z"></Path>
        <Path d="M18.5939494 23.7072L19.8173987 23.7072 19.8173987 -0.000124675325 18.5939494 -0.000124675325z"></Path>
        <Path d="M23.4951899 23.7072L24.7183291 23.7072 24.7183291 -0.000124675325 23.4951899 -0.000124675325z"></Path>
        <Path d="M27.3233924 23.7072L28.5468418 23.7072 28.5468418 -0.000124675325 27.3233924 -0.000124675325z"></Path>
        <Path d="M36.6203671 23.7072L37.8438165 23.7072 37.8438165 -0.000124675325 36.6203671 -0.000124675325z"></Path>
        <Path d="M40.8988734 23.7072L42.1223228 23.7072 42.1223228 -0.000124675325 40.8988734 -0.000124675325z"></Path>
        <Path d="M47.7768608 23.7072L49.0003101 23.7072 49.0003101 -0.000124675325 47.7768608 -0.000124675325z"></Path>
      </G>
      <G fill="#000" transform="translate(642 307)">
        <Path d="M0 23.7072L1.22344937 23.7072 1.22344937 -0.000124675325 0 -0.000124675325z"></Path>
        <Path d="M1.86572152 23.7072L2.47729114 23.7072 2.47729114 -0.000124675325 1.86572152 -0.000124675325z"></Path>
        <Path d="M3.69981013 23.7072L4.31137975 23.7072 4.31137975 -0.000124675325 3.69981013 -0.000124675325z"></Path>
        <Path d="M6.81999367 23.7072L7.43156329 23.7072 7.43156329 -0.000124675325 6.81999367 -0.000124675325z"></Path>
        <Path d="M11.1465696 23.7072L11.7584494 23.7072 11.7584494 -0.000124675325 11.1465696 -0.000124675325z"></Path>
        <Path d="M17.3208797 23.7072L17.9327595 23.7072 17.9327595 -0.000124675325 17.3208797 -0.000124675325z"></Path>
        <Path d="M20.4078797 23.7072L21.0197595 23.7072 21.0197595 -0.000124675325 20.4078797 -0.000124675325z"></Path>
        <Path d="M25.4465063 23.7072L26.0580759 23.7072 26.0580759 -0.000124675325 25.4465063 -0.000124675325z"></Path>
        <Path d="M31.6232975 23.7072L32.234557 23.7072 32.234557 -0.000124675325 31.6232975 -0.000124675325z"></Path>
        <Path d="M39.6996139 23.7072L40.3114937 23.7072 40.3114937 -0.000124675325 39.6996139 -0.000124675325z"></Path>
        <Path d="M46.5518608 23.7072L47.1637405 23.7072 47.1637405 -0.000124675325 46.5518608 -0.000124675325z"></Path>
        <Path d="M9.32922785 23.7072L10.5526772 23.7072 10.5526772 -0.000124675325 9.32922785 -0.000124675325z"></Path>
        <Path d="M13.6399873 23.7072L15.4902025 23.7072 15.4902025 -0.000124675325 13.6399873 -0.000124675325z"></Path>
        <Path d="M29.159962 23.7072L31.0098671 23.7072 31.0098671 -0.000124675325 29.159962 -0.000124675325z"></Path>
        <Path d="M34.0816709 23.7072L35.9318861 23.7072 35.9318861 -0.000124675325 34.0816709 -0.000124675325z"></Path>
        <Path d="M44.0243291 23.7072L45.8745443 23.7072 45.8745443 -0.000124675325 44.0243291 -0.000124675325z"></Path>
        <Path d="M18.5939494 23.7072L19.8173987 23.7072 19.8173987 -0.000124675325 18.5939494 -0.000124675325z"></Path>
        <Path d="M23.4951899 23.7072L24.7183291 23.7072 24.7183291 -0.000124675325 23.4951899 -0.000124675325z"></Path>
        <Path d="M27.3233924 23.7072L28.5468418 23.7072 28.5468418 -0.000124675325 27.3233924 -0.000124675325z"></Path>
        <Path d="M36.6203671 23.7072L37.8438165 23.7072 37.8438165 -0.000124675325 36.6203671 -0.000124675325z"></Path>
        <Path d="M40.8988734 23.7072L42.1223228 23.7072 42.1223228 -0.000124675325 40.8988734 -0.000124675325z"></Path>
        <Path d="M47.7768608 23.7072L49.0003101 23.7072 49.0003101 -0.000124675325 47.7768608 -0.000124675325z"></Path>
      </G>
      <G fill="#000" transform="translate(694 307)">
        <Path d="M0 23.7072L1.22344937 23.7072 1.22344937 -0.000124675325 0 -0.000124675325z"></Path>
        <Path d="M1.86572152 23.7072L2.47729114 23.7072 2.47729114 -0.000124675325 1.86572152 -0.000124675325z"></Path>
        <Path d="M3.69981013 23.7072L4.31137975 23.7072 4.31137975 -0.000124675325 3.69981013 -0.000124675325z"></Path>
        <Path d="M6.81999367 23.7072L7.43156329 23.7072 7.43156329 -0.000124675325 6.81999367 -0.000124675325z"></Path>
        <Path d="M11.1465696 23.7072L11.7584494 23.7072 11.7584494 -0.000124675325 11.1465696 -0.000124675325z"></Path>
        <Path d="M17.3208797 23.7072L17.9327595 23.7072 17.9327595 -0.000124675325 17.3208797 -0.000124675325z"></Path>
        <Path d="M20.4078797 23.7072L21.0197595 23.7072 21.0197595 -0.000124675325 20.4078797 -0.000124675325z"></Path>
        <Path d="M25.4465063 23.7072L26.0580759 23.7072 26.0580759 -0.000124675325 25.4465063 -0.000124675325z"></Path>
        <Path d="M31.6232975 23.7072L32.234557 23.7072 32.234557 -0.000124675325 31.6232975 -0.000124675325z"></Path>
        <Path d="M39.6996139 23.7072L40.3114937 23.7072 40.3114937 -0.000124675325 39.6996139 -0.000124675325z"></Path>
        <Path d="M46.5518608 23.7072L47.1637405 23.7072 47.1637405 -0.000124675325 46.5518608 -0.000124675325z"></Path>
        <Path d="M9.32922785 23.7072L10.5526772 23.7072 10.5526772 -0.000124675325 9.32922785 -0.000124675325z"></Path>
        <Path d="M13.6399873 23.7072L15.4902025 23.7072 15.4902025 -0.000124675325 13.6399873 -0.000124675325z"></Path>
        <Path d="M29.159962 23.7072L31.0098671 23.7072 31.0098671 -0.000124675325 29.159962 -0.000124675325z"></Path>
        <Path d="M34.0816709 23.7072L35.9318861 23.7072 35.9318861 -0.000124675325 34.0816709 -0.000124675325z"></Path>
        <Path d="M44.0243291 23.7072L45.8745443 23.7072 45.8745443 -0.000124675325 44.0243291 -0.000124675325z"></Path>
        <Path d="M18.5939494 23.7072L19.8173987 23.7072 19.8173987 -0.000124675325 18.5939494 -0.000124675325z"></Path>
        <Path d="M23.4951899 23.7072L24.7183291 23.7072 24.7183291 -0.000124675325 23.4951899 -0.000124675325z"></Path>
        <Path d="M27.3233924 23.7072L28.5468418 23.7072 28.5468418 -0.000124675325 27.3233924 -0.000124675325z"></Path>
        <Path d="M36.6203671 23.7072L37.8438165 23.7072 37.8438165 -0.000124675325 36.6203671 -0.000124675325z"></Path>
        <Path d="M40.8988734 23.7072L42.1223228 23.7072 42.1223228 -0.000124675325 40.8988734 -0.000124675325z"></Path>
        <Path d="M47.7768608 23.7072L49.0003101 23.7072 49.0003101 -0.000124675325 47.7768608 -0.000124675325z"></Path>
      </G>
      <Path
        stroke="#009ABC"
        strokeWidth="2"
        d="M605 329H719V343H605z"
      ></Path>
      <G transform="translate(571 248)">
        <Path
          fill="#FEFEFE"
          d="M0.485778462 1.96776077L0.485778462 8.25892231 3.92257846 8.25892231 3.92257846 7.36529923 1.42484 7.36529923 1.42484 5.26273769 3.84960154 5.26273769 3.84960154 4.37186846 1.42484 4.37186846 1.42484 2.86000692 3.92257846 2.86000692 3.92257846 1.96776077z"
        ></Path>
        <Path
          fill="#FEFEFE"
          d="M5.39616154 8.25988615L6.30493077 8.25988615 6.30493077 1.37664769 5.39616154 1.37664769z"
        ></Path>
        <G transform="translate(0 .184)">
          <Path
            fill="#FEFEFE"
            d="M11.737 4.105v2.278c0 .658.258.987.772.987.513 0 .77-.33.77-.987V4.105h.907v2.298c0 .318-.039.594-.117.824a1.377 1.377 0 01-.391.559c-.306.268-.697.403-1.17.403-.47 0-.859-.135-1.166-.403a1.4 1.4 0 01-.399-.56c-.076-.184-.113-.458-.113-.823V4.105h.907z"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M18.028 4.782l-.75.403c-.12-.245-.265-.366-.44-.366a.3.3 0 00-.215.084.283.283 0 00-.088.213c0 .153.175.303.525.452.48.21.805.402.972.578.167.178.25.416.25.715 0 .383-.14.703-.42.96-.27.246-.6.369-.984.369-.658 0-1.125-.327-1.4-.975l.775-.362c.107.19.19.31.246.362.11.103.241.155.396.155.305 0 .46-.143.46-.425 0-.163-.119-.314-.356-.456l-.274-.134-.278-.134c-.264-.131-.45-.262-.558-.392-.136-.165-.205-.38-.205-.64 0-.344.117-.629.351-.855.24-.226.53-.339.872-.339.503 0 .876.262 1.12.787"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M20.252 6.078c0 .396.104.71.315.942.215.234.499.35.851.35.354 0 .639-.116.851-.346.212-.232.318-.54.318-.925 0-.387-.106-.694-.318-.926-.215-.234-.498-.35-.851-.35-.347 0-.628.116-.844.35-.215.234-.322.534-.322.905m-.928-.017c0-.572.204-1.06.61-1.462.405-.402.9-.604 1.484-.604.585 0 1.084.203 1.493.608.403.405.604.902.604 1.491 0 .594-.202 1.094-.609 1.496-.408.399-.91.599-1.504.599-.59 0-1.083-.204-1.48-.612-.399-.402-.598-.907-.598-1.516"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M28.593 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.206-.242.31-.549.31-.92 0-.374-.104-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.311.54-.311.897zm2.153-4.885h.913v6.881h-.913V7.66c-.356.352-.761.53-1.213.53-.538 0-.984-.199-1.34-.595-.352-.405-.528-.912-.528-1.516 0-.592.176-1.087.528-1.483.35-.4.79-.6 1.32-.6.46 0 .871.191 1.233.571V1.193z"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M35.865 5.556c-.127-.489-.431-.732-.916-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.056.089-.1.19-.13.305h1.824zm.932.75H33.98c.023.326.13.586.314.777a.952.952 0 00.715.287.92.92 0 00.56-.164c.146-.108.311-.309.496-.602l.767.431a3.045 3.045 0 01-.375.523 2.009 2.009 0 01-.424.36c-.15.093-.313.162-.487.205a2.33 2.33 0 01-.57.066c-.586 0-1.057-.192-1.412-.572-.355-.381-.533-.89-.533-1.522 0-.628.172-1.136.517-1.525.347-.383.806-.575 1.38-.575.578 0 1.035.187 1.37.559.335.369.502.881.502 1.536l-.004.217z"
          ></Path>
          <Mask id="Mask-6" fill="#fff">
            <Use xlinkHref="#Path-5"></Use>
          </Mask>
          <Path
            fill="#FEFEFE"
            d="M41.1644923 8.07427692L42.0718846 8.07427692 42.0718846 1.19241538 41.1644923 1.19241538z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M44.376 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.207-.242.31-.549.31-.92 0-.374-.103-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.31.54-.31.897zm2.154-1.973h.913v3.97h-.913v-.416c-.373.352-.776.53-1.207.53a1.73 1.73 0 01-1.346-.595c-.353-.405-.529-.912-.529-1.516 0-.596.176-1.09.529-1.487a1.697 1.697 0 011.323-.596c.457 0 .868.191 1.23.571v-.461z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M51.9637 8.07427692L52.8710923 8.07427692 52.8710923 1.19241538 51.9637 1.19241538z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M56.31 2.15l-1.34 1.345-.5-.31L55.49 1.7l.82.45zm-1.875 5.925h.907v-3.97h-.907v3.97z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M56.904 4.105h.912v.368c.318-.318.674-.478 1.072-.478.458 0 .814.146 1.07.436.22.248.33.652.33 1.21v2.434h-.91V5.857c0-.391-.055-.661-.162-.81-.105-.153-.296-.227-.573-.227-.301 0-.515.099-.641.3-.124.198-.186.545-.186 1.04v1.915h-.912v-3.97z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M64.494 5.556c-.127-.489-.431-.732-.916-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.057.089-.1.19-.13.305h1.824zm.932.75H62.61c.023.326.13.586.314.777a.952.952 0 00.714.287.92.92 0 00.56-.164c.147-.108.312-.309.497-.602l.767.431a3.045 3.045 0 01-.375.523 2.009 2.009 0 01-.424.36c-.15.093-.313.162-.488.205a2.33 2.33 0 01-.57.066c-.585 0-1.056-.192-1.41-.572-.356-.381-.534-.89-.534-1.522 0-.628.172-1.136.517-1.525.347-.383.806-.575 1.38-.575.578 0 1.035.187 1.37.559.335.369.502.881.502 1.536l-.004.217z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M67.573 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.206-.242.31-.549.31-.92 0-.374-.104-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.311.54-.311.897zm2.153-1.973h.913v3.97h-.913v-.416c-.373.352-.775.53-1.206.53a1.73 1.73 0 01-1.347-.595c-.352-.405-.528-.912-.528-1.516 0-.596.176-1.09.528-1.487a1.697 1.697 0 011.324-.596c.457 0 .867.191 1.23.571v-.461z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M75.903 6.078c0 .396.105.71.315.942.215.234.499.35.851.35.354 0 .639-.116.851-.346.212-.232.318-.54.318-.925 0-.387-.106-.694-.318-.926-.215-.234-.498-.35-.85-.35-.348 0-.629.116-.845.35-.215.234-.322.534-.322.905m-.928-.017c0-.572.204-1.06.61-1.462.405-.402.9-.604 1.484-.604.585 0 1.084.203 1.493.608.403.405.604.902.604 1.491 0 .594-.202 1.094-.608 1.496-.41.399-.91.599-1.505.599-.59 0-1.083-.204-1.48-.612-.399-.402-.598-.907-.598-1.516"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M85.785 4.782l-.75.403c-.12-.245-.265-.366-.44-.366a.3.3 0 00-.215.084.283.283 0 00-.088.213c0 .153.175.303.525.452.48.21.805.402.972.578.167.178.25.416.25.715 0 .383-.14.703-.42.96-.27.246-.6.369-.984.369-.658 0-1.125-.327-1.4-.975l.775-.362c.107.19.19.31.246.362.11.103.241.155.396.155.305 0 .46-.143.46-.425 0-.163-.119-.314-.356-.456l-.274-.134-.278-.134c-.264-.131-.45-.262-.558-.392-.136-.165-.205-.38-.205-.64 0-.344.117-.629.351-.855.24-.226.53-.339.872-.339.503 0 .876.262 1.12.787"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M89.913 5.556c-.127-.489-.431-.732-.916-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.056.089-.1.19-.13.305h1.824zm.932.75h-2.816c.024.326.13.586.314.777a.952.952 0 00.715.287.92.92 0 00.56-.164c.146-.108.311-.309.496-.602l.767.431a3.045 3.045 0 01-.375.523 2.009 2.009 0 01-.424.36c-.15.093-.312.162-.487.205a2.33 2.33 0 01-.57.066c-.586 0-1.056-.192-1.412-.572-.355-.381-.533-.89-.533-1.522 0-.628.173-1.136.517-1.525.347-.383.807-.575 1.38-.575.578 0 1.035.187 1.37.559.336.369.502.881.502 1.536l-.004.217z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M92.251 4.105h.907v.355c.167-.176.316-.297.444-.363.132-.068.288-.102.468-.102.24 0 .49.08.75.237l-.415.838c-.173-.124-.34-.187-.504-.187-.495 0-.743.379-.743 1.133v2.059h-.907v-3.97z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M96.2421038 4.10515846L97.2582731 6.33439692 98.2716885 4.10515846 99.2919885 4.10515846 97.2500115 8.34745846 95.2259346 4.10515846z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M100.318 8.074h.908v-3.97h-.908v3.97zm-.137-5.619c0-.16.058-.299.173-.416a.562.562 0 01.416-.175c.164 0 .303.058.419.175a.568.568 0 01.173.42.582.582 0 01-.173.424.552.552 0 01-.415.175.567.567 0 01-.42-.175.587.587 0 01-.173-.428z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M105.846 4.277v1.219c-.207-.256-.393-.433-.557-.526a1.076 1.076 0 00-.568-.147c-.342 0-.625.12-.853.362-.225.242-.338.545-.338.908 0 .374.108.68.327.917.22.24.502.36.843.36.218 0 .41-.048.577-.143.161-.093.351-.273.569-.539v1.21c-.37.195-.737.291-1.106.291-.607 0-1.115-.2-1.524-.596-.41-.4-.613-.894-.613-1.487 0-.592.206-1.092.62-1.5.414-.407.923-.611 1.525-.611.387 0 .753.094 1.098.282"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M107.138 8.074h.908v-3.97h-.908v3.97zm-.137-5.619c0-.16.058-.299.173-.416a.564.564 0 01.416-.175c.164 0 .303.058.419.175a.568.568 0 01.173.42.582.582 0 01-.173.424.552.552 0 01-.415.175.567.567 0 01-.42-.175.587.587 0 01-.173-.428z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M110.352 6.078c0 .396.104.71.315.942.215.234.498.35.85.35.355 0 .64-.116.852-.346.212-.232.318-.54.318-.925 0-.387-.106-.694-.318-.926-.215-.234-.499-.35-.851-.35-.347 0-.628.116-.844.35-.215.234-.322.534-.322.905m-.928-.017c0-.572.203-1.06.61-1.462.404-.402.9-.604 1.484-.604.585 0 1.083.203 1.492.608.404.405.605.902.605 1.491 0 .594-.203 1.094-.609 1.496-.409.399-.91.599-1.505.599-.589 0-1.082-.204-1.48-.612-.398-.402-.597-.907-.597-1.516"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M118.692 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .608-.115.816-.35.206-.242.31-.549.31-.92 0-.374-.104-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.312.54-.312.897zm2.154-4.885h.913v6.881h-.913V7.66c-.357.352-.762.53-1.213.53-.539 0-.985-.199-1.34-.595-.352-.405-.529-.912-.529-1.516 0-.592.177-1.087.53-1.483.349-.4.788-.6 1.318-.6.46 0 .872.191 1.234.571V1.193z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M125.963 5.556c-.127-.489-.431-.732-.916-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.056.089-.1.19-.13.305h1.824zm.932.75h-2.816c.024.326.13.586.314.777a.952.952 0 00.715.287.92.92 0 00.56-.164c.146-.108.311-.309.496-.602l.767.431a3.045 3.045 0 01-.375.523 2.009 2.009 0 01-.424.36c-.15.093-.312.162-.487.205a2.33 2.33 0 01-.57.066c-.585 0-1.056-.192-1.412-.572-.355-.381-.532-.89-.532-1.522 0-.628.172-1.136.516-1.525.347-.383.807-.575 1.38-.575.578 0 1.035.187 1.371.559.335.369.501.881.501 1.536l-.004.217z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M133.91 5.556c-.127-.489-.431-.732-.916-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.056.089-.1.19-.13.305h1.824zm.932.75h-2.816c.024.326.13.586.314.777a.952.952 0 00.715.287.92.92 0 00.56-.164c.146-.108.311-.309.496-.602l.767.431a3.045 3.045 0 01-.375.523 2.009 2.009 0 01-.424.36c-.15.093-.312.162-.487.205a2.33 2.33 0 01-.57.066c-.586 0-1.056-.192-1.412-.572-.355-.381-.533-.89-.533-1.522 0-.628.173-1.136.517-1.525.347-.383.807-.575 1.38-.575.578 0 1.035.187 1.37.559.335.369.502.881.502 1.536l-.004.217z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M138.53 4.782l-.75.403c-.119-.245-.265-.366-.44-.366a.3.3 0 00-.214.084.283.283 0 00-.089.213c0 .153.175.303.525.452.48.21.806.402.972.578.167.178.25.416.25.715 0 .383-.14.703-.42.96-.27.246-.6.369-.984.369-.658 0-1.125-.327-1.4-.975l.775-.362c.108.19.19.31.247.362.11.103.24.155.395.155.305 0 .46-.143.46-.425 0-.163-.119-.314-.356-.456l-.274-.134-.278-.134c-.264-.131-.45-.262-.557-.392-.137-.165-.205-.38-.205-.64 0-.344.117-.629.35-.855.24-.226.53-.339.872-.339.503 0 .876.262 1.121.787"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M140.947767 4.96174231L140.947767 8.07496538 140.040375 8.07496538 140.040375 4.96174231 139.653459 4.96174231 139.653459 4.10529615 140.040375 4.10529615 140.040375 2.64988846 140.947767 2.64988846 140.947767 4.10529615 141.654128 4.10529615 141.654128 4.96174231z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M143.426 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.207-.242.31-.549.31-.92 0-.374-.103-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.311.54-.311.897zm2.153-1.973h.913v3.97h-.913v-.416c-.373.352-.775.53-1.206.53a1.73 1.73 0 01-1.346-.595c-.353-.405-.53-.912-.53-1.516 0-.596.177-1.09.53-1.487a1.697 1.697 0 011.323-.596c.457 0 .867.191 1.23.571v-.461z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M151.949658 4.96174231L151.949658 8.07496538 151.042265 8.07496538 151.042265 4.96174231 150.65535 4.96174231 150.65535 4.10529615 151.042265 4.10529615 151.042265 2.64988846 151.949658 2.64988846 151.949658 4.10529615 152.656019 4.10529615 152.656019 4.96174231z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M154.428 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.207-.242.31-.549.31-.92 0-.374-.103-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.311.54-.311.897zm2.153-1.973h.913v3.97h-.913v-.416c-.373.352-.775.53-1.206.53a1.73 1.73 0 01-1.346-.595c-.353-.405-.53-.912-.53-1.516 0-.596.177-1.09.53-1.487a1.697 1.697 0 011.323-.596c.457 0 .867.191 1.23.571v-.461z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M159.052 4.105h.907v.355c.167-.176.316-.297.444-.363.132-.068.288-.102.468-.102.24 0 .49.08.75.237l-.415.838c-.173-.124-.34-.187-.504-.187-.495 0-.743.379-.743 1.133v2.059h-.907v-3.97z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M162.555 10.235h.908V4.106h-.908v6.129zm-.137-7.78c0-.16.058-.299.173-.416a.564.564 0 01.416-.175c.164 0 .303.058.419.175a.568.568 0 01.173.42.582.582 0 01-.173.424.552.552 0 01-.415.175.569.569 0 01-.42-.175.587.587 0 01-.173-.428z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M167.671 5.556c-.126-.489-.43-.732-.915-.732a.868.868 0 00-.574.197.883.883 0 00-.204.23c-.057.089-.1.19-.13.305h1.823zm.933.75h-2.816c.023.326.13.586.314.777a.952.952 0 00.714.287.92.92 0 00.56-.164c.147-.108.312-.309.496-.602l.767.431a3.045 3.045 0 01-.374.523 2.009 2.009 0 01-.424.36c-.15.093-.313.162-.488.205a2.33 2.33 0 01-.57.066c-.585 0-1.056-.192-1.411-.572-.355-.381-.533-.89-.533-1.522 0-.628.172-1.136.516-1.525.347-.383.807-.575 1.38-.575.578 0 1.036.187 1.371.559.335.369.502.881.502 1.536l-.004.217z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M170.945551 4.96174231L170.945551 8.07496538 170.038158 8.07496538 170.038158 4.96174231 169.651243 4.96174231 169.651243 4.10529615 170.038158 4.10529615 170.038158 2.64988846 170.945551 2.64988846 170.945551 4.10529615 171.651912 4.10529615 171.651912 4.96174231z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M173.424 6.078c0 .38.1.69.303.93a.984.984 0 00.782.361c.336 0 .607-.115.815-.35.206-.242.31-.549.31-.92 0-.374-.104-.68-.31-.922a1.022 1.022 0 00-.807-.354.994.994 0 00-.782.358c-.208.241-.311.54-.311.897zm2.153-1.973h.913v3.97h-.913v-.416c-.373.352-.775.53-1.206.53a1.73 1.73 0 01-1.347-.595c-.352-.405-.528-.912-.528-1.516 0-.596.176-1.09.528-1.487a1.697 1.697 0 011.324-.596c.457 0 .867.191 1.23.571v-.461z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M2.59 14.71a1.403 1.403 0 00-.37-.224.978.978 0 00-.359-.073.582.582 0 00-.358.105.331.331 0 00-.139.275c0 .077.024.142.07.192.046.05.107.096.182.132.076.037.16.07.255.096.094.028.187.057.28.088.37.125.64.29.812.499.172.208.257.48.257.815 0 .226-.037.43-.112.613-.076.184-.185.34-.33.47-.144.131-.322.233-.532.305-.21.071-.448.107-.715.107-.55 0-1.062-.165-1.531-.497l.486-.922c.17.152.337.265.502.34.166.073.33.11.492.11.185 0 .324-.043.413-.128a.394.394 0 00.1-.466.384.384 0 00-.115-.135.953.953 0 00-.21-.113 9.391 9.391 0 00-.315-.117 6.131 6.131 0 01-.43-.154 1.39 1.39 0 01-.376-.224 1.05 1.05 0 01-.268-.354 1.26 1.26 0 01-.1-.54c0-.219.034-.416.106-.593.071-.178.172-.33.301-.456.13-.127.288-.225.475-.295.186-.07.396-.106.627-.106.216 0 .442.03.677.091.234.06.46.15.676.266l-.451.893z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M4.22164615 17.9867462L5.35485385 17.9867462 5.35485385 13.5833462 4.22164615 13.5833462z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M6.36014538 17.9874346L7.09955308 13.5840346 8.22174538 13.5840346 9.09471462 15.9316885 9.96217615 13.5840346 11.0843685 13.5840346 11.8251531 17.9874346 10.6960762 17.9874346 10.3215531 15.4525192 9.29161462 17.9874346 8.84136077 17.9874346 7.86374538 15.4525192 7.48646846 17.9874346z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M15.909 17.987h.907v-3.969h-.907v3.969zm-.138-5.62a.57.57 0 01.174-.414.57.57 0 01.416-.175c.163 0 .304.06.42.175a.565.565 0 01.173.419.583.583 0 01-.173.425.558.558 0 01-.416.175.569.569 0 01-.42-.175.588.588 0 01-.174-.43z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M18.379 14.02h.907v.366c.175-.185.325-.31.448-.375.132-.069.297-.102.497-.102.443 0 .794.196 1.052.587a1.36 1.36 0 011.158-.587c.885 0 1.327.541 1.327 1.626v2.453h-.911v-2.205c0-.38-.046-.648-.138-.807-.094-.16-.248-.24-.464-.24-.25 0-.433.096-.547.286-.114.19-.17.495-.17.917v2.049h-.912v-2.192c0-.707-.203-1.06-.606-1.06-.255 0-.442.097-.558.288-.118.195-.176.499-.176.915v2.049h-.907v-3.969z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M28.394 16.028c0-.38-.102-.69-.307-.93a.982.982 0 00-.782-.361c-.334 0-.604.115-.811.35-.207.232-.31.54-.31.92 0 .372.103.68.31.92.205.237.473.356.806.356.316 0 .575-.12.778-.36.211-.237.316-.537.316-.895zm-2.158 4.119h-.909v-6.128h.909v.431c.357-.36.763-.54 1.217-.54.541 0 .986.199 1.336.6.355.4.533.905.533 1.514 0 .595-.177 1.09-.529 1.487-.35.394-.79.59-1.323.59-.46 0-.872-.185-1.234-.557v2.603z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M30.6943692 17.9867462L31.6017615 17.9867462 31.6017615 11.1048846 30.6943692 11.1048846z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M33.165 17.987h.907v-3.969h-.907v3.969zm-.138-5.62a.57.57 0 01.173-.414.57.57 0 01.416-.175c.164 0 .304.06.42.175a.565.565 0 01.174.419.583.583 0 01-.174.425.555.555 0 01-.416.175.569.569 0 01-.42-.175.588.588 0 01-.173-.43z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M38.692 14.19v1.219c-.206-.256-.392-.431-.556-.526a1.076 1.076 0 00-.569-.148c-.341 0-.625.122-.852.364-.226.242-.339.545-.339.909 0 .371.11.677.328.915.22.24.501.36.843.36.217 0 .41-.047.577-.142.16-.094.35-.273.568-.538v1.21c-.369.193-.736.289-1.105.289-.608 0-1.116-.198-1.525-.595-.409-.4-.612-.895-.612-1.487 0-.593.206-1.093.62-1.5.414-.408.923-.61 1.525-.61.387 0 .753.093 1.097.28"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M40.727 15.99c0 .38.1.692.303.93a.984.984 0 00.782.362c.336 0 .607-.115.815-.35.206-.24.31-.549.31-.92 0-.372-.104-.68-.31-.92a1.023 1.023 0 00-.807-.356.996.996 0 00-.782.36c-.208.24-.311.54-.311.895zm2.153-1.971h.913v3.968h-.913v-.416c-.373.354-.775.53-1.206.53-.542 0-.991-.196-1.347-.593-.352-.405-.528-.912-.528-1.517 0-.595.176-1.09.528-1.487a1.696 1.696 0 011.324-.595c.457 0 .867.19 1.23.57v-.46z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M48.3148538 17.9867462L49.2222462 17.9867462 49.2222462 11.1048846 48.3148538 11.1048846z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M51.527 16.01c0 .377.1.684.303.92a.99.99 0 00.782.36c.336 0 .607-.116.815-.347.207-.24.31-.544.31-.912 0-.369-.103-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.312 0-.572.118-.782.354-.208.24-.31.535-.31.888zm2.154-1.952h.912v3.93h-.912v-.412c-.374.35-.776.525-1.207.525-.542 0-.991-.197-1.346-.59-.353-.4-.529-.9-.529-1.5 0-.59.176-1.08.529-1.472.352-.393.793-.59 1.323-.59.457 0 .867.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M59.856 16.01c0 .377.1.684.303.92a.99.99 0 00.782.36c.336 0 .607-.116.815-.347.207-.24.31-.544.31-.912 0-.369-.103-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.312 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-1.952h.913v3.93h-.913v-.412c-.373.35-.775.525-1.206.525-.542 0-.991-.197-1.346-.59-.353-.4-.53-.9-.53-1.5 0-.59.177-1.08.53-1.472.352-.393.793-.59 1.323-.59.457 0 .867.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M67.54 14.228v1.206c-.207-.253-.393-.427-.557-.52a1.088 1.088 0 00-.569-.145c-.341 0-.625.118-.852.358-.226.24-.339.54-.339.9 0 .368.109.67.328.908a1.1 1.1 0 00.843.355c.217 0 .41-.048.577-.142.16-.09.35-.268.568-.533v1.198a2.382 2.382 0 01-1.105.288c-.608 0-1.116-.197-1.525-.59-.409-.394-.612-.886-.612-1.473 0-.585.206-1.08.62-1.484.414-.403.923-.604 1.525-.604.387 0 .753.092 1.097.278"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M71.478 15.494c-.127-.484-.431-.725-.916-.725a.928.928 0 00-.574.195.894.894 0 00-.204.227c-.056.09-.1.19-.13.303h1.824zm.932.743h-2.816c.023.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.311-.307.496-.598l.767.427a3.057 3.057 0 01-.375.52 1.985 1.985 0 01-.424.354c-.15.094-.313.16-.487.204a2.4 2.4 0 01-.57.065c-.586 0-1.057-.189-1.412-.566-.355-.379-.533-.881-.533-1.508 0-.62.172-1.125.517-1.509.347-.379.806-.569 1.38-.569.578 0 1.035.185 1.37.553.335.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M76.88 16.047c0-.376-.101-.683-.307-.92a.988.988 0 00-.782-.36c-.333 0-.603.117-.81.348-.207.231-.31.536-.31.911 0 .37.103.674.31.912.205.234.473.351.806.351a.983.983 0 00.778-.354c.21-.237.316-.533.316-.888zm-2.157 4.079h-.909v-6.067h.909v.427c.356-.358.763-.536 1.217-.536.541 0 .986.198 1.336.596.355.395.533.895.533 1.497 0 .59-.177 1.08-.53 1.472-.349.39-.79.585-1.322.585-.46 0-.872-.184-1.234-.552v2.578z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M80.1169577 14.9054677L80.1169577 17.9870215 79.2095654 17.9870215 79.2095654 14.9054677 78.82265 14.9054677 78.82265 14.05866 79.2095654 14.05866 79.2095654 12.6183985 80.1169577 12.6183985 80.1169577 14.05866 80.8233192 14.05866 80.8233192 14.9054677z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M82.595 16.01c0 .377.1.684.303.92a.99.99 0 00.782.36c.336 0 .608-.116.815-.347.207-.24.31-.544.31-.912 0-.369-.103-.673-.31-.911a1.029 1.029 0 00-.806-.351c-.313 0-.573.118-.783.354-.207.24-.31.535-.31.888zm2.154-1.952h.913v3.93h-.913v-.412c-.373.35-.775.525-1.206.525-.543 0-.992-.197-1.347-.59-.353-.4-.529-.9-.529-1.5 0-.59.176-1.08.529-1.472.352-.393.793-.59 1.323-.59.457 0 .868.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M90.279 14.228v1.206c-.207-.253-.393-.427-.557-.52a1.088 1.088 0 00-.568-.145c-.342 0-.626.118-.853.358-.226.24-.338.54-.338.9 0 .368.108.67.327.908a1.1 1.1 0 00.843.355c.217 0 .41-.048.577-.142.16-.09.351-.268.569-.533v1.198a2.382 2.382 0 01-1.106.288c-.607 0-1.115-.197-1.524-.59-.41-.394-.613-.886-.613-1.473 0-.585.206-1.08.62-1.484.414-.403.923-.604 1.525-.604.387 0 .753.092 1.098.278"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M91.57 17.987h.908v-3.93h-.907v3.93zm-.137-5.563a.56.56 0 01.174-.41.568.568 0 01.416-.174c.163 0 .304.058.42.174.115.113.173.25.173.414a.57.57 0 01-.174.42.56.56 0 01-.415.174.573.573 0 01-.42-.174.575.575 0 01-.174-.424z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M97.312 12.122l-1.467 1.332-.497-.308 1.11-1.467.854.443zm-2.529 3.888c0 .393.105.704.315.933.215.23.499.347.851.347.355 0 .64-.115.851-.343.212-.229.318-.534.318-.916 0-.381-.106-.687-.318-.916-.215-.23-.498-.347-.85-.347-.348 0-.629.116-.845.347-.215.232-.322.53-.322.895zm-.928-.015c0-.568.204-1.05.61-1.448.406-.398.9-.598 1.484-.598.587 0 1.084.201 1.493.602.403.4.604.892.604 1.476 0 .59-.202 1.082-.608 1.48-.41.395-.91.594-1.505.594-.59 0-1.083-.203-1.48-.606-.398-.398-.598-.898-.598-1.5z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M99.42 14.058h.911v.364c.318-.315.675-.472 1.073-.472.457 0 .813.143 1.07.43.22.246.33.645.33 1.199v2.41h-.912v-2.196c0-.387-.053-.655-.16-.802-.105-.152-.297-.226-.573-.226-.302 0-.515.099-.642.297-.124.197-.186.54-.186 1.03v1.896h-.912v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M108.068 16.01c0 .377.1.684.303.92a.99.99 0 00.782.36c.336 0 .608-.116.816-.347.206-.24.31-.544.31-.912 0-.369-.104-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.312.535-.312.888zm2.154-4.836h.913v6.814h-.913v-.412c-.357.35-.762.525-1.213.525a1.73 1.73 0 01-1.34-.59c-.352-.4-.529-.9-.529-1.5 0-.587.177-1.076.53-1.468.349-.397.788-.594 1.318-.594.46 0 .872.19 1.234.565v-3.34z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M115.339 15.494c-.127-.484-.431-.725-.916-.725a.928.928 0 00-.574.195.894.894 0 00-.204.227c-.056.09-.1.19-.13.303h1.824zm.932.743h-2.816c.024.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.311-.307.496-.598l.767.427a3.057 3.057 0 01-.375.52 1.985 1.985 0 01-.424.354c-.15.094-.312.16-.487.204a2.4 2.4 0 01-.57.065c-.586 0-1.056-.189-1.412-.566-.355-.379-.533-.881-.533-1.508 0-.62.173-1.125.517-1.509.347-.379.807-.569 1.38-.569.578 0 1.035.185 1.37.553.335.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M120.637738 17.9867462L121.545131 17.9867462 121.545131 11.1737308 120.637738 11.1737308z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M123.85 16.01c0 .393.105.704.316.933.215.231.498.347.85.347.355 0 .64-.114.852-.343.212-.229.318-.534.318-.916s-.106-.687-.318-.915c-.215-.232-.499-.347-.851-.347-.347 0-.628.115-.844.347-.215.231-.322.528-.322.895m-.929-.017c0-.567.204-1.049.61-1.447.405-.4.901-.598 1.485-.598.585 0 1.083.2 1.492.6.404.401.605.894.605 1.478 0 .588-.203 1.083-.609 1.48-.409.396-.91.594-1.505.594-.59 0-1.082-.203-1.48-.606-.398-.398-.598-.898-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M130.771 14.728l-.75.4c-.118-.243-.264-.364-.44-.364a.305.305 0 00-.214.084.28.28 0 00-.088.21c0 .152.175.3.524.448.48.208.806.398.972.573.167.175.251.41.251.706 0 .38-.14.697-.42.953-.271.24-.6.362-.984.362-.659 0-1.125-.32-1.4-.964l.774-.36c.108.19.19.31.247.36a.56.56 0 00.395.153c.306 0 .46-.139.46-.419 0-.162-.118-.312-.355-.451a8.342 8.342 0 00-.274-.134c-.092-.042-.185-.088-.278-.133-.265-.13-.45-.258-.558-.387-.136-.164-.205-.376-.205-.634 0-.341.117-.623.35-.846.24-.223.531-.335.873-.335.502 0 .875.259 1.12.778"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M137.107391 12.6423569L137.107391 17.9875723 136.166952 17.9875723 136.166952 12.6423569 134.734952 12.6423569 134.734952 11.7583723 138.53526 11.7583723 138.53526 12.6423569z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M142.688 12.122l-1.468 1.332-.497-.308 1.11-1.467.855.443zm-.429 3.372c-.125-.483-.43-.726-.915-.726a.898.898 0 00-.778.423c-.057.09-.1.19-.13.303h1.823zm.933.742h-2.816c.025.324.13.58.315.771a.956.956 0 00.713.283.935.935 0 00.562-.161c.145-.108.31-.308.496-.598l.767.428a3.096 3.096 0 01-.376.52 2.012 2.012 0 01-.423.353 1.679 1.679 0 01-.489.204 2.397 2.397 0 01-.568.065c-.587 0-1.058-.189-1.412-.565-.355-.38-.533-.883-.533-1.509 0-.621.173-1.124.517-1.51.347-.378.807-.568 1.38-.568.578 0 1.035.185 1.37.554.334.365.5.871.5 1.52l-.003.213z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M144.597 14.058h.907v.351c.167-.174.316-.294.444-.359.132-.067.288-.1.468-.1.24 0 .49.078.75.234l-.415.83a.863.863 0 00-.504-.185c-.495 0-.743.374-.743 1.121v2.038h-.907v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M148.101 14.058h.908v.364c.175-.183.325-.307.447-.372.132-.067.298-.1.497-.1.444 0 .795.194 1.052.58a1.365 1.365 0 011.158-.58c.886 0 1.328.537 1.328 1.61v2.428h-.912v-2.182c0-.378-.045-.643-.138-.8-.093-.159-.247-.237-.464-.237-.249 0-.432.094-.546.282-.115.187-.171.49-.171.908v2.03h-.912v-2.172c0-.698-.202-1.048-.605-1.048-.255 0-.442.095-.558.286-.118.19-.176.492-.176.904v2.03h-.908v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M155.05 17.987h.907v-3.93h-.907v3.93zm-.138-5.563a.56.56 0 01.174-.41.566.566 0 01.415-.174.57.57 0 01.419.174c.116.113.174.25.174.414a.57.57 0 01-.174.42.556.556 0 01-.414.174.57.57 0 01-.42-.174.575.575 0 01-.174-.424z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M157.52 14.058h.91v.364c.319-.315.675-.472 1.073-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.91v-2.196c0-.387-.055-.655-.162-.802-.105-.152-.296-.226-.573-.226-.301 0-.515.099-.641.297-.124.197-.186.54-.186 1.03v1.896h-.912v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M163.204 16.01c0 .393.105.704.316.933.214.231.498.347.85.347.354 0 .64-.114.852-.343.212-.229.318-.534.318-.916s-.106-.687-.318-.915c-.215-.232-.499-.347-.851-.347-.347 0-.628.115-.844.347-.215.231-.323.528-.323.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.4.9-.598 1.485-.598.585 0 1.083.2 1.492.6.404.401.605.894.605 1.478 0 .588-.203 1.083-.609 1.48-.409.396-.91.594-1.505.594-.59 0-1.082-.203-1.48-.606-.398-.398-.598-.898-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M170.124 14.728l-.75.4c-.119-.243-.265-.364-.44-.364a.305.305 0 00-.215.084.28.28 0 00-.088.21c0 .152.175.3.525.448.48.208.805.398.972.573.167.175.25.41.25.706 0 .38-.14.697-.42.953-.27.24-.6.362-.984.362-.658 0-1.125-.32-1.4-.964l.775-.36c.107.19.19.31.247.36a.56.56 0 00.395.153c.305 0 .46-.139.46-.419 0-.162-.119-.312-.356-.451a8.343 8.343 0 00-.274-.134c-.092-.042-.184-.088-.278-.133-.264-.13-.45-.258-.557-.387-.137-.164-.206-.376-.206-.634 0-.341.117-.623.352-.846.24-.223.53-.335.871-.335.503 0 .876.259 1.12.778"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M175.807742 17.2572523L174.041149 14.05866 175.093118 14.05866 176.295172 16.3168138 177.417365 14.05866 178.441795 14.05866 175.312049 20.1253831 174.275226 20.1253831z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M5.088 22.008v1.114c-.544-.455-1.106-.683-1.687-.683-.638 0-1.178.23-1.616.69-.442.457-.662 1.017-.662 1.678 0 .654.22 1.205.662 1.654.44.45.98.675 1.62.675.332 0 .613-.054.845-.163a2.42 2.42 0 00.4-.217c.138-.091.285-.201.438-.33v1.132a3.392 3.392 0 01-1.693.456c-.891 0-1.651-.31-2.28-.93a3.092 3.092 0 01-.94-2.269c0-.793.262-1.5.787-2.121.645-.762 1.48-1.142 2.504-1.142.56 0 1.1.152 1.622.456"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M7.256 25.925c0 .392.104.703.315.932.215.231.498.347.851.347.354 0 .639-.114.85-.343.213-.228.319-.534.319-.916s-.106-.687-.318-.915c-.215-.231-.499-.347-.851-.347-.347 0-.628.116-.844.347-.215.231-.322.529-.322.895m-.928-.017c0-.567.203-1.049.61-1.447.404-.4.9-.597 1.484-.597.585 0 1.084.2 1.493.6.403.4.604.894.604 1.477 0 .588-.202 1.083-.609 1.48-.409.396-.91.594-1.505.594-.589 0-1.082-.202-1.48-.606-.398-.398-.597-.898-.597-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M11.892 23.973h.912v.363c.318-.315.674-.472 1.072-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.911v-2.196c0-.386-.054-.655-.161-.802-.105-.152-.296-.226-.573-.226-.301 0-.515.099-.642.297-.123.197-.185.54-.185 1.03v1.896h-.912v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M17.579 25.925c0 .376.1.683.303.92a.99.99 0 00.782.359c.336 0 .607-.116.815-.347.206-.24.31-.544.31-.912 0-.369-.104-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-4.837h.913v6.814h-.913v-.412c-.356.35-.761.525-1.213.525a1.73 1.73 0 01-1.34-.59c-.352-.4-.528-.9-.528-1.5 0-.587.176-1.076.528-1.468.35-.397.79-.593 1.32-.593.46 0 .871.188 1.233.564v-3.34z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M22.203 27.902h.907v-3.93h-.907v3.93zm-.138-5.563c0-.16.058-.296.174-.412a.568.568 0 01.416-.173c.163 0 .304.058.42.173.115.113.173.251.173.416a.57.57 0 01-.174.419.56.56 0 01-.415.173.573.573 0 01-.42-.173.575.575 0 01-.174-.423z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M27.73 24.142v1.206c-.206-.253-.392-.427-.556-.52a1.088 1.088 0 00-.568-.145c-.342 0-.625.118-.853.358-.225.24-.338.54-.338.9 0 .368.108.671.327.908a1.1 1.1 0 00.843.355c.218 0 .41-.048.577-.142.161-.09.351-.268.569-.533v1.198a2.382 2.382 0 01-1.106.288c-.607 0-1.115-.197-1.524-.59-.41-.394-.613-.886-.613-1.472s.207-1.081.621-1.485c.413-.403.923-.604 1.524-.604.387 0 .753.092 1.098.278"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M29.023 27.902h.907v-3.93h-.907v3.93zm-.137-5.563c0-.16.058-.296.173-.412a.568.568 0 01.415-.173c.164 0 .305.058.42.173.116.113.174.251.174.416a.57.57 0 01-.174.419.56.56 0 01-.415.173.573.573 0 01-.42-.173.579.579 0 01-.173-.423z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M32.235 25.925c0 .392.105.703.316.932.214.231.498.347.85.347.354 0 .64-.114.851-.343.213-.228.319-.534.319-.916s-.106-.687-.319-.915c-.214-.231-.498-.347-.85-.347-.347 0-.628.116-.845.347-.214.231-.322.529-.322.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.4.9-.597 1.485-.597.585 0 1.083.2 1.492.6.404.4.605.894.605 1.477 0 .588-.203 1.083-.609 1.48-.409.396-.91.594-1.505.594-.59 0-1.082-.202-1.48-.606-.398-.398-.598-.898-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M36.873 23.973h.912v.363c.318-.315.674-.472 1.072-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.911v-2.196c0-.386-.054-.655-.161-.802-.105-.152-.296-.226-.573-.226-.301 0-.515.099-.642.297-.123.197-.185.54-.185 1.03v1.896h-.912v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M44.462 25.408c-.126-.484-.43-.725-.915-.725a.928.928 0 00-.575.195.894.894 0 00-.203.228c-.057.09-.1.19-.13.302h1.823zm.932.743H42.58c.023.322.129.58.314.77a.965.965 0 00.714.283.928.928 0 00.56-.161c.147-.109.312-.307.496-.598l.767.427a2.99 2.99 0 01-.374.518 1.908 1.908 0 01-.424.355c-.15.095-.313.161-.488.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.411-.566-.355-.379-.533-.881-.533-1.508 0-.62.172-1.125.516-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.371.552.335.366.502.873.502 1.521l-.005.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M49.082 24.642l-.75.4c-.118-.243-.264-.364-.44-.364a.305.305 0 00-.214.084.28.28 0 00-.088.21c0 .152.175.3.525.448.48.208.805.398.972.573.166.175.25.41.25.706 0 .38-.14.697-.42.953-.271.241-.6.362-.984.362-.658 0-1.125-.32-1.4-.964l.775-.359c.107.189.19.308.246.36a.56.56 0 00.395.152c.306 0 .46-.139.46-.418 0-.163-.118-.313-.355-.452a8.342 8.342 0 00-.274-.134c-.092-.042-.185-.088-.278-.133-.265-.13-.45-.258-.558-.387-.136-.164-.205-.376-.205-.633 0-.342.117-.624.351-.847.24-.223.53-.335.872-.335.502 0 .875.259 1.12.778"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M54.269 25.925c0 .376.1.683.303.92a.99.99 0 00.782.359c.336 0 .607-.116.815-.347.206-.24.31-.544.31-.912 0-.369-.104-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.312 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-4.837h.913v6.814h-.913v-.412c-.356.35-.761.525-1.213.525a1.73 1.73 0 01-1.34-.59c-.352-.4-.528-.9-.528-1.5 0-.587.176-1.076.528-1.468.35-.397.79-.593 1.32-.593.46 0 .871.188 1.233.564v-3.34z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M61.54 25.408c-.126-.484-.43-.725-.915-.725a.928.928 0 00-.574.195.894.894 0 00-.204.228c-.056.09-.1.19-.13.302h1.824zm.933.743h-2.816c.023.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.311-.307.496-.598l.767.427a2.99 2.99 0 01-.375.518 1.908 1.908 0 01-.424.355c-.15.095-.313.161-.487.205a2.4 2.4 0 01-.57.065c-.586 0-1.057-.189-1.412-.566-.355-.379-.533-.881-.533-1.508 0-.62.172-1.125.517-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.37.552.335.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M63.8782154 27.9019692L64.7856077 27.9019692 64.7856077 21.0889538 63.8782154 21.0889538z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M72.368 24.142v1.206c-.207-.253-.393-.427-.557-.52a1.088 1.088 0 00-.568-.145c-.342 0-.625.118-.852.358-.226.24-.34.54-.34.9 0 .368.11.671.328.908a1.1 1.1 0 00.843.355c.218 0 .41-.048.577-.142.161-.09.351-.268.569-.533v1.198a2.382 2.382 0 01-1.106.288c-.607 0-1.115-.197-1.524-.59-.41-.394-.613-.886-.613-1.472s.207-1.081.621-1.485c.413-.403.923-.604 1.524-.604.387 0 .754.092 1.098.278"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M74.402 25.925c0 .392.105.703.316.932.214.231.498.347.85.347.354 0 .64-.114.852-.343.212-.228.318-.534.318-.916s-.106-.687-.318-.915c-.215-.231-.499-.347-.851-.347-.347 0-.628.116-.844.347-.215.231-.323.529-.323.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.4.9-.597 1.485-.597.585 0 1.083.2 1.492.6.404.4.605.894.605 1.477 0 .588-.203 1.083-.609 1.48-.409.396-.91.594-1.505.594-.59 0-1.082-.202-1.48-.606-.398-.398-.598-.898-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M79.04 23.973h.911v.363c.319-.315.675-.472 1.073-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.911v-2.196c0-.386-.054-.655-.161-.802-.105-.152-.296-.226-.573-.226-.302 0-.515.099-.642.297-.124.197-.186.54-.186 1.03v1.896h-.911v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M84.9188392 24.8195892L84.9188392 27.9011431 84.0114469 27.9011431 84.0114469 24.8195892 83.6245315 24.8195892 83.6245315 23.9727815 84.0114469 23.9727815 84.0114469 22.53252 84.9188392 22.53252 84.9188392 23.9727815 85.6252008 23.9727815 85.6252008 24.8195892z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M86.655 23.973h.907v.35c.167-.174.315-.294.443-.359.133-.067.288-.1.469-.1.24 0 .49.078.75.234l-.416.83a.863.863 0 00-.504-.184c-.494 0-.742.373-.742 1.12v2.038h-.907v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M90.9 25.925c0 .376.1.683.303.92a.99.99 0 00.782.359c.336 0 .607-.116.815-.347.206-.24.31-.544.31-.912 0-.369-.104-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-1.953h.913v3.93h-.913v-.412c-.373.35-.775.525-1.206.525-.542 0-.991-.197-1.347-.59-.352-.4-.528-.9-.528-1.5 0-.59.176-1.08.528-1.472.353-.393.794-.59 1.324-.59.457 0 .867.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M96.4599331 24.8195892L96.4599331 27.9011431 95.5525408 27.9011431 95.5525408 24.8195892 95.1656254 24.8195892 95.1656254 23.9727815 95.5525408 23.9727815 95.5525408 22.53252 96.4599331 22.53252 96.4599331 23.9727815 97.1662946 23.9727815 97.1662946 24.8195892z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M98.94 25.925c0 .392.104.703.315.932.215.231.498.347.85.347.355 0 .64-.114.852-.343.212-.228.318-.534.318-.916s-.106-.687-.318-.915c-.215-.231-.499-.347-.851-.347-.347 0-.628.116-.844.347-.215.231-.323.529-.323.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.4.9-.597 1.485-.597.585 0 1.083.2 1.492.6.404.4.605.894.605 1.477 0 .588-.203 1.083-.609 1.48-.409.396-.91.594-1.505.594-.59 0-1.082-.202-1.48-.606-.398-.398-.598-.898-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M107.28 25.917c0 .379.102.687.307.924a.973.973 0 00.778.363c.334 0 .605-.115.811-.347.21-.234.314-.538.314-.911 0-.37-.103-.674-.31-.913a1.027 1.027 0 00-.806-.35 1 1 0 00-.782.354c-.208.24-.312.533-.312.88zm2.154 4.122v-2.577c-.36.369-.771.552-1.234.552-.53 0-.97-.194-1.319-.584-.352-.394-.529-.884-.529-1.473 0-.596.177-1.096.529-1.497.355-.398.8-.596 1.336-.596.454 0 .86.179 1.217.535v-.426h.913v6.066h-.913z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M112.812 23.973v2.255c0 .65.258.976.771.976.514 0 .77-.326.77-.976v-2.255h.907v2.274c0 .315-.038.587-.117.815a1.361 1.361 0 01-.39.554c-.306.266-.697.4-1.17.4-.47 0-.859-.134-1.166-.4a1.384 1.384 0 01-.4-.554c-.075-.183-.112-.454-.112-.815v-2.274h.907z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M119.466 25.408c-.127-.484-.431-.725-.916-.725a.928.928 0 00-.574.195.894.894 0 00-.204.228c-.057.09-.1.19-.13.302h1.824zm.932.743h-2.816c.023.322.13.58.314.77a.965.965 0 00.714.283.928.928 0 00.56-.161c.147-.109.312-.307.497-.598l.767.427a2.99 2.99 0 01-.375.518 1.908 1.908 0 01-.424.355c-.15.095-.313.161-.488.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.41-.566-.356-.379-.534-.881-.534-1.508 0-.62.172-1.125.517-1.509.347-.378.806-.568 1.38-.568.578 0 1.035.184 1.37.552.335.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M127.832 25.961c0-.376-.102-.683-.307-.92a.988.988 0 00-.782-.359c-.333 0-.603.116-.811.347-.207.231-.31.536-.31.912 0 .369.103.673.31.911.205.234.474.351.807.351a.983.983 0 00.778-.354c.21-.237.315-.533.315-.888zm-2.158 4.079h-.908v-6.067h.908v.427c.357-.358.763-.536 1.218-.536.54 0 .985.198 1.335.596.355.396.533.895.533 1.497 0 .59-.176 1.08-.529 1.472-.35.391-.79.585-1.323.585-.46 0-.871-.184-1.234-.552v2.578z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M131.04 23.973v2.255c0 .65.258.976.771.976.514 0 .77-.326.77-.976v-2.255h.907v2.274c0 .315-.038.587-.117.815a1.361 1.361 0 01-.39.554c-.306.266-.697.4-1.17.4-.47 0-.859-.134-1.166-.4a1.384 1.384 0 01-.4-.554c-.075-.183-.112-.454-.112-.815v-2.274h.907z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M137.694 25.408c-.127-.484-.431-.725-.916-.725a.928.928 0 00-.574.195.894.894 0 00-.204.228c-.056.09-.1.19-.13.302h1.824zm.932.743h-2.816c.023.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.311-.307.496-.598l.767.427a2.99 2.99 0 01-.375.518 1.908 1.908 0 01-.424.355c-.15.095-.313.161-.487.205a2.4 2.4 0 01-.57.065c-.586 0-1.057-.189-1.412-.566-.355-.379-.533-.881-.533-1.508 0-.62.172-1.125.517-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.37.552.335.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M140.773 25.925c0 .376.1.683.303.92a.99.99 0 00.782.359c.336 0 .607-.116.815-.347.207-.24.31-.544.31-.912 0-.369-.103-.673-.31-.911a1.029 1.029 0 00-.807-.351c-.312 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-4.837h.913v6.814h-.913v-.412c-.356.35-.761.525-1.213.525a1.73 1.73 0 01-1.34-.59c-.352-.4-.528-.9-.528-1.5 0-.587.176-1.076.529-1.468.35-.397.789-.593 1.319-.593.46 0 .871.188 1.233.564v-3.34z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M148.045 25.408c-.127-.484-.431-.725-.916-.725a.928.928 0 00-.574.195.894.894 0 00-.204.228c-.056.09-.1.19-.13.302h1.824zm.932.743h-2.816c.024.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.311-.307.496-.598l.767.427a2.99 2.99 0 01-.375.518 1.908 1.908 0 01-.424.355c-.15.095-.312.161-.487.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.412-.566-.355-.379-.532-.881-.532-1.508 0-.62.172-1.125.516-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.37.552.336.366.502.873.502 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M155.627 24.642l-.75.4c-.119-.243-.265-.364-.44-.364a.305.305 0 00-.214.084.28.28 0 00-.088.21c0 .152.175.3.524.448.48.208.806.398.972.573.167.175.251.41.251.706 0 .38-.14.697-.42.953-.271.241-.6.362-.984.362-.659 0-1.125-.32-1.4-.964l.774-.359c.108.189.19.308.247.36a.56.56 0 00.395.152c.306 0 .46-.139.46-.418 0-.163-.118-.313-.355-.452a8.342 8.342 0 00-.274-.134c-.093-.042-.185-.088-.278-.133-.265-.13-.45-.258-.558-.387-.136-.164-.205-.376-.205-.633 0-.342.117-.624.35-.847.24-.223.531-.335.872-.335.503 0 .876.259 1.121.778"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M159.755 25.408c-.126-.484-.43-.725-.915-.725a.928.928 0 00-.575.195.894.894 0 00-.203.228c-.057.09-.1.19-.13.302h1.823zm.932.743h-2.815c.023.322.13.58.314.77a.965.965 0 00.714.283.928.928 0 00.56-.161c.147-.109.312-.307.496-.598l.767.427a2.99 2.99 0 01-.374.518 1.908 1.908 0 01-.424.355c-.15.095-.313.161-.488.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.411-.566-.355-.379-.533-.881-.533-1.508 0-.62.172-1.125.516-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.371.552.335.366.502.873.502 1.521l-.005.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M162.092 23.973H163v.35c.166-.174.315-.294.443-.359.132-.067.288-.1.468-.1.24 0 .49.078.75.234l-.415.83a.863.863 0 00-.504-.184c-.494 0-.742.373-.742 1.12v2.038h-.908v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M3.37 34.056v1.206c-.207-.253-.393-.427-.557-.52a1.088 1.088 0 00-.568-.145c-.342 0-.625.119-.853.358-.225.24-.338.54-.338.9 0 .368.109.671.327.908a1.1 1.1 0 00.843.355c.218 0 .41-.048.577-.142.161-.09.351-.268.569-.532v1.197a2.382 2.382 0 01-1.106.288c-.607 0-1.115-.197-1.524-.589-.41-.395-.613-.887-.613-1.473 0-.586.207-1.081.621-1.485.413-.403.923-.604 1.524-.604.387 0 .754.092 1.098.278"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M5.404 35.839c0 .392.105.704.315.932.215.231.499.347.851.347.354 0 .64-.114.851-.343.212-.228.318-.534.318-.915 0-.383-.106-.687-.318-.916-.214-.231-.498-.347-.85-.347-.348 0-.628.116-.845.347-.214.231-.322.529-.322.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.399.9-.597 1.484-.597.586 0 1.084.2 1.493.6.403.4.604.894.604 1.477 0 .588-.202 1.083-.608 1.48-.41.396-.91.594-1.505.594-.59 0-1.082-.202-1.48-.606-.398-.398-.598-.897-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M10.04 33.887h.912v.363c.318-.315.675-.472 1.073-.472.457 0 .813.143 1.07.43.22.246.33.645.33 1.199v2.41h-.912v-2.195c0-.387-.053-.656-.16-.803-.105-.152-.297-.226-.573-.226-.302 0-.515.1-.642.297-.124.197-.186.54-.186 1.03v1.896h-.911v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M17.268 34.556l-.75.4c-.119-.243-.265-.364-.44-.364a.31.31 0 00-.214.083.285.285 0 00-.089.212c0 .151.175.3.525.447.48.208.806.398.972.573.167.175.25.41.25.706 0 .38-.14.697-.42.953-.27.241-.6.362-.984.362-.658 0-1.125-.32-1.4-.964l.775-.359c.108.189.19.309.247.36a.56.56 0 00.395.152c.305 0 .46-.139.46-.418 0-.163-.119-.313-.356-.452a8.342 8.342 0 00-.274-.133c-.092-.043-.184-.089-.278-.134-.264-.13-.45-.258-.557-.387-.137-.164-.206-.376-.206-.633 0-.342.118-.624.352-.847.24-.223.53-.335.871-.335.503 0 .876.26 1.121.778"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M19.658 33.887v2.255c0 .65.257.976.77.976.514 0 .77-.326.77-.976v-2.255h.908v2.274c0 .316-.039.587-.117.815a1.361 1.361 0 01-.391.554c-.306.266-.697.4-1.17.4-.47 0-.859-.134-1.166-.4a1.384 1.384 0 01-.399-.554c-.076-.183-.113-.454-.113-.815v-2.274h.908z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M23.6651769 37.8158154L24.5725692 37.8158154 24.5725692 31.0028 23.6651769 31.0028z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M27.0705831 34.7337108L27.0705831 37.8152646 26.1631908 37.8152646 26.1631908 34.7337108 25.7762754 34.7337108 25.7762754 33.8869031 26.1631908 33.8869031 26.1631908 32.4466415 27.0705831 32.4466415 27.0705831 33.8869031 27.7769446 33.8869031 27.7769446 34.7337108z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M29.549 35.839c0 .376.1.683.303.92a.99.99 0 00.782.36c.336 0 .607-.117.815-.348.206-.24.31-.544.31-.911 0-.37-.104-.674-.31-.912a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-1.953h.913v3.93h-.913v-.411c-.373.35-.775.524-1.206.524-.542 0-.991-.197-1.347-.59-.352-.4-.528-.9-.528-1.5 0-.59.176-1.08.528-1.472.353-.392.794-.59 1.324-.59.457 0 .867.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M34.917 35.839c0 .376.1.683.302.92a.99.99 0 00.783.36c.336 0 .607-.117.815-.348.206-.24.31-.544.31-.911 0-.37-.104-.674-.31-.912a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-4.837h.913v6.814h-.913v-.411c-.357.35-.761.524-1.213.524a1.73 1.73 0 01-1.34-.59c-.352-.4-.528-.9-.528-1.5 0-.587.176-1.075.528-1.468.35-.396.79-.593 1.32-.593.46 0 .87.188 1.233.564v-3.34z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M40.283 35.839c0 .392.105.704.315.932.215.231.499.347.851.347.354 0 .64-.114.851-.343.212-.228.318-.534.318-.915 0-.383-.106-.687-.318-.916-.214-.231-.498-.347-.85-.347-.348 0-.628.116-.845.347-.214.231-.322.529-.322.895m-.928-.017c0-.567.204-1.049.61-1.447.405-.399.9-.597 1.484-.597.586 0 1.084.2 1.493.6.403.4.604.894.604 1.477 0 .588-.202 1.083-.608 1.48-.41.396-.91.594-1.505.594-.59 0-1.082-.202-1.48-.606-.398-.398-.598-.897-.598-1.5"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M50.528 35.323c-.127-.485-.431-.726-.916-.726a.928.928 0 00-.574.196.894.894 0 00-.204.227c-.056.09-.1.19-.13.303h1.824zm.932.742h-2.816c.024.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.312-.307.496-.598l.767.427a2.99 2.99 0 01-.375.518 1.908 1.908 0 01-.424.355c-.15.095-.312.161-.487.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.411-.566-.356-.378-.533-.881-.533-1.508 0-.62.172-1.124.516-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.371.552.335.366.501.873.501 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M52.865 33.887h.912v.363c.318-.315.674-.472 1.072-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.911v-2.195c0-.387-.054-.656-.161-.803-.105-.152-.296-.226-.573-.226-.301 0-.515.1-.642.297-.123.197-.185.54-.185 1.03v1.896h-.912v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M60.7718769 37.8158154L61.6792692 37.8158154 61.6792692 31.0028 60.7718769 31.0028z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M65.117 31.95l-1.34 1.332-.5-.307 1.021-1.468.82.443zm-1.875 5.866h.907v-3.93h-.907v3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M65.712 33.887h.911v.363c.318-.315.675-.472 1.073-.472.457 0 .814.143 1.07.43.22.246.33.645.33 1.199v2.41h-.911v-2.195c0-.387-.054-.656-.161-.803-.105-.152-.296-.226-.573-.226-.302 0-.515.1-.642.297-.124.197-.186.54-.186 1.03v1.896h-.911v-3.93z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M73.301 35.323c-.127-.485-.43-.726-.916-.726a.928.928 0 00-.574.196.894.894 0 00-.204.227c-.056.09-.1.19-.129.303h1.823zm.932.742h-2.816c.024.322.13.58.314.77a.965.965 0 00.715.283.928.928 0 00.56-.161c.146-.109.312-.307.496-.598l.767.427a2.99 2.99 0 01-.374.518 1.908 1.908 0 01-.425.355c-.15.095-.312.161-.487.205a2.4 2.4 0 01-.57.065c-.585 0-1.056-.189-1.411-.566-.356-.378-.533-.881-.533-1.508 0-.62.172-1.124.516-1.509.347-.378.807-.568 1.38-.568.578 0 1.035.184 1.371.552.335.366.501.873.501 1.521l-.004.214z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M76.382 35.839c0 .376.1.683.303.92a.99.99 0 00.782.36c.336 0 .607-.117.815-.348.206-.24.31-.544.31-.911 0-.37-.104-.674-.31-.912a1.029 1.029 0 00-.807-.351c-.313 0-.573.118-.782.354-.208.24-.311.535-.311.888zm2.153-1.953h.913v3.93h-.913v-.411c-.373.35-.775.524-1.206.524-.543 0-.991-.197-1.347-.59-.352-.4-.528-.9-.528-1.5 0-.59.176-1.08.528-1.472.353-.392.793-.59 1.324-.59.457 0 .867.19 1.23.565v-.456z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M85.0639669 33.5772331L86.0801362 35.8078485 87.1376131 33.5772331 88.0794285 33.5772331 89.1369054 35.8739408 90.2026438 33.5772331 91.8632131 33.5772331 89.6491208 37.8154023 88.6494746 37.8154023 87.5919977 35.4952869 86.58409 37.8154023 85.5679208 37.8154023 83.3951362 33.5772331z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M93.9453962 33.5772331L94.9615654 35.8078485 96.0190423 33.5772331 96.9608577 33.5772331 98.0183346 35.8739408 99.0840731 33.5772331 100.744642 33.5772331 98.53055 37.8154023 97.5309038 37.8154023 96.4734269 35.4952869 95.4655192 37.8154023 94.44935 37.8154023 92.2765654 33.5772331z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M102.826825 33.5772331L103.842995 35.8078485 104.900472 33.5772331 105.842287 33.5772331 106.899764 35.8739408 107.965502 33.5772331 109.626072 33.5772331 107.411979 37.8154023 106.412333 37.8154023 105.354856 35.4952869 104.346948 37.8154023 103.330779 37.8154023 101.157995 33.5772331z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M110.48 37.022a.96.96 0 01.28-.686.93.93 0 01.31-.205.934.934 0 01.374-.075c.134 0 .26.024.378.075a.964.964 0 01.59.89.963.963 0 01-.282.687.934.934 0 01-.308.207.941.941 0 01-.378.074.955.955 0 01-.965-.967"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M116.873 35.08a.9.9 0 00-.306-.502.85.85 0 00-.561-.191c-.232 0-.422.06-.566.181a.83.83 0 00-.277.513h1.71zm-1.751.868c0 .646.303.967.908.967.325 0 .57-.133.736-.397h1.445c-.291.97-1.023 1.455-2.189 1.455-.358 0-.686-.053-.983-.162a2.195 2.195 0 01-.764-.459 2.051 2.051 0 01-.492-.709 2.375 2.375 0 01-.173-.926c0-.353.055-.67.165-.953.11-.285.267-.525.47-.724.205-.197.451-.352.74-.459.29-.107.616-.161.98-.161.357 0 .68.054.966.161.286.107.529.264.727.467.198.204.35.453.454.748.105.296.157.626.157.996v.156h-3.147z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M121.135634 35.51897L119.417234 33.5775085 121.366957 33.5775085 122.093972 34.4532315 122.837511 33.5775085 124.787234 33.5775085 123.027526 35.51897 125.150742 37.8156777 123.167972 37.8156777 122.084334 36.5599238 120.993811 37.8156777 119.02068 37.8156777z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M126.075 37.816h1.496v-4.238h-1.496v4.238zm-.099-5.866a.837.837 0 01.25-.596.87.87 0 01.266-.18.797.797 0 01.328-.067.79.79 0 01.326.067.833.833 0 01.449.45.814.814 0 010 .651.867.867 0 01-.449.452.812.812 0 01-.326.064.819.819 0 01-.595-.246.83.83 0 01-.249-.595z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M131.818 34.668a1.747 1.747 0 00-.81-.215.605.605 0 00-.335.083.251.251 0 00-.128.223c0 .05.007.09.02.12.015.03.045.059.092.086a.814.814 0 00.206.076l.36.081c.413.083.721.23.925.442.204.212.306.483.306.814 0 .248-.05.47-.149.67a1.43 1.43 0 01-.413.499c-.176.135-.39.24-.636.314-.248.074-.52.111-.818.111-.573 0-1.137-.162-1.694-.487l.545-1.058c.42.287.816.43 1.19.43.138 0 .25-.03.338-.09.089-.061.133-.139.133-.232 0-.055-.007-.1-.022-.136a.202.202 0 00-.085-.098.688.688 0 00-.194-.084 4.196 4.196 0 00-.335-.079c-.463-.093-.786-.232-.97-.417-.187-.184-.278-.44-.278-.764 0-.237.044-.45.133-.64a1.31 1.31 0 01.38-.482c.165-.134.365-.234.599-.307.234-.072.494-.108.78-.108.468 0 .923.091 1.363.273l-.503.975z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M133.553 37.022c0-.132.025-.257.075-.374a.93.93 0 01.516-.516.934.934 0 01.375-.076c.133 0 .258.024.377.075a.964.964 0 01.59.89.963.963 0 01-.282.687.934.934 0 01-.308.207.941.941 0 01-.377.074.955.955 0 01-.965-.967"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M136.85 33.577h1.495v.52c.407-.39.864-.586 1.371-.586.628 0 1.107.266 1.437.793.336-.533.816-.801 1.438-.801.197 0 .386.029.562.087.176.057.326.148.454.272a1.3 1.3 0 01.302.485c.074.197.111.436.111.717v2.751h-1.495v-2.088c0-.217-.015-.393-.046-.53a.835.835 0 00-.132-.323.447.447 0 00-.202-.157.773.773 0 00-.248-.041c-.48 0-.719.35-.719 1.05v2.09h-1.495v-2.09a2.85 2.85 0 00-.041-.534.982.982 0 00-.12-.334.4.4 0 00-.203-.17.839.839 0 00-.289-.045.83.83 0 00-.264.041.469.469 0 00-.219.161.888.888 0 00-.149.33c-.036.141-.053.324-.053.552v2.088h-1.496v-4.238z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M147.060479 35.51897L145.342079 33.5775085 147.291802 33.5775085 148.018818 34.4532315 148.762356 33.5775085 150.712079 33.5775085 148.952372 35.51897 151.075587 37.8156777 149.092818 37.8156777 148.009179 36.5599238 146.918656 37.8156777 144.945525 37.8156777z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M154.875619 30.9579123L156.057019 30.9579123 152.653265 39.2456123 151.480127 39.2456123z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M159.544215 32.9573423L159.544215 37.8151269 157.923576 37.8151269 157.923576 32.9573423 156.593468 32.9573423 156.593468 31.5859269 160.874322 31.5859269 160.874322 32.9573423z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M163.403455 37.23737L161.354593 33.5775085 163.088139 33.5775085 164.221347 35.7502931 165.320132 33.5775085 167.038532 33.5775085 163.634778 39.9306315 161.957685 39.9306315z"
            Mask="url(#Mask-6)"
          ></Path>
          <Path
            fill="#FEFEFE"
            d="M172.59 33.669c-.375-.457-.837-.686-1.39-.686a1.686 1.686 0 00-1.205.49 1.722 1.722 0 00-.478 1.22c0 .248.042.477.126.686a1.646 1.646 0 00.89.907c.202.09.423.134.66.134.517 0 .983-.22 1.397-.661v1.917l-.165.057a4.88 4.88 0 01-.694.194 3.374 3.374 0 01-.636.062c-.43 0-.842-.08-1.235-.243a3.215 3.215 0 01-1.041-.686 3.415 3.415 0 01-.725-1.05 3.228 3.228 0 01-.272-1.327 3.211 3.211 0 01.988-2.35c.3-.29.649-.514 1.045-.676a3.26 3.26 0 011.248-.245c.248 0 .49.027.73.08.24.052.493.133.757.243v1.934z"
            Mask="url(#Mask-6)"
          ></Path>
        </G>
      </G>
    </G>
  </G>
</Svg>
);

export default AddonIcon;

