import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Redirect } from 'react-router-dom'

import client from '@sart/client'
import { useContent } from '@sart/content'
import { colors, backgroundColors, radius, typography } from '@sart/styles'

import {
  Text,
  Space,
  Button,
  Subhead,
  HorizontalLineSpacer,
  Anchor,
  StoredPaymentMethod
} from '@sart/components'

import CheckoutSummary from './CheckoutSummary'

export default function CheckoutReview(props) {
  const history = props.history

  const content = useContent()

  const {
    selectedPlan,
    customerDetails,
    cardDetails,
    paymentDone = false
  } = client.cache
  function editCustomerDetails() {
    history.push('/checkout/customer')
  }

  function editPaymentDetails() {
    history.push('/checkout/payment')
  }

  function submitReview() {
    history.push('/checkout/process')
  }

  if (!selectedPlan) return <Redirect to="/checkout" />
  if (!customerDetails) return <Redirect to="/checkout/customer" />
  if (!cardDetails && !paymentDone) return <Redirect to="/checkout/payment" />

  return (
    <View style={{flexDirection:'row'}}>
      <View style={{flex: 0.5}}>
        <Subhead style={styles.demi}>{content.checkout.review}</Subhead>

        <Space height={12} />
        <HorizontalLineSpacer />
        <Space height={12} />

        <View style={styles.personalDetails}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={styles.book}>{customerDetails.firstName + ' ' + customerDetails.lastName}</Text>
            <Text style={styles.book}>{customerDetails.email}</Text>
          </View>

          <Space height={8} />
          <Text style={styles.book}>{customerDetails.address1}</Text>
          <Space height={8} />
          <Text style={styles.book}>{customerDetails.address2}</Text>
          <Space height={8} />
          <Text style={styles.book}>{customerDetails.city}</Text>
          <Space height={8} />
          <Text style={styles.book}>{customerDetails.postcode}</Text>
          <Space height={8} />
          <Anchor
            title={content.forms.editDetails}
            onPress={editCustomerDetails}
            style={styles.book}
          />
        </View>

        <Space height={16}/>
        <Text style={styles.cardLabel}>{content.storedPayment.title}</Text>
        <StoredPaymentMethod
          editable
          payment={cardDetails}
          onEditPayment={editPaymentDetails}
        />

        <Space height={32}/>

        <Button
          title={content.checkout.purchase}
          onPress={submitReview}
          style={{ backgroundColor: colors.exisOrange }}
          titleStyle={styles.bold}
        />
      </View>

      <Space flex={0.1} />

      <CheckoutSummary />
    </View>

  )
}

const styles = StyleSheet.create({
  personalDetails: {},
  wrapper: {
    flexDirection: 'row',
    padding: 12,
    borderRadius: radius.smedium,
    backgroundColor: backgroundColors.subtle
  },
  label: {
    fontSize: 14
  },
  choose: {
    fontSize: 15,
    color: colors.common
  },
  bold: {
    ...typography.bold
  },
  demi: {
    ...typography.demi
  },
  book: {
    ...typography.book
  },
  cardLabel: {
    ...typography.book,
    color: colors.exisCharcoal
  }
})
