import React from 'react'
import Svg, { Path, Rect } from 'svgs'

export default function AmericanExpress(props) {
  return (
    <Svg width={28} height={22} viewBox="0 0 32 24" {...props}>
      <Rect fill="#2AA0D0" width="32" height="24" rx="4" />
      <Path
        d="M22.986 9.343h-4.402c-.183 0-.367.137-.367.309 0-.172-.183-.309-.367-.309h-2.127c-.147 0-.294.069-.33.206l-1.174 2.745-1.468-2.78c-.073-.102-.183-.205-.33-.205h-1.908c-.22 0-.366.137-.366.343v3.225L8.532 9.515a.354.354 0 0 0-.33-.206H6.258a.354.354 0 0 0-.33.206l-2.531 5.147a.383.383 0 0 0 .036.343c.074.103.184.171.294.171h1.577c.147 0 .294-.068.33-.205l.55-1.167h2.018l.514 1.167a.354.354 0 0 0 .33.205h2.935c.22 0 .367-.137.367-.343v-1.956l.917 2.094a.354.354 0 0 0 .33.205h1.137c.147 0 .294-.068.33-.205l.954-2.128v1.99c0 .206.147.343.367.343h1.467c.184 0 .367-.137.367-.308 0 .171.184.308.367.308h4.402c.22 0 .367-.137.367-.343v-1.03c0-.205-.147-.342-.367-.342h-2.568v-.343h2.495c.22 0 .367-.138.367-.343v-1.03c0-.206-.147-.343-.367-.343h-2.495v-.343h2.568c.22 0 .367-.137.367-.343v-1.03c0-.171-.183-.343-.367-.343zM6.882 12.088l.33-.686.33.686h-.66zM29.113 13.77l-1.468-1.476 1.468-1.475a.302.302 0 0 0 .11-.24v-.893a.302.302 0 0 0-.11-.24.344.344 0 0 0-.257-.103h-.99c-.11 0-.22.034-.294.103l-1.284 1.441-1.32-1.407c-.074-.103-.147-.137-.258-.137h-.99c-.22 0-.367.137-.367.343v5.147c0 .206.147.343.367.343h.88c.11 0 .184-.034.257-.102l1.394-1.373 1.394 1.373a.41.41 0 0 0 .257.102h.954c.11 0 .183-.034.257-.102a.302.302 0 0 0 .11-.24v-.824a.302.302 0 0 0-.11-.24zm-5.026-.652v-1.682l.844.824-.844.858z"
        fill="#2AA0D0"
      />
      <Path
        fill="#FFF"
        d="M6.331 12.431h1.761l-.88-1.853zM23.72 14.01l1.724-1.716-1.724-1.716z"
      />
      <Path
        d="M29.406 13.53l-1.247-1.236 1.247-1.235a.788.788 0 0 0 .184-.48v-.893a.657.657 0 0 0-.22-.48.75.75 0 0 0-.514-.206h-.99a.679.679 0 0 0-.55.24l-1.028 1.098L25.26 9.24a.803.803 0 0 0-.55-.24h-.991a.65.65 0 0 0-.367.103.65.65 0 0 0-.367-.103h-4.402a.65.65 0 0 0-.367.103A.65.65 0 0 0 17.85 9h-2.127c-.294 0-.587.172-.697.446l-.88 2.059-1.101-2.093c-.11-.24-.367-.378-.66-.378h-1.872c-.403 0-.733.31-.733.687v1.612l-.917-1.887a.727.727 0 0 0-.66-.412H6.257a.727.727 0 0 0-.66.412l-2.532 5.147c-.11.206-.073.446.037.652a.736.736 0 0 0 .624.309h1.577c.294 0 .55-.172.66-.412l.44-.96h1.542l.403.96c.11.275.367.446.697.446h2.935c.403 0 .734-.309.734-.686v-.24l.22.514c.11.24.367.412.66.412h1.137c.294 0 .55-.171.697-.412l.22-.514v.171c0 .378.33.687.734.687h1.467a.65.65 0 0 0 .367-.103.65.65 0 0 0 .367.103h4.402a.65.65 0 0 0 .367-.103.65.65 0 0 0 .367.103h.88a.84.84 0 0 0 .55-.206l1.138-1.133 1.137 1.133c.147.137.33.206.55.206h.88a.75.75 0 0 0 .514-.206.657.657 0 0 0 .22-.48v-.824a.788.788 0 0 0-.183-.48zM17.85 14.832h-1.467v-3.74l-1.65 3.74h-1.138l-1.614-3.706v3.706H9.046l-.587-1.372H5.965l-.624 1.372H3.763l2.532-5.147h1.944l2.311 4.804V9.686h1.871l1.834 3.466 1.468-3.466h2.127v5.147zm5.136-4.117h-2.934v1.03h2.86v1.029h-2.86v1.029h2.934v1.03h-4.402V9.685h4.402v1.03zm4.99 4.117l-1.688-1.647-1.688 1.647h-.88V9.686h.99l1.578 1.716 1.577-1.716h.99v.892l-1.723 1.716 1.724 1.716v.823h-.88z"
        fill="#FFF"
      />
    </Svg>
  )
}
