import React from 'react'
import Svg, { G, Path, Rect,  } from 'svgs'
import PropTypes from 'prop-types';

import { colors } from '@sart/styles'

// function Menu(props) {
//   const { color, size, ...otherProps } = props;

//   return (
//     <Svg width="24" height="24"  viewBox="0 0 38 24" fill={color} {...otherProps}>
//         <Rect y="4" width="38" height="4" rx="1" />
//         <Rect y="14" width="38" height="4" rx="1" />
//         <Rect y="24" width="38" height="4" rx="1" />
//     </Svg>
//   )
// }

const Menu = (props) => {
  const { color, height, width, ...otherProps } = props;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 12 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G transform="translate(-73 -158)" fill="none" fillRule="evenodd">
        <Rect fill="#C2E7EF" x={25} y={103} width={327} height={96} rx={5} />
        <G fill="#4A4A4A" fillRule="nonzero">
          <Path
            d="M84.26 160.865c0-1.03-.836-1.865-1.866-1.865h-6.529c-1.03 0-1.865.836-1.865 1.865V175c0 1.03.836 1.865 1.865 1.865h6.53c1.029 0 1.865-.836 1.865-1.865v-14.135zm-9.399 1.364h8.538v10.439H74.86v-10.44zm7.537 13.775h-6.536c-.553 0-1.001-.459-1.001-1.011v-1.464h8.538v1.464a1.01 1.01 0 01-1.001 1.011zm1-15.131v.495h-8.537v-.495a1.01 1.01 0 011-1.012h6.537c.552 0 1 .46 1 1.012z"
            stroke="#4A4A4A"
            strokeWidth={0.7}
          />
          <Path d="M80.005 174.354h-1.75a.432.432 0 00-.43.43c0 .237.193.43.43.43h1.75c.237 0 .43-.193.43-.43a.432.432 0 00-.43-.43z" />
        </G>
      </G>
    </Svg>
  );
};

Menu.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Menu.defaultProps = {
  color: colors.feature,
  width: '20',
  height: '24'
};

export default Menu;
