import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import { withRouter, matchPath } from 'react-router-dom'

import { useClient } from '@sart/client'

import { Text } from '@sart/components'
import { ShoppingBag } from '@sart/icons'
import { colors, typography } from '@sart/styles'

function CheckoutLink(props) {
  const { to, history, location } = props

  const { cache } = useClient()

  // HACK: Only buying one product at the time
  const count = cache.selectedPlan ? 1 : 0

  const isActive = matchPath(location.pathname, {
    path: to,
    exact: false,
  })

  function goToCheckout() {
    history.push('/checkout')
  }

  return (
    <TouchableOpacity
      onPress={goToCheckout}
    >
      {count > 0 &&
        <View style={styles.circle}>
          <Text style={styles.text}>
            {count}
          </Text>
        </View>
      }
      <ShoppingBag stroke={isActive ? colors.exisOnTint : colors.exisOnTintTransparentOnOrange} />
    </TouchableOpacity>
  )
}

export default withRouter(CheckoutLink)

const styles = StyleSheet.create({
  circle: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 16,
    left: 16,
    backgroundColor: colors.exisBlue,
    borderRadius: 8
  },
  text: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: '700',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 2,
    textAlign: 'center',
    color: colors.exisOnTint,
    ...typography.demi
  }
})
