import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import {
  Headline,
  Subhead,
  Space,
  Button,
} from '@sart/components'
import { GooglePlay, AppleStore, GuruRelaxed, GuruRelaxedFlipped } from '@sart/icons'
import { useContent } from '@sart/content'
import {
  colors, typography
} from '@sart/styles'
import { testID } from '@sart/utilities'

export default function PortConfirmaton(props) {
  const { history } = props

  const content = useContent()

  return (
    <View style={styles.content}>
      <Space height={40} />
      
      <GuruRelaxedFlipped width={220} height={181} />

      <Space height={24} />

      <Headline style={styles.headerText} {...testID('portConfirmationTitle')}>{content.port.confirmationTitle}</Headline>

      {/* <Space height={24} /> */}

      <Subhead style={styles.centerText}>{content.port.confirmationInstruction}</Subhead>

      <Space height={200} />

      {/* <View style={styles.iconsrow}>
        <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=uk.co.separatereality.mobile.myExis', '_blank')}>
          <GooglePlay />
        </TouchableOpacity>
        <Space width={6} />
        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/us/app/my-exis/id1453851339', '_blank')}>
          <AppleStore />
        </TouchableOpacity>
      </View> */}
      {/* <Button
        title={content.navigation.home}
        onPress={() => history.push('/')}
        style={{ backgroundColor: colors.exisOrange }}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    backgroundColor: colors.exisBlue,
    minHeight: 540,
    paddingTop: 48,
    width: '100%'
  },
  headerText: {
    textAlign: 'center',
    color: colors.black,
    ...typography.bold,
    fontSize: 20
  },
  centerText: {
    textAlign: 'center',
    color: colors.white,
    ...typography.lightbold,
    fontSize: 14
  },
  iconsrow: { 
    flexDirection: 'row', 
    justifyContent: 'center' 
  },
})

