import React from 'react'
import Svg, { Path, Rect } from 'svgs'

export default function PayPal(props) {
  return (
    <Svg width="32" height="24" viewBox="0 0 32 24" {...props}>
      <Rect fill="#FFF" width="32" height="24" rx="4" />
      <Path
        d="M24.42 10.953c.51 0 .956.084 1.245.558.014-.134.024-.391.166-.447l.06-.01h.997c.088 0 .157.077.169.164v.053l-.536 3.588c-.023.134-.115.272-.23.309l-.059.01h-.908c-.116 0-.168-.095-.168-.224 0-.083.037-.262.052-.346-.315.39-.903.664-1.391.664-1.024 0-1.664-.708-1.664-1.797 0-1.322.982-2.522 2.268-2.522zM20.647 9c.971 0 1.822.39 1.822 1.563 0 1.607-.919 2.555-2.41 2.555-.233 0-.796-.119-.923.18l-.022.083-.236 1.618a.243.243 0 01-.15.169l-.06.01H17.59c-.084 0-.152-.078-.167-.168l-.001-.056.85-5.692a.296.296 0 01.229-.255l.06-.007h2.084zm8.568 0c.071 0 .136.054.16.132l.008.063v.017l-.85 5.647c-.01.134-.107.272-.218.309l-.055.01h-.867a.188.188 0 01-.177-.147l-.006-.049.015-.028.85-5.803a.18.18 0 01.122-.143L28.243 9h.971zm-4.557 3.086c-.64 0-1.14.541-1.14 1.228 0 .54.368.837.851.837.63 0 1.14-.514 1.14-1.2.005-.547-.363-.865-.851-.865zm-4.609-1.841h-.326c-.094 0-.157.055-.168.15l-.22 1.49.42-.017c.682 0 1.155-.167 1.155-1.004-.005-.552-.44-.613-.861-.619z"
        fill="#009CDE"
        fillRule="nonzero"
      />
      <Path
        d="M13.805 11.06a.31.31 0 01.262.163l.027.06.578 2.092 1.39-2.176a.305.305 0 01.183-.133l.054-.007h1.008c.09 0 .168.1.168.195 0 .027 0 .048-.008.067l-.018.028-3.344 5.168c-.04.063-.1.11-.167.132l-.07.013H12.86c-.089 0-.168-.078-.168-.179 0-.167.935-1.551 1.077-1.763-.09-.296-1.113-3.399-1.113-3.466a.21.21 0 01.116-.184l.052-.01h.981zm-4.289-.107c.499 0 .94.084 1.244.558.01-.134.019-.391.161-.447l.06-.01h.997c.088 0 .157.077.169.164v.053l-.536 3.588c-.023.134-.115.272-.23.309l-.059.01h-.903c-.115 0-.168-.095-.168-.224 0-.083.026-.262.053-.346-.331.39-.92.664-1.392.664-1.023 0-1.664-.708-1.664-1.797 0-1.322.982-2.522 2.268-2.522zM5.736 9c.971 0 1.822.39 1.822 1.563 0 1.607-.919 2.555-2.42 2.555-.233 0-.797-.119-.923.18l-.022.083-.237 1.484c-.013.134-.112.272-.229.309l-.06.009H2.67c-.087 0-.157-.077-.168-.168v-.055l.861-5.698a.287.287 0 01.217-.255L3.636 9h2.1zm4.011 3.086c-.64 0-1.14.547-1.14 1.228 0 .546.358.837.84.837.647 0 1.156-.52 1.156-1.2 0-.547-.368-.865-.856-.865zm-4.699-1.842h-.246a.168.168 0 00-.168.151l-.226 1.49.43-.017c.578 0 1.024-.083 1.129-.792.12-.748-.325-.832-.919-.832z"
        fill="#003087"
        fillRule="nonzero"
      />
    </Svg>
  )
}
