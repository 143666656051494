import * as React from "react";
import Svg, { G, Polygon } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */
function Tick(props) {
    const { tickColor } = props

    return (
        <Svg
            width="191px"
            height="154px"
            viewBox="0 0 191 154"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <G id="tick-sign-svgrepo-com" fill={tickColor} fillRule="nonzero">
                    <Polygon
                        id="Path"
                        points="161.812494 0 66.312215 95.566162 29.1891797 58.4209946 0 87.6362384 66.3127729 154 191 29.2158022"
                    />
                </G>
            </G>
        </Svg>
    )
};
export default Tick;
