import * as React from "react"
import Svg, { G, Path, Text, TSpan } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const EliotPlanName = (props) => {
  const { name, colour, fontFamily } = props
  const ystart = 26.585
  let nameLines

  if (name) {
    nameLines = name.split("\n")
  }

  return (
    <Svg width={114} height={79} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path
          d="m70.375 65.652-53.1 2.045L.17 14.3 112.029.415l.21 62.012-20.908 1.736-.877 14.139-20.079-12.65ZM101.266 8 10.2 19.013l11.78 42.6 50.7-1.943 11.384 7.172.5-8.019 20.191-1.677L101.266 8Z"
          fill="#000"
          fillRule="nonzero"
        />
        <Path
          fill="#FFF"
          fillRule="nonzero"
          d="m109.694 2.389-.216 56.008-20.551 1.707-.686 11.079-15.731-9.91-51.487 1.986L6.168 15.266z"
        />
        <Text
          transform="rotate(-5 66.668 31.846)"
          fontFamily="AvertaExtraBold"
          fontSize={16}
          fontStyle="italic"
          fontWeight={700}
          fill={colour}
        >
          
          {/* <TSpan inlineSize={100} x={34.536} y={28.585}>
            Pakete atreuaz
          </TSpan> */}
          {(nameLines && nameLines.length) && nameLines.map((part, index) => (
            <TSpan x={59.536} y={(ystart + (15*index))} textAnchor={'middle'}>
              {part}
            </TSpan>
          )


          )}
        </Text>
      </G>
    </Svg>
  )
}

export default EliotPlanName
