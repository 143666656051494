import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { Redirect } from 'react-router-dom'
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Headline,
  Subhead,
  Space,
  TextInputExtended,
  Button,
  Anchor,
  HorizontalLine,
} from '@sart/components'
import {
  SIMCard,
  EliotSIMCard
} from '@sart/icons'
import {
  colors,
  typography
} from '@sart/styles'
import { useContent } from '@sart/content'
import { client, useClient } from '@sart/client'
import { testID } from '@sart/utilities'

import Wrapper from './Wrapper'

import EliotExternalLinkBar from './Plans/EliotExternalLinkBar'

export default function AlreadyHaveSIM(props) {
  const { history } = props

  const content = useContent()
  const { cache } = useClient()
  const [eliotJourney, setEliotJourney] = useState(false)
  
  useEffect(() => {
    getEliotJourney()
  })
  
  async function getEliotJourney() {
    const journey = await AsyncStorage.getItem('eliotJourney')
    setEliotJourney(journey)
  }
  
  const {
    selectedPlan,
    customerDetails,
    cardDetails,
  } = client.cache

  function luhn(simNumber) {
    // Build an array with the digits in the card number
    var digits = simNumber.split('');
    for (var i = 0; i < digits.length; i++) {
      digits[i] = parseInt(digits[i], 10);
    }
    // Run the Luhn algorithm on the array
    var sum = 0;
    var alt = false;
    for (i = digits.length - 1; i >= 0; i--) {
      if (alt) {
        digits[i] *= 2;
        if (digits[i] > 9) {
          digits[i] -= 9;
        }
      }
      sum += digits[i];
      alt = !alt;
    }
    // Check the result
    if (sum % 10 == 0) {
      return (true);
    } else {
      return (false);
    }
  }

  const formik = useFormik({
    initialValues: {
      sim: '',
    },
    validationSchema: Yup.object().shape({
      //20 digits
      // 89334140202011111119 — valid
      // 89334140202011111118 — testing Luhn check
      // 9999999999999999998 — testing regex
      // 89334140200011111113 — testing year check
      //19 digits
      // 8933414020209999954

      sim: Yup.string().trim().required(content.alreadyHaveSim.valid)
        .test('Luhn algorithm check', content.alreadyHaveSim.validSIM,
          val => {
            if (val) {
              return luhn(val);
            } else {
              return false;
            }
          })
        .matches(/^8952140\d{4}\d{7}\d$/, content.alreadyHaveSim.checkSIM)
    }),
    onSubmit: handleSubmit
  })

  async function handleSubmit(values, form) {
    form.setSubmitting(true)

    try {
      client.setCache({
        existingSIM: values.sim
      })
      if (cache.selectedPlan) {
        history.push('/checkout')
      } else if(eliotJourney) {
        history.push('/hol_plans')
      } else {
        history.push('/plans')
      }
    } catch (error) {
      form.setSubmitting(false)
      console.error(error)
    }
  }

  return (
    [
      (eliotJourney ? <EliotExternalLinkBar /> : <View/>),
      <Wrapper>
        <Wrapper style={styles.container}>

          <View style={{ flex: 6 / 12 }}>
            <Headline style={styles.header}>{content.alreadyHaveSim.header}</Headline>
            <Subhead style={styles.subheader}>{content.alreadyHaveSim.subheader}</Subhead>

            <Space height={24} />

            <TextInputExtended
              label={content.alreadyHaveSim.label}
              value={formik.values.sim}
              placeholder={content.alreadyHaveSim.placeholder}
              maxLength={19}
              onChangeText={formik.handleChange('sim')}
              onBlur={formik.handleBlur('sim')}
              error={formik.touched.sim && formik.errors.sim}
              showErrorAndFooter
              footer={
                <>
                  {/* {content.forms.forgotPassword} */}
                  {" "}
                  <Anchor
                    title={content.alreadyHaveSim.getSIM}
                    onPress={() => void history.push('/plans')}
                    style={{ ...typography.book }}
                  />
                </>
              }
              footerStyle={styles.subheader}
              style={{ ...typography.demi }}
              labelStyle={{ ...typography.demi }}
              onSubmitEditing={formik.handleSubmit}
              {...testID('simTextInput')}
            />

            <Space height={24} />
            {cache.eSimAvailable &&
              <View style={styles.eSIMContainer}>
                <Text style={styles.eSIMText}><Text style={styles.asterisk}>**</Text>{content.alreadyHaveSim.eSIM1} <Text style={styles.here} onPress={() => eliotJourney ? history.push('/hol_plans') : history.push('/plans')
  }>{content.alreadyHaveSim.eSIM2}</Text> {content.alreadyHaveSim.eSIM3}</Text>
                <Space height={24} />
              </View>
            }
            
            <Button
              title={content.common.continue}
              titleStyle={{ ...typography.bold }}
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              onPress={formik.handleSubmit}
              style={{ backgroundColor: colors.exisOrange }}
              disabledStyle={{ backgroundColor: colors.exisOnTintTransparentOnOrange }}
              {...testID('simSubmitButton')}
            />
          </View>

          <View style={{ flex: 1 / 12 }} />

          <View style={{ flex: 5 / 12 }}>
            { !eliotJourney &&
              <SIMCard />
            }

            { eliotJourney &&
              <EliotSIMCard />
            }
            <View style={{ flexDirection: 'row' }}>
              <Space width={8} />
              <Text style={styles.simText}>{content.alreadyHaveSim.withoutLetter}</Text>
            </View>
          </View>
        </Wrapper>
        <HorizontalLine height={3} color={colors.exisBlue} />
      </Wrapper>
    ]
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    minHeight: 540,
    paddingTop: 48
  },
  headers: {
    alignItems: 'center',
  },
  header: {
    color: colors.exisBlue,
    ...typography.medium
  },
  subheader: {
    color: colors.trivial,
    ...typography.medium
  },
  simText: {
    ...typography.medium,
    color: colors.exisBlue
  },
  eSIMContainer: {
    width: '85%'
  },
  eSIMText: {
    ...typography.demi, 
    color: colors.black,
    fontSize: 14
  },
  asterisk: {
    color: colors.exisOrange
  },
  here: {
    color: colors.exisBlue,
    textDecorationLine: 'underline'
  }
})

