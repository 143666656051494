import React from 'react'
import PropTypes from 'prop-types'

import Svg, { G, Path } from 'svgs'

import { colors } from '@sart/styles'

const ProductSMS = ({ color, ...rest }) => (
  <Svg
    width="48px"
    height="48px"
    stroke={color}
    fill="none"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 48 48"
    {...rest}
  >
    <G transform="translate(5.000000, 5.000000)">
      <Path d="M35.4275484,12.8749641 C36.4010016,13.4449641 37.0547203,14.5012453 37.0547203,15.7107141 L37.0547203,29.2535578 C37.0547203,31.0668703 35.5842984,32.5372922 33.7709859,32.5372922 L32.0271422,32.5372922 L32.0271422,37.6975734 L26.0127516,32.5372922 L15.8293453,32.5372922 C14.6359078,32.5372922 13.5912047,31.9013859 13.0167516,30.9493078" />
      <Path d="M5.74898438,22.9975406 L3.75576563,22.9975406 C1.6815,22.9975406 0,21.3160406 0,19.241775 L0,3.7555875 C0,1.68132187 1.6815,-0.000178125 3.75576563,-0.000178125 L24.2722031,-0.000178125 C26.3464688,-0.000178125 28.0279687,1.68132187 28.0279687,3.7555875 L28.0279687,19.241775 C28.0279687,21.3160406 26.3464688,22.9975406 24.2722031,22.9975406 L12.6272813,22.9975406 L5.74898438,28.8979313 L5.74898438,22.9975406 Z" />
      <Path fill={color} stroke="none" d="M9.5,11.578125 C9.5,12.72525 8.569,13.65625 7.421875,13.65625 C6.27371094,13.65625 5.34375,12.72525 5.34375,11.578125 C5.34375,10.431 6.27371094,9.5 7.421875,9.5 C8.569,9.5 9.5,10.431 9.5,11.578125" />
      <Path fill={color} stroke="none" d="M16.03125,11.578125 C16.03125,12.72525 15.10025,13.65625 13.953125,13.65625 C12.806,13.65625 11.875,12.72525 11.875,11.578125 C11.875,10.431 12.806,9.5 13.953125,9.5 C15.10025,9.5 16.03125,10.431 16.03125,11.578125" />
      <Path fill={color} stroke="none" d="M22.5625,11.578125 C22.5625,12.72525 21.6315,13.65625 20.484375,13.65625 C19.33725,13.65625 18.40625,12.72525 18.40625,11.578125 C18.40625,10.431 19.33725,9.5 20.484375,9.5 C21.6315,9.5 22.5625,10.431 22.5625,11.578125" />
    </G>
  </Svg>
)

ProductSMS.propTypes = {
  color: PropTypes.string
}

ProductSMS.defaultProps = {
  color: colors.feature
}

export default ProductSMS
