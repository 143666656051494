import React from 'react'
import { View, StyleSheet } from 'react-native'

import {
  Headline,
  Subhead,
  Space,
} from '@sart/components'
import {
  colors, typography
} from '@sart/styles'
import { useContent } from '@sart/content'

import AgentOrUserSignInSwitcher from '../Agent/AgentOrUserSignInSwitcher'

export default function LostSignIn(props) {
  const { history } = props

  const content = useContent()

  return (
    <View style={styles.content}>
      <View style={styles.headers}>
        <Headline style={styles.header}>{content.lost.header}</Headline>
        <Subhead style={styles.subheader}>{content.lost.subheader}</Subhead>
      </View>

      <Space height={24} />

      <AgentOrUserSignInSwitcher
        onLoginSuccess={() => void history.push(`/lost/form`)}
        title={content.login.actionLost}
        submittingTitle={content.login.inProgressLost}
        enabledStyle={styles.enabled}
        disabledStyle={styles.disabled}
        onPressFooter={() => void history.push('/contactus')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    minHeight: 600,
    paddingTop: 60,
  },
  form: {
    width: 336,
  },
  header: {
    textAlign: 'center',
    color: colors.exisBlue,
    ...typography.medium
  },
  subheader: {
    textAlign: 'center',
    color: colors.trivial,
    ...typography.medium
  },
  enabled: {
    backgroundColor: colors.exisOrange
  },
  disabled: {
    backgroundColor: colors.exisOnTintTransparentOnOrange
  }
})

