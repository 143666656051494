import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Redirect } from 'react-router-dom'
import { Headline, Subhead, Space, Button } from '@sart/components'
import { backgroundColors, typography, colors } from '@sart/styles'
import { useContent } from '@sart/content'
import { client } from '@sart/client'
import { GuruMeditation, GuruRelaxed } from '@sart/icons'

import Wrapper from './Wrapper'

export default function About(props) {
  const { history } = props

  const content = useContent()

  const {
    selectedPlan,
    customerDetails,
    cardDetails
  } = client.cache
  
  return (
    <Wrapper wrapperStyle={styles.wrapper} style={styles.container}>
      <View style={styles.content}>
        <View style={styles.top}>
          <View style={styles.text}>
            <Headline style={styles.headerText}>{content.about.header}</Headline>
            <Space height={40} />
            <Subhead style={styles.subheaderText}>{content.about.subheader}</Subhead>
            <Space height={40} />
          </View>
          <Space width={40} />
          <View style={styles.guru}>
            <GuruMeditation height={270} width={200}/>
          </View>
        </View>
        <Button
          style={styles.button}
          title={content.about.buttonText}
          onPress={() => history.push('/plans')}
          titleStyle={{...typography.bold}}
        />
      </View>
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: backgroundColors.exisBlue,
  },
  container: {
    minHeight: 600,
    paddingTop: 150,
    // backgroundColor: 'green'
  },
  top: {
    flexDirection: 'row',
  },
  guru: {
    justifyContent: 'flex-start'
  },
  button: {
    width: 190,
    backgroundColor: colors.exisOrange,
    borderRadius: 15
  },
  content: {
    alignItems: 'center',
  },
  headerText: {
    ...typography.bold,
    fontSize: 70,
    lineHeight: 70
  },
  subheaderText: {
    ...typography.demi,
    color: colors.black,
    // width: 600
  },
  text: {
    // backgroundColor: 'red',
    // alignItems: 'center'
    width: 550
  }
})

