import React from "react";
import Svg, { Path } from "svgs";

export default function Back (props){
  return  (
    <Svg width={12} height={21} {...props}>
        <Path
        d="M10.5 19l-9-8.5 9-8.5"
        stroke="#BCC3CA"
        strokeWidth={3}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </Svg>
  )
}

