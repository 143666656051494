import client from '@sart/client'

export async function getProductImage(displayDetails, imageCategory) {

    if (!displayDetails || !displayDetails.images || displayDetails.images.length === 0) {
      return undefined
    }
    const { links } = displayDetails.images.find(image => image.category === imageCategory);
    
    let link

    if (links && links.length >= 1) {
      //get the first one that has 'SELF'
      for (let imageLink of links) {
        if (imageLink && imageLink.rel === 'self'){
          //just return the first one that matches here
          link =  imageLink
          break
        }
      }
    } else {
      return undefined
    }

    if (!link) {
      return undefined
    }

    let imageResult = await client.retrieveDataFromUrlWithoutTokenRefresh(link.href);

    return imageResult.message
  }

  export async function getWebSafeProductImage(displayDetails, imageCategory) {

    if (!displayDetails || !displayDetails.images || displayDetails.images.length === 0) {
      return undefined
    }
    const { links } = displayDetails.images.find(image => image.category === imageCategory);
    
    let link

    if (links && links.length >= 1) {
      //get the first one that has 'SELF'
      for (let imageLink of links) {
        if (imageLink && imageLink.rel === 'self'){
          //just return the first one that matches here
          link = imageLink
          break
        }
      }
    } else {
      return undefined
    }

    if (!link) {
      return undefined
    }

    let imageResult = await client.retrieveDataFromUrlWithoutTokenRefresh(link.href);

    imageResult = encodeURI(imageResult.message);
    imageResult = imageResult.replaceAll('=', '%3D');
    imageResult = imageResult.replaceAll(':', '%3A');
    imageResult = imageResult.replaceAll('(', '%28');
    imageResult = imageResult.replaceAll(')', '%29');
    imageResult = imageResult.replaceAll(',', '%2C');
    imageResult = imageResult.replaceAll(';', '%3B');

    return imageResult
  }